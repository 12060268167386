import React, { Component } from 'react';
import './index.scss';
import geren from '../../../../../../static/img/geren.png'
import qiye from '../../../../../../static/img/qiye1.png'
import { withRouter } from 'react-router-dom';
import cookie from 'react-cookies';
import request from '../../../../../../static/js/http';
class Caigoutuijian extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseList: [],
    }
  }
  componentDidMount() {
    this.getList()
  }

  getList() {
    request('post', '/api/apply/purchase/list', {
      pageSize: 10,
      pageNum: 1,
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          purchaseList: res.data.list,
          fenye: res.data
        })
      }
    })
  }
  xiangqing(id) {
    this.props.history.push({ pathname: '/home/caigouxiangqing', state: { id } })
  }
  render() {
    const { purchaseList} = this.state
    return (
      <>
      <div className="caigoudating">
        <div className="tuijian">
        采购推荐
        </div>
      <div className="biaodan">
            <table className="listTable">
              <thead className="listThead">
                <tr className="listTr">
                  <th>采购品种</th>
                  <th>采购数量</th>
                  <th>货源地</th>
                  <th>发布人</th>
                  <th>认证情况</th>
                  <th>发布时间</th>
                  <th>详情</th>
                </tr>
              </thead>
              <tbody className="listTbody">
                {
                  purchaseList.map((item) => {
                    return <tr className="TbodyTr" onClick={() => this.xiangqing(item.purchaseId)} key={item.purchaseId}>
                      <td>{item.purchaseClass}</td>
                      <td>{item.purchaseNum}</td>
                      <td>{item.fromword}</td>
                      <td>{item.createby}</td>
                      <td><img src={
                        (() => {
                          switch (item.memeberlevel) {
                            case "0": return geren;
                            case "1": return qiye;
                            case "2": return geren;
                            default: return geren;
                          }
                        }
                        )()}
                        alt="" />
                      </td>
                      <td>{item.createAt}</td>
                      <td className="ckxq">查看详情</td>
                    </tr>
                  })
                }
              </tbody>
            </table>
          </div>
      </div>
      </>
    )
  }
}
export default withRouter(Caigoutuijian)