import React, { Component } from 'react';
import HomeType from '../../../components/HomeType';
import Carousel1 from '../../../components/Carousel1';
import Ranking from './Ranking';
import Quotations from './Quotations';
import Iiem from '../../../components/Item';
// import lun from '../../../static/img/del_home_lun.png';
import lengku from '../../../static/img/lengku.png';
import lengku1 from '../../../static/img/lengku1.png';
import BackTop1 from '../../../components/BackTop1';
import { Link } from 'react-router-dom';
import request from '../../../static/js/http'
import cookie from 'react-cookies';
export default class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lunbo: [],
      wantedList: [],
      leaseList: [],
      merchants: [],
      shopId: ''
    };
  }
  componentDidMount() {
    this.lunbo()
    this.wantedList()
    this.leaseList()
    this.merchants()
  }

  wantedList() {
    request('post', '/api/storage/lease/list', {
      pageSize: 3,
      pageNum: 1,
      statu: 0
    }).then(res => {
      ////console.log(res);
      this.setState({
        wantedList: res.data
      })
    })
  }
  leaseList() {
    request('post', '/api/storage/rent/list', {
      pageSize: 3,
      pageNum: 1,
      statu: 0
    }).then(res => {
      ////console.log(res);
      this.setState({
        leaseList: res.data
      })
    })
  }

  lunbo() {
    request('post', '/api/cms/slide/list', {
      type: 1
    }).then(res => {
      this.setState({
        lunbo: res.data
      })
    })
  }
  //优选商家
  merchants() {
    // let memberId = cookie.load('lj-pc-userInfo').memberId&&''
    request('post', '/api/shop/shop/list', {
      memberId: '',
      pageNum: 1,
      pageSize: 10,
      recommend:1
    }).then(res => {
      ////console.log(res);
      this.setState({
        merchants: res.data.list
      })
    })
  }
  getShopId(shopId) {
    this.setState({
      shopId
    })
  }
  wanted(id) {
    this.props.history.push({ pathname: '/home/qiuzuxiangqing', state: { id } })
  }
  lease(id) {
    this.props.history.push({ pathname: '/home/chuzuxiangqing', state: { id } })
  }
  xiangqing(id) {
    this.props.history.push({ pathname: '/home/details', state: { id } })
  }
  render() {
    const { lunbo, wantedList, leaseList, merchants ,shopId} = this.state;
    ////console.log(shopId);
    ////console.log(leaseList);
    return (
      <div id="index">
        <Carousel1 />
        <HomeType />
        <div className="youzhi">
          <div className="title">优选商家</div>
          <div className="list">
            {
              merchants && merchants.map(a => (
                <Link target="_blank" key={a.shopId} to={'/store/'+ a.shopId}>
                  <div className="item sheng hover">
                    <img src={a.shopLogo} alt=" " />
                    <div>{a.shopName}</div>
                  </div>
                </Link>
              ))
            }
          </div>
        </div>
        <div className="paihang">
          {/* <Ranking /> */}
          <Ranking />
          <Quotations />
        </div>
        <div className="freezer">
          <img className="lengkuimg" src={lengku} alt="冷库出租" />
          <div className="ding">
            <img className="imgname" src={lengku1} alt="冷库出租" />
            <div className="list">
              <div>
                <div className="name">求租冷库</div>
                {
                  wantedList.list && wantedList.list.map(a => (
                    <div key={a.leaseId} onClick={() => { this.wanted(a.leaseId) }} className="item hover">
                      <div className="left">
                        <div className="sheng">{a.title}</div>
                        
                        <div className="sheng" style={{display:'flex'}} dangerouslySetInnerHTML={{__html:` ${'内容：'+a.content}`}}></div>
                        {/* <div >内容：{a.content}</div> */}
                        <div>{a.area}㎡</div>
                      </div>
                      <div className="right">查看详情</div>
                    </div>
                  ))
                }
              </div>
              <div>
                <div className="name">出租冷库</div>
                {
                  leaseList.list && leaseList.list.map(a => (
                    <div key={a.rentId} onClick={() => this.lease(a.rentId)} className="item hover">
                      <div className="left">
                        <div className="sheng">{a.title}</div>
                        <div className="sheng">内容：{a.conent}</div>
                        <div>{a.price}/月</div>
                      </div>
                      <div className="right">查看详情</div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
        <div className="love">
          <div className="title">
            <hr className="hr" />
            <div>猜您喜欢</div>
            <hr className="hr" />
          </div>
          <Iiem />
        </div>
        <BackTop1 />

      </div>
    );
  }
}
