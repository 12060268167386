import React, { Component } from 'react';
import './index.scss';
import shenhezhong from '../../../../static/img/shenhezhong.png'
import { withRouter, Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
class FinancialSubmitInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    return (
      <>
        <div className="breadcrumbW">
          <div className="breadcrumb">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>当前位置</Breadcrumb.Item>
              <Breadcrumb.Item><Link to="/home/financial">金融服务</Link></Breadcrumb.Item>
              <Breadcrumb.Item>信息提交</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="financialSubmitInfo">
          <div className="regulationW">
            <div className="regulation">
              仓库存储辣椒（抵押物）
            </div>
          </div>
          <div className="guaranteeW">
            <div className="guarantee">
              只能抵押处于持仓状态的辣椒
            </div>
          </div>
          <div className="guaranteeTable">
            <table className="listTable">
              <thead className="listThead">
                <tr className="listTr">
                  <th>辣椒品种</th>
                  <th>质检等级</th>
                  <th>现存数量（斤）</th>
                  <th>存储地址</th>
                </tr>
              </thead>
              <tbody className="listTbody">
                <tr className="TbodyTr" >
                  <td>山樱椒</td>
                  <td>优质</td>
                  <td>1000</td>
                  <td>一号仓库，007库位</td>
                </tr>
                <tr className="TbodyTr" >
                  <td>子弹头</td>
                  <td>优质</td>
                  <td>1000</td>
                  <td>一号仓库，007库位</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="examineIng">
            <div className="examineIngPic">
              <img src={shenhezhong} alt="" />
            </div>
            <div className="hint">
              额度正在审批中 ···
            </div>
          </div>
          <div className="callService">
            联系客服
          </div>
          <div className="warmPrompt">
            <span>温馨提示</span>
            <span>保持良好的借还习惯有助于您提升额度</span>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(FinancialSubmitInfo)