import React, { Component } from 'react';
import { withRouter } from "react-router-dom"
import cookie from 'react-cookies'
import { Form, Input, Button, Checkbox, Cascader } from 'antd';
import './index.scss';
import city from '../../../../../static/js/city/city'
import request from '../../../../../static/js/http';
class Biaodan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: city,
      shouhuodi: '',
      fullName: '',
      phone: '',
      address: '',       //详细地址
      province: '',      //省
      city: '',          //市
      area: '',          //区
      default1: 1,        //1-默认，0-非默认
    }
  }




  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.fullName !== nextState.fullName) {
      return false
    }
    if (this.state.phone !== nextState.phone) {
      return false
    }
    if (this.state.address !== nextState.address) {
      return false
    }
    if (this.state.province !== nextState.province) {
      return false
    }
    if (this.state.city !== nextState.city) {
      return false
    }
    if (this.state.area !== nextState.area) {
      return false
    }
    if (this.state.default1 !== nextState.default1) {
      return false
    }
    return true;
  }



  //添加收货地址
  address = () => {
    const { fullName, phone, address, province, city, area, default1 } = this.state
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/member/address/change', {
      memberId,
      fullName,
      phone,
      address,
      province,
      city,
      area,
      default: default1
    }).then(res => {
      ////console.log(res);
    })
  }

  //收货人
  fullName = (e) => {
    let fullName = e.target.value
    this.setState({
      fullName
    })
  }

  // 电话
  phone(e) {
    this.setState({
      phone: e.target.value
    })
    ////console.log(e.target.value);
  }

  //所在地区
  city(e) {
    if (!e[2]) {
      this.setState({
        province: e[0],
        city: e[1],
        area: ''
      })
    } else {
      this.setState({
        province: e[0],
        city: e[1],
        area: e[2]
      })
    }

  }

  //详细地址
  xiangxi(e) {
    this.setState({
      address: e.target.value
    })
    ////console.log(e.target.value);
  }
  //默认地址
  default(e) {
    if (e.target.checked) {
      ////console.log(1);
      this.setState({
        default1: 1
      })
    } else {
      ////console.log(0);
      this.setState({
        default1: 0
      })
    }
  }
  render() {
    const layout = {
      labelCol: {
        span: 2,
      },
      wrapperCol: {
        span: 9,
      },
    };
    const tailLayout = {
      wrapperCol: {
        offset: 2,
        span: 10,
      },
    };

    const Demo = () => {
      const onFinish = (values) => {
        ////console.log('Success:', values);
      };

      const onFinishFailed = (errorInfo) => {
        ////console.log('Failed:', errorInfo);
      };

      return (
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="收货人："
            name="username"
            onChange={(e) => this.fullName(e)}
            rules={[
              {
                message: '请输入姓名!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="电话："
            name="phone"
            onChange={(e) => this.phone(e)}
            rules={[
              {
                message: '请输入手机号!',
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <div className="citydiv">
            <span>所在地区：</span>
            <Cascader className="city" options={this.state.options} onChange={(e) => this.city(e)} placeholder="请选择" />
          </div>
          <div className="xiangxi">
            <span>详细地址：</span>
            <textarea
              onChange={(e) => this.xiangxi(e)}
              name="" id="" cols="66" rows="4" placeholder="请填写街道、门牌号"></textarea>
          </div>
          <Form.Item {...tailLayout} name="remember" valuePropName="checked">
            <Checkbox onChange={(e) => this.default(e)}>设为默认地址</Checkbox>
          </Form.Item>


          <Form.Item {...tailLayout}>
            <Button onClick={() => this.address()} type="primary">
              保存
            </Button>
          </Form.Item>
        </Form>
      );
    };
    return (
      <>
        <div className="biaodan">
          <Demo />
        </div>

      </>
    )
  }
}
export default withRouter(Biaodan)