import React, { Component } from 'react';
import './index.scss';
import { Link, withRouter } from 'react-router-dom';
import { Input, Select, Button, Pagination, Badge } from 'antd';
import { LeftOutlined, RightOutlined, LineChartOutlined, FieldTimeOutlined, ShoppingCartOutlined, BellOutlined } from '@ant-design/icons';
import cookie from 'react-cookies';
import geren from '../../../static/img/geren.png'
import qiye from '../../../static/img/qiye.png'
import loginlogo from '../../../static/img/webljLogo.jpg'
import { Rate } from 'antd';
import request from '../../../static/js/http';
const { Option } = Select;
const { Search } = Input;
class Shopshoucang extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shopList: [],
      shopNum: 0,
      historyList: [],
      content: '',
      fenye: [],
      shopNumList:[]
    }
  }
  componentDidMount() {
    this.shop()
    this.history()
    this.shopNum()
  }
  history() {
    if (!cookie.load('lj-pc-userInfo')) {
      return
    } else {
      let memberId = cookie.load('lj-pc-userInfo').memberId
      ////console.log(memberId);
      request('post', '/api/goods/search/list', {
        memberId,
        type: 1,
      }).then(res => {
        ////console.log(res);
        let shopNum = 0
        res.data.map(item => {
          shopNum += item.list.length
        })
        this.setState({
          historyList: res.data[0].list
        })
      })
    }
  }

  shop() {
    request('post', '/api/shop/shop/list', {
      memberId: !cookie.load('lj-pc-userInfo') ? '' : cookie.load('lj-pc-userInfo').memberId,
      pageNum: 1,
      pageSize: 4,
    }).then(res => {
      ////console.log(res);
      this.setState({
        shopList: res.data.list,
        fenye: res.data
      })
    })
  }

  searchShop() {
    const { content } = this.state
    request('post', '/api/shop/shop/list', {
      memberId: !cookie.load('lj-pc-userInfo') ? '' : cookie.load('lj-pc-userInfo').memberId,
      pageNum: 1,
      pageSize: 4,
      content
    }).then(res => {
      this.setState({
        shopList: res.data.list,
        fenye: res.data
      })
    })
  }
  onSearch = (e) => {
    this.setState({
      content: e
    }, function () {
      this.searchShop()
    })
  }
  handleChange = (value) => {
    ////console.log(`selected ${value}`);
    if (value == '商品') {
      this.props.history.push('/home/search')
    }
  }
  //分页
  pagination = (a, b) => {
    const { content } = this.state
    request('post', '/api/shop/shop/list', {
      memberId: !cookie.load('lj-pc-userInfo') ? '' : cookie.load('lj-pc-userInfo').memberId,
      pageNum: a,
      pageSize: b,
      content
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          shopList: res.data.list,
          fenye: res.data
        })
      }
    })
  }
  //购物车数量
  shopNum = () => {
    if (!cookie.load('lj-pc-userInfo')) {
      request('post', '/api/member/cart/list', {
        memberId: ''
      }).then(res => {
        if (res.code === 0) {
          this.setState({
            shopNumList: res.data
          }, function () {
            let shopNum = 0
            this.state.shopNumList.map(item => {
              shopNum += item.goodsList.length
              this.setState({
                shopNum
              })
            })
          })
        }
      })
    } else {
      let userInfo = cookie.load('lj-pc-userInfo')
      request('post', '/api/member/cart/list', {
        memberId: userInfo.memberId
      }).then(res => {
        if (res.code === 0) {
          this.setState({
            shopNumList: res.data
          }, function () {
            let shopNum = 0
            this.state.shopNumList.map(item => {
              shopNum += item.goodsList.length
              this.setState({
                shopNum
              })
            })
          })
        }
      })
    }
  }
  render() {
    const { shopList, shopNum, historyList, fenye } = this.state
    return (
      <>
        <div className="storeList">
          <div className="homeToplo">
            <img className="login" alt="辣椒开放式物流 线上电子交易中心" src={loginlogo} />
            <div className="hometop">
              <div className="flex1">
                <Search
                  addonBefore={
                    <Select defaultValue="商家" className="select-before" onChange={this.handleChange}>
                      <Option value="商品">商品</Option>
                      <Option value="商家">商家</Option>
                    </Select>
                  }
                  placeholder="请输入搜索内容"
                  onSearch={this.onSearch}
                  enterButton
                />
                <Link to={`/myProfile/Shoppingcat`} target="_blank">
                  <div className="searchright1">
                    <Badge count={shopNum} className="svg">
                      <ShoppingCartOutlined style={{ color: '#B92115' }} />
                    </Badge>
                    <div className="one">
                      <div className="a2" >
                        <div >购物车 </div>
                        <div>查看我的购物车</div>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to={`/home/news`} target="_blank">
                  <div className="searchright1">
                    <Badge className="svg">
                      <BellOutlined style={{ color: '#B92115' }} />
                    </Badge>
                    <div className="one">
                      <div>消息</div>
                      <div>查看最新消息公告</div>
                    </div>
                  </div>
                </Link>
              </div>
              {/* <div className="list">
                {
                  historyList.map(a => <div onClick={() => this.lishi(a.content)} key={a.id}>{a.content}</div>)
                }
              </div> */}
            </div>
          </div>
          <div className="shopList">
            {
              shopList.map(item => {
                return <div key={item.shopId} className="shopItem">
                  <Link target="_blank" to={'/store/' + item.shopId}>
                    <div className="shopItemLeft">
                      <div className="shopLogo">
                        <img src={item.shopLogo} alt="" />
                      </div>
                      <div className="shopInfoRight">
                        <div className="shopInfoName">
                          {item.shopName}
                        </div>
                        <div className="reputation">
                          信誉分：{item.shopStar}
                        </div>
                        <div className="shopItemIdentity">
                          身份认证 <img src={item.shopType == 1 ? qiye : geren} alt="" />
                        </div>
                        <div className="area">
                          所在地区：{item.shopProvince} {item.shopCity}
                        </div>
                        <div className="lei">
                          主营类目：{item.shopMain}
                        </div>
                      </div>
                    </div>
                  </Link>
                  <div className="rule"></div>
                  <div className="shopItemRight">
                    {
                      item.goodsList.map(res => {
                        return <Link target="_blank" key={res.goodsId} to={'/home/details/' + res.goodsId}>
                          <div className="shopItemGoods hover">
                            <img className="img" src={res.goodsImg} alt="辣椒" />
                            <div className="you">
                              <div className="name sheng">{res.goodsName}</div>
                            </div>
                          </div >
                        </Link>
                      })
                    }
                  </div>
                </div>
              })
            }
            {/* {
                shopList.map(item => {
                  return <Link target="_blank" to={'/store/' + item.shopId}>
                    <div className="item111 hover" key={item.shopId}>
                      <img className="img" src={item.shopLogo} alt="辣椒" />
                      <div className="you">
                        <div className="name sheng">{item.shopName}</div>
                      </div>
                    </div >
                  </Link>
                })
              } */}
          </div>
          <Pagination style={{ marginBottom: '57px', textAlign: 'center' }} pageSize='4' current={fenye.pageNum} onChange={this.pagination} defaultCurrent={1} total={fenye.total} />
        </div>
      </>
    )
  }
}
export default withRouter(Shopshoucang)