import React, { Component } from 'react';
import { Input } from 'antd';
import { NavLink } from 'react-router-dom';
import mylogo from '../../static/img/login_logo.png';


import './index.scss';
import { Link } from 'react-router-dom';
const { Search } = Input;
export default class My extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [
        { name: '首页', path: '/my/index', id: 0 },
        { name: '账户设置', path: '/my/index1', id: 2 },
        { name: '我的服务', path: '/my/serve', id: 3 },
        { name: '我的采购', path: '/my/caigou', id: 4 },
        { name: '我的供应', path: '/my/gongying', id: 5 },
        { name: '冷库出租', path: '/my/chuzu', id: 6 },
        { name: '冷库租赁', path: '/my/zuping', id: 7 },
        { name: '我的行情', path: '/my/hangqing', id: 8 },
        { name: '地址管理', path: '/my/dizhi', id: 9 },
        { name: '评价管理', path: '/my/pingjia', id: 10 },
        { name: '意见反馈', path: '/my/yijian', id: 11 },
        { name: '我的足迹', path: '/my/zuji', id: 12 },
        { name: '联系客服', path: '/my/kefu', id: 13 }]
    };
  }

  // eslint-disable-next-line no-console
  onSearch = value => console.log(value)

  render() {
    const { list } = this.state;
    const { children } = this.props;
    ////console.log(children)
    return (
      <div className="my">
        <div className="top">
          <div className="topnei">
            <Link to="/home/index">
              <img className="img" src={mylogo} alt="辣椒开放式物流线上电子交易中心" />
            </Link>

            <div>
              <Link to="/home/search">
                <Search
                  placeholder="请输入搜索内容"
                  allowClear
                  size="large"
                  onSearch={this.onSearch}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="body">
          <div className="left">
            {list.map(res => (
              <NavLink
                to={res.path}
                key={res.id}
                activeClassName="sty"
                className="item "
              >
                {res.name}
              </NavLink>
            ))}
          </div>
          <div className="right">
            {children}
          </div>
        </div>
      </div>
    );
  }
}
