import React, { Component } from 'react';
import {
  Breadcrumb, Radio, Avatar, Checkbox, Table, Input, Button, Modal, Form, Cascader, Space, message
} from 'antd';
import './index.scss';
import di from '../../../static/img/di.png';
import request from '../../../static/js/http';
import city from '../../../static/js/city/city'
import cookie from 'react-cookies';
import { withRouter, Link } from "react-router-dom"
const { TextArea } = Input;
class Confirmorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 1,
      isModalVisible: false, // 弹框显示隐藏
      options: city,
      address: [],
      orderInfo: [],
      orderAddress: [],
      detailList: [],
      isModalVisible1: false,
      fullName: '',
      phone: '',
      addres: '',       //详细地址
      province: '',      //省
      city: '',          //市
      area: '',          //区
      default1: 1,        //1-默认，0-非默认
      addressId: '',
      gouwu: [],
      receipt: [],
      buyerMsg: [],
      columns: [
        { title: '商品信息', dataIndex: 'goodsImg', align: 'center', render: text => <img className="tu" src={text} alt="111" /> },
        { title: '商品名称', dataIndex: 'goodsName', align: 'center' },
        { title: '商品等级', dataIndex: `${props.location.state.data[0].shopId == 0 ? 'productName' : 'goodsProp'}`, align: 'center' },
        {
          title: '数量',
          dataIndex: 'total',
          align: 'center',
          render: (a, b) => (
            <div>
              <div>{a}</div>
              <div>单位：{b.productUnit}</div>
            </div>
          )
        },
        { title: '商品价格', dataIndex: 'price', align: 'center', render: (a, b) => <div>{a}{b.productPrice}</div> },
        { title: '总重(斤)', dataIndex: 'productWeight', align: 'center', render: (a, b) => <div>{a}{b.danwei3}</div> },
        { title: '小计', dataIndex: 'allPrice', align: 'center', render: (a, b) => <div>￥{(b.productPrice * b.total * b.productWeight).toFixed(2)}</div> }
      ],
      isSite: false,
      sitefullName: '',
      sitephone: '',
      siteaddres: '',       //详细地址
      siteprovince: '',      //省
      sitecity: '',          //市
      sitearea: '',          //区
      sitedefault1: 1,        //1-默认，0-非默认
      radioValue: '',
      addressItem: [],
      cityValue: []

    };
  }
  componentDidMount() {
    // this.xiangqing()
    this.address()
    this.gouwuxiadan()

    ////console.log(this.props);
    ////console.log(this.props.location);
  }
  address() {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/member/address/list', {
      memberId
    }).then(res => {
      ////console.log(res);
      this.setState({
        address: res.data
      }, function () {
        this.state.address.map((item, index) => {
          if (item.defaultValue == 1) {

            this.setState({
              radioValue: index,
              receipt: item
            })
          }
        })
      })
    })
  }

  moren(addressId) {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/member/address/default', {
      addressId,
      memberId
    }).then(res => {
      ////console.log(res);
      this.address()
    })
  }
  showAdd() {
    this.setState({
      isSite: true
    })
  }
  siteCancel = () => {
    this.setState({
      isSite: false
    })
  }
  //添加收货地址
  add() {
    const { sitefullName, sitephone, siteaddres, siteprovince, sitecity, sitearea, sitedefault1, addressId } = this.state
    if (sitefullName == '') {
      message.warning('请填写收货人')
    } if (sitephone == '') {
      message.warning('请填写电话')
    } if (siteprovince == '') {
      message.warning('请选择所在地区')
    } if (siteaddres == '') {
      message.warning('请填写详细地址')
    } else {
      request('post', '/api/member/address/add', {
        phone: sitephone,
        fullName: sitefullName,
        province: siteprovince,
        city: sitecity,
        area: sitearea,
        address: siteaddres,
        default: sitedefault1
      }).then(res => {
        if (res.code == 0) {
          message.success(res.msg)
          this.siteCancel()
        } else {
          message.warning(res.msg)
        }
        this.address()
      })
    }
  }
  //所在地区
  sitecity(e) {
    if (!e[2]) {
      this.setState({
        siteprovince: e[0],
        sitecity: e[1],
        sitearea: ''
      })
    } else {
      this.setState({
        siteprovince: e[0],
        sitecity: e[1],
        sitearea: e[2]
      })
    }
  }
  //收货人
  sitefullName = (e) => {
    this.setState({
      sitefullName: e.target.value
    })
  }

  // 电话
  sitephone(v) {
    this.setState({
      sitephone: v.target.value
    })
    ////console.log(v.target.value);
  }

  //详细地址
  siteparticular(e) {
    this.setState({
      siteaddres: e.target.value
    })
    ////console.log(e.target.value);
  }
  //默认地址
  siteregdefault(e) {
    if (e.target.checked) {
      ////console.log(1);
      this.setState({
        siteregdefault: 1
      })
    } else {
      ////console.log(0);
      this.setState({
        default1: 0
      })
    }
  }
  //修改收货地址信息
  xiugai() {
    const { fullName, phone, addres, province, city, area, default1, addressId } = this.state

    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/member/address/change', {
      memberId,
      fullName,
      phone,
      address: addres,
      province,
      city,
      area,
      default: default1,
      addressId
    }).then(res => {
      ////console.log(res);
      if (res.code == 0) {
        message.success(res.msg)
        this.handleCancel()
      } else {
        message.warning(res.msg)
      }
      this.address()
      this.setState({
        isxiugai: false
      })
    })

  }
  //所在地区
  city(e) {

    if (!e[2]) {
      this.setState({
        province: e[0],
        city: e[1],
        area: ''
      })
    } else {
      this.setState({
        province: e[0],
        city: e[1],
        area: e[2]
      })
    }
    this.setState({
      cityValue: e
    })
  }
  //收货人
  fullName = (e) => {
    this.setState({
      fullName: e.target.value
    })
  }

  // 电话
  phone(v) {
    this.setState({
      phone: v.target.value
    })
    ////console.log(v.target.value);
  }

  //详细地址
  particular(e) {
    this.setState({
      addres: e.target.value
    })
    ////console.log(e.target.value);
  }
  //默认地址
  default(e) {
    if (e.target.checked) {
      ////console.log(1);
      this.setState({
        default1: 1
      })
    } else {
      ////console.log(0);
      this.setState({
        default1: 0
      })
    }
  }
  //设为默认地址
  regdefault(addressId) {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/member/address/default', {
      memberId,
      addressId
    }).then(res => {
      ////console.log(res);
      this.address()
    })
  }
  // xiangqing() {
  //   let orderId = this.props.location.state.orderId
  //   ////console.log(orderId);
  //   let memberId = cookie.load('lj-pc-userInfo').memberId
  //   ////console.log(memberId);
  //   request('post', '/api/order/order/info', {
  //     orderId,
  //     memberId
  //   }).then(res => {
  //     if (res.code === 0) {
  //       ////console.log(res);
  //       this.setState({
  //         orderInfo: res.data,
  //         orderAddress: res.data.orderAddress,
  //         detailList: res.data.detailList,
  //       }, function () {
  //         ////console.log(this.state.orderInfo);
  //       })
  //     }
  //   })
  // }

  gouwuxiadan() {
    let gouwu = this.props.location.state
    this.setState({
      gouwu
    })
  }
  // //详情页下单
  // queren = () => {
  //   const { receipt } = this.state
  //   // let orderId = this.props.location.state.orderId
  //   let productId = this.props.location.state.productId
  //   let userInfo = cookie.load('lj-pc-userInfo')
  //   request('post', '/api/order/goods/addOrder', {
  //     memberId: userInfo.memberId,
  //     productId,
  //     addressId: receipt.addressId,
  //     num: this.state.orderInfo.goodsNum,
  //     buyerMsg: ''
  //   }).then(res => {
  //     ////console.log(res);
  //     if (res.code === 0) {
  //       ////console.log(res);
  //       this.setState({
  //         isModalVisible1: true
  //       });
  //     }
  //   })
  // }

  //购物车下单
  tocart() {
    const { receipt, gouwu } = this.state
    console.log(gouwu);
    let userInfo = cookie.load('lj-pc-userInfo')
    ////console.log(this.props.location.state);
    let data = this.props.location.state.data
    //console.log(data);
    let productId = ''
    let num = ''
    data.map(item => {
      item.goodsList.map(itm => {
        productId = itm.productId
        num = itm.total
      })
    })
    //console.log(gouwu);
    if (gouwu.cartArr) {
      if (!receipt.addressId) {
        message.warning('请添加默认地址')
      } else {
        request('post', '/api/order/cart/addCartOrder', {
          memberId: userInfo.memberId,
          addressId: receipt.addressId,
          shopIdList: gouwu.shopArr,
          cartList: gouwu.cartArr,
          buyerMsg: ''
        }).then(res => {
          //console.log(res);
          if (res.code === 0) {
            if (gouwu.data[0].goodsList[0].recomXq == 0 || !gouwu.data[0].goodsList[0].recomXq) {
              let data = res.data
              this.props.history.push({ pathname: '/payment', state: { data, gouwu, receipt } })
            } else {
              this.setState({
                isModalVisible1: true
              });
            }
          }
        })
      }

    } else {
      // let productId = this.props.location.state.productId
      //console.log(productId);
      if (!receipt.addressId) {
        message.warning('请添加默认地址')
      } else {
        let userInfo = cookie.load('lj-pc-userInfo')
        request('post', '/api/order/goods/addOrder', {
          memberId: userInfo.memberId,
          productId,
          addressId: receipt.addressId,
          num,
          buyerMsg: ''
        }).then(res => {
          //console.log(res);
          if (res.code === 0) {
            ////console.log(res);
            if (gouwu.data[0].goodsList[0].recomXq == 0 || !gouwu.data[0].goodsList[0].recomXq) {
              let data = res.data
              this.props.history.push({ pathname: '/payment', state: { data, gouwu, receipt } })
            } else {
              this.setState({
                isModalVisible1: true
              });
            }
          }
        })
      }

    }


  }

  //判断recomXq是包邮就调支付
  payment() {
    this.props.history.push('/payment')
  }

  handleOk1 = () => {
    this.props.history.push({ pathname: '/myProfile/order' })
    this.setState({
      isModalVisible1: false
    });
  };

  handleCancel1 = () => {
    this.setState({
      isModalVisible1: false
    });
  };
  change = e => {
    // eslint-disable-next-line no-console
    console.log('radio checked', e.target.value);
    // this.setState({
    //   value: e.target.value
    // });
  };
  radio = e => {
    console.log(e.target);
    this.setState({
      radioValue: e.target.value
    })
  }
  a = e => {
    console.log(e);
    // e.stopPropagation();
    // e.nativeEvent.stopImmediatePropagation();
    // // eslint-disable-next-line no-console
    console.log('aaaaaaaa');
  }

  handleOk = e => {
    // eslint-disable-next-line no-console
    ////console.log('确定弹框', e);
    this.setState({
      isModalVisible: false
    });
  }

  handleCancel = () => {
    // eslint-disable-next-line no-console
    ////console.log('取消弹框');
    this.setState({
      isModalVisible: false
    });
  }

  xian = (id, item) => {
    const { fullName } = this.state
    // eslint-disable-next-line no-console
    ////console.log('显示弹框', a);
    this.setState({
      isModalVisible: true,
      addressId: id,
      fullName: item.fullName,
      phone: item.phone,
      province: item.province,
      city: item.city,
      area: item.area,
      addres: item.address,
    });
    console.log(item);

  }

  onFinish = values => {
    // eslint-disable-next-line no-console
    ////console.log('Success:', values);
  };

  onFinishFailed = errorInfo => {
    // eslint-disable-next-line no-console
    ////console.log('Failed:', errorInfo);
  };

  value(e, name, i) {
    //console.log(e.target.value+"+++++"+name);
    this.setState({
      [name]: e.target.value
    }, function (params) {
      // let a = name + i
      // //console.log(a);
      // const {} = this.state
      // //console.log(`${name+i}`);
      // //console.log(this.state);
      // // let namee = 'name' +'0'
      // //console.log(this.state.);

    })
  }



  render() {
    //console.log(this.state.gouwu);
    const radioStyle = {
      height: '50px',
      lineHeight: '50px',
      width: '100%',
      background: ' #F9F9F9',
      border: '1px solid #EEEEEE',
      marginBottom: '10px',
      padding: '0 20px',
      display: 'flex',
      alignItems: 'center'
    };
    const tailLayout = {
      wrapperCol: {
        offset: 6,
        span: 10,
      },
    };
    const {
      receipt, gouwu, columns, data, address, isModalVisible, residences, orderInfo, orderAddress, detailList, addressItem
    } = this.state;
    console.log([this.state.province, this.state.city, this.state.area]);
    ////console.log(gouwu.data == undefined);
    ////console.log(gouwu.data);
    console.log([this.state.siteprovince, this.state.sitecity, this.state.sitearea]);
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 }
    };
    let zong = 0
    gouwu.data && gouwu.data.map(item => {
      let money = 0;
      item.goodsList.map(it => {
        it.money = it.productWeight * it.productPrice * it.total
        money += it.money
        zong += it.productWeight * it.productPrice * it.total
      })
      item.money = money
    })

    return (
      <div className="confirmorder">
        <div className="breadcrumb">
          <div className="nei">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>当前位置</Breadcrumb.Item>
              <Breadcrumb.Item>消息列表</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="body">
          <div className="dizi">
            <div className="top">
              <div>确认收货地址</div>
              <div className="guanli" onClick={() => this.showAdd()}>新增收货地址</div>
            </div>
            <div className="ul">
              <Radio.Group onChange={(e) => this.change(e)} value={this.state.radioValue}>
                {
                  address && address.map((a, i) => (
                    <Radio key={a.addressId} style={radioStyle} onChange={(e) => this.radio(e)} value={i} >
                      <div className="li" onClick={e => this.a(e)}>
                        <div className="di">
                          <img style={{ opacity: a.defaultValue === 1 ? '1' : '0' }} src={di} alt="默认" />
                        </div>
                        <div className="nei">
                          <span>{a.fullName}</span>
                          <span>{a.phone}</span>
                          <span>
                            <span>{a.province}</span>
                            <span>{a.city}</span>
                            <span>{a.area}</span>
                            <span>{a.address}</span>
                          </span>
                        </div>
                        <div className="xiugai" onClick={() => this.xian(a.addressId, a)}>修改地址</div>
                        <div className="flex1" />
                        <div className="moren" onClick={() => this.moren(a.addressId)}>设置默认收货地址</div>
                      </div>
                    </Radio>
                  ))
                }
              </Radio.Group>
            </div>
          </div>
          {
            gouwu.data && gouwu.data.map((item, index) => (
              <div className="list" key={item.shopId}>
                <div className="tou">
                  <Avatar src={item.shopLogo} />
                  <span>{item.shopName}</span>
                </div>
                <div className="biao">
                  <Table
                    columns={columns}
                    dataSource={item.goodsList}
                    bordered
                    pagination={false}
                  />
                  <div className="bodi">
                    <div className="left">
                      <div>给卖家留言：</div>
                      {/* {
                        [1, 2, 3,].map((v, i) => { */}
                      <TextArea onChange={(e) => this.value(e, `name${index}`, index)} autoSize={false} rows={3} />
                      {/* })
                      } */}

                      {/* <button onClick={() => this.add()}>提交</button> */}
                    </div>
                    <div className="right">
                      <div className="on1">
                        {
                          gouwu.data[0].goodsList[0].cartId ?<div>快递费：包邮</div> :gouwu.data[0].goodsList[0].recomXq == 0 ? <div>快递费：{gouwu.data[0].goodsList[0].recomXq == 0 ? '包邮' : orderInfo.orderPost}</div> : <div className="on2">
                          <div>待协商</div>
                          {/* <div>0.00</div> */}
                        </div>
                          // !gouwu.data[0].goodsList[0].cartId ? gouwu.data[0].goodsList[0].recomXq == 0 ? <div>快递费：{gouwu.data[0].goodsList[0].recomXq == 0 ? '包邮' : orderInfo.orderPost}</div> : <div className="on2">
                          //   <div>待协商</div>
                          //   {/* <div>0.00</div> */}
                          // </div> : <></>

                        }

                        {/* <div>优惠：</div> */}
                      </div>


                    </div>
                  </div>
                  <div className="di">
                    <span>店铺合计：</span>
                    <span>￥{item.money.toFixed(2)}</span>
                  </div>
                </div>
                {
                  ////console.log(item)
                }
              </div>
            ))
          }

          <div className="jie">
            <div>
              <span>实付：</span>
              <span>￥{zong.toFixed(2)}</span>
            </div>
            <div>
              <span>配送至：</span>
              <span>{orderAddress.receiverProvince || receipt.province}</span>
              <span>{orderAddress.receiverCity || receipt.city}</span>
              <span>{orderAddress.receiverArea || receipt.area}</span>
              <span>{orderAddress.receiverAddress || receipt.address}</span>
            </div>
            <div>
              <span>收货人：</span>
              <span>{orderAddress.receiverName || receipt.fullName}</span>
              <span>{orderAddress.receiverPhone || receipt.phone}</span>
            </div>
          </div>
          <div className="anniu">
            <Link to={`/myProfile/Shoppingcat`} target="_blank">
              <Button size="large" >返回购物车</Button>
            </Link>
            {/* gouwu.data[0].goodsList[0].recomXq == 0 ? this.payment(orderAddress.orderId, detailList.productId) : */}
            <Button size="large" onClick={() => this.tocart()} type="primary">确认订单</Button>
          </div>
          <Modal title="提示" okText='确定' cancelText='取消' visible={this.state.isModalVisible1} onOk={this.handleOk1} onCancel={this.handleCancel1}>
            <p>订单已提交给商家，请及时跟商家沟通最终订单价格以及运费</p>
            <p>是否跳转订单列表</p>
          </Modal>
        </div>
        <Modal
          title="修改地址"
          visible={isModalVisible}
          onCancel={this.handleCancel}
          footer={null}
        >
          <>
            <Form
              name="basic"
              initialValues={{ remember: true }}
              // onFinish={() => this.onFinish}
              // onFinishFailed={() => this.onFinishFailed}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...formItemLayout}
            >
              <Form.Item
                label="收货人："
                name="username"
              // rules={[{ required: true, message: '请输入收货人姓名' }]}
              >
                <span>
                  <Input
                    onChange={(e) => this.fullName(e)}
                    value={this.state.fullName}
                    placeholder="请输入收货人姓名" />
                </span>

              </Form.Item>
              <Form.Item
                name="phone"
                label="电话："
                rules={[
                  // {
                  //   required: true,
                  //   message: '请输入手机号'
                  // },
                  {
                    pattern: /^1\d{10}$/,
                    message: '不合法的手机号格式!'
                  }
                ]}
              >
                <span>
                  <Input
                    value={this.state.phone}
                    type="number"
                    autoComplete="off"
                    maxLength="11"
                    onChange={(v) => this.phone(v)}
                    placeholder="请输入手机号"
                  />
                </span>

              </Form.Item>
              <Form.Item
                name=""
                label="所在地区："
              // rules={[
              //   { type: 'array', required: true, }
              // ]}
              >
                <span>
                  <Cascader options={this.state.options} key={[this.state.province, this.state.city, this.state.area]} defaultValue={[this.state.province, this.state.city, this.state.area]} onChange={(e) => this.city(e)} placeholder='' />
                </span>

              </Form.Item>
              <Form.Item
                name="Particular "
                label="详细地址："
              // rules={[{ required: true, message: '请输入详细地址' }]}
              >
                <span>
                  <Input.TextArea
                    value={this.state.addres}
                    onChange={(e) => this.particular(e)}
                  />
                </span>

                {/* <textarea onChange={(e)=>this.particular(e)} name="" id="" cols="42" rows="3"></textarea> */}
              </Form.Item>
              <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                <Checkbox onChange={(e) => this.regdefault(e)}>设为默认地址</Checkbox>
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                <Button onClick={() => this.xiugai()} type="primary" htmlType="submit">
                  确认修改
                </Button>
              </Form.Item>
            </Form>
          </>
        </Modal>


        <Modal
          title="添加收货地址"
          visible={this.state.isSite}
          onCancel={this.siteCancel}
          footer={null}
        >
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={() => this.onFinish}
            onFinishFailed={() => this.onFinishFailed}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...formItemLayout}
          >
            <Form.Item
              label="收货人："
              name="username"
              rules={[{ required: true, message: '请输入收货人姓名' }]}
            >
              <Input

                onChange={(e) => this.sitefullName(e)}
                placeholder="请输入收货人姓名" />
            </Form.Item>
            <Form.Item
              name="phone"
              label="电话："
              rules={[
                {
                  required: true,
                  message: '请输入手机号'
                }, {
                  pattern: /^1\d{10}$/,
                  message: '不合法的手机号格式!'
                }
              ]}
            >
              <Input
                type="number"
                autoComplete="off"
                maxLength="11"
                onChange={(v) => this.sitephone(v)}
                placeholder="请输入手机号"
              />
            </Form.Item>
            <Form.Item
              name=""
              label="所在地区："
              rules={[
                { type: 'array', }
              ]}
            >
              <Cascader options={this.state.options} onChange={(e) => this.sitecity(e)} placeholder='' />
            </Form.Item>
            <Form.Item
              name="Particular "
              label="详细地址："
              rules={[{ required: true, message: '请输入详细地址' }]}
            >
              <Input.TextArea
                onChange={(e) => this.siteparticular(e)}
              />
              {/* <textarea onChange={(e)=>this.particular(e)} name="" id="" cols="42" rows="3"></textarea> */}
            </Form.Item>
            <Form.Item {...tailLayout} name="remember" valuePropName="checked">
              <Checkbox onChange={(e) => this.siteregdefault(e)}>设为默认地址</Checkbox>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
              <Button onClick={() => this.add()} type="primary" htmlType="submit">
                确认修改
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}
export default withRouter(Confirmorder)