import React, { Component } from 'react';
import './index.scss';
import cookie from 'react-cookies';
import { Tabs } from 'antd';
import * as echarts from 'echarts';
import './china'
import request from '../../../static/js/http';
class Pricedistribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: [
        { name: '昨日', id: 0, type: 0 },
        { name: '7日', id: 1, type: 1 },
        { name: '30日', id: 2, type: 2 }
      ],
      timeIndex:0,
      currentIndex: 2,
      type: 0,
      marketList: [],
      data:'昨日'
    }
  }
  componentDidMount() {
    this.market()
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentIndex } = this.state
    if (currentIndex !== nextProps.currentIndex) {
        this.setState({
          currentIndex: nextProps.currentIndex,
        },function () {
          this.market()
          ////console.log(this.state.currentIndex);
        })
    }
  }

  tab = (type, index) => {
    this.setState({
      timeIndex: index,
      type
    }, function () {
      this.market()
      if (type===0) {
        this.setState({
          data:'昨日'
        })
      }else if (type===1) {
        this.setState({
          data:'7日'
        })
      }else{
        if (type===2) {
          this.setState({
            data:'30日'
          })
        }
      
      }
    })
  }
  market() {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/market/hall/list', {
      memberId,
      type: 1,
      classId: 1
    }).then(res => {
      ////console.log(res);
      this.setState({
        marketList: res.data
      }, function () {
        this.map()
      })
    })
  }
    //地图
  map() {
    const { marketList } = this.state
    window.addEventListener("resize", function () {
      myChart.resize();
    });
    var option = {
      tooltip: {
        formatter: function (params, ticket, callback) {
          return params.seriesName + '<br />' + params.name + '：' + params.value
        }//数据格式化
        // trigger: 'item'
      },
      visualMap: {
        min: marketList.minPrice,   //最小值
        max: marketList.maxPrice,//最大值
        right: '15%',
        top: '45%',
        itemHeight: '100',
        text: ['均价'],//取值范围的文字
        inRange: {
          color: ['#e0ffff', '#006edd']//取值范围的颜色
        },
        // legend.show = true

        show: true,//图注 
        // orient: 'vertical',
        // x: 'left',

      },

      geo: {
        map: 'china',
        roam: false,//不开启缩放和平移
        zoom: 1,//视角缩放比例
        label: {
          normal: {
            show: true,
            fontSize: '10',
            color: 'rgba(0,0,0,0.7)'
          }
        },
        itemStyle: {
          normal: {
            borderColor: 'rgba(0, 0, 0, 0.2)'
          },
          emphasis: {
            areaColor: '#F3B329',//鼠标选择区域颜色
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowBlur: 20,
            borderWidth: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      },
      series: [
        {
          name: '信息量',
          type: 'map',
          geoIndex: 0,
          data: marketList.provinceList.map(item => {
            return item
          })
        }
      ]
    };
    var myChart = echarts.init(document.getElementById('map'));
    myChart.setOption(option);
  }
  render() {
    const { time,timeIndex, currentIndex,marketList,data} = this.state
    return (
      <>
        <div className="pricedistributionW">
          <div className="pricedistribution">
            <div className="timeTab">
            {
                time.map((item, index) => {
                  return <div className={`day ${timeIndex === index ? 'active' : ''}`} onClick={() => this.tab(item.type, index)} key={item.id}>
                    {item.name}
                  </div>
                })
              }
            </div>
            <div className="info">
              <div className="infoItemList">
                <div className="infoItem">
                  <div className="data">
                    <span>{data}均价</span>
                  </div>
                  <div className="price">
                    {marketList.avgPrice}
                </div>
                  <div className="time">

                  </div>
                </div>
                <div className="infoItem">
                  <div className="data">
                    <span>{data}最低 </span>
                  </div>
                  <div className="price">
                  {marketList.minPrice}
                </div>
                  <div className="time">
                  {marketList.minDay}
                </div>
                </div>
                <div className="infoItem">
                  <div className="data">
                    <span>{data}最高 </span>
                  </div>
                  <div className="price">
                  {marketList.maxPrice}
                </div>
                  <div className="time">
                  {marketList.maxDay}
                </div>
                </div>
              </div>
              <div className="infoBottom">
                <div className="updateTime">
                  更新时间:{marketList.nowTime}
              </div>
                <div className="unit">
                  单位:元/斤
              </div>
              </div>
            </div>
            <div id="map" style={{ width: 610, height: 540 }}></div>
          </div>
        </div>
      </>
    )
  }
}
export default Pricedistribution