import React, { Component } from 'react';
import './index.scss';
import { Tabs } from 'antd';
import PriceTrend from './PriceTrend'
import Priceexpectation from './Priceexpectation'
import Pricedistribution from './Pricedistribution'
import request from '../../static/js/http';
class MarketApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 2,
      flg: true,
      varietyList: [],
    }
  }
  componentDidMount() {
    this.variety()
  }
  variety() {
    request('post', '/api/goods/class/sList', {
      classId: 1
    }).then(res => {
      ////console.log(res);
      this.setState({
        varietyList: res.data
      })
    })
  }
  tab = (classId) => {
    ////console.log(classId);
    // this.child.market()
    ////console.log(this);
    this.setState({
      currentIndex: classId
    })
  }
  render() {
    const { varietyList, currentIndex } = this.state
    const { TabPane } = Tabs;
    function callback(key) {
      ////console.log(key);
    }
    const Demo = () => (
      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab="价格走势" key="1">
          <PriceTrend />
        </TabPane>
        <TabPane tab="价格分布" key="2">
          <Pricedistribution />
        </TabPane>
        <TabPane tab="价格预测" key="3">
          <Priceexpectation />
        </TabPane>
      </Tabs>
    );
    return (
      <>
        <div className="marketAppW">
          <div className="marketApp">
            {/* <Demo /> */}
            <div className="marketTab">
              {
                varietyList && varietyList.map((item, index)=>(
                  <div className={`tabName ${currentIndex === item.classId ? 'active' : ''}`} key={item.classId} onClick={() => this.tab(item.classId)}>
                {item.className}
              </div>
                ))
              }
            </div>

            <div className="priceTab">
              <Demo />
            </div>

          </div>
        </div>

      </>
    )
  }
}
export default MarketApp