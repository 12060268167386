import React, { Component } from 'react';
import './index.scss';
import copy from 'copy-to-clipboard';
import { Popconfirm, message, Button, Modal ,Image} from 'antd';
import geren from '../../../../../static/img/geren.png'
import qiye from '../../../../../static/img/qiye1.png'
import { withRouter } from 'react-router-dom';
import request from '../../../../../static/js/http';
import ShopRecommend from './ShopRecommend'
import cookie from 'react-cookies';
class Caigoudetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caigoudetails: [],
      isShowBaojia: false,
      ipt: ''
    }
    this.img = React.createRef();
  }
  componentDidMount() {
    this.caigoudetails()
  }
  caigoudetails() {
    let id = this.props.location.state.id
    request('post', '/api/apply/purchase/selectOne', {
      id
    }).then(res => {
      ////console.log(res);
      this.setState({
        caigoudetails: res.data
      })
    })
  }
  renzheng = () => {
    const { caigoudetails } = this.state
    switch (caigoudetails.memeberlevel) {
      case 0: return geren;
      case 1: return qiye;
      case 2: return geren;
      default: return geren
    }
  }
  shenfen = () => {
    const { caigoudetails } = this.state
    switch (caigoudetails.memeberlevel) {
      case 0: return '个人';
      case 1: return '企业';
      case 2: return '经纪人';
      default: return '个人'
    }
  }
  baojia() {
    const { ipt, caigoudetails } = this.state
    ////console.log(caigoudetails);
   
    ////console.log(cookie.load('lj-pc-userInfo'));
    if (!cookie.load('lj-pc-userInfo')) {
      message.warning('请先登录')
    } else {
      let memberId = cookie.load('lj-pc-userInfo').memberId
      let memberPhone = cookie.load('lj-pc-userInfo').memberPhone
      request('post', '/api/apply/purchase/insertApplyPurchaseReport', {
        phone: memberPhone,
        memberId,
        price: ipt,
        purchaseId: caigoudetails.purchaseId,
        memberphone: caigoudetails.memberphone,
        createBy: caigoudetails.createby,
        level: caigoudetails.purchaseLevel,
        classtype: caigoudetails.purchaseClass
      }).then(res => {
        ////console.log(res);
        if (res.code == 0) {
          message.success(res.msg)
        }
      })
    }

  }

  showBaoJiaModal = () => {

    this.setState({
      isShowBaojia: true
    })
  };

  baoJiaOk = () => {
    this.baojia()
    this.setState({
      isShowBaojia: false
    })
  };

  baoJiaCancel = () => {
    this.setState({
      isShowBaojia: false
    })
  }
  ipt(e) {
    this.setState({
      ipt: e.target.value
    })
  }
  render() {
    const { caigoudetails } = this.state
    let img = eval(caigoudetails.image)
    ////console.log(caigoudetails.image);
    const text = caigoudetails.memberphone
    function confirm() {
      copy(text)
      message.success('复制成功，去📞吧');
    }
    return (
      <>
        <div className="caigouxiangqing">
          <div className="caigouinfo">
            <div className="caigouinfotop">
              采购大厅信息
            </div>
            <div className="caigouinfobody">
              <div className="body-left">
                <div className="caigou-item">
                  <span className="itemtext-lf">标题：</span>
                  <span className="itemtext-lr">{caigoudetails.title}</span>
                </div>
                <div className="caigou-item">
                  <span className="itemtext-lf">采购数量：</span>
                  <span className="itemtext-lr">{caigoudetails.purchaseNum}斤</span>
                </div>
                <div className="caigou-item">
                  <span className="itemtext-lf">价格：</span>
                  <span className="itemtext-lr">{caigoudetails.purchasePrice}</span>
                </div>
                <div className="caigou-item">
                  <span className="itemtext-lf">采购品种：</span>
                  <span className="itemtext-lr">{caigoudetails.purchaseClass}</span>
                </div>
                <div className="caigou-item">
                  <span className="itemtext-lf">等级：</span>
                  <span className="itemtext-lr">{caigoudetails.purchaseLevel}</span>
                </div>
                <div className="caigou-item">
                  <span className="itemtext-lf">收货地：</span>
                  <span className="itemtext-lr">{caigoudetails.go}</span>
                </div>
                <div className="caigou-item">
                  <span className="itemtext-lf">货源地：</span>
                  <span className="itemtext-lr">{caigoudetails.fromword}</span>
                </div>
                <div className="caigou-item">
                  <span className="itemtext-lf">发布时间：</span>
                  <span className="itemtext-lr">{caigoudetails.createAt}</span>
                </div>
                <div className="caigou-item">
                  <span className="itemtext-lf">内容：</span>
                  <span className="itemtext-lr">{caigoudetails.conent}</span>
                </div><br />
                <div className="caigou-item-pic">
                  {
                    img && img.map((item, index) => {
                      return <Image key={index} src={item} alt="" />
                    })

                  }
                </div>
              </div>
              <div className="body-right">
                <div className="baojia" onClick={() => this.showBaoJiaModal()}>立即报价</div>
                <Popconfirm
                  placement="topRight"
                  title={text}
                  onConfirm={confirm}
                  okText="复制"
                  cancelText="取消"

                >
                  <Button>手机号码</Button>

                </Popconfirm>
                <Modal okText='确定' cancelText='取消' title="报价" className="baojiaModal" visible={this.state.isShowBaojia} onOk={this.baoJiaOk} onCancel={this.baoJiaCancel}>
                  单价（元）： <input className="baojiaIpt" onChange={(e) => this.ipt(e)} type="number" />
                </Modal>
                {/* <div className="liaoyiliao">聊一聊</div> */}
              </div>
            </div>
          </div>
          <div className="caigoureninfo">
            <div className="caigourentop">
              采购人信息
            </div>
            <div className="caogourenbody">
              <div className="caigourentouxiang">
                <img src={caigoudetails.memeberavator} alt="" />
              </div>
              <div className="caigourenxiangqing">
                <div className="caigourenxiangqing-item">
                  <span>发布人：</span><span>{caigoudetails.createby}</span><span>({this.shenfen()})</span>
                </div>
                <div className="caigourenxiangqing-item">
                  <span>认证情况：</span><img src={this.renzheng()} alt="" />
                </div>
                <div className="caigourenxiangqing-item">
                  <span>发布数量：</span><span>{caigoudetails.total}条</span>
                </div>
              </div>
            </div>
          </div>
          <div className="shopRecommend">
            <div className="title">
              商品推荐
            </div>
            <ShopRecommend />
          </div>
        </div>

      </>
    )
  }
}
export default withRouter(Caigoudetails)