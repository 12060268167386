import React, { Component } from 'react';
import {
  Form, Row, Col, 
} from 'antd';
import { ExclamationCircleFilled
} from '@ant-design/icons';
import './index.scss';
import request from '../../../../static/js/http'
import cookie from 'react-cookies'
export default class Geren extends Component {
  constructor() {
    super();
    this.state = {
      userInfo: [],
      shopAuth: [],
    };
  }
  componentDidMount() {
    this.userInfo()
  }
  userInfo() {
    let userInfo = cookie.load('lj-pc-userInfo')
    request('post', '/api/member/user/info', {
      memberId: userInfo.memberId
    }).then(res => {
      ////console.log(res);
      this.setState({
        userInfo: res.data,
        shopAuth: res.data.shopAuth
      })
    })
  }
  render() {
    const {shopAuth } = this.state;
    return (
      <div className="geren">
        <Form
          name="normal_login1"
          className="login-form"
          autoComplete="off"
          initialValues={{
            remember: true
          }}
          wrapperCol={{ span: 14 }}
          labelCol={{ span: 6 }}
          size="large"
          id="mobilelogin1"
        >
          <Form.Item
            name="ipo"
            label="手机号"
            rules={[
              {
                required: true,
              }
            ]}

          >
            <div className="div">
              {shopAuth.authPhone}
            </div>
          </Form.Item>
          <Form.Item

            name="username"
            label="姓名"
            rules={[
              {
                required: true,
              }
            ]}
          >
            <div className="div">
              {shopAuth.authName}
            </div>
          </Form.Item>
          <Form.Item
            name="id"
            label="身份证"
            rules={[
              {
                required: true,
              }
            ]}
          >
            <div className="div">
              {shopAuth.authIdcard}
            </div>
          </Form.Item>
          <Form.Item
            label="负责人身份证"
            rules={[{ required: true }]}
            className="tu"
          >
            <Row>
              <Col span={12}>
              <img src={shopAuth.authHead} alt="" />
              </Col>
              <Col span={12}>
              <img src={shopAuth.authTail} alt="" />
              </Col>
            </Row>
            <Row>
              <Col style={{ textAlign: 'left', color: '#C7C7C7' }} span={12}>
                <ExclamationCircleFilled style={{ color: '#D94511', marginRight: '4px' }} /><span>要求：证件文字清晰，建议使用原图；</span>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    );
  }
}
