import React, { Component } from 'react';
import { Breadcrumb } from 'antd';
import './index.scss';
import request from '../../../static/js/http'
export default class Newsitem extends Component {

  constructor(props) {
    super(props);
    this.state = {
      NewsItemInfo:'',
    };
  }
  componentDidMount(){
    this.NewsItemInfo()
  }
  NewsItemInfo=()=>{
    let noticeId = this.props.location.state.noticeId
    request('post','/api/cms/notice/info',{
      noticeId
    }).then(res=>{
      if (res.code===0) {
        this.setState({
          NewsItemInfo:res.data
        })
      }
    })
  }
  

  render() {
    return (
      <div className="news">
        <div className="breadcrumb">
          <div className="nei">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>当前位置</Breadcrumb.Item>
              <Breadcrumb.Item href="/home/news">消息列表</Breadcrumb.Item>
              <Breadcrumb.Item>消息详情</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="list">
          <div className="title">{this.state.NewsItemInfo.noticeTitle}</div>
          <div className="time">{this.state.NewsItemInfo.createAt}  </div>
          <div dangerouslySetInnerHTML={{ __html :`${this.state.NewsItemInfo.noticeContent}` }} />
        </div>
      </div>
    );
  }
}
