import React, { Component, useState } from 'react';
import cookie from 'react-cookies';
import { Pagination } from 'antd';
import './index.scss'
import { withRouter } from 'react-router-dom';
import request from '../../../../static/js/http';
 class Myfuwu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionList: []
    }
  }
  componentDidMount() {
    this.question()
  }
  question() {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/qa/question/myList', {
      memberId,
      pageNum: 1,
      pageSize: 10
    }).then(res => {
      ////console.log(res);
      this.setState({
        questionList: res.data
      })
    })
  }
  
  fenye = (a, b) => {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/order/order/myCommentList', {
      memberId,
      pageNum: a,
      pageSize: b,
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          questionList: res.data
        })
      }
    })
  }
  xiangqing(id) {
    this.props.history.push({ pathname: '/home/questiondetails', state: { id } })
  }
  render() {
    const { questionList } = this.state

    // eval('[http://img.hntyp.com/IMG_320_416@Fo5811KVJV307RClrrGJgTmDJj2_.png, http://img.hntyp.com/IMG_320_416@FvGT8-R1p_wtm04WkMfreXza5ZuR.png]')

    return (
      <>
        <div className="myfuwu">
          {
            questionList.list && questionList.list.map(item => {
              return <div className="myfuwuitem" key={item.questionId}>
                <div className="itemtitle">
                  <span className="wen">问</span>
                  <span className="titletext">{item.questionContent}</span>
                  {
                    // ////console.log(eval(item.questionImg))
                    // ////console.log(item.questionImg)
                    // eval(item.questionImg).map((pic,index) => {
                    //   <img key={index} src={pic} alt="" />
                    // })
                  }

                </div>
                <div className="iteminfo">
                  <div className="infoleft">
                    <div className="infolefttop">
                      <img src={item.memberAvator} alt="" />
                      <span className="name">{item.memberName}</span>
                    </div>
                    <div className="infoleftbottom">
                      <span className="liulan">浏览次数{item.likeNum}次</span>
                      <span className="shijian">（提问时间：{item.createAt}）</span>
                    </div>
                  </div>
                  <div className="inforight">
                    <div className="chakanxiangqing" onClick={()=>this.xiangqing(item.questionId)}>
                      查看详情
                  </div>
                  </div>
                </div>
              </div>
            })
          }
          <Pagination
            pageSize='5'
            current={questionList.pageNum}
            onChange={this.fenye}
            total={questionList.total}
          />
        </div>
      </>
    )
  }
}
export default withRouter(Myfuwu)