import React, { Component } from 'react';
import { Input, Select, Button, Pagination } from 'antd';
import { LeftOutlined, LineChartOutlined, RightOutlined, FieldTimeOutlined } from '@ant-design/icons';
import Carousel1 from '../../../components/Carousel1';
import BackTop1 from '../../../components/BackTop1';
import './index.scss';
import request from '../../../static/js/http';
import { Link } from 'react-router-dom';
const { Option } = Select;
export default class Boutique extends Component {
  constructor(props) {
    super(props);
    this.state = {
      priceTop: 0,
      priceDown: 0,
      pageNum: 1,
      list: [{ name: '不限', id: 0 }, { name: '子弹头1', id: 1 }, { name: '子弹头1', id: 2 }],
      index2: 0, // 切换种类
      optimizationList: [],
      sortType: '',
      zhonglei: [],
      classId: '',
      weightTop: '',
      weightDown: '',
      all: true,
    };
  }

  componentDidMount() {
    this.zhonglei()
    this.all()
  }

  //获取列表
  getList() {
    const { pageNum, zhonglei, classId } = this.state
    console.log(zhonglei);
    request('post', '/api/goods/goods/appIndex', {
      classId: !classId ? zhonglei[0].classId : classId,
      pageNum,
      pageSize: 10,
      lat: 1,
      lon: 1,
      recomSy: 0,
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          optimizationList: res.data
        })
      }
    })
  }
  //种类
  zhonglei() {
    request('post', '/api/goods/class/sList', {
      classId: 1
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          zhonglei: res.data
        }, function (params) {
          // this.getList()
        })
      }
    })
  }
  //全部
  zonghe() {
    this.all()
  }
  all() {
    this.setState({
      all: true,
      index2: '',
      classId: '',
    }, function () {
      const { pageNum } = this.state
      request('post', '/api/goods/goods/appIndex', {
        classId: '',
        pageNum,
        pageSize: 10,
        lat: 1,
        lon: 1,
        recomSy: 0,
      }).then(res => {
        console.log(res);
        if (res.code === 0) {
          this.setState({
            optimizationList: res.data
          })
        }
      })
    })
  }
  //销量
  //0 从高到低
  //1 从小到高
  //null 全部顺序
  salesClick(){
    const {sortType} = this.state
   if (sortType == 0) {
      this.setState({
        sortType: 1
      },function (params) {
        this.sales()
      })
    } else if (sortType == 1) {
      this.setState({
        sortType: 0
      },function (params) {
        this.sales()
      })
    }
  }
  sales = () => {
    const { sortType, pageNum, priceTop, priceDown, classId, zhonglei,all } = this.state
    request('post', '/api/goods/goods/appIndex', {
      classId: all ? '' : classId,
      pageNum,
      pageSize: 10,
      lat: 1,
      lon: 1,
      recomSy: 0,
      sortType,
      priceTop: priceTop,
      priceDown: priceDown,
    }).then(res => {;
      this.setState({
        optimizationList: res.data
      })
    })
  }
  //价格筛选
  jiaqian() {
    ////console.log('价格筛选');
    let { priceTop, priceDown, pageNum, classId, all } = this.state
    request('post', '/api/goods/goods/appIndex', {
      classId: all ? '' : classId,
      pageNum,
      pageSize: 10,
      lat: 1,
      lon: 1,
      recomSy: 0,
      priceTop: priceTop,
      priceDown: priceDown,
    }).then(res => {
      this.setState({
        optimizationList: res.data
      })
    })
  }
  input = (e, name) => {
    // eslint-disable-next-line no-console
    ////console.log('aaa', e, name);
    this.setState({
      [name]: e.target.value
    });
  }

  select1 = e => {
    // eslint-disable-next-line no-console
    ////console.log('aaa', e);
    const { priceTop, priceDown, pageNum, classId, zhonglei ,all} = this.state
    request('post', '/api/goods/goods/appIndex', {
      classId: all ? '' : classId,
      pageNum,
      pageSize: 10,
      lat: 1,
      lon: 1,
      goodsProp: e,
      priceTop,
      priceDown,
      recomSy: 0,
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          optimizationList: res.data
        })
      }
    })
  }
  groupof = (e, name) => {
    this.setState({
      [name]: e.target.value
    }, function (params) {
      this.weight()
    });
  }
  weight() {
    const { classId, weightTop, weightDown, zhonglei ,all} = this.state
    request('post', '/api/goods/goods/appIndex', {
      classId: all ? '' : classId,
      pageNum: 1,
      pageSize: 10,
      lat: 1.22,
      lon: 1.22,
      weightTop,
      weightDown,
      recomSy: 0,

      // goodsProp: goodsProp,
    }).then(res => {
      ////console.log(res);
      this.setState({
        optimizationList: res.data
      })
    })
  }
  add = () => {
    const { pageNum } = this.state;

    this.setState({
      pageNum: pageNum + 1
    }, function () {
      this.getList()
    });
  }

  jian = () => {
    const { pageNum } = this.state;
    this.setState({
      pageNum: pageNum - 1
    }, function () {
      this.getList()
    });
  }

  onChange = pageNum => {
    ////console.log(pageNum);
    // eslint-disable-next-line no-console
    this.setState({
      pageNum
    });
    const { priceTop, priceDown, sortType, classId, zhonglei,all } = this.state
    ////console.log(sortType)
    if (sortType) {
      request('post', '/api/goods/goods/appIndex', {
        classId: all ? '' : classId,
        pageNum,
        pageSize: 10,
        lat: 1,
        lon: 1,
        priceTop,
        priceDown,
        recomSy: 0,
        sortType
      }).then(res => {
        ////console.log(res);
        if (res.code === 0) {
          this.setState({
            optimizationList: res.data
          })
        }
      })
    } else {
      request('post', '/api/goods/goods/appIndex', {
        classId:all ? '' : classId,
        pageNum,
        pageSize: 10,
        lat: 1,
        lon: 1,
        priceTop,
        priceDown,
        recomSy: 0,
      }).then(res => {
        ////console.log(res);
        if (res.code === 0) {
          this.setState({
            optimizationList: res.data
          })
        }
      })
    }
  };

  sta = (name, index, classId) => {
     // 切换类别
     this.setState({
      index2: index,
      all: false
    },function (params) {
      const { priceTop, priceDown, pageNum, zhonglei ,all } = this.state
      request('post', '/api/goods/goods/appIndex', {
        classId: all ? '' : classId,
        pageNum,
        pageSize: 10,
        lat: 1,
        lon: 1,
        goodsName: name,
        priceTop,
        priceDown,
        recomSy: 0,
      }).then(res => {
        ////console.log(res);
        if (res.code === 0) {
          this.setState({
            optimizationList: res.data,
            classId
          })
        }
      })
    });
 
   
  }

  xiangqing(id) {
    this.props.history.push({ pathname: '/home/details', state: { id } })
  }
  render() {
    const {
      pageNum, priceTop, priceDown, index2, list, optimizationList, zhonglei
    } = this.state;
    return (
      <div>
        <Carousel1 />
        <div className="search">
          <div className="searchwai">
            <div className="searchnei">
              <div style={{ width: '43px' }}>品种：</div>
              <div className="dataList">
                <div className={`item hover ${this.state.all ? 'sta' : ''}`} onClick={() => this.all()}>全部商品</div>
                {
                  zhonglei.map((a, index) => (
                    <div
                      className={`item hover ${index2 === index ? 'sta' : ''}`}
                      key={a.classId}
                      onClick={() => this.sta(a.className, index, a.classId,)}
                    >
                      {a.className}
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          <div className="topwai">
            <div className="topnei">
              <div className="o1" onClick={() => this.zonghe()}>全部</div>
              <div className="o1" onClick={() => this.salesClick()}>销量</div>
              <div className="o2">
                <span className="span">价格</span>
                <Input type="number" onChange={e => this.input(e, 'priceTop')} style={{ width: 100, height: 35, textAlign: 'center' }} prefix={<span>￥</span>} />
                <span className="span">-</span>
                <Input type="number"
                  className="site-input-right"
                  onChange={e => this.input(e, 'priceDown')}
                  prefix={<span>￥</span>}
                  style={{
                    width: 100,
                    height: 35,
                    textAlign: 'center'
                  }}
                />
              </div>
              <div className="o3">
                <span className="span">起批量</span>
                {/* defaultValue="lucy" */}
                <Input type="number" onChange={e => this.groupof(e, 'weightTop')} style={{ width: 100, height: 35, textAlign: 'center' }} />
                <span className="span">-</span>
                <Input type="number" onChange={e => this.groupof(e, 'weightDown')} style={{ width: 100, height: 35, textAlign: 'center' }} />
              </div>
              <div className="o3">
                <span className="span">等级</span>
                {/* defaultValue="lucy" */}
                <Select onChange={e => this.select1(e, 'select2')} style={{ width: 120, height: 35 }} allowClear>
                  <Option value="精品">精品</Option>
                  <Option value="优质">优质</Option>
                  <Option value="通货">通货</Option>
                  <Option value="花皮">花皮</Option>
                </Select>
              </div>
              <div className="o5">
                <button onClick={() => this.jiaqian()}>确定</button>
              </div>
              <div className="o4">
                <Button type="primary" disabled={pageNum <= 1} onClick={() => this.jian()} icon={<LeftOutlined />} />
                <span className="span1"><span>{pageNum}</span>/{optimizationList.pages}</span>
                <Button type="primary" disabled={pageNum >= optimizationList.pages} onClick={() => this.add()} icon={<RightOutlined />} />
              </div>
            </div>
          </div>
          <div className="list">
            {
              optimizationList.list && optimizationList.list.map(item => (
                <Link target="_blank" key={item.goodsId} to={'/home/details/' + item.goodsId}>
                  <div className="item111 hover" key={item.goodsId}>
                    <img className="img" src={item.goodsImg} alt="辣椒" />
                    <div className="jia">￥<span>{item.productPrice}</span>元/斤</div>
                    <div className="you">
                      <div className="youpin">优品优选</div>
                      
                      <div className="name sheng">{item.goodsName}</div>
                    </div>
                    <div className="dian">
                      <img className="dianlogo" src={item.shopLogo} alt=" " />
                      <div className="dianright">
                        <div className="sheng">{item.shopName}</div>
                        <div className="sheng">河南省</div>
                      </div>
                    </div>
                    <div className="diandi">
                      <div>
                        <LineChartOutlined className="icon" />
                        <span>{item.goodsSaleNum?item.goodsSaleNum:'0'}</span>
                      </div>
                      <div>
                        <FieldTimeOutlined className="icon" />
                        <span>48小时发货</span>
                      </div>
                    </div>

                  </div >
                </Link>

              ))
            }
          </div>
          <div className="seardi">
            <Pagination
              showQuickJumper
              showSizeChanger={false}
              current={pageNum}
              pageSize={10}
              onChange={(e) => this.onChange(e)}
              defaultCurrent={1}
              total={optimizationList.total}
            />
          </div>
          <BackTop1 />
        </div>
      </div>
    );
  }
}
