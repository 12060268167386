import React, { Component } from 'react';
import './index.scss';
import request from '../../../../static/js/http'
import geren from '../../../../static/img/geren.png'
import qiye from '../../../../static/img/qiye1.png'
import city from '../../../../static/js/city/city'
import { withRouter } from 'react-router-dom';
import Caigou from './Pushpurchase/index'
import cookie from 'react-cookies';
import { Select, Dropdown, Button, message, Space, Table, Tag, Modal, Pagination, Cascader } from 'antd';
import { DownOutlined, CloseCircleOutlined } from '@ant-design/icons';
const { Option } = Select;
class Caigoudating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: city,
      isModalVisible: false,
      purchaseList: [],
      fenye: [],
      varietyList: [], //品种列表
      varietyName: '',  //品种名字
      lvName: '',       //等级名字
      fromword: '',     //期望货源地
      min: '',
      max: '',
      test: [
        { name: 'a' },
        { name: 'b' },
        { name: 'c' },
        { name: 'd' },
      ]
    }
    this.child = React.createRef();
  }
  componentDidMount() {
    this.getList()
    this.varietyList()
  }
  getList() {
    request('post', '/api/apply/purchase/list', {
      pageSize: 10,
      pageNum: 1,
      statu: 0
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          purchaseList: res.data.list,
          fenye: res.data
        })
      }
    })
  }
  //品种下拉
  varietyList() {
    request('post', '/api/goods/class/sList', {
      classId: 1,
    }).then(res => {
      ////console.log(res);
      this.setState({
        varietyList: res.data
      })
    })
  }
  //品种选择
  variety() {
    const { varietyName } = this.state
    ////console.log(varietyName);
    request('post', '/api/apply/purchase/list', {
      pageSize: 10,
      pageNum: 1,
      purchaseClass: this.state.varietyName
    }).then(res => {
      //console.log(res);
      if (res.code === 0) {
        this.setState({
          purchaseList: res.data.list,
          fenye: res.data
        })
      }
    })
  }
  //等级选择
  lvxuanze() {
    const { lvName } = this.state
    ////console.log(lvName);
    request('post', '/api/apply/purchase/list', {
      pageSize: 10,
      pageNum: 1,
      purchaseLevel: this.state.lvName
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          purchaseList: res.data.list,
          fenye: res.data
        })
      }
    })
  }
  //期望地
  qiwangdi() {
    const { fromword } = this.state
    request('post', '/api/apply/purchase/list', {
      pageSize: 10,
      pageNum: 1,
      fromword
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          purchaseList: res.data.list,
          fenye: res.data
        })
      }
    })
  }

  //期望货源地
  city(e) {
    this.setState({
      fromword: e.join("")
    }, function () {
      ////console.log(this.state.fromword);
      this.qiwangdi()
    })
  }

  priceChange(e, name) {
    this.setState({
      [name]: e.target.value
    })
  }

  priceOk() {
    const { min, max } = this.state
    request('post', '/api/apply/purchase/list', {
      pageSize: 10,
      pageNum: 1,
      min,
      max,
      statu: '0'
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          purchaseList: res.data.list,
          fenye: res.data
        })
      }
    })
  }

  fabu = () => {
    if (!cookie.load('lj-pc-userInfo')) {
      message.warning('请先登录')
    } else {
      if (this.child.current.state.title == '') {
        message.warning('请输入标题')
      } else if (this.child.current.state.purchaseClass == "") {
        message.warning('请输入采购品种')
      } else if (this.child.current.state.purchaseNum == "") {
        message.warning('请输入采购数量(斤)')
      } else if (this.child.current.state.purchasePrice == "") {
        message.warning('请输入价格')
      } else if (this.child.current.state.purchaseLevel == "") {
        message.warning('请输入期望货品等级')
      } else if (this.child.current.state.go == "") {
        message.warning('请输入收货地')
      } else if (this.child.current.state.fromword == "") {
        message.warning('请输入期望货源地')
      } else if (this.child.current.state.imagesPath == "") {
        message.warning('请上传图片')
      } else if (this.child.current.state.conent == "") {
        message.warning('请输入内容')
      } else {
        let memberId = cookie.load('lj-pc-userInfo').memberId
        let title = this.child.current.state.title
        request('post', '/api/apply/purchase/insertApplyPurchase', {
          memberId,
          title,
          purchaseNum: this.child.current.state.purchaseNum,
          purchaseClass: this.child.current.state.purchaseClass,
          purchaseLevel: this.child.current.state.purchaseLevel,
          purchasePrice: this.child.current.state.purchasePrice,
          fromword: this.child.current.state.fromword,
          go: this.child.current.state.go,
          imagesPath: this.child.current.state.imagesPath,
          conent: this.child.current.state.conent,
          statu: '0'
        }).then(res => {
          this.getList()
          if (res.code == 0) {
            message.success(res.msg);
            this.setState({
              isModalVisible: false
            })
          } else {
            message.warning(res.msg)
          }
        })
      }
    }
  }

  handleButtonClick(e) {
    message.info('Click on left button.');
    ////console.log('click left button', e);
  }
  //品种下拉
  pinzhongmenu(e) {
    ////console.log('click', e);
    this.setState({
      varietyName: e
    }, function () {
      this.variety()
    })
  }
  //等级下拉
  dengjimenu(e) {
    ////console.log('click', e);
    this.setState({
      lvName: e
    }, function () {
      this.lvxuanze()
    })
  }
  showModal = () => {
    this.setState({
      isModalVisible: true
    })
  };

  handleOk = () => {
    this.fabu()
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false
    })
  };
  pagination = (a, b) => {
    ////console.log(a, b);
    request('post', '/api/apply/purchase/list', {
      pageSize: b,
      pageNum: a,
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          purchaseList: res.data.list,
          fenye: res.data
        })
      }
    })
  }
  renzheng = () => {
    const { caigoudetails } = this.state
    switch (caigoudetails.memeberlevel) {
      case '0': return geren;
      case '1': return qiye;
      case '2': return geren;
      default: return geren
    }
  }
  xiangqing(id) {
    this.props.history.push({ pathname: '/home/caigouxiangqing', state: { id } })
  }

  render() {
    ////console.log(this.props);
    const { purchaseList, fenye, varietyList, varietyName, lvName } = this.state
    // ////console.log(varietyName );
    // const pinzhongmenu = (
    //   <Menu onClick={(e) => this.pinzhongmenu(e)} >
    //     {
    //       varietyList.map(item => {
    //         return <Menu.Item key={item.classId}>
    //           {item.className}
    //         </Menu.Item>
    //       })
    //     }
    //   </Menu>
    // );
    // const dengjimenu = (
    //   <Menu onClick={(e) => this.dengjimenu(e)} icon={<CloseCircleOutlined />} >
    //     <Menu.Item key='1'>
    //       精品
    //     </Menu.Item>
    //     <Menu.Item key='2'>
    //       优质
    //     </Menu.Item>
    //     <Menu.Item key='3'>
    //       通货
    //     </Menu.Item>
    //     <Menu.Item key='4'>
    //       花皮
    //     </Menu.Item>
    //   </Menu>
    // );

    return (
      <>
        <div className="caigoudating">
          <div className="xuanzelanW">
            <div className="xuanzelan">
              <div className="xuanzeleft">
                <div className="pz">
                  <span>品种</span>
                  <Select style={{ width: 120 }} onChange={(e) => this.pinzhongmenu(e)} allowClear>
                    {
                      varietyList.map(item => {
                        return <Option key={item.classId} value={item.className}>{item.className}</Option>
                      })
                    }
                  </Select>
                </div>
                <div className="lv">
                  <span>等级</span>
                  <Select style={{ width: 120 }} onChange={(e) => this.dengjimenu(e)} allowClear>
                    <Option value="精品">精品</Option>
                    <Option value="优质">优质</Option>
                    <Option value="通货">通货</Option>
                    <Option value="花皮">花皮</Option>
                  </Select>
                </div>
                <div className="huoyuan">
                  <span>期望货源地</span>
                  <Cascader options={this.state.options} onChange={(e) => this.city(e)} placeholder="请选择期望货源地" />
                </div>
                <div className="jiage">
                  <span>价格</span>
                  <input type="number" name="min" onChange={(e) => this.priceChange(e, 'min')} />—
              <input type="number" name="max" onChange={(e) => this.priceChange(e, 'max')} />
                </div>
                <button onClick={() => this.priceOk()}>确定</button>
              </div>
              <div className="fabucaigou">
                <Button type="primary" onClick={this.showModal}>
                  发布采购
              </Button>
                <Modal className="caigouModal" title="发布采购" visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}
                  footer={[<Button key="submit" style={{
                    width: "120px",
                    height: '40px',
                    background: '#BD2C21',
                  }} onClick={this.handleOk} type="primary" >
                    确认发布
                </Button>]}>
                  <Caigou ref={this.child} />
                </Modal>
              </div>
            </div>
          </div>
          <div className="biaodan">
            <table className="listTable">
              <thead className="listThead">
                <tr className="listTr">
                  <th>采购品种</th>
                  <th>采购数量</th>
                  <th>货源地</th>
                  <th>发布人</th>
                  <th>认证情况</th>
                  <th>发布时间</th>
                  <th>详情</th>
                </tr>
              </thead>
              <tbody className="listTbody">
                {
                  purchaseList.map((item) => {
                    return <tr className="TbodyTr" onClick={() => this.xiangqing(item.purchaseId)} key={item.purchaseId}>
                      <td>{item.purchaseClass}</td>
                      <td>{item.purchaseNum}</td>
                      <td>{item.fromword}</td>
                      <td>{item.createby}</td>
                      <td><img src={
                        (() => {
                          switch (item.memeberlevel) {
                            case "0": return geren;
                            case "1": return qiye;
                            case "2": return geren;
                            default: return geren;
                          }
                        }
                        )()}
                        alt="" />
                      </td>
                      <td>{item.createAt}</td>
                      <td className="ckxq">查看详情</td>
                    </tr>
                  })
                }
              </tbody>
            </table>
          </div>
          <div className="fenyeqi">
            <Pagination style={{ marginBottom: '57px',marginTop:'100px' }} pageSize='10' current={fenye.pageNum} onChange={this.pagination} defaultCurrent={1} total={fenye.total} />
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(Caigoudating)