import React, { Component } from 'react';
import './index.scss';
import { Link, withRouter } from 'react-router-dom';
import {  Pagination } from 'antd';
import cookie from 'react-cookies';
import geren from '../../../../static/img/geren.png'
import qiye from '../../../../static/img/qiye.png'
import request from '../../../../static/js/http';
import { Rate } from 'antd';
class Shopshoucang extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shoucangList: [],
      fenye:[]
    }
  }
  componentDidMount() {
    this.shop()
  }
  shop() {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    ////console.log(memberId);
    request('post', '/api/shop/shop/collectList', {
      memberId,
      pageNum: 1,
      pageSize: 4,
    }).then(res => {
      ////console.log(res);
      this.setState({
        shoucangList: res.data.list,
        fenye: res.data
      })
    })
  }
      //分页
      pagination = (a, b) => {
        request('post', '/api/shop/shop/collectList', {
          memberId: !cookie.load('lj-pc-userInfo') ? '' : cookie.load('lj-pc-userInfo').memberId,
          pageNum: a,
          pageSize: b,
        }).then(res => {
          ////console.log(res);
          if (res.code === 0) {
            this.setState({
              shoucangList: res.data.list,
              fenye: res.data
            })
          }
        })
      }
  render() {
    const { shoucangList,fenye } = this.state
    return (
      <>
        <div className="shopshoucang">
          <div className="shopList">
            {
              shoucangList.map(item => {
                return <div key={item.shopId} className="shopItem">
                  <Link target="_blank" to={'/store/' + item.shopId}>
                    <div className="shopItemLeft">
                      <div className="shopLogo">
                        <img src={item.shopLogo} alt="" />
                      </div>
                      <div className="shopInfoRight">
                        <div className="shopInfoName">
                          {item.shopName}
                        </div>
                        <div className="reputation">
                          信誉分 <Rate defaultValue={0} />
                        </div>
                        <div className="shopItemIdentity">
                        身份认证 <img src={item.shopType == 1 ? qiye : geren} alt="" />
                    </div>
                        <div className="area">
                          所在地区：{item.shopProvince} {item.shopCity}
                        </div>
                        <div className="lei">
                        主营类目：{item.shopMain}
                    </div>
                      </div>
                    </div>
                  </Link>
                  <div className="rule"></div>
                  <div className="shopItemRight">
                    {
                      item.goodsList.map(res => {
                        return <Link target="_blank" key={res.goodsId} to={'/home/details/' + res.goodsId}>
                          <div className="shopItemGoods hover">
                            <img className="img" src={res.goodsImg} alt="辣椒" />
                            <div className="you">
                              <div className="name sheng">{res.goodsName}</div>
                            </div>
                          </div >
                        </Link>
                      })
                    }
                  </div>
                </div>
              })
            }

           
          </div>
          <Pagination style={{ marginBottom: '57px',textAlign: 'center'}} pageSize='4' current={fenye.pageNum} onChange={this.pagination} defaultCurrent={1} total={fenye.total} />
        </div>
      </>
    )
  }
}
export default withRouter(Shopshoucang)