import React, { Component, useState } from 'react';
import cookie from 'react-cookies';
import './index.scss'
import { withRouter } from 'react-router-dom';
import { Menu, Dropdown, Button, message, Space, Tooltip, Select, Pagination } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import request from '../../../static/js/http';
const { Option } = Select;
class Mypingjia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pingjiaList: [
        { id: "1", pingjia: '好评', dingdan: '51745186414', content: '辣椒非常的漂亮，价格也公道', time: '2020-09-08 12:34', name: '王大锤', shopname: '子弹头', pingfen: '4分' },
        { id: "2", pingjia: '好评', dingdan: '51745186414', content: '辣椒非常的漂亮，价格也公道', time: '2020-09-08 12:34', name: '王大锤', shopname: '子弹头', pingfen: '4分' },
        { id: "3", pingjia: '好评', dingdan: '51745186414', content: '辣椒非常的漂亮，价格也公道', time: '2020-09-08 12:34', name: '王大锤', shopname: '子弹头', pingfen: '4分' },
        { id: "4", pingjia: '好评', dingdan: '51745186414', content: '辣椒非常的漂亮，价格也公道', time: '2020-09-08 12:34', name: '王大锤', shopname: '子弹头', pingfen: '4分' },
        { id: "5", pingjia: '好评', dingdan: '51745186414', content: '辣椒非常的漂亮，价格也公道', time: '2020-09-08 12:34', name: '王大锤', shopname: '子弹头', pingfen: '4分' },
        { id: "6", pingjia: '好评', dingdan: '51745186414', content: '辣椒非常的漂亮，价格也公道', time: '2020-09-08 12:34', name: '王大锤', shopname: '子弹头', pingfen: '4分' },
        { id: "7", pingjia: '好评', dingdan: '51745186414', content: '辣椒非常的漂亮，价格也公道', time: '2020-09-08 12:34', name: '王大锤', shopname: '子弹头', pingfen: '4分' },
      ],
      myCommentList: [],
      commentType: '',
      startAt: '',
      endAt: '',
    }
  }
  componentDidMount() {
    this.myCommentList()
  }
  myCommentList() {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/order/order/myCommentList', {
      memberId,
      pageNum: 1,
      pageSize: 7,
    }).then(res => {
      // console.log(res);
      this.setState({
        myCommentList: res.data
      })
    })
  }
  //筛选评论类型
  getCommentType(commentType) {
    ////console.log(commentType);
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/order/order/myCommentList', {
      memberId,
      pageNum: 1,
      pageSize: 7,
      type: commentType,
    }).then(res => {
      ////console.log(res);
      this.setState({
        commentType
      })
      if (res.code === 0) {
        this.setState({
          myCommentList: res.data
        }, function (params) {
          this.fenye()
        })
      }
    })
  }
  //获取评论Value
  getTime(e, name) {
    ////console.log(e.target.value);
    this.setState({
      [name]: e.target.value
    })
    // const {startAt,endAt}= this.state
    // request('post','/api/order/order/myCommentList',{
    //   memberId,

    // })
  }
  //查询评论
  inquire() {
    const { startAt, endAt } = this.state
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/order/order/myCommentList', {
      memberId,
      startAt,
      endAt,
      pageNum:1,
      pageSize:7
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          myCommentList: res.data
        })
      }
    })
  }
  //重置
  chongzhi(){
    this.setState({
      startAt:'',
      endAt:'',
      commentType:''
    },function (params) {
      this.myCommentList()
    })
  }
  fenye = (a, b) => {
    const { commentType } = this.state
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/order/order/myCommentList', {
      memberId,
      pageNum: a,
      pageSize: b,
      type: commentType
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          myCommentList: res.data
        })
      }
    })
  }
  contentMenuClick(e) {
    ////console.log('click', e);
  }
  typeMenuClick(e) {
    ////console.log('click', e);
  }
  onChange(pageNumber) {
    ////console.log('Page: ', pageNumber);
  }
  xiangqing(item) {
    this.props.history.push({ pathname: '/myProfile/Pingjiaxiangqing', state: { item } })
  }
  render() {
    const { myCommentList } = this.state
    return (
      <>
        <div className="mypingjia">
          <div className="pingjiatop">
            <div className="title">
              评价管理
          </div>
            <div className="shaixuan">
              <div className="shaixuanleft">
                <div className="content">
                  类型：
                <Select onChange={e => this.getCommentType(e)} value={this.state.commentType} style={{ width: 120, height: 35 }} allowClear>
                    <Option value="2">好评</Option>
                    <Option value="1">中评</Option>
                    <Option value="0">差评</Option>
                  </Select>
                </div>
                <div className="time">
                  评论时间：
                <input type="text" onChange={(e) => this.getTime(e, 'startAt')} value={this.state.startAt} placeholder='例如:1970-01-01' />
                  <span className="heng">—</span>
                  <input type="text" onChange={(e) => this.getTime(e, 'endAt')} value={this.state.endAt} placeholder='例如:1970-01-01' />
                </div>
              </div>
              <div className="shaixuanright">
                <div onClick={()=>this.inquire()} className="chaxun">
                  查询
                </div>
                <div onClick={()=>this.chongzhi()} className="chongzhi">
                  重置
                </div>
              </div>
            </div>
          </div>
          <div className="biaodan">
            <table className="listTable">
              <thead className="listThead">
                <tr className="listTr">
                  <th>评价</th>
                  {/* <th>订单编号</th> */}
                  <th>评论内容</th>
                  <th>评论时间</th>
                  <th>评论人</th>
                  <th>商品名称</th>
                  <th>评分</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="listTbody">
                {
                  myCommentList.list && myCommentList.list.map(item => {
                    return <tr className="TbodyTr" key={item.commentId}>
                      <td>{item.star<3?'差评':(item.star>3?'好评':'中评')}</td>
                      {/* <td>{item.dingdan}</td> */}
                      <td>{item.content}</td>
                      <td>{item.createAt}</td>
                      <td>{item.memberName}</td>
                      <td>{item.goodsName}</td>
                      <td>{item.star}</td>
                      <td>
                        {/* <div className="liaoyiliao">
                          聊一聊
                      </div> */}
                        <div className="xiangqing" onClick={() => this.xiangqing(item)}>
                          查看详情
                      </div>
                      </td>
                    </tr>
                  })
                }

              </tbody>
            </table>

          </div>
          <Pagination
            pageSize='7'
            current={myCommentList.pageNum}
            onChange={this.fenye}
            total={myCommentList.total}
          />
        </div>
      </>
    )
  }
}
export default withRouter(Mypingjia)