import React, { Component } from 'react';
import { Modal, Button, Space, Checkbox, message } from 'antd';

import './index.scss';
import request from '../../static/js/http'
import cookie from 'react-cookies'
function formatPrice(shopPrice) {
  if (typeof shopPrice !== "number") {
    shopPrice = Number("aaa") || 0
  }
  return "¥" + shopPrice.toFixed(2)
}

export default class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      selectedall: false,
      fields: '',
      number: 0,
      // count: 0,
      xuanze: [],
      xuanchecked: 0,
      isjsbg: false,
      shopList: [],
      shopNum: 0,
      removeModal: false, //删除弹框
      goodsList: [], //购物车列表
      cartIds: [],
      itemGoodsList: [],
      shopIdList: [],
      cartList: [],
      goodsnum: 0
    }
  }

  componentDidMount() {
    this.shopList()

  }

  //商品列表
  shopList = () => {
    let userInfo = cookie.load('lj-pc-userInfo')
    request('post', '/api/member/cart/list', {
      memberId: userInfo.memberId
    }).then(res => {
      if (res.code === 0) {
        ////console.log(res);
        let checkall = 'checkall'
        res.data[checkall] = false
        res.data.map(item => {
          let shopCheck = 'shopCheck'
          item[shopCheck] = false
          ////console.log(item);
          this.setState({
            itemGoodsList: item
          })
          item.goodsList.map(ite => {
            let itemCheck = 'itemCheck'
            ite[itemCheck] = false
          })
        })
        this.setState({
          shopList: res.data
        }, function () {
          let shopNum = 0
          this.state.shopList.map(item => {
            shopNum += item.goodsList.length
            this.setState({
              shopNum
            })
          })
        })
      }
    })
  }

  //实现全选
  check_all() {
    const { shopList } = this.state
    let carts = []
    let shopIds = []
    shopList.checkall = !shopList.checkall
    shopList.forEach(item => {
      ////console.log(item);
      shopIds.push(item.shopId)
      item.shopCheck = shopList.checkall;
      item.goodsList.forEach((item2, i) => {
        ////console.log(item2);
        item2.itemCheck = shopList.checkall;
        carts.push(item2.cartId)
      });
    })
    var tmp = new Array()
    for (const i in shopIds) {
      tmp[shopIds[i]] = 1;
    }
    var tmparr = new Array();
    for (var z in tmp) {
      tmparr.push(z);
    }
    this.setState({
      cartList: carts,
      shopIdList: tmparr
    }, function (params) {
    })
    this.setState({
      shopList
    }, function (params) {
      if (shopList.checkall) {
        this.setState({
          isjsbg: true
        })
      } else {
        this.setState({
          isjsbg: false
        })
      }
    })
  }
  // 判断店铺是否全部选中，全部选中的话，全选checkall = true；反之that.checkall = false
  abc() {
    const { shopList } = this.state
    //   过滤返回选中的店铺
    var aaa = shopList.filter(item => {
      return item.shopCheck == true;
    });

    aaa.length == shopList.length
      ? (shopList.checkall = true)
      : (shopList.checkall = false);

    this.setState({
      shopList
    }, function (params) {
    })
  }
  //  点击店铺全选，则该店铺的商品都选中
  shopcheck(i) {
    const { shopList, goodsnum } = this.state
    let carts = []
    let shopIds = []
    shopList[i].shopCheck = !shopList[i].shopCheck
    shopList[i].goodsList.forEach(item => {
      carts.push(item.cartId)
      shopIds.push(shopList[i].shopId)
      item.itemCheck = shopList[i].shopCheck

    })
    let shopArr = []
    for (let i = 0; i < shopList.length; i++) {
      if (shopList[i].shopCheck) {
        shopArr.push(shopList[i].shopId)
      }
    }
    if (shopArr.length > 0) {
      this.setState({
        isjsbg: true
      })
    } else {
      this.setState({
        isjsbg: false
      })
    }

    var tmp = new Array()
    for (const i in shopIds) {
      tmp[shopIds[i]] = 1;
    }
    var tmparr = new Array();
    for (var z in tmp) {
      tmparr.push(z);
    }
    this.setState({
      cartList: carts,
      shopIdList: tmparr
    })
    this.abc();
    this.setState({
      shopList
    })
  }
  //  判断某个店铺的商品是否全部选中，全部选中的话该店铺checkBox中的this_all = true，反之this_all = false
  checkOne(i, j, index) {
    const { shopList, cartList, shopIdList } = this.state
    let length = 0;
    let length2 = 0;
    let carts = []
    let shopIds = []
    shopList[index].goodsList[i].itemCheck = !shopList[index].goodsList[i].itemCheck
    shopList[index].goodsList.forEach(item => {
      if (item.itemCheck) {
        length++
        carts.push(item.cartId)
        shopIds.push(shopList[index].shopId)
      }
    })
    var tmp = new Array()
    for (const i in shopIds) {
      tmp[shopIds[i]] = 1;
    }
    var tmparr = new Array();
    for (var z in tmp) {
      tmparr.push(z);
    }
    this.setState({
      cartList: carts,
      shopIdList: tmparr
    })
    if (shopList[index].goodsList.length == length) {
      shopList[index].shopCheck = true;
    } else {
      shopList[index].shopCheck = false;
    }
    if (shopList[index].goodsList.length == length) {
      shopList[index].shopCheck = true;
    } else {
      shopList[index].shopCheck = false;
    }
    shopList.forEach(item => {
      if (item.shopCheck) {
        length2++;
      }
    })
    if (shopList.length === length2) {
      shopList.checkall = true
    } else {
      shopList.checkall = false
    }
    if (shopList.length === length2) {
      shopList.checkall = true
    } else {
      shopList.checkall = false
    }
    this.setState({
      shopList
    }, function (params) {
      shopList.map(item => {
        item.goodsList.map(ite => {
          if (ite.itemCheck) {
            this.setState({
              isjsbg: true
            })
          }
        })
      })
    })
    this.jiesuan()
  }

  // 计算总价
  allprice() {
    const { shopList } = this.state
    let price = 0;
    shopList.forEach(item => {
      item.goodsList.forEach(goodsitem => {
        // ////console.log(goodsitem);
        if (goodsitem.itemCheck) {

          price += goodsitem.productPrice * goodsitem.num * goodsitem.productWeight;
        }

      })
    })
    return "￥" + price.toFixed(2);
  }
  //计算总量
  allnum() {
    const { shopList } = this.state
    let num = 0;
    shopList.forEach(item => {
      item.goodsList.forEach(goodsitem => {
        if (goodsitem.itemCheck) {
          num += goodsitem.num;
        }
      })
    })
    return num
  }

  // 删除单个商品
  remove(cartIds) {
    let userInfo = cookie.load('lj-pc-userInfo')
    request('post', '/api/member/cart/remove', {
      memberId: userInfo.memberId,
      cartIds
    }).then(res => {
      if (res.code === 0) {
        ////console.log(res);
        this.shopList()
      }
    })
  }

  //删除弹框
  showRemoveModal = (cartIds) => {
    this.setState({
      removeModal: true,
      cartIds
    })
  };

  removeModalOk = () => {
    let cartIds = this.state.cartIds
    this.remove(cartIds)
    this.setState({
      removeModal: false
    })

  };

  removeModalCancel = () => {
    this.setState({
      removeModal: false
    })
  };

  //选中结算高亮
  jiesuan() {
    const { shopList } = this.state
    shopList.map(item => {
      if (item.shopCheck) {
        this.setState({
          isjsbg: true
        })
      } else {
        this.setState({
          isjsbg: false
        })
      }
      item.goodsList.map(ite => {
        if (ite.itemCheck) {
          this.setState({
            isjsbg: true
          })
        } else {
          this.setState({
            isjsbg: false
          })
        }
      })
    })
  }

  //数量加减
  onAdd(item, count, e) {
    const { shopList } = this.state
    let userInfo = cookie.load('lj-pc-userInfo')
    item.num += count
    item.num = item.num < 1 ? 1 : item.num;
    request('post', '/api/member/cart/addAndSub', {
      cartId: e.target.id,
      memberId: userInfo.memberId,
      num: item.num
    }).then(res => {
        if (res.code != 0) {
          message.warning(res.msg)
          this.shopList()
        }
      this.setState({
        shopList
      })
    })

  }
  // //选删
  delAll = () => {
    let cartIds = ""
    let { shopList } = this.state
    let userInfo = cookie.load('lj-pc-userInfo')
    shopList.map((item, index) => {
      item.goodsList.map(ite => {
        if (ite.itemCheck) {
          cartIds += ite.cartId
          cartIds += ","
        }
      })
    })
    this.shopList()
    request('post', '/api/member/cart/remove', {
      memberId: userInfo.memberId,
      cartIds
    }).then(res => {
      this.shopList()
    })
  }



  qujiesuan = () => {
    const { isjsbg, shopList } = this.state
    let cartArr = []
    let shopArr = []
    for (let i = 0; i < shopList.length; i++) {
      if (shopList[i].shopCheck) {
        shopArr.push(shopList[i].shopId)
      }
      let cart = []
      for (let j = 0; j < shopList[i].goodsList.length; j++) {
        if (shopList[i].goodsList[j].itemCheck) {
          cart.push(shopList[i].goodsList[j].cartId)
          if (!shopList[i].shopCheck) {
            shopArr.push(shopList[i].shopId)
          }
        }
      }
      if (cart.length > 0) {
        cartArr.push(cart.join(","))
      }
    }
    let userInfo = cookie.load('lj-pc-userInfo')
    let zero = shopArr.indexOf('0')
    if (isjsbg) {
      if (shopArr.length > 1) {
        if (zero == -1) {
          request('post', '/api/order/cart/cartCheck', {
            memberId: userInfo.memberId,
            cartList: cartArr,
            shopIdList: shopArr,
          }).then(res => {
            if (res.code == 0) {
              let data = res.data
              this.props.history.push({ pathname: '/home/confirmorder', state: { cartArr, shopArr, data } })
            }
          })
        } else {
          message.warning('农资商品和辣椒商品不能同一结算')
        }
      } else {
        request('post', '/api/order/cart/cartCheck', {
          memberId: userInfo.memberId,
          cartList: cartArr,
          shopIdList: shopArr,
        }).then(res => {
          if (res.code == 0) {
            let data = res.data
            this.props.history.push({ pathname: '/home/confirmorder', state: { cartArr, shopArr, data } })
          }else{
            message.warning(res.msg)
          }
        })
      }
    }
  }
    itemIpu(){}
  //总价
  getTotalPrice() {
    let totalPrice = this.state.goodsList.reduce((pre, item) => {
      if (item.selected) {
        pre += item.productPrice * item.num * item.productWeight
      }
      return pre
    }, 0)
    return formatPrice(totalPrice)
  }
  // //已选商品
  getZongShu() {
    var ZongShu = this.state.goodsList.reduce((zong, item) => {
      if (item.selected) {
        zong += item.num
      }
      return zong
    }, 0)
    this.setState({
      ZongShu
    })
    return ZongShu
  }

  render() {
    const { shopList, shopNum } = this.state
    return (
      <>
        <div className="crumbsW">
          <div className="crumbs">
          </div>
        </div>
        <div className="catAll">
          <div className="catTitle">
            全部商品&nbsp;&nbsp;({shopNum})
          </div>
          <div className="catType">
            <div className="fl checkAll">
              <input type="checkbox" checked={shopList.checkall} onChange={() => {
                this.check_all()
              }} />

              &nbsp;&nbsp;全选
            </div>
            <div className="fl lj-item">
              <div className="td-inner">
                商品信息
              </div>
            </div>
            <div className="fl lj-info">
              <div className="td-inner">
                &nbsp;
              </div>
            </div>
            <div className="fl lj-lv">
              <div className="td-inner">
                商品等级
              </div>
            </div>
            <div className="fl lj-price">
              <div className="td-inner">
                商品价格
              </div>
            </div>
            <div className="fl lj-number">
              <div className="td-inner">
                数量
              </div>
            </div>
            <div className="fl lj-weight">
              <div className="td-inner">
                总重(斤)
              </div>
            </div>
            <div className="fl lj-subtotal">
              <div className="td-inner">
                小计
              </div>
            </div>
            <div className="fl lj-operation">
              <div className="td-inner">
                操作
              </div>
            </div>
          </div>
          {
            this.state.shopList.map((res, index) => (
              <div className="shopItem" key={index}>
                <div className="shopName">
                  <input type="checkbox" checked={res.shopCheck} onChange={(e) => {
                    this.shopcheck(index, res)
                  }} /> {res.shopName}
                </div>
                {

                  res.goodsList.map((item, i) => {
                    ////console.log(item.itemCheck);
                    return (
                      <div className="shopInfo" key={item.cartId}>
                        <div className="fl checkAll">
                          <input type="checkbox" checked={item.itemCheck} onChange={() => this.checkOne(i, item.itemCheck, index)} />
                        </div>
                        <div className="fl lj-item">
                          <div className="td-inner">
                            <div className="fl itemPicW">
                              <img className="itemPic" src={item.goodsImg} alt="" />
                            </div>
                            <div className="fl itemNameW">
                              <span >{item.goodsName}</span>
                            </div>
                          </div>
                        </div>
                        <div className="fl lj-info">
                          <div className="td-inner">
                            &nbsp;
                          </div>
                        </div>
                        <div className="fl lj-lv">
                          <div className="td-inner">
                            {item.goodsProp || item.productName}
                          </div>
                        </div>
                        <div className="fl lj-price">
                          <div className="td-inner">
                            {item.productPrice}元/斤
                          </div>
                        </div>
                        <div className="fl lj-number">
                          <div className="td-inner">
                            <div className="num">
                              <button className="fl numsub" id={item.cartId} onClick={(e) => this.onAdd(item, -1, e)}>-</button>
                              <input className="fl numInp" value={item.num} onChange={() => this.itemIpu()} type="text" />
                              <button className="fl numadd" id={item.cartId} onClick={(e) => this.onAdd(item, 1, e)}>+</button>
                            </div>
                          </div>
                        </div>
                        <div className="fl lj-weight">
                          <div className="td-inner">
                            {item.productWeight}斤
                          </div>
                        </div>
                        <div className="fl lj-subtotal">
                          <div className="td-inner">
                            {/* {this.getTotalPrice()} */}
                            {(item.num * item.productPrice * item.productWeight).toFixed(2)}
                          </div>
                        </div>
                        <div className="fl lj-operation">
                          <div className="td-inner" onClick={() => this.showRemoveModal(item.cartId)}>
                            <div className="del">
                              删除
                            </div>
                          </div>
                          <Modal title="删除" visible={this.state.removeModal} onOk={this.removeModalOk} onCancel={this.removeModalCancel} okText={'确定'} cancelText={"取消"}>
                            <p style={{ textAlign: 'center' }}>是否确认删除</p>
                          </Modal>
                        </div>
                      </div>
                    )

                  })
                }
              </div>
            ))
          }
          <div className="shopItemBottom">
            <div className="itemLeft fl">
              <div className="fl checkAll quan">
                <input type="checkbox" checked={shopList.checkall} onChange={() => {
                  this.check_all()
                }} />
                &nbsp;&nbsp;全选
              </div>
              <div className="fl del" onClick={() => { this.delAll() }}>
                删除
              </div>
              {/* <div className="fl delsx" >
                清楚失效商品
                </div> */}
            </div>
            <div className="itemRight fr">
              <div className="yx fl">
                已选商品 <span className="jq">{
                  this.allnum()
                }</span>件
              </div>
              <div className="hj fl">
                合计: <span className="jq">{
                  // this.getTotalPrice()
                  this.allprice()
                }</span>
              </div>
              <div className="js fr" onClick={() => this.qujiesuan()} style={{ background: this.state.isjsbg ? "red" : "#909090" }}>
                <span >结算</span>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}






