import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import request from '../../../../../../static/js/http';
import './index.scss';
class ShopRecommend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: []
    }
  }
  componentDidMount(){
    this.getList()
  }
  getList() {
    request('post', '/api/goods/goods/appIndex', {
      classId: 0,
      pageNum: 1,
      pageSize: 10,
      lat: 1,
      lon: 1,
      recomSy: 1,
      sortType: 0,
    }).then(res => {
      ////console.log(res);
      this.setState({
        list: res.data
      })
    })
  }
  xiangqing(id) {
    this.props.history.push({  pathname: '/home/details', state: { id } })
  }
  render() {
    const { list } = this.state
    return (
      <>
        <div className="shopRecommend">
          <div className="itemList">
            {
              list.list&&list.list.map(item => {
                return <Link target="_blank" to={'/home/details/' + item.goodsId}>
                  <div className="item" onClick={()=>this.xiangqing(item.goodsId)} key={item.goodsId}>
                  <div className="itempic">
                    <img src={item.goodsImg} alt="" />
                  </div>
                  <div className="iteminfo">
                    <div className="itemname">
                      {item.goodsName}
                    </div>
                    <div className="price">
                      {item.productPrice}元/斤
                    </div>
                  </div>
                </div>
                </Link>
                 
              })
            }
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(ShopRecommend)