import React, { Component } from 'react';
import './index.scss';
import copy from 'copy-to-clipboard';
import { Popconfirm, message, Button,Image  } from 'antd';
import geren from '../../../../../static/img/geren.png'
import qiye from '../../../../../static/img/qiye1.png'
import { withRouter } from 'react-router-dom';
import request from '../../../../../static/js/http';
class Chuzudetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chuzudetails: []
    }
    this.img = React.createRef();
  }
  componentDidMount() {
    this.chuzudetails()
  }
  chuzudetails() {
    let id = this.props.location.state.id
    request('post', '/api/storage/rent/selectOne', {
      id
    }).then(res => {
      ////console.log(res);
      this.setState({
        chuzudetails: res.data
      })
    })
  }

  renzheng = () => {
    const { chuzudetails } = this.state
    switch (chuzudetails.memeberlevel) {
      case 0: return geren;
      case 1: return qiye;
      case 2: return geren;
      default: return geren
    }
  }
  shenfen = () => {
    const { chuzudetails } = this.state
    switch (chuzudetails.memeberlevel) {
      case 0: return '个人';
      case 1: return '企业';
      case 2: return '经纪人';
      default: return '个人'
    }
  }
  render() {
    const { chuzudetails } = this.state
    let img = eval(chuzudetails.image)
    const text = chuzudetails.memberphone
    function confirm() {
      copy(text)
      message.success('复制成功，去📞吧');
    }
    return (
      <>
        <div className="chuzuxiangqing">
          <div className="chuzuinfo">
            <div className="chuzuinfotop">
              冷库出租信息
            </div>
            <div className="chuzuinfobody">
              <div className="body-left">
                <div className="chuzu-item">
                  <span className="itemtext-lf">标题：</span>
                  <span className="itemtext-lr">{chuzudetails.title}</span>
                </div>
                <div className="chuzu-item">
                  <span className="itemtext-lf">出租价格：</span>
                  <span className="itemtext-lr">{chuzudetails.price}元/月</span>
                </div>
                <div className="chuzu-item">
                  <span className="itemtext-lf">出租面积：</span>
                  <span className="itemtext-lr">{chuzudetails.area}m²</span>
                </div>
                <div className="chuzu-item">
                  <span className="itemtext-lf">冷库地址：</span>
                  <span className="itemtext-lr">{chuzudetails.address}</span>
                </div>
                <div className="chuzu-item">
                  <span className="itemtext-lf">发布时间：</span>
                  <span className="itemtext-lr">{chuzudetails.createAt}</span>
                </div>
                <div className="chuzu-item">
                  <span className="itemtext-lf">内容：</span>
                  <span className="itemtext-lr">{chuzudetails.conent}</span>
                </div><br />
                <div className="chuzu-item-pic">
                  {
                    img && img.map((item, index) => {
                      return <Image key={index} src={item} alt="" />
                    })

                  }
                </div>
              </div>
              <div className="body-right">
                <Popconfirm
                  placement="topRight"
                  title={text}
                  onConfirm={confirm}
                  okText="复制"
                  cancelText="取消"
                >
                  <Button>手机号码</Button>
                </Popconfirm>
                {/* <div className="liaoyiliao">聊一聊</div> */}
              </div>
            </div>
          </div>
          <div className="chuzureninfo">
            <div className="chuzurentop">
              发布人信息
            </div>
            <div className="caogourenbody">
              <div className="chuzurentouxiang">
                <img src={chuzudetails.memeberavator} alt="" />
              </div>
              <div className="chuzurenxiangqing">
                <div className="chuzurenxiangqing-item">
                  <span>发布人：</span><span>{chuzudetails.createBy}</span><span>({this.shenfen()})</span>
                </div>
                <div className="chuzurenxiangqing-item">
                  <span>认证情况：</span><img src={this.renzheng()} alt="" />
                </div>
                <div className="chuzurenxiangqing-item">
                  <span>发布数量：</span><span>{chuzudetails.total}条</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(Chuzudetails)