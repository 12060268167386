import React, { Component, useState } from 'react';
import cookie from 'react-cookies';
import { Tabs ,Pagination} from 'antd';
import './index.scss'
import request from '../../../../static/js/http';
export default class Mypingjia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answerList: []
    }
  }
  componentDidMount() {
    this.answer()
  }
  answer() {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/qa/answer/myList', {
      memberId,
      pageNum: 1,
      pageSize: 5,
    }).then(res => {
      ////console.log(res);
      this.setState({
        answerList: res.data
      })
    })
  }
  // del(answerId){
  //   request('post','/api/order/order/myCommentRemove',{
  //     commentId:answerId
  //   }).then(res=>{
  //     //console.log(res);
  //   })
  // }
  pagination = (a, b) => {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/qa/answer/myList', {
      pageNum: a,
      pageSize: b,
      memberId,
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          answerList: res.data
        })
      }
    })
  }
  render() {
    const { answerList } = this.state
    return (
      <>
        <div className="mypingjia">
          <div className="mypingjiaitem">
            {/* <div className="itemtitle">
              <span className="wen">问</span>
              <span className="titletext">晚上好！请问老师湖南地域早春辣椒苗可以在广东省育苗吗？什么时候育苗最合适？恳请老师指导为谢！</span>
            </div> */}
            {
              answerList.list && answerList.list.map(item => {
                return <div className="iteminfo" key={item.answerId}>
                  <div className="infoleft">
                    <div className="infolefttop">
                      <img src={item.memberAvator} alt="" />
                      <span className="name">{item.memberName}</span>
                    </div>
                    <div className="infopingjia">
                      <div className="pingjia">
                        {item.answerContent}
                      </div>
                      {/* <div className="shanchu" onClick={()=>this.del(item.answerId)}>
                        删除
                </div> */}
                    </div>
                    <div className="infoleftbottom">
                      <span className="liulan">浏览次数{item.likeNum}次</span>
                      <span className="shijian">（提问时间：{item.createAt}）</span>
                    </div>
                  </div>
                </div>
              })
            }
            <Pagination
              pageSize='5'
              current={answerList.pageNum}
              onChange={this.pagination}
              total={answerList.total}
            />
          </div>
        </div>
      </>
    )
  }
}