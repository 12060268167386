import React, { Component, useState } from 'react';
import cookie from 'react-cookies';
import './index.scss'
import Xiugai from './xiugaichuzu'
import { Modal, Button, Pagination, message } from 'antd';
import request from '../../../static/js/http';
export default class Mychuzu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      biaotitab: [
        { name: '出租中', id: '1', type: '0' },
        { name: '已结束', id: '2', type: '1' },
        { name: '被驳回', id: '3', type: '2' },
      ],
      isguanli: false,
      currentIndex: 0,
      rentoutlist: [],
      fenye: [],
      statu: '0',
      rentId: '',
      item: [],
    }
    this.child = React.createRef();
  }
  componentDidMount() {
    this.getrentoutlist()
  }
  //我的出租列表
  getrentoutlist() {
    const { statu } = this.state
    ////console.log(statu);
    let memberId = cookie.load('lj-pc-userInfo').memberId
    ////console.log(memberId);
    request('post', '/api/storage/rent/listByUserId', {
      memberId,
      statu,
      pageSize: 5,
      pageNum: 1
    }).then(res => {
      if (res.code == 0) {
        this.setState({
          rentoutlist: res.data.list,
          fenye: res.data
        }, function () {  
        })
        message.success(res.msg);
      } else {
        message.warning(res.msg);
      }
      ////console.log(res);

    })
  }
  //结束出租
  finishrentout() {
    const { rentId } = this.state
    request('post', '/api/storage/rent/updateStorageRent  ', {
      statu: 1,
      rentId,
    }).then(res => {
      ////console.log(res);
      if (res.code == 0) {
        this.getrentoutlist()
        message.success(res.msg);
      } else {
        message.warning(res.msg);
      }
    })
  }
  //重新上架
  uprent() {
    const { rentId } = this.state
    request('post', '/api/storage/rent/updateStorageRent  ', {
      statu: 0,
      rentId,
    }).then(res => {
      ////console.log(res);
      if (res.code == 0) {
        this.getrentoutlist()
        message.success(res.msg);
      } else {
        message.warning(res.msg);
      }
      this.setState({
        isguanli:false
      })
    })
  }
  //删除商品
  // 
  delrent() {
    const { rentId } = this.state
    ////console.log(rentId);
    request('post', '/api/storage/rent/deleteStorageRent', {
      id: rentId
    }).then(res => {
      if (res.code==0) {
        message.success(res.msg)
        this.getrentoutlist()
      }else{
        message.warning(res.msg)
      }
      this.setState({
        isguanli: false
      })
    })
  }
  //分页
  pagination = (a, b) => {
    ////console.log(a, b);
    const { statu } = this.state
    ////console.log(statu);
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/storage/rent/listByUserId', {
      pageSize: !b?5:b,
      pageNum: !a?1:a,
      memberId,
      statu,
    }).then(res => {
      // ////console.log(res);
      if (res.code == 0) {
        this.setState({
          rentoutlist: res.data.list,
          fenye: res.data
        })
        message.success(res.msg);
      }

    })
  }
  tab = (type, index) => {
    ////console.log(index);
    this.setState({
      currentIndex: index,
      statu: type
    }, function () {
      this.getrentoutlist()
    })
  }
  showguanli = (rentId, item) => {
    this.setState({
      isguanli: true,
      rentId,
      item
    })
  };

  guanliOk = () => {
    this.setState({
      isguanli: false
    })
  };

  guanliCancel = () => {
    this.setState({
      isguanli: false
    })
  };
  guanliover = () => {
    this.setState({
      isguanli: false
    }, function () {
      this.finishrentout()
    })
  };
  guanlireg = () => {
    this.setState({
      isxiugai: true
    })
  };
  xiugaiCancel = () => {
    this.setState({
      isxiugai: false
    })
  };
  xiugaiOk = () => {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    let title = this.child.current.state.title
    const { rentId } = this.state
    request('post', '/api/storage/rent/updateStorageRent', {
      memberId,
      title,
      rentId,
      price: this.child.current.state.price,
      area: this.child.current.state.chuzuarea,
      address: this.child.current.state.address,
      imagesPath: this.child.current.state.imagesPath,
      conent: this.child.current.state.conent,
      statu: 1
    }).then(res => {
      ////console.log(res);
      this.getrentoutlist()
    })
    this.setState({
      isxiugai: false
    })
  };
  render() {
    const { biaotitab, itemList, rentoutlist, fenye, statu, item } = this.state
    return (
      <>
        <div className="mychuzu">
          <div className="biaoti">
            冷库出租
          </div>
          <div className="chuzu">
            <div className="chuzutab">
              {
                biaotitab.map((item, index) => {
                  return <div className={`tabitem ${this.state.currentIndex === index ? 'active' : ''}`} onClick={() => this.tab(item.type, index)} key={item.id}>
                    {item.name}
                  </div>
                })
              }
            </div>
            <div className="chuzulist">
              {
                rentoutlist && rentoutlist.map(item => {
                  return <div className="chuzuitem" key={item.rentId}>
                    <div className="itemleft">
                      <div className="item-name">
                        {item.title}
                      </div>
                      <div className="pinzhi">
                        具体要求：{item.area}平米
                    </div>
                    </div>
                    <div className="itemright">
                      <div className="guanli">
                        <Button type="primary" onClick={() => this.showguanli(item.rentId, item)}>
                          管理
                      </Button>
                      </div>
                    </div>
                  </div>
                })
              }

            </div>
          </div>
          <Pagination pageSize='5' defaultCurrent={1}  current={fenye.pageNum} onChange={this.pagination} total={fenye.total} />
        </div>
        <Modal title="管理"
          className="gunaliModal"
          visible={this.state.isguanli}
          onOk={this.guanliOk}
          onCancel={this.guanliCancel}
          cancelText={statu == 0 ? '结束出租' : (statu == 1 ? '删除出租' : '删除')}
          okText={statu == 0 ? '修改出租' : (statu == 1 ? '重新上架' : '修改')}
          footer={[

            <div className="footer">
              <Button onClick={statu == 0 ? () => this.guanliover() : (statu == 1 ? () => this.delrent() : () => this.delrent())}>
                {statu == 0 ? '结束出租' : (statu == 1 ? '删除出租' : '删除')}
              </Button>
              <Button onClick={statu == 0 ? () => this.guanlireg() : (statu == 1 ? () => this.uprent() : () => this.guanlireg())}>
                {statu == 0 ? '修改出租' : (statu == 1 ? '重新上架' : '修改')}
              </Button>
            </div>

          ]}
        >
          <div className="guanliinfo">
            <div className="guanlixiangqing">
              <div className="guanliname">
                {item.title}
              </div>
              {/* <div className="guanlizhong">
                500斤
                  </div> */}
            </div>
            <div className="chuzutype">
              <div className="chuzutypeitem">
                <div>出租状态：</div><div>{item.statu == 0 ? '出租中' : (item.statu == 1 ? '已结束' : '被驳回')}</div>
              </div>
              <div className="chuzutypeitem">
                <div>出租面积：</div><div>{item.area}</div>
              </div>
              <div className="chuzutypeitem">
                <div>出租价格：</div><div>{item.price}元/月</div>
              </div>
              <div className="chuzutypeitem">
                <div>浏览次数：</div><div>{item.scannum}次</div>
              </div>
              <div className="chuzutypeitem">
                <div>出租时间：</div><div>{item.createAt}</div>
              </div>
            </div>

          </div>
        </Modal>
        <Modal cancelText="取消" okText="确定" className="chuzuModal" title="修改出租" visible={this.state.isxiugai} onOk={this.xiugaiOk} onCancel={this.xiugaiCancel}>
          <Xiugai ref={this.child} />
        </Modal>
      </>
    )
  }
}