import React, { Component } from 'react';
import './index.scss';
import di1 from '../../../../static/img/di1.png';
import di2 from '../../../../static/img/di2.png';
import di3 from '../../../../static/img/di3.png';
import di4 from '../../../../static/img/di4.png';
import a1 from '../../../../static/img/erwei.jpg';
import request from '../../../../static/js/http';

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link:[]
    };
  }
  componentDidMount(){
    request('post','/api/cms/link/list',{
      pageNum:1,
      pageSize:99
    }).then(res=>{
      console.log(res);
      this.setState({
        link:res.data.list
      })
    })
  }
    tiao(value){
      window.open(value)
    }
  render() {
    const {link} = this.state
    return (
      <div className="homebottom">
        <div className="one">
          <span> 友情链接:</span>
          {link.map(a => <span key={a.id} className="a" onClick={()=>this.tiao(a.value)}> {a.title}</span>)}
        </div>
        <div className="two">
          <div className="twonei">
            <div className="nei1">
              <img className="homebottomimg1" src={di1} alt="新手指南" />
              <div className="homebottomright">
                <div>新手指南</div>
                <div> <a href="/login">注册新用户</a></div>
                <div><a href="http://server.yingshanhongcn.com/">供应商后台</a></div>
              </div>
            </div>
            <div className="nei1">
              <img className="homebottomimg1" src={di2} alt="新手指南" />
              <div className="homebottomright">
                <div>采购商服务</div>
                <div> <a href="/home/cuohedating">找供应</a> </div>
                <div> <a href="/myProfile/Market">看行情</a> </div>
              </div>
            </div>
            <div className="nei1">
              <img className="homebottomimg1" src={di3} alt="新手指南" />
              <div className="homebottomright">
                <div>供应商服务</div>
                <div> <a href="/home/cuohedating">找采购</a></div>
                <div><a href="/home/cuohedating">发布供应</a></div>
              </div>
            </div>
            <div className="nei1">
              <img className="homebottomimg1" src={di4} alt="新手指南" />
              <div className="homebottomright">
                <div>交易安全</div>
                <div> <a href="/home/guarantee">买家保障</a> </div>
                <div>  <a href="/home/boutique">优选优品</a>  </div>
              </div>
            </div>
            <div className="nei2">
              <div>
                <img src={a1} alt="二维码" />
                <div>扫码下载APP</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
