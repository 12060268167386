import React, { Component } from 'react';
import {
  Breadcrumb, Steps, Button, Avatar, Table, Input, Modal
} from 'antd';
import { CheckCircleOutlined, SettingOutlined, FlagOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './index.scss';
import request from '../../../static/js/http';
import cookie from 'react-cookies';
import moment from 'moment'
const { Step } = Steps;
const { TextArea } = Input;
const { confirm } = Modal;
export default class RefundDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: '商品信息', dataIndex: 'goodsImg', align: 'center', render: text => <img className="tu" src={text} alt="111" /> },
        { title: '商品名称', dataIndex: 'goodsName', align: 'center' },
        { title: '商品等级', dataIndex: 'dengji', align: 'center' },
        {
          title: '数量',
          dataIndex: 'total',
          align: 'center',
          render: (a, b) => (
            <div>
              <div>{a}</div>
              <div>单位：{b.productUnit}</div>
            </div>
          )
        },
        { title: '商品价格', dataIndex: 'price', align: 'center', render: (a, b) => <div>{a}{b.danwei2}</div> },
        { title: '总重(斤)', dataIndex: 'productWeight', align: 'center', render: (a, b) => <div>{a}{b.danwei3}</div> },
        { title: '小计', dataIndex: 'allPrice', align: 'center', render: a => <div>￥{a}</div> }
      ],
      data: [
        {
          key: '1',
          url: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
          name: '子弹头',
          dengji: '花皮',
          sum: '1',
          jiage: '99',
          danwei: '包',
          danwei2: '元/斤',
          zhong: '400',
          danwei3: '斤',
          jiaoji: '1236'
        }
      ],
      orderInfo: [],
      orderAddress: [],
      detailList: []
    };
  }
  componentDidMount() {
    this.xiangqing()
  }
  xiangqing() {
    let orderId = this.props.location.state.orderId
    let memberId = cookie.load('lj-pc-userInfo').memberId
    ////console.log(this.props.location.state);
    this.setState({
      orderInfo:this.props.location.state.res
    })
   
  }
  // /api/order/order/cancel
  ordercancel() {
    let orderId = this.props.location.state.orderId
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/order/order/cancel', {
      orderId,
      memberId
    }).then(res => {
      ////console.log(res);
    })
  }
  status = () => {
    switch (this.state.orderInfo.status) {
      case 0: return 1;
      case 1: return 2;
      case 2: return 3;
      case 3: return 4;
      case 4: return 5;
      case 5: return 8;
      case 7: return 5;
      default: return 0
    }
  }
  dingdantype = () => {
    ////console.log(this.props.location.state.res.status);
    switch (this.props.location.state.res.status) {
      case 0: return '退货/退款中';
      case 1: return '退货/退款成功';
      case 2: return '退货/退款失败';
      default: return '退货/退款中'
      
    }
  }
  quxiao = () => {
    let that = this
    confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '确定取消该订单？',
      okText: '确定',
      cancelText: '取消',
      onOk() {
        ////console.log('OK');
        that.ordercancel()
      },
      onCancel() {
        ////console.log('Cancel');
      },
    });
  }
  render() {
    const styicon = {
      fontSize: '30px',
      color: '#C33F35'
    };
    const sta = false;
    const { columns, data, orderInfo, orderAddress, detailList } = this.state;
    // let orderAddress = orderInfo.orderAddress
    ////console.log(orderAddress);

    return (
      <div className="refundDetails">
        <div className="wei">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>当前位置</Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/my/index">个人中心</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/myProfile/order">我的订单</Link></Breadcrumb.Item>
            <Breadcrumb.Item>商品详情</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="width1400">
          <div className="buzhou">
            {this.dingdantype()}
            {/* <Steps progressDot current={
            this.status()
            
            }>
              <Step title="提交订单" description="2020-10-10 13:00" />
              <Step title="待修改" description="2020-10-10 13:00" />
              <Step title="待支付" description="2020-10-10 13:00" />
              <Step title="待发货" description="2020-10-10 13:00" />
              <Step title="确认收货" description="2020-10-10 13:00" />
              <Step title="评价" description="2020-10-10 13:00" />
            </Steps> */}
          </div>
          <div className="xinxi">
            <div className="a1">
              <div className="title">订单信息</div>
              <div className="aa">
                <div className="bb">
                  <div>订单编号：{orderInfo.orderSn}  </div>
                  <div>申请时间：{moment(orderInfo.createAt).format('YYYY-MM-DD HH:mm:ss')}</div>
                </div>
              </div>
            </div>
            <div className="a2">
              <div>
                {
                  sta ? <CheckCircleOutlined style={styicon} /> : <SettingOutlined style={styicon} />
                }
                <span>订单状态：{this.dingdantype()}</span>
              </div>
              {
                sta ? (
                  <div className="wuliuxinxi">
                    <div>
                      <FlagOutlined style={styicon} />
                      <div className="xinxi">
                        物流：中通快递运单号:754203367628672020-12-14 19:08:37 您已在郑州市学府花园店完成取件，感谢使用菜鸟驿站，期待再次为您服务。
                      </div>
                    </div>
                    <Button className="bo">去评价</Button>
                  </div>
                )
                  : (
                    <div className="ding">
                      {/* <div>商家修改运费，优惠券，才能支付，您可以主动点击通过“联系商家”</div> */}
                      <div>
                        <Button type="primary">联系商家</Button>
                        {/* <Button onClick={this.quxiao}>取消订单</Button> */}
                      </div>
                    </div>
                  )
              }
            </div>
          </div>
          <div className="list">
            <div className="tou">
              <Avatar src={orderInfo.shopLogo} />
              <span>{orderInfo.shopName}</span>
            </div>
            <div className="biao">
              <Table
                columns={columns}
                dataSource={orderInfo.detailList}
                bordered
                pagination={false}
              />
              <div className="bodi">
                <div className="left">
                  <div>退货/退款原因：</div>
                  <TextArea disabled autoSize={false} rows={3} value={orderInfo.reasonF} />
                </div>
                <div className="right">
                  <div className="on1">
                    <div>快递费：{orderInfo.orderPost}</div>
                    <div>优惠：</div>
                  </div>
                  <div className="on2">
                    <div>待协商</div>
                    <div>0.00</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="jie">
            <div>
                {/* <span>优惠：</span>
                <span>￥15</span> */}
            </div>
            <div>
              <span>商品总价：</span>
              <span>￥{orderInfo.orderPrice}</span>
            </div>
            <div>
              <span>实付：</span>
              <span>￥{orderInfo.payment}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
