import React, { Component } from 'react'
import { Steps } from 'antd';
import './index.scss'
import img1 from '../../static/img/shenhe1.png'
import img2 from '../../static/img/shenhe2.png'
import img3 from '../../static/img/shenhe3.png'
const { Step } = Steps;
export default class Examine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index1: 3,
    };
  }
  render() {
    const { index1 } = this.state
    return (
      <div className="examine">
        <div className="hong">
          <Steps className="steps" progressDot current={1}>
            <Step title="提交资料" />
            <Step title="审核中" />
            <Step title="审核成功" />
          </Steps>
        </div>
        <div className="body">
          <img src={index1 === 1 ? img1 : index1 === 2 ? img2 : img3} alt="申请中" />
          <span>{index1 === 1 ? "提交资料成功，正在审核中，请耐心等候！！" : index1 === 2 ? <span>审核失败，请重新<span>上传资料</span></span> : "审核成功"}</span>
        </div>
      </div>
    )
  }
}
