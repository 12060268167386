import React, { Component } from 'react';
import './index.scss';
import cookie from 'react-cookies';
import * as echarts from 'echarts';
import { message } from 'antd';
import request from '../../../static/js/http';
class Priceexpectation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tp: [
        { name: '看涨', id: 0, type: 0 },
        { name: '看稳', id: 1, type: 1 },
        { name: '看跌', id: 2, type: 2 },
      ],
      time: [
        { name: '昨日', id: 0, type: 0 },
        { name: '7日', id: 1, type: 1 },
        { name: '30日', id: 2, type: 2 }
      ],
      timeIndex: 0,
      currentIndex: 2,
      type: 0,
      marketList: [],
      data: '昨日'

    }
  }
  componentDidMount() {
    this.market()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentIndex } = this.state
    if (currentIndex !== nextProps.currentIndex) {
      this.setState({
        currentIndex: nextProps.currentIndex,
      }, function () {
        ////console.log(this.state.currentIndex);
        this.market()
      })
    }
  }

  tab = (type, index) => {
    this.setState({
      timeIndex: index,
      type
    }, function () {
      this.market()
      if (type === 0) {
        this.setState({
          data: '昨日'
        })
      } else if (type === 1) {
        this.setState({
          data: '7日'
        })
      } else {
        if (type === 2) {
          this.setState({
            data: '30日'
          })
        }

      }
    })
  }
  market() {
    const { currentIndex } = this.state
    ////console.log(currentIndex);
    let memberId = cookie.load('lj-pc-userInfo').memberId
    ////console.log(memberId);
    request('post', '/api/market/hall/list', {
      memberId,
      type: 1,
      classId: currentIndex
    }).then(res => {
      ////console.log(res);
      this.setState({
        marketList: res.data
      }, function () {
        this.rise()
        this.steady()
        this.fall()
      })
    })
  }
  //发布预测
  put(type) {
    const { marketList, currentIndex } = this.state
    ////console.log(currentIndex);
    if (marketList.memberPut === 0) {

      let memberId = cookie.load('lj-pc-userInfo').memberId
      request('post', '/api/market/predict/put', {
        memberId,
        classId: currentIndex,
        type
      }).then(res => {
        ////console.log(res);
        this.market()
        message.success('投票成功')
      })
    } else {
      message.error('请勿重复投票')

    }

  }
  rise() {
    const { marketList } = this.state
    var chart = echarts.init(document.getElementById('rise'));
    var option = {
      color: ['#E64F38', '#FCEDEB'],
      title: {
        text: '',
        right: 'center'
      },
      series: [
        {
          name: '看涨',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          hoverAnimation: false,   //关闭放大动画
          selectedOffset: 0,     //选中块的偏移量
          label: {
            show: false,
            position: 'center',
            formatter: '{d}%'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '20',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            {
              value: marketList.preList[0].rate,
              name: '直接访问',
              selected: true,     //默认选中第一块
              label: {
                show: true,     //默认显示第一块
                fontSize: '28',
                fontWeight: '400',
                color: '#E64F38'
              }
            },
            {
              value: 100 - marketList.preList[0].rate, name: '邮件营销',
              label: {
                show: false,
                position: 'center',
                formatter: '{d}%'
              },
            },
          ]
        }
      ]
    };

    chart.setOption(option)
  }
  steady() {
    const { marketList } = this.state
    var chart = echarts.init(document.getElementById('steady'));
    var option = {
      color: ['#0B6DF1', '#E6F0FE'],
      title: {
        text: '',
        right: 'center'
      },
      series: [
        {
          name: '看涨',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          hoverAnimation: false,   //关闭放大动画
          selectedOffset: 0,     //选中块的偏移量
          label: {
            show: false,
            position: 'center',
            formatter: '{d}%'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '20',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            {
              value: marketList.preList[1].rate,
              name: '直接访问',
              selected: true,     //默认选中第一块
              label: {
                show: true,     //默认显示第一块
                fontSize: '28',
                fontWeight: '400',
                color: '#E64F38'
              }
            },
            {
              value: 100 - marketList.preList[1].rate, name: '邮件营销',
              label: {
                show: false,
                position: 'center',
                formatter: '{d}%'
              },
            },
          ]
        }
      ]
    };

    chart.setOption(option)
  }
  fall() {
    const { marketList } = this.state
    var chart = echarts.init(document.getElementById('fall'));
    var option = {
      color: ['#F1820B', '#FEF2E6'],
      title: {
        text: '',
        right: 'center',

      },
      series: [
        {
          name: '看涨',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          hoverAnimation: false,   //关闭放大动画
          selectedOffset: 0,     //选中块的偏移量
          label: {
            show: false,
            position: 'center',
            formatter: '{d}%'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '20',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            {
              value: marketList.preList[2].rate,
              name: '直接访问',
              selected: true,     //默认选中第一块
              label: {
                show: true,     //默认显示第一块
                fontSize: '28',
                fontWeight: '400',
                color: '#E64F38'
              }
            },
            {
              value: 100 - marketList.preList[2].rate, name: '邮件营销',
              label: {
                show: false,
                position: 'center',
                formatter: '{d}%'
              },
            },
          ]
        }
      ]
    };

    chart.setOption(option)
  }
  render() {
    const { time, timeIndex, currentIndex, marketList, data } = this.state
    let marketData = ''
    if (marketList) {
      ////console.log(marketList);
      marketData = marketList
    }
    return (
      <>
        <div className="priceexpectationapp">
          <div className="priceexpectatio">
            <div className="timeTab">
              {
                time.map((item, index) => {
                  return <div className={`day ${timeIndex === index ? 'active' : ''}`} onClick={() => this.tab(item.type, index)} key={item.id}>
                    {item.name}
                  </div>
                })
              }
            </div>
            <div className="info">
              <div className="infoItemList">
                <div className="infoItem">
                  <div className="data">
                    <span>{data}均价</span>
                  </div>
                  <div className="price">
                    {marketList.avgPrice}
                  </div>
                  <div className="time">

                  </div>
                </div>
                <div className="infoItem">
                  <div className="data">
                    <span>{data}最低 </span>
                  </div>
                  <div className="price">
                    {marketList.minPrice}
                  </div>
                  <div className="time">
                    {marketList.minDay}
                  </div>
                </div>
                <div className="infoItem">
                  <div className="data">
                    <span>{data}最高 </span>
                  </div>
                  <div className="price">
                    {marketList.maxPrice}
                  </div>
                  <div className="time">
                    {marketList.maxDay}
                  </div>
                </div>
              </div>
              <div className="infoBottom">
                <div className="updateTime">
                  更新时间:{marketList.nowTime}
                </div>
                <div className="unit">
                  单位:元/斤
              </div>
              </div>
            </div>

            <div className="priceexpectationList">
              <div className="tpItem">
                <div id="rise" style={{ width: '100%', height: '100%' }}></div>
              </div>
              <div className="tpItem">
                <div id="steady" style={{ width: '100%', height: '100%' }}></div>
              </div>
              <div className="tpItem">
                <div id="fall" style={{ width: '100%', height: '100%' }}></div>
              </div>
            </div>
            <div className="voteList">
              {
                this.state.tp.map(item => {
                  return <div className={`${marketData.predictType == item.id ? 'tp' : 'vote'}`} key={item.id} onClick={() => this.put(item.type)} >
                    {marketData.predictType == item.id ? '已投票' : item.name}
                  </div>
                })
              }
            </div>
            <p className="content">截止{marketData.nowTime}已有{marketData.allNum}人参与投票</p>
          </div>
        </div>

      </>
    )
  }
}
export default Priceexpectation