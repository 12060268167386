import React, { Component } from 'react';
import './index.scss';
import { withRouter } from 'react-router-dom';
import request from '../../../../static/js/http';
// import axios from '_axios@0.21.1@axios';
class MarketConsultDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: []
    }
  }
  componentDidMount() {
    this.details()
  }
  details() {
    let informationId = this.props.location.state.id
    request('post', '/api/market/information/info', {
      informationId
    }).then(res => {
      ////console.log(res);
      this.setState({
        details: res.data
      })
    })
  }
  render() {

    return (
      <>
        <div className="MarketConsultDetails">
          <div className="MarketConsultDetailsTop">
            <div className="title">
              {this.state.details.title}
            </div>
            <div className="time">
              发布时间：{this.state.details.createAt}
            </div>
          </div>
          <div className="MarketConsultDetailsContent">

            {
              !this.state.details.img ? '':<div className="pic">
              <img src={this.state.details.img} alt="" />
            </div>
              }



            <div className="content">
            <div dangerouslySetInnerHTML={{__html:`${this.state.details.content}`}}></div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(MarketConsultDetails)