import React, { Component } from 'react';
import './index.scss';
import { Link, withRouter } from 'react-router-dom';
import cookie from 'react-cookies';
import request from '../../../../static/js/http';
class Shangpinshoucang extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shoucangList: []
    }
  }
  componentDidMount() {
    this.shangpin()
  }
  shangpin() {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    ////console.log(memberId);
    request('post', '/api/member/goods/collectList', {
      memberId,
      pageNum: 1,
      pageSize: 10,
    }).then(res => {
      ////console.log(res);
      this.setState({
        shoucangList: res.data.list
      })
    })
  }
  xiangqing(id) {
    ////console.log(id);
    this.props.history.push({ pathname: '/home/details', state: { id } })
  }
  render() {
    const { shoucangList } = this.state
    return (
      <>
        <div className="shangpinshoucang">
          <div className="list">
            {
              shoucangList.map(item => {
                return <Link target="_blank" to={'/home/details/' + item.goodsId}>
                  <div className="item111 hover" key={item.goodsId}>
                    <img className="img" src={item.goodsImg} alt="辣椒" />
                    <div className="you">
                      <div className="name sheng">{item.goodsName}</div>
                    </div>
                    <div className="jia">￥<span>{item.productPrice}</span>/斤</div>
                  </div >
                </Link>
              })
            }
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(Shangpinshoucang)