import React, { Component } from 'react';
import { Pagination } from 'antd';
import { withRouter, Link } from "react-router-dom"
import cookie from 'react-cookies';
import { LineChartOutlined, FieldTimeOutlined } from '@ant-design/icons';
import './index.scss';
import hometypeimg from '../../static/img/del_home_type.png';
import left1 from '../../static/img/del_home_left.png';
import request from '../../static/js/http'
import pic2 from '../../static/img/index2.jpg'
import pic3 from '../../static/img/index3.png'
import pic4 from '../../static/img/sanyingjiao.png'
import pic5 from '../../static/img/index5.png'
import pic6 from '../../static/img/index6.png'
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      homePage: [],
      isLoading: false,
      youxuan: [],
      youxuanfenye: '',
      tuijian: [],
      tuijianfenye: '',
      jingpin: [],
      jingpinfenye: '',
      zidantou: [],
      zidantoufenye: '',
      shanyingjiao: [],
      shanyingjiaofenye: '',
      remai: [],
      remaifenye: '',
    };
  }
  componentDidMount() {
    this.homePage()
    this.youxuan()
    this.tuijian()
    this.jingpin()
    this.remai()
    // window.location.reload()
    //console.log();
  }
  homePage = () => {
    request('post', '/api/goods/class/sList', {
      classId: 1
    }).then(res => {
      if (res.code === 0) {
        let homeClassList = res.data
        homeClassList.forEach(item => {
          let sty = 'sty'
          let false1 = false
          item[sty] = false1
        });
        this.setState({
          homePage: res.data,
        }, function () {
          this.zidantou()
          this.shanyingjiao()
        })
      }
    })
  }
  //优选优品专区
  youxuan() {
    request('post', '/api/goods/goods/appIndex', {
      classId: 0,
      pageNum: 1,
      pageSize: 8,
      lat: 1,
      lon: 1,
      recomSy: 0,
    }).then(res => {
      this.setState({
        youxuan: res.data.list,
        youxuanfenye: res.data
      })
    })
  }
  //精品零售专区
  jingpin() {
    request('post', '/api/goods/goods/appIndex', {
      classId: 0,
      pageNum: 1,
      pageSize: 8,
      lat: 1,
      lon: 1,
      recomSy: 1,
    }).then(res => {
      this.setState({
        jingpin: res.data.list,
        jingpinfenye: res.data
      })
    })
  }
  //子弹头专区
  zidantou() {
    const { homePage } = this.state
    console.log(homePage[0].classId);
    request('post', '/api/goods/goods/appIndex', {
      classId: homePage[0].classId,
      pageNum: 1,
      pageSize: 8,
      lat: 1,
      lon: 1,
    }).then(res => {
      this.setState({
        zidantou: res.data.list,
        zidantoufenye: res.data
      })
    })
  }

  //山樱椒专区
  shanyingjiao() {
    const { homePage } = this.state
    request('post', '/api/goods/goods/appIndex', {
      classId: homePage[1].classId,
      pageNum: 1,
      pageSize: 8,
      lat: 1,
      lon: 1,
    }).then(res => {
      this.setState({
        shanyingjiao: res.data.list,
        shanyingjiaofenye: res.data
      })
    })
  }
  //热卖推荐专区
  remai() {
    request('post', '/api/goods/goods/appIndex', {
      classId: 0,
      pageNum: 1,
      pageSize: 8,
      lat: 1,
      lon: 1,
    }).then(res => {
      this.setState({
        remai: res.data.list,
        remaifenye: res.data
      })
    })
  }
  //商品推荐专区
  tuijian() {
    request('post', '/api/goods/goods/appIndex', {
      classId: 0,
      pageNum: 1,
      pageSize: 8,
      lat: 1,
      lon: 1,
      recommend:1
    }).then(res => {
      this.setState({
        tuijian: res.data.list,
        tuijianfenye: res.data
      })
    })
  }

  ste = (e, id) => {
    // 切换分类
    // eslint-disable-next-line no-console
    e.stopPropagation();
    const { homePage } = this.state;
    const tab1 = homePage.map(a => {
      a.sty = false;
      return a;
    });
    tab1[id].sty = true;
    this.setState({
      homePage: tab1
    });
  }

  tofen = () => {
    // 跳转
    // eslint-disable-next-line no-console
  }
  //优选分页
  youxuanpagination = (a, b) => {
    request('post', '/api/goods/goods/appIndex', {
      classId: 0,
      pageNum: a,
      pageSize: b,
      lat: '',
      lon: '',
      recomSy: 0,
    }).then(res => {
      if (res.code === 0) {
        this.setState({
          youxuan: res.data.list,
          youxuanfenye: res.data
        })
      }
    })
  }
  //精品零售分页
  jingpinpagination = (a, b) => {
    request('post', '/api/goods/goods/appIndex', {
      classId: 0,
      pageNum: a,
      pageSize: b,
      lat: '',
      lon: '',
      recomSy: 1,
    }).then(res => {
      if (res.code === 0) {
        this.setState({
          jingpin: res.data.list,
          jingpinfenye: res.data
        })
      }
    })
  }
  //子弹头分页
  zidantoupagination = (a, b) => {
    const { homePage } = this.state;
    request('post', '/api/goods/goods/appIndex', {
      classId: homePage[0].classId,
      pageNum: a,
      pageSize: b,
      lat: '',
      lon: '',
    }).then(res => {
      if (res.code === 0) {
        this.setState({
          zidantou: res.data.list,
          zidantoufenye: res.data
        })
      }
    })
  }
  //山樱椒分页
  shanyingjiaopagination = (a, b) => {
    const { homePage } = this.state;
    request('post', '/api/goods/goods/appIndex', {
      classId: homePage[1].classId,
      pageNum: a,
      pageSize: b,
      lat: '',
      lon: '',
    }).then(res => {
      if (res.code === 0) {
        this.setState({
          shanyingjiao: res.data.list,
          shanyingjiaofenye: res.data
        })
      }
    })
  }
  //热卖分页
  remaipagination = (a, b) => {
    request('post', '/api/goods/goods/appIndex', {
      classId: 0,
      pageNum: a,
      pageSize: b,
      lat: '',
      lon: '',
    }).then(res => {
      if (res.code === 0) {
        this.setState({
          remai: res.data.list,
          remaifenye: res.data
        })
      }
    })
  }
  //推荐分页
  tuijianpagination = (a, b) => {
    request('post', '/api/goods/goods/appIndex', {
      classId: 0,
      pageNum: a,
      pageSize: b,
      lat: '',
      lon: '',
      recommend:1
    }).then(res => {
      if (res.code === 0) {
        this.setState({
          tuijian: res.data.list,
          tuijianfenye: res.data
        })
      }
    })
  }

  render() {
    let pic = [left1, pic2, pic3, pic4, pic5, pic6,]
    pic.map(item => {
      return item
    })
    const left = {
      background: `#ffffff url(${pic2}) no-repeat 100% 100%`,
      backgroundSize: '100% 100%'
    };
    const { youxuan, youxuanfenye, tuijian, tuijianfenye, jingpin, jingpinfenye, zidantou, zidantoufenye, shanyingjiao, shanyingjiaofenye, remai, remaifenye } = this.state
    const Item = (src) => {
      return (
        <>
          {/* ///////////////////////////////////////优品优选专区////////////////////////////////////// */}
          <div className="hometype1" style={{ background: `#eeee url(${hometypeimg}) no-repeat 100% 100% `, backgroundSize: '100% 100%' }}>
            <div className="dafenlei" style={{ background: `#ffffff url(${left1}) no-repeat 100% 100%`, backgroundSize: '100% 100%' }} onClick={this.tofen}>
            </div>
            <div className="right">
              <div className="top">
                {
                  youxuan && youxuan.map(a => (
                    <Link target="_blank" key={a.goodsId} style={{height:'425px'}} to={'/home/details/' + a.goodsId}>
                      <div className="item hover">
                        {/* <Link target="_blank" to={'/home/details'}> */}
                        <img className="img" src={a.goodsImg} alt="辣椒" />
                        <div className="jia">￥<span>{a.productPrice}</span>元/斤</div>
                        <div className="you">
                          <div className="youpin" >{a.recomSy == 0 ? '优品优选' : '乡村振兴'}</div>
                          <div className="name sheng">{a.goodsName}</div>
                        </div>
                        <div className="dian">
                          <img className="dianlogo" src={a.shopLogo} alt="  " />
                          <div className="dianright">
                            <div className="sheng">{a.shopName}</div>

                          </div>
                        </div>
                        <div className="diandi">
                          <div>
                            <LineChartOutlined className="icon" />
                            <span>{a.goodsSaleNum ? a.goodsSaleNum : '0'}</span>
                          </div>
                          <div>
                            <FieldTimeOutlined className="icon" />
                            <span>48小时发货</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                }
              </div>
              <div className="rightbuttom">
                <Pagination
                  pageSize='8'
                  current={youxuanfenye.pageNum}
                  onChange={this.youxuanpagination}
                  total={youxuanfenye.total}
                />
              </div>
            </div>
          </div>

          {/* ///////////////////////////////////////精品零售专区////////////////////////////////////// */}
          <div className="hometype1" style={{ background: '#eeee' }}>
            <div className="dafenlei" style={{ background: `#ffffff url(${pic2}) no-repeat 100% 100%`, backgroundSize: '100% 100%' }} onClick={this.tofen}>
            </div>
            <div className="right">
              <div className="top">
                {
                  jingpin && jingpin.map(a => (
                    <Link target="_blank" key={a.goodsId} style={{height:'425px'}} to={'/home/details/' + a.goodsId}>
                      <div className="item hover">
                        {/* <Link target="_blank" to={'/home/details'}> */}
                        <img className="img" src={a.goodsImg} alt="辣椒" />
                        <div className="jia">￥<span>{a.productPrice}</span>元</div>
                        <div className="you">
                          <div className="youpin" >{a.recomSy == 0 ? '优品优选' : '乡村振兴'}</div>
                          <div className="name sheng">{a.goodsName}</div>
                        </div>
                        <div className="dian">
                          <img className="dianlogo" src={a.shopLogo} alt="  " />
                          <div className="dianright">
                            <div className="sheng">{a.shopName}</div>

                          </div>
                        </div>
                        <div className="diandi">
                          <div>
                            <LineChartOutlined className="icon" />
                            <span>{a.goodsSaleNum ? a.goodsSaleNum : '0'}</span>
                          </div>
                          <div>
                            <FieldTimeOutlined className="icon" />
                            <span>48小时发货</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                }
              </div>
              <div className="rightbuttom">
                <Pagination
                  pageSize='8'
                  current={jingpinfenye.pageNum}
                  onChange={this.jingpinpagination}
                  total={jingpinfenye.total}
                />
              </div>
            </div>
          </div>
          {/* ///////////////////////////////////////子弹头专区////////////////////////////////////// */}
          <div className="hometype1" style={{ background: '#eeee' }}>
            <div className="dafenlei" style={{ background: `#ffffff url(${pic3}) no-repeat 100% 100%`, backgroundSize: '100% 100%' }} onClick={this.tofen}>
            </div>
            <div className="right">
              <div className="top">
                {
                  zidantou && zidantou.map(a => (
                    <Link target="_blank" key={a.goodsId} style={{height:'425px'}} to={'/home/details/' + a.goodsId}>
                      <div className="item hover">
                        {/* <Link target="_blank" to={'/home/details'}> */}
                        <img className="img" src={a.goodsImg} alt="辣椒" />
                        <div className="jia">￥<span>{a.productPrice}</span>元/斤</div>
                        <div className="you">
                          <div className="youpin" >{a.recomSy == 0 ? '优品优选' : '乡村振兴'}</div>
                          <div className="name sheng">{a.goodsName}</div>
                        </div>
                        <div className="dian">
                          <img className="dianlogo" src={a.shopLogo} alt="  " />
                          <div className="dianright">
                            <div className="sheng">{a.shopName}</div>

                          </div>
                        </div>
                        <div className="diandi">
                          <div>
                            <LineChartOutlined className="icon" />
                            <span>{a.goodsSaleNum ? a.goodsSaleNum : '0'}</span>
                          </div>
                          <div>
                            <FieldTimeOutlined className="icon" />
                            <span>48小时发货</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                }
              </div>
              <div className="rightbuttom">
                <Pagination
                  pageSize='8'
                  current={zidantoufenye.pageNum}
                  onChange={this.zidantoupagination}
                  total={zidantoufenye.total}
                />
              </div>
            </div>
          </div>
          {/* ///////////////////////////////////////山樱椒专区////////////////////////////////////// */}
          <div className="hometype1" style={{ background: '#eeee' }}>
            <div className="dafenlei" style={{ background: `#ffffff url(${pic4}) no-repeat 100% 100%`, backgroundSize: '100% 100%' }} onClick={this.tofen}>
            </div>
            <div className="right">
              <div className="top">
                {
                  shanyingjiao && shanyingjiao.map(a => (
                    <Link target="_blank" key={a.goodsId} style={{height:'425px'}} to={'/home/details/' + a.goodsId}>
                      <div className="item hover">
                        {/* <Link target="_blank" to={'/home/details'}> */}
                        <img className="img" src={a.goodsImg} alt="辣椒" />
                        <div className="jia">￥<span>{a.productPrice}</span>元/斤</div>
                        <div className="you">
                          <div className="youpin" >{a.recomSy == 0 ? '优品优选' : '乡村振兴'}</div>
                          <div className="name sheng">{a.goodsName}</div>
                        </div>
                        <div className="dian">
                          <img className="dianlogo" src={a.shopLogo} alt="  " />
                          <div className="dianright">
                            <div className="sheng">{a.shopName}</div>

                          </div>
                        </div>
                        <div className="diandi">
                          <div>
                            <LineChartOutlined className="icon" />
                            <span>{a.goodsSaleNum ? a.goodsSaleNum : '0'}</span>
                          </div>
                          <div>
                            <FieldTimeOutlined className="icon" />
                            <span>48小时发货</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                }
              </div>
              <div className="rightbuttom">
                <Pagination
                  pageSize='8'
                  current={shanyingjiaofenye.pageNum}
                  onChange={this.shanyingjiaopagination}
                  total={shanyingjiaofenye.total}
                />
              </div>
            </div>
          </div>
          {/* ///////////////////////////////////////热卖推荐专区////////////////////////////////////// */}
          <div className="hometype1" style={{ background: '#eeee' }}>
            <div className="dafenlei" style={{ background: `#ffffff url(${pic5}) no-repeat 100% 100%`, backgroundSize: '100% 100%' }} onClick={this.tofen}>
            </div>
            <div className="right">
              <div className="top">
                {
                  remai && remai.map(a => (
                    <Link target="_blank" key={a.goodsId} style={{height:'425px'}} to={'/home/details/' + a.goodsId}>
                      <div className="item hover">
                        {/* <Link target="_blank" to={'/home/details'}> */}
                        <img className="img" src={a.goodsImg} alt="辣椒" />
                        <div className="jia">￥<span>{a.productPrice}</span>元/斤</div>
                        <div className="you">
                          <div className="youpin" >{a.recomSy == 0 ? '优品优选' : '乡村振兴'}</div>
                          <div className="name sheng">{a.goodsName}</div>
                        </div>
                        <div className="dian">
                          <img className="dianlogo" src={a.shopLogo} alt="  " />
                          <div className="dianright">
                            <div className="sheng">{a.shopName}</div>

                          </div>
                        </div>
                        <div className="diandi">
                          <div>
                            <LineChartOutlined className="icon" />
                            <span>{a.goodsSaleNum ? a.goodsSaleNum : '0'}</span>
                          </div>
                          <div>
                            <FieldTimeOutlined className="icon" />
                            <span>48小时发货</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                }
              </div>
              <div className="rightbuttom">
                <Pagination
                  pageSize='8'
                  current={remaifenye.pageNum}
                  onChange={this.remaipagination}
                  total={remaifenye.total}
                />
              </div>
            </div>
          </div>
          {/* ///////////////////////////////////////商品推荐专区////////////////////////////////////// */}
          <div className="hometype1" style={{ background: '#eeee' }}>
            <div className="dafenlei" style={{ background: `#ffffff url(${pic6}) no-repeat 100% 100%`, backgroundSize: '100% 100%' }} onClick={this.tofen}>
            </div>
            <div className="right">
              <div className="top">
                {
                  tuijian && tuijian.map(a => (
                    <Link target="_blank" key={a.goodsId} style={{height:'425px'}} to={'/home/details/' + a.goodsId}>
                      <div className="item hover">
                        {/* <Link target="_blank" to={'/home/details'}> */}
                        <img className="img" src={a.goodsImg} alt="辣椒" />
                        <div className="jia">￥<span>{a.productPrice}</span>元/斤</div>
                        <div className="you">
                          <div className="youpin" >{a.recomSy == 0 ? '优品优选' : '乡村振兴'}</div>
                          <div className="name sheng">{a.goodsName}</div>
                        </div>
                        <div className="dian">
                          <img className="dianlogo" src={a.shopLogo} alt="  " />
                          <div className="dianright">
                            <div className="sheng">{a.shopName}</div>

                          </div>
                        </div>
                        <div className="diandi">
                          <div>
                            <LineChartOutlined className="icon" />
                            <span>{a.goodsSaleNum ? a.goodsSaleNum : '0'}</span>
                          </div>
                          <div>
                            <FieldTimeOutlined className="icon" />
                            <span>48小时发货</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                }
              </div>
              <div className="rightbuttom">
                <Pagination
                  pageSize='8'
                  current={tuijianfenye.pageNum}
                  onChange={this.tuijianpagination}
                  total={tuijianfenye.total}
                />
              </div>
            </div>
          </div>
        </>
      )
    }
    const { homePage } = this.state;
    return (
      <>
        <Item src={pic2} />
      </>
    );
  }
}
export default withRouter(index)