import React, { Component } from 'react';
import './index.scss';
import copy from 'copy-to-clipboard';
import geren from '../../../../../static/img/geren.png'
import qiye from '../../../../../static/img/qiye1.png'
import Tuijian from './caigoutuijian'
import { Popconfirm, message, Button,Image  } from 'antd';
import { withRouter } from 'react-router-dom';
import request from '../../../../../static/js/http';
class Gongyingdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gongyingdetails: []
    }
    this.img = React.createRef();
  }
  componentDidMount() {
    this.gongyingdetails()
  }
  gongyingdetails() {
    let id = this.props.location.state.id
    ////console.log(id);
    request('post', '/api/apply/supply/selectOne', {
      id
    }).then(res => {
      ////console.log(res);
      this.setState({
        gongyingdetails: res.data
      })
    })
  }
  renzheng = () => {
    const { gongyingdetails } = this.state
    switch (gongyingdetails.memeberlevel) {
      case 0: return geren;
      case 1: return qiye;
      case 2: return geren;
      default: return geren
    }
  }
  shenfen = () => {
    const { gongyingdetails } = this.state
    switch (gongyingdetails.memeberlevel) {
      case 0: return '个人';
      case 1: return '企业';
      case 2: return '经纪人';
      default: return '个人'
    }
  }

  render() {
    const { gongyingdetails } = this.state
    let img = eval(gongyingdetails.image)
    const text = gongyingdetails.memberphone
    function confirm() {
      copy(text)
      message.success('复制成功，去📞吧');
    }
    return (
      <>
        <div className="gongyingxiangqing">
          <div className="gongyinginfo">
            <div className="gongyinginfotop">
              供应大厅信息
            </div>
            <div className="gongyinginfobody">
              <div className="body-left">
                <div className="gongying-item">
                  <span className="itemtext-lf">标题：</span>
                  <span className="itemtext-lr">{gongyingdetails.title}</span>
                </div>
                <div className="gongying-item">
                  <span className="itemtext-lf">供应价格：</span>
                  <span className="itemtext-lr">{gongyingdetails.supperPrice}</span>
                </div>
                <div className="gongying-item">
                  <span className="itemtext-lf">采购品种：</span>
                  <span className="itemtext-lr">{gongyingdetails.goodclass}</span>
                </div>
                <div className="gongying-item">
                  <span className="itemtext-lf">等级：</span>
                  <span className="itemtext-lr">{gongyingdetails.level}</span>
                </div>
                <div className="gongying-item">
                  <span className="itemtext-lf">收货地：</span>
                  <span className="itemtext-lr">{gongyingdetails.go}</span>
                </div>
                <div className="gongying-item">
                  <span className="itemtext-lf">货源地：</span>
                  <span className="itemtext-lr">{gongyingdetails.fromword}</span>
                </div>
                <div className="gongying-item">
                  <span className="itemtext-lf">发布时间：</span>
                  <span className="itemtext-lr">{gongyingdetails.createAt}</span>
                </div>
                <div className="gongying-item">
                  <span className="itemtext-lf">内容：</span>
                  <span className="itemtext-lr">{gongyingdetails.conment}</span>
                </div><br />
                <div className="gongying-item-pic">
                  {
                    img && img.map((item, index) => {
                      return <Image  key={index} src={item} alt="" />
                    })

                  }
                </div>
              </div>
              <div className="body-right">
                <Popconfirm
                  placement="topRight"
                  title={text}
                  onConfirm={confirm}
                  okText="复制"
                  cancelText="取消"
                >
                  <Button>手机号码</Button>
                </Popconfirm>
                {/* <div className="liaoyiliao">聊一聊</div> */}
              </div>
            </div>
          </div>
          <div className="gongyingreninfo">
            <div className="gongyingrentop">
              供应人信息
            </div>
            <div className="caogourenbody">
              <div className="gongyingrentouxiang">
                <img src={gongyingdetails.memeberavator} alt="" />
              </div>
              <div className="gongyingrenxiangqing">
                <div className="gongyingrenxiangqing-item">
                  <span>发布人：</span><span>{gongyingdetails.createby}</span><span>({this.shenfen()})</span>
                </div>
                <div className="gongyingrenxiangqing-item">
                  <span>认证情况：</span><img src={this.renzheng()} alt="" />
                </div>
                <div className="gongyingrenxiangqing-item">
                  <span>发布数量：</span><span>{gongyingdetails.total}条</span>
                </div>
              </div>
            </div>
          </div>
          <Tuijian />
        </div>
      </>
    )
  }
}
export default withRouter(Gongyingdetails)