import React, { Component } from 'react';
import './index.scss';
import upload from '../../../static/img/upload.png'
import cookie from 'react-cookies'
import { Rate, Form, Button, Select, Upload, message, Icon, Modal } from 'antd';
import request from '../../../static/js/http';

const FormItem = Form.Item;
class Toevaluate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: '',
      imgList: [],
      imgs: [],
      valuerate: 0,
      cont: 0,
      evaluatevalue: '',
      toevaluateInfo: [],
      isSecond: false,
    }
  }
  componentDidMount() {
    let id = this.props.location.state
    this.setState({
      toevaluateInfo: id,
      isSecond: true
    })

  }
  ordercomment() {
    let memberId = cookie.load('lj-pc-userInfo').memberId //会员Id
    const { toevaluateInfo, evaluatevalue, imgs, cont } = this.state
    let detailId = toevaluateInfo.detailId
    let commentImg = imgs.join(",")
    let star = cont
    ////console.log(commentImg);
    let commentList = [
      {
        detailId,
        content: evaluatevalue,
        star,
        commentImg, key: 1
      }
    ]
    ////console.log(commentList);
    request('post', '/api/order/order/comment', {
      memberId,
      orderId: toevaluateInfo.orderId,
      commentList
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        message.success('评价成功');
        this.props.history.push({ pathname: '/myProfile/order', state: { type: 10 } })
      } else {
        message.info('评价失败');
      }
    })
  }
  rate(e) {
    ////console.log(e);
    this.setState({
      cont: e
    })
  }
  evaluatevalue(e) {
    this.setState({
      evaluatevalue: e.target.value
    })
  }
  info() {
    const { toevaluateInfo } = this.state
    if (toevaluateInfo.item) {
      return toevaluateInfo.item
    }
  }
  /////////////////////////////////////////////////////////////////////////////
  //上传图片
  uploadhandleChange = ({ file, fileList }) => {
    fileList.forEach(imgItem => {
      if (imgItem && imgItem.status == 'done' && imgItem.response && imgItem.response.imgUrl) {
        imgItem.thumbUrl = imgItem.response.imgUrl;
      }
    });

    if (file.status === 'done') {
      let filesList = []
      fileList.map(item => {
        filesList.push(item.response.data)
      })
      setTimeout(() => {
        this.setState({
          imgs: filesList
        })
      }, 0);
    }
    setTimeout(() => {
      this.setState({
        imgList: fileList,
      });
    }, 0);
  };
  handleCancel = () => setTimeout(() => {
    this.setState({ previewVisible: false })
  }, 0);;

  handlePreview = file => {
    setTimeout(() => {
      this.setState({
        previewImage: file.url || file.thumbUrl,
        previewVisible: true,
      });
    }, 0);
  };

  handleBeforeUpload = file => {
    //限制图片 格式、size、分辨率
    const isJPG = file.type === 'image/jpeg';
    const isJPEG = file.type === 'image/jpeg';
    const isGIF = file.type === 'image/gif';
    const isPNG = file.type === 'image/png';
    if (!(isJPG || isJPEG || isGIF || isPNG)) {
      Modal.error({
        title: '只能上传JPG 、JPEG 、GIF、 PNG格式的图片~',
      });
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      Modal.error({
        title: '超过2M限制，不允许上传~',
      });
      return;
    }
    return (isJPG || isJPEG || isGIF || isPNG) && isLt2M && this.checkImageWH(file);
  };

  //返回一个 promise：检测通过则返回resolve；失败则返回reject，并阻止图片上传
  checkImageWH(file) {
    let self = this;
    return new Promise(function (resolve, reject) {
      let filereader = new FileReader();
      filereader.onload = e => {
        let src = e.target.result;
        const image = new Image();
        image.onload = function () {
          file.width = this.width;
          file.height = this.height;
          resolve();
        };
        image.onerror = reject;
        image.src = src;
      };
      filereader.readAsDataURL(file);
    });
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props;
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {// values 是form表单里的参数
      // 点击按钮后，将表单提交给后台
      dispatch({
        type: 'mymodel/submitFormData',
        payload: values,
      });
    });
  };
  render() {
    const { previewVisible, previewImage, cont, toevaluateInfo } = this.state; //  从 state 中拿数据
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text" style={{ fontSize: '20px' }} ><img src={upload} alt="" /></div>
      </div>
    );
    return (
      <>
        <div className="toevalueW">
          <div className="toevaluate">
            <div className="toevaluateleft">
              <div className="pic">
                {
                  this.state.isSecond && <img src={toevaluateInfo.item.goodsImg} alt="" />
                }
              </div>
              <div className="info">
                <div className="name">
                  {
                    this.state.isSecond && toevaluateInfo.item.goodsName
                  }
                </div>
              </div>
            </div>
            <div className="toevaluateright">
              <div className="evaluatergTop">
                商品描述：<Rate onChange={(e) => this.rate(e)} defaultValue={cont} /><span className="minute">{cont}分</span>
              </div>
              <div className="evaluatergContent">
                <textarea className="evaluatergText" onChange={(e) => this.evaluatevalue(e)} placeholder="亲，写点评价吧，你的评价对其他买家有很大帮助的。"></textarea>
              </div>
              <div className="toevaluateUpload">
                <Form onSubmit={this.handleSubmit} hideRequiredMark style={{ marginTop: 8 }}>
                  <FormItem>
                    <Upload
                      action="http://1.116.154.35:25921/clife-boot-api-app/api/cms/common/upload" // 这个是图片上传的接口请求，实际开发中，要替换成你自己的业务接口

                      listType="picture-card"
                      fileList={this.state.imgList}
                      onPreview={this.handlePreview} // 点击图片缩略图，进行预览
                      beforeUpload={this.handleBeforeUpload} // 上传之前，对图片的格式做校验，并获取图片的宽高
                      onChange={this.uploadhandleChange} // 每次上传图片时，都会触发这个方法
                    >
                      {this.state.imgList.length >= 4 ? null : uploadButton}

                    </Upload>
                  </FormItem>
                </Form>
                <div />
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                  <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
              </div>
            </div>
          </div>
          <div className="btn">
            <Button type="primary" onClick={() => this.ordercomment()}>发表评论</Button>
          </div>
        </div>
      </>
    )
  }
}
export default Toevaluate
