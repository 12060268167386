import React, { Component } from 'react';
import cookie from 'react-cookies'
import request from '../../../../static/js/http';
export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hotList: []
    };
  }
  componentDidMount() {
    this.hotList()
  }
  //热销榜
  hotList() {
    request('post', '/api/goods/goods/otherList', {
      memberId: cookie.load('lj-pc-userInfo') ? cookie.load('lj-pc-userInfo').memberId: '',
      type: 0,
      pageNum: 1,
      pageSize: 5,

    }).then(res => {
      ////console.log(res);
      this.setState({
        hotList: res.data
      })
    })
  }

  render() {
    const { hotList } = this.state;
    return (
      <div>
        {
          hotList.list && hotList.list.map(a => (
            <div key={a.goodsId} className="list hover">
              <div>
                <img src={a.goodsImg} alt="辣椒，大鹏辣椒" />
                <div className="jia">￥{a.productPrice}/斤</div>
              </div>
              <div>{a.goodsName}</div>
            </div>
          ))
        }
      </div>
    );
  }
}
