import React, { Component } from 'react';
import { Carousel } from 'antd';
import request from '../../static/js/http'
import banner from '../../static/img/banner.jpg'
import banner2 from '../../static/img/banner1.png'
import banner3 from '../../static/img/banner2.png'
import banner4 from '../../static/img/banner3.png'
export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [1, 2, 3],
      lunbo: [],
    };
  }
  componentDidMount() {
    this.lunbo()
  }
  lunbo() {
    request('post', '/api/cms/slide/list', {
      type: 1
    }).then(res => {
      console.log(res);
      this.setState({
        lunbo: res.data
      })
    })
  }
  render() {
    const { lunbo } = this.state;
    const contentStyle = {
      height: '600px',
      width: '100%',
      minWidth: '1400px',
      textAlign: 'center',
      backgroundSize: 'cover'
    };
    const lunboStyle = {
      width: '100%',
      height: '100%',
    }
    return (
      <div style={{ marginBottom: '30px' }} id="co">
        <Carousel autoplay>
          {
            lunbo.map(item => {
              return <div key={item.slideId}>
                <div style={contentStyle}>
                  <a target={item.slideMode!=1?'':'_blank'} href={item.slideMode!=1?'#':item.slideLink}>
                    <img style={lunboStyle} src={item.slideImg} alt="" />
                  </a>
                </div>
              </div>
            })
          }
          {/* <div>
            <div style={contentStyle}>
              <img style={lunboStyle} src={banner3} alt="" />
            </div>
          </div>
          <div>
            <div style={contentStyle}>
              <img style={lunboStyle} src={banner4} alt="" />
            </div>
          </div>
          <div >
            <div style={contentStyle}>
              <img style={lunboStyle} src={banner} alt="" />
            </div>
          </div> */}
        </Carousel>
      </div>
    );
  }
}
