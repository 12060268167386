import React, { Component, useState } from 'react';
import cookie from 'react-cookies';
import './index.scss'
export default class Lianxikefu extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render(){
    return(
      <>

      </>
    )
  }
}
