import React, { Component, useState } from 'react';
import cookie from 'react-cookies';
import { Tabs } from 'antd';
import Fuwu from './myfuwu'
import Pingjia from './mypingjia'
import './index.scss'
export default class Myserve extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  callback(key) {
    ////console.log(key);
  }
  render() {
    const { TabPane } = Tabs;
    const Demo = () => (
      <Tabs defaultActiveKey="1" onChange={this.callback}>
        <TabPane tab="我的服务" key="1">
          <Fuwu/>
        </TabPane>
        <TabPane tab="我的评价" key="2">
          <Pingjia/>
        </TabPane>
      </Tabs>
    );
    return (
      <>
        <div className="myserve">
          <Demo />
        </div>
      </>
    )
  }
}