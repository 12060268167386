import React, { Component } from 'react';
import { withRouter } from "react-router-dom"
import './index.scss';
import request from '../../../static/js/http';
import cookie from 'react-cookies';
class AliPayPc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zhifu: ''
    };

  }
  componentDidMount() {
    this.aliPayPc()
  }
  aliPayPc() {
    let orderId = this.props.location.state.orderId
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/order/order/aliPayPc', {
      memberId,
      orderId
    }).then(res => {
      let divForm = document.getElementsByTagName('divform')
      if (divForm.length) {
          document.body.removeChild(divForm[0])
      }
      const div = document.createElement('divform')
      div.innerHTML = res // 放入支付宝的表单数据
      document.body.appendChild(div)
      document.forms[0].setAttribute('target', '_blank') // 新开窗口跳转
      document.forms[0].submit()
    })
  }
  render() {
    return (
      <>
      </>
    )
  }
}

export default withRouter(AliPayPc)