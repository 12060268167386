import React, { Component } from 'react';
import { LineChartOutlined, FieldTimeOutlined } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import './index.scss';
import request from '../../static/js/http';
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: { name: '子弹头', id: 0, sty: true },
      searchData: [],
      pageNum: 1,
    };
  }
  componentDidMount() {
    this.search()
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      pageNum: nextProps.index1,
      goodsProp: nextProps.goodsProp
    }, function () {
      this.search()
    })
  }
  search() {
    request('post', '/api/goods/goods/appIndex', {
      classId: 0,
      pageNum: 1,
      pageSize: 10,
      lat: 1,
      lon: 1,
      sortType: 0,
    }).then(res => {
      this.setState({
        searchData: res.data
      })
    })
  }

  xiangqing(id) {
    this.props.history.push({ pathname: '/home/details', state: { id } })
  }
  render() {
    const { searchData } = this.state;
    return (
      <>
        <div className="itemitem">
          {
            searchData.list && searchData.list.map(item => (
              <Link target="_blank" key={item.goodsId} to={'/home/details/' + item.goodsId}>
                <div className="item111 hover"  >
                  <img className="img" src={item.goodsImg} alt="辣椒" />
                  <div className="jia">￥<span>{item.productPrice}</span>元/斤</div>
                  <div className="you">
                      <div className="youpin">{item.recomSy ==0 ? '优品优选': '乡村振兴'}</div> 
                    <div className="name sheng">{item.goodsName}</div>
                  </div>
                  <div className="dian">
                    <img className="dianlogo" src={item.shopLogo} alt=" " />
                    <div className="dianright">
                      <div className="sheng">{item.shopName}</div>
                      {/* <div className="sheng">河南省</div> */}
                    </div>
                  </div>
                  <div className="diandi">
                    <div>
                      <LineChartOutlined className="icon" />
                      <span>{item.goodsSaleNum?item.goodsSaleNum:'0'}</span>
                    </div>
                    <div>
                      <FieldTimeOutlined className="icon" />
                      <span>48小时发货</span>
                    </div>
                  </div>
                </div >
              </Link>
            ))
          }
        </div>
      </>
    );
  }
}
export default withRouter(index)