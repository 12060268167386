import React, { Component } from 'react'
import {
  Form, Input, Button, message, Row, Col, Upload
} from 'antd';
import cookie from 'react-cookies';
import { LoadingOutlined, PlusOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import request from '../../../static/js/http';
export default class Qiye extends Component {
  constructor() {
    super();
    this.state = {
      btnText: '发送验证码',
      btnBool: false,
      ipo: '',
      loading: false,
      imageUrl: '',
      loading1: false,
      imageUrl1: '',
      legalperson: '',
      enterpriseName: '',
      idNumber: '',
      codeValue: '',
      authCode: '',
    };
  }
  person() {
    const { ipo, authCode, idNumber, imageUrl, imageUrl1, codeValue, enterpriseName, legalperson } = this.state
    let memberId = cookie.load('lj-pc-userInfo').memberId
    if (!legalperson) {
      message.warning('请填写企业法人')
    } else if (!enterpriseName) {
      message.warning('请填写企业全称')
    } else if (!idNumber) {
      message.warning('请填写身份证号码')
    } else if (!authCode) {
      message.warning('请填写机构/社会信用代码')
    } else if (!imageUrl) {
      message.warning('请上传营业资质')
    } else if (!imageUrl1) {
      message.warning('请上传食品安全')
    } else if (!ipo) {
      message.warning('请填写手机号码')
    } else if (!codeValue) {
      message.warning('请填写验证码')
    } else {
      request('post', '/api/member/shop/auth', {
        memberId,
        authPhone: ipo,
        authName: '',
        authIdcard: '',
        authHead: '',
        authTail: '',
        authType: 1,
        authEnterprise: enterpriseName,
        authLicense: imageUrl,
        authFood: imageUrl1,
        authCode,
        authOperator: legalperson,
      }).then(res => {
        if (res.code == 0) {
          message.success(res.msg)
        } else {
          message.warning(res.msg)
        }
      })
    }
  }
  onFinish = values => {
    // eslint-disable-next-line no-console
  };

  code() {
    const { ipo } = this.state
    request('post', '/api/member/sms/send', {
      phone: ipo
    }).then(res => {
      if (res.code == 0) {
        message.success(res.msg)
      } else {
        message.warning(res.msg)
      }
    })
  }
  //企业法人
  legalperson(e) {
    this.setState({
      legalperson: e.target.value
    })
  }
  //企业全称
  enterpriseName(e) {
    this.setState({
      enterpriseName: e.target.value
    })
  }
  //身份证
  idNumber(e) {
    this.setState({
      idNumber: e.target.value
    })
  }
  //机构/社会信用代码
  authCode(e) {
    this.setState({
      authCode: e.target.value
    })
  }
  //手机号
  input(v) {
    this.setState({
      ipo: v.target.value
    });
  }
  //验证码
  codeValue(e) {
    this.setState({
      codeValue: e.target.value
    })
  }
  sendCode() {
    const { ipo } = this.state;
    const text = /^[1][3,4,5,7,8,9][0-9]{9}$/;
    if (!text.test(ipo)) {
      message.error('手机号格式错误');
    } else {
      this.code()
      let maxTime = 60;
      this.timer = setInterval(() => {
        if (maxTime > 0) {
          --maxTime;
          this.setState({
            btnText: `重新获取${maxTime}s`,
            btnBool: true
          });
        } else {
          this.setState({
            btnText: '发送验证码',
            btnBool: false
          });
        }
      }, 1000);
    }
  }

  handleChange = (info, a) => {
    if (info.file.status === 'uploading') {
      if (a === 1) {
        this.setState({ loading: true });
      } else {
        this.setState({ loading1: true });
      }
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      if (a === 1) {
        this.setState({
          imageUrl: info.file.response.data,
          loading: false
        })
      } else {
        this.setState({
          imageUrl1: info.file.response.data,
          loading1: false
        })
      }
    }
  };
  render() {
    const { btnText, btnBool, loading, imageUrl, loading1, imageUrl1 } = this.state;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>上传营业执照</div>
      </div>
    );
    const uploadButton1 = (
      <div>
        {loading1 ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>上传食品安全资质</div>
      </div>
    );
    return (
      <div className="qiye">
        <Form
          name="normal_login1"
          className="login-form"
          autoComplete="off"
          initialValues={{
            remember: true
          }}
          wrapperCol={{ span: 14 }}
          labelCol={{ span: 6 }}
          size="large"
          onFinish={values => this.onFinish(values)}
          id="mobilelogin1"
        >
          <Form.Item
            name="faren"
            label="企业法人"
            rules={[{
              required: true,
              message: '请输入法定身份证姓名'
            }
            ]}
          >
            <Input

              autoComplete="off"
              onChange={v => this.legalperson(v)}
              placeholder="请输入法定身份证姓名"
            />
          </Form.Item>
          <Form.Item
            name="quancheng"
            label="企业全称"
            rules={[{
              required: true,
              message: '请按照营业执照上的名字填写'
            }
            ]}
          >
            <Input
              autoComplete="off"
              onChange={v => this.enterpriseName(v)}
              placeholder="请按照营业执照上的名字填写"
            />
          </Form.Item>
          <Form.Item
            name="id"
            label="身份证"
            rules={[
              {
                required: true,
                message: '请输入身份证号'
              }, {
                pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
                message: '不合法的身份证号格式!'
              }
            ]}
          >
            <Input
              type="number"
              autoComplete="off"
              maxLength="18"
              onChange={v => this.idNumber(v)}
              placeholder="请输入身份证号"
            />
          </Form.Item>
          <Form.Item
            name="xinyong"
            label="机构/社会信用代码"
            rules={[
              {
                required: true,
                message: '机构/社会信用代码'
              }
            ]}
          >
            <Input
              type="number"
              autoComplete="off"
              onChange={v => this.authCode(v)}
              placeholder="机构/社会信用代码"
            />
          </Form.Item>
          <Form.Item
            label="营业资质"
            rules={[{ required: true }]}
            className="tu"
          >
            <Upload
              action="http://1.116.154.35:25921/clife-boot-api-app/api/cms/common/upload"
              name="file"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              onChange={(i) => this.handleChange(i, 1)}
            >
              {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%', height: '100%' }} /> : uploadButton}
            </Upload>
            <Row>
              <Col style={{ textAlign: 'left', color: '#C7C7C7' }} span={12}>
                <ExclamationCircleFilled style={{ color: '#D94511', marginRight: '4px' }} /><span>要求：证件文字清晰，建议使用原图；</span>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            label="食品安全"
            rules={[{ required: true }]}
            className="tu"
          >
            <Upload
              action="http://1.116.154.35:25921/clife-boot-api-app/api/cms/common/upload"
              name="file"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              onChange={(i) => this.handleChange(i, 2)}
            >
              {imageUrl1 ? <img src={imageUrl1} alt="avatar" style={{ width: '100%', height: '100%' }} /> : uploadButton1}
            </Upload>
            <Row>
              <Col style={{ textAlign: 'left', color: '#C7C7C7' }} span={12}>
                <ExclamationCircleFilled style={{ color: '#D94511', marginRight: '4px' }} /><span>要求：证件文字清晰，建议使用原图；</span>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            name="ipo"
            label="手机号"
            rules={[
              {
                required: true,
                message: '请输入手机号'
              }, {
                pattern: /^1\d{10}$/,
                message: '不合法的手机号格式!'
              }
            ]}
          >
            <Input
              type="number"
              autoComplete="off"
              onChange={v => this.input(v)}
              maxLength="11"
              placeholder="请输入手机号"
            />
          </Form.Item>
          <Form.Item
            label="验证码"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Row>
              <Col span={16}>
                <Form.Item
                  name="yanzheng"
                  rules={[
                    {
                      required: true,
                      message: '请输入验证码!'
                    }
                  ]}
                  className="yan"
                >
                  <Input autoComplete="off"
                    type="number"
                    onChange={v => this.codeValue(v)}
                    placeholder="请输入验证码!" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Button className="botton" onClick={() => this.sendCode()} type="primary" disabled={btnBool}>{btnText}</Button>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item wrapperCol={{ span: 8, offset: 6 }} className="an">
            <Button type="primary" onClick={() => this.person()} htmlType="submit" className="bo">
              确认提交
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}
