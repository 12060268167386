import React, { Component } from 'react';
import { Breadcrumb } from 'antd';
import './index.scss'

export default class Newsitem extends Component {
  render() {
    return (
      <div className="news">
        <div className="breadcrumb">
          <div className="nei">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>当前位置</Breadcrumb.Item>
              <Breadcrumb.Item>买家保障</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="safeguard">
          <div className='safeguardTop'>
            什么是买家保障服务<br /><br />
          </div>
          <div className="safeguardContent">
            买家保障服务是惠农网为保障平台上买家权益而打造的保障体系，提供买家保障服务的商家为买家保障商家。当买家通过惠农网与买家保障商家交易，且商家存在违约行为时，买家可以依据买家保障规则向商家申请赔付。当买家申请赔付成功且商家拒绝赔付时可申请惠农网介入，惠农网将按照协议内容划扣买家保障商家的保证金代为赔付给买家。<br /> 买家保障赔付为独立于常规售后流程外的额外赔付，买家申请买家保障赔付时，仍有权力申请常规售后服务（如：申请补发、退货或退款等）。
            <br />
            买家保障服务范围
            <br />
            <br /> 目前买家保障服务包含基础服务（指商家必须向买家提供的强制性服务）和可选服务（指商家可自行选择是否向买家提供的服务）两大类，具体包含：
            <br /> 1.未按时发货赔付：指买家通过惠农网在线交易下单付款后，提供了未按时发货保障服务的商家未在双方约定日期（包含：商家单方面承诺时间内发货）内，则买家有权申请赔付。未按时发货赔付中的收钱不发货赔付、未按约定时间发货赔付为基础服务，24/48/72小时发货为可选服务。
            <br /> 2.货不对板赔付：指在线交易中，商家存在货不对板行为时，买家有权申请赔付。货不对板行为包含但不限于：商家提供商品的品种、规格、品质、货源等信息和双方在平台预留约定不一致；商家恶意跨平台倒货（如：商品包装、服务信息中包含非惠农网等其他第三方平台信息）等。货不对板赔付为基础服务。
            <br /> 3.少货赔付：指买家实际收到货品的重量、数量和双方在平台预留约定不一致或差值超出行业标准范围时，买家可申请赔付。少货赔付为基础服务。
            <br /> 4.虚假价格赔付：指商家承诺加入了该服务的商品供应价格真实有效，如果买家在针对提供本服务的货品进行询盘或交易时，发现商家实际报价比供应详情（不含预售供应）内标准偏高，且并非市场正常价格波动导致的，买家可以举报商家并申请赔付。虚假价格赔付为基础服务。
            <br /> 5.承诺在线交易：指商家承诺加入了该服务的供应所产生的所有订单都走惠农在线交易，且商家有拒绝买家通过其他非惠农网官方渠道交易的义务。如果商家违约则买家可以举报并申请赔付。承诺在线交易为可选服务。
            <br /> 6.7天退换货服务：指买家购买了已加入本服务的商品后，可以在商品实际到货后（指买家实际收到货品时间，或买家同意或默认的代签收时间）7天内，可以申请退货或换货。如果商家拒绝提供该服务，则买家可以申请赔付。7天退换货服务为可选服务。
            <br /> 目前土地流转、飞防服务、农工服务、农业防治、农业检测、租赁服务等供应暂不支持任一买家保障服务，生鲜易腐类供应（如水果、蔬菜等）不支持7天退换货服务；部分类目（点击此处查看详细类目）不支持展示买家保障图标，但商家仍需遵守买家保障相关义务。
          </div>
        </div>
      </div>
    );
  }
}
