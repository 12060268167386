import React, { Component } from 'react';
import { withRouter } from "react-router-dom"
import cookie from 'react-cookies'
import './index.scss';
import request from '../../../../static/js/http';
class Hotlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hotList: [],
      commonList: []
    }
  }
  componentDidMount() {
    this.hotList()
    this.commonList()
  }
  //热门行情
  hotList() {
    request('post', '/api/market/information/list', {
      pageNum: 1,
      pageSize: 10,
      type: 1,
    }).then(res => {
      ////console.log(res);
      this.setState({
        hotList: res.data
      })
    })
  }
  //普通行情
  commonList() {
    request('post', '/api/market/information/list', {
      pageNum: 1,
      pageSize: 10,
      // type: 1,
    }).then(res => {
      ////console.log(res);
      this.setState({
        commonList: res.data
      })
    })
  }
  xiangqing(id){
    this.props.history.push({ pathname: '/home/MarketConsultDetails', state: { id } })
  }
  render() {
    const { hotList, commonList } = this.state
    return (
      <>
        <div className="hotlist">
          <div className="hotlistleft">
            <div className="hotlistTop">
              <div className="left">
                <div className="red"></div>
                <div className="title">
                  热门行情
                </div>
              </div>
            </div>
            <div className="content">
              {
                hotList.list && hotList.list.map(item => {
                  return <div onClick={()=>this.xiangqing(item.informationId)} className="item" key={item.informationId}>
                    <div className="title">
                      {item.title}
                    </div>
                    <div className="time">
                      发布时间：{item.createAt}
                    </div>
                    <div className="neirong">
                      <div className="itemContent" dangerouslySetInnerHTML={{ __html: `${item.content}` }}></div>
                    </div>
                  </div>
                })
              }

            </div>
          </div>
          <div className="hotlistleft">
            <div className="hotlistTop">
              <div className="left">
                <div className="red"></div>
                <div className="title">
                  普通行情
                </div>
              </div>
            </div>
            <div className="content">

              {
                commonList.list && commonList.list.map(item => {
                  return <div onClick={()=>this.xiangqing(item.informationId)} className="item" key={item.informationId}>
                    <div className="title">
                      {item.title}
                    </div>
                    <div className="time">
                      发布时间：{item.createAt}
                    </div>
                    <div className="neirong">
                      <div className="itemContent" dangerouslySetInnerHTML={{ __html: `${item.content}` }}></div>
                    </div>
                  </div>
                })
              }
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(Hotlist)