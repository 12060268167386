import React, { Component } from 'react';
import './index.scss';
import copy from 'copy-to-clipboard';
import { Popconfirm, message, Button , Image  } from 'antd';
import geren from '../../../../../static/img/geren.png'
import qiye from '../../../../../static/img/qiye1.png'
import { withRouter } from 'react-router-dom';
import request from '../../../../../static/js/http';
class Qiuzudetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qiuzudetails: []
    }
    this.img = React.createRef();
  }
  componentDidMount() {
    this.qiuzudetails()
  }
  qiuzudetails() {
    let id = this.props.location.state.id
    request('post', '/api/storage/lease/selectOne', {
      id
    }).then(res => {
      ////console.log(res);
      this.setState({
        qiuzudetails: res.data
      })
    })
  }
  renzheng = () => {
    const { qiuzudetails } = this.state
    switch (qiuzudetails.memeberlevel) {
      case 0: return geren;
      case 1: return qiye;
      case 2: return geren;
      default: return geren
    }
  }
  shenfen = () => {
    const { qiuzudetails } = this.state
    switch (qiuzudetails.memeberlevel) {
      case 0: return '个人';
      case 1: return '企业';
      case 2: return '经纪人';
      default: return '个人'
    }
  }
  render() {
    const { qiuzudetails } = this.state
    let img = eval(qiuzudetails.image)
    const text = qiuzudetails.memberphone
    function confirm() {
      copy(text)
      message.success('复制成功，去📞吧');
    }
    return (
      <>
        <div className="qiuzuxiangqing">
          <div className="qiuzuinfo">
            <div className="qiuzuinfotop">
              冷库求租信息
            </div>
            <div className="qiuzuinfobody">
              <div className="body-left">
                <div className="qiuzu-item">
                  <span className="itemtext-lf">标题：</span>
                  <span className="itemtext-lr">{qiuzudetails.title}</span>
                </div>
                <div className="qiuzu-item">
                  <span className="itemtext-lf">出租价格：</span>
                  <span className="itemtext-lr">{qiuzudetails.price}元/月</span>
                </div>
                <div className="qiuzu-item">
                  <span className="itemtext-lf">出租面积：</span>
                  <span className="itemtext-lr">{qiuzudetails.area}m²</span>
                </div>
                <div className="qiuzu-item">
                  <span className="itemtext-lf">冷库地址：</span>
                  <span className="itemtext-lr">{qiuzudetails.address}</span>
                </div>
                <div className="qiuzu-item">
                  <span className="itemtext-lf">发布时间：</span>
                  <span className="itemtext-lr">{qiuzudetails.createAt}</span>
                </div>
                <div className="qiuzu-item">
                  <span className="itemtext-lf">内容：</span>
                  <span className="itemtext-lr">{qiuzudetails.content}</span>
                </div><br />
                <div className="qiuzu-item-pic">
                  {
                    img && img.map((item, index) => {
                      return <Image  key={index} src={item} alt="" />
                    })

                  }
                </div>
              </div>
              <div className="body-right">
                <Popconfirm
                  placement="topRight"
                  title={text}
                  onConfirm={confirm}
                  okText="复制"
                  cancelText="取消"
                >
                  <Button>手机号码</Button>
                </Popconfirm>
                {/* <div className="liaoyiliao">聊一聊</div> */}
              </div>
            </div>
          </div>
          <div className="qiuzureninfo">
            <div className="qiuzurentop">
              发布人信息
            </div>
            <div className="caogourenbody">
              <div className="qiuzurentouxiang">
                <img src={qiuzudetails.memeberavator} alt="" />
              </div>
              <div className="qiuzurenxiangqing">
                <div className="qiuzurenxiangqing-item">
                  <span>发布人：</span><span>{qiuzudetails.createBy}</span><span>({this.shenfen()})</span>
                </div>
                <div className="qiuzurenxiangqing-item">
                  <span>认证情况：</span><img src={this.renzheng()} alt="" />
                </div>
                <div className="qiuzurenxiangqing-item">
                  <span>发布数量：</span><span>{qiuzudetails.total}条</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(Qiuzudetails)