import React, { Component, useState } from 'react';
import cookie from 'react-cookies';
import './index.scss'
import Biaodan from './biaodan'
import Biaoge from './biaoge'
export default class Mydizhi extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    return (
      <>
        <div className="mydizhi">
          <div className="biaoti">
            地址管理
          </div>
          <div className="shouhuobiaoti">
            新增收货地址
            <Biaodan />
          </div>
        </div>
      </>
    )
  }
}