import React, { Component } from 'react';
import './index.scss';
import cookie from 'react-cookies';
import * as echarts from 'echarts';
import request from '../../../static/js/http';
class PriceTrend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: [
        { name: '昨日', id: 0, type: 0 },
        { name: '7日', id: 1, type: 1 },
        { name: '30日', id: 2, type: 2 }
      ],
      timeIndex:0,
      currentIndex: 2,
      type: 0,
      marketList: [],
      data:'昨日'
    }
  }
  componentDidMount(){
    this.zhexian()
    this.market()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentIndex } = this.state
    if (currentIndex !== nextProps.currentIndex) {
        this.setState({
          currentIndex: nextProps.currentIndex,
        },function () {
          this.market()
          ////console.log(this.state.currentIndex);
        })
    }
  }

  tab = (type, index) => {
    this.setState({
      timeIndex: index,
      type
    }, function () {
      this.market()
      if (type===0) {
        this.setState({
          data:'昨日'
        })
      }else if (type===1) {
        this.setState({
          data:'7日'
        })
      }else{
        if (type===2) {
          this.setState({
            data:'30日'
          })
        }
      
      }
    })
  }
  market() {
    const { type,currentIndex } = this.state
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/market/hall/list', {
      memberId,
      type,
      classId: currentIndex
    }).then(res => {
      ////console.log(res);
      this.setState({
        marketList: res.data
      }, function () {
        this.zhexian()
      })
    })
  }

  zhexian = () => {
    const { marketList } = this.state

    var chartDom = document.getElementById('main');
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: marketList.nameList && marketList.nameList.map(item => {
          return item
        })
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        data: marketList.priceList && marketList.priceList.map(item => {
          return item
        }),
        type: 'line',
        areaStyle: {}
      }]
    };
    option && myChart.setOption(option)
  }
  render() {
    const { time,timeIndex, currentIndex,marketList,data} = this.state
    ////console.log(marketList);
    return (
      <>
        <div className="priceTrendW">
          <div className="priceTrend">
            <div className="timeTab">
            {
                time.map((item, index) => {
                  return <div className={`day ${timeIndex === index ? 'active' : ''}`} onClick={() => this.tab(item.type, index)} key={item.id}>
                    {item.name}
                  </div>
                })
              }
            </div>
            <div className="info">
              <div className="infoItemList">
                <div className="infoItem">
                  <div className="data">
                    <span>{data}均价</span>
                  </div>
                  <div className="price">
                  {marketList.avgPrice}
                </div>
                  <div className="time">

                  </div>
                </div>
                <div className="infoItem">
                  <div className="data">
                    <span>{data}最低 </span>
                  </div>
                  <div className="price">
                  {marketList.minPrice}
                </div>
                  <div className="time">
                    {marketList.minDay}
                </div>
                </div>
                <div className="infoItem">
                  <div className="data">
                    <span>{data}最高 </span>
                  </div>
                  <div className="price">
                  {marketList.maxPrice}
                </div>
                  <div className="time">
                    {marketList.maxDay}
                </div>
                </div>
              </div>
              <div className="infoBottom">
                <div className="updateTime">
                  更新时间:{marketList.nowTime}
              </div>
                <div className="unit">
                  单位:元/斤
              </div>
              </div>
            </div>
            <div id="main" style={{ width: 700, height: 456 }}></div>
          </div>
        </div>

      </>
    )
  }
}
export default PriceTrend