import React, { Component } from 'react';
import request from '../../../static/js/http';
import Carousel1 from '../../../components/Carousel1';
import Pricetrend from './Pricetrend'
import Pricedistributionarea from './Pricedistributionarea'
import Piechart from './Piechart'
import Priceexpectation from './Priceexpectation'
import Hotlist from './Hotlist/index'
import './index.scss';
class Market extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: '',
      flg: true,
      varietyList: [],
      type: '',
      timeType: 1,
      areaType:'',
      areaCode:''
    }
    this.child = React.createRef();
  }
  componentDidMount() {
    this.variety()
    this.timeType()
    this.areaType()
    this.areaCode()
    ////console.log(this.child.current.state.type);
    this.setState({
      type: this.child.current.state.type
    })
  }
  variety() {
    request('post', '/api/market/dock/productList').then(res => {
      //console.log(res);
      this.setState({
        varietyList: res.data
      },  ()=> {
        console.log('aaaaaaaaaa');
        if (this.state.currentIndex == '') {
          this.setState({
            // currentIndex: res.data[0].agriculturalProduct[0].productId
          })
          ////console.log(this.state.currentIndex);
        }
      })
    })
  }
  tab = (classId) => {
    ////console.log(classId);
    // this.child.market()
    ////console.log(this);
    this.setState({
      currentIndex: classId
    })
  }

  timeType = (params) => {
    this.setState({
      timeType: params
    }, function () {
      ////console.log(params);
    })
  }
  areaType = (params) => {
    //console.log(params);
    this.setState({
      areaType:params
    })
  }
  areaCode = (params) => {
    //console.log(params);
    this.setState({
      areaCode:params
    })
  }
  // flg(){
  //   this.setState({
  //     flg:!flg
  //   })
  // }
  render() {
    const { varietyList, currentIndex } = this.state
    ////console.log(varietyList);
    // ////console.log('我更新了');
    return (
      <>
              <Carousel1 />
        <div className="market">
          <div className="marketTopW">
            <div className="marketTop">
              <div className="varietyList">
                {
                  varietyList && varietyList.map((item, index) => {
                    return item.agriculturalProduct && item.agriculturalProduct.map(ite => {
                      return <div className={`varietyItem ${currentIndex === ite.productId ? 'active' : ''}`} key={ite.productId} onClick={() => this.tab(ite.productId)}>
                        {ite.productName}
                      </div>
                    })
                  })
                }
              </div>

            </div>
          </div>
          <Pricetrend ref={this.child} onClick={this.timeType} areaType={this.areaType} areaCode={this.areaCode} currentIndex={this.state.currentIndex} />
          <Pricedistributionarea currentIndex={this.state.currentIndex} areaType={this.state.areaType} areaCode ={this.state.areaCode}type={this.state.timeType} />
          {/* <Piechart currentIndex={this.state.currentIndex} /> */}
          <Priceexpectation currentIndex={this.state.currentIndex} />
          <Hotlist />
        </div>
      </>
    )
  }
}
export default Market