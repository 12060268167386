import React, { Component } from 'react';
import './index.scss';

export default class Footer extends Component {
  render() {
    return (
      <div id="Footer">
        <div>
          <span>客服热线：15738786666</span>
          <span>邮箱：1985786934@qq.com</span>
        </div>
        <div>版权所有：河南省颍山红农业开发有限公司 </div>
        <div>网站备案/许可证号：<a href="https://beian.miit.gov.cn">豫ICP备2021005134号-1</a>`</div>
      </div>
    );
  }
}
