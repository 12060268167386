import React, { Component, useState } from 'react';
import cookie from 'react-cookies';
import './index.scss'
import { Modal, Button, Pagination, message, Image } from 'antd';
import Xiugai from './xiugaiqiuzu'
import request from '../../../static/js/http';
export default class Myzuping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      biaotitab: [
        { name: '求租中', id: '1', type: '0' },
        { name: '已结束', id: '2', type: '1' },
        { name: '被驳回', id: '3', type: '2' },
      ],
      itemList: [
        { img: 'https://t.irocks.cn/cherry_custom/imgs/20200429/42554d773c5c9949f98bdf1dd08ed957.jpg', name: '辣椒-子弹头', jiage: 200, lv: '花皮', connet: '优质好货', id: '1', type: '2' },
        { img: 'https://t.irocks.cn/cherry_custom/imgs/20200429/42554d773c5c9949f98bdf1dd08ed957.jpg', name: '辣椒-子弹头', jiage: 200, lv: '花皮', connet: '优质好货', id: '2', type: '2' },
        { img: 'https://t.irocks.cn/cherry_custom/imgs/20200429/42554d773c5c9949f98bdf1dd08ed957.jpg', name: '辣椒-子弹头', jiage: 200, lv: '花皮', connet: '优质好货', id: '3', type: '2' },
        { img: 'https://t.irocks.cn/cherry_custom/imgs/20200429/42554d773c5c9949f98bdf1dd08ed957.jpg', name: '辣椒-子弹头', jiage: 200, lv: '花皮', connet: '优质好货', id: '4', type: '2' },


      ],
      isbianji: false,
      isdel: false,
      isover: false,
      isup: false,
      currentIndex: 0,
      storagelist: [],
      fenye: [],
      statu: 0,
      leaseId: '',

    }
    this.child = React.createRef();
  }
  componentDidMount() {
    this.getstoragelist()
  }
  //我的供应列表
  getstoragelist() {
    const { statu } = this.state
    ////console.log(statu);
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/storage/lease/listByUserId', {
      memberId,
      statu,
      pageSize: 5,
      pageNum: 1
    }).then(res => {
      ////console.log(res);
      if (res.code == 0) {
        this.setState({
          storagelist: res.data.list,
          fenye: res.data
        })
        message.success(res.msg);
      } else {
        message.warning(res.msg);
      }

    })
  }
  // 下架
  leaseover() {
    const { leaseId } = this.state
    ////console.log(leaseId);
    request('post', '/api/storage/lease/updateStorageLease', {
      leaseId,
      statu: 1,
    }).then(res => {
      ////console.log(res);
      if (res.code == 0) {
        this.getstoragelist()
        message.success(res.msg);
      } else {
        message.warning(res.msg);
      }
    })
  }
  // 上架
  leaseup() {
    const { leaseId } = this.state
    ////console.log(leaseId);
    request('post', '/api/storage/lease/updateStorageLease', {
      leaseId,
      statu: 0,
    }).then(res => {
      ////console.log(res);
      if (res.code == 0) {
        this.getstoragelist()
        message.success(res.msg);
      } else {
        message.warning(res.msg);
      }
    })
  }
  // 删除
  leasedel() {
    const { leaseId } = this.state
    ////console.log(leaseId);
    request('post', '/api/storage/lease/deleteStorageLease', {
      id: leaseId,
    }).then(res => {
      ////console.log(res);
      if (res.code == 0) {
        this.getstoragelist()
        message.success(res.msg);
      } else {
        message.warning(res.msg);
      }
    })
  }
  //修改
  reg = () => {
    const { leaseId } = this.state
    ////console.log(leaseId);
    let memberId = cookie.load('lj-pc-userInfo').memberId
    let title = this.child.current.state.title
    request('post', '/api/storage/lease/updateStorageLease', {
      memberId,
      title,
      leaseId,
      price: this.child.current.state.price,
      area: this.child.current.state.qiuzuarea,
      address: this.child.current.state.address,
      imagesPath: this.child.current.state.imagesPath,
      content: this.child.current.state.conent,
      statu: 0,
    }).then(res => {
      ////console.log(res);

      if (res.code == 0) {
        this.getstoragelist()
        message.success({
          content: res.msg,
        });
        this.bianjiOk()
      }
    })
  }
  //分页
  pagination = (a, b) => {
    ////console.log(a, b);
    const { statu } = this.state
    ////console.log(statu);
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/storage/lease/listByUserId', {
      pageSize: b,
      pageNum: a,
      memberId,
      statu,
    }).then(res => {
      ////console.log(res);
      if (res.code == 0) {
        this.setState({
          storagelist: res.data.list,
          fenye: res.data
        })
        message.success(res.msg);
      }
    })
  }
  tab = (type, index) => {
    ////console.log(index);
    this.setState({
      currentIndex: index,
      statu: type
    }, function () {
      this.getstoragelist()
    })
  }
  showbianji = (leaseId) => {
    this.setState({
      leaseId,
      isbianji: true
    })
  };

  bianjiOk = () => {
    this.setState({
      isbianji: false
    })
  };

  bianjiCancel = () => {
    this.setState({
      isbianji: false
    })
  };
  //删除商品
  showdel = (leaseId) => {
    ////console.log(leaseId);
    this.setState({
      isdel: true,
      leaseId
    })
  };

  delOk = () => {

    this.setState({
      isdel: false,
    }, function () {
      this.leasedel()
    })
  };

  delCancel = () => {
    this.setState({
      isdel: false
    })
  };
  //下架商品
  showover = (leaseId) => {
    ////console.log(leaseId);
    this.setState({
      isover: true,
      leaseId
    })
  };

  overOk = () => {

    this.setState({
      isover: false,
    }, function () {
      this.leaseover()
    })
  };

  overCancel = () => {
    this.setState({
      isover: false
    })
  };
  //上架商品
  showup = (leaseId) => {
    ////console.log(leaseId);
    this.setState({
      isup: true,
      leaseId
    })
  };

  upOk = () => {
    this.setState({
      isup: false,
    }, function () {
      this.leaseup()
    })
  };

  upCancel = () => {
    this.setState({
      isup: false
    })
  };
  render() {
    const { biaotitab, itemList, storagelist, fenye, statu } = this.state
    return (
      <>
        <div className="myzuping">
          <div className="biaoti">
            冷库求租
          </div>
          <div className="zuping">
            <div className="zupingtab">
              {
                biaotitab.map((item, index) => {
                  return <div className={`tabitem ${this.state.currentIndex === index ? 'active' : ''}`} onClick={() => this.tab(item.type, index)} key={item.id}>
                    {item.name}
                  </div>
                })
              }
            </div>
            <table className="listTable">
              <thead className="listThead">
                <tr className="listTr">
                  <th>主图</th>
                  <th>标题</th>
                  <th>求租位置</th>
                  <th>求租面积</th>
                  <th>内容</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody className="listTbody">
                {
                  storagelist && storagelist.map(item => (
                    <tr className="TbodyTr" key={item.leaseId}>
                      <td>
                        <Image width={80} src={item.image.replace(/(\[)|(\])|(\")/g, "").split(",")[0]} alt="" />
                      </td>
                      <td>{item.title}</td>
                      <td>{item.address}</td>
                      <td>{item.area}平方</td>
                      <td>{item.content}</td>
                      {
                        statu == 0 ?
                          <td>
                            <Button onClick={() => this.showbianji(item.leaseId)}>
                              编辑
                         </Button>
                            <Button onClick={() => this.showover(item.leaseId)}>
                              下架
                         </Button>
                          </td> :
                          (statu == 1 ?
                            <td>
                              <Button onClick={() => this.showdel(item.leaseId)}>
                                删除供应
                         </Button>
                              <Button onClick={() => this.showup(item.leaseId)}>
                                重新上架
                         </Button>
                            </td> :
                            <td>
                              <Button onClick={() => this.showdel(item.leaseId)}>
                                删除
                         </Button>
                              <Button onClick={() => this.showbianji(item.leaseId)}>
                                修改
                         </Button>
                            </td>
                          )
                      }
                    </tr>

                  ))
                }
              </tbody>
            </table>
          </div>
          <Pagination pageSize='5' current={fenye.pageNum} defaultCurrent={1} onChange={this.pagination} total={fenye.total} />
        </div>
        <Modal className="bianjiModal" title="编辑" visible={this.state.isbianji} onOk={this.bianjiOk} onCancel={this.bianjiCancel}
          footer={[
            <Button onClick={() => this.reg()} type="primary">
              确认编辑
                        </Button>
          ]}
        >
          <Xiugai ref={this.child} />
        </Modal>
        <Modal title="删除信息"
          cancelText={'取消'}
          okText={'确认'}
          visible={this.state.isdel} onOk={() => this.delOk()} onCancel={this.delCancel}>
          确认删除该商品
          </Modal>
        <Modal title="下架信息"
          cancelText={'取消'}
          okText={'确认'}
          visible={this.state.isover} onOk={() => this.overOk()} onCancel={this.overCancel}>
          确认下架该商品
          </Modal>
        <Modal title="上架信息"
          cancelText={'取消'}
          okText={'确认'}
          visible={this.state.isup} onOk={() => this.upOk()} onCancel={this.upCancel}>
          确认下架该商品
          </Modal>
      </>
    )
  }
}