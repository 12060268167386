import React, { Component, useState } from 'react';
import cookie from 'react-cookies';
import './index.scss'
import { Modal, Button, Pagination, message } from 'antd';
import Xiugai from './xiugaicaigou'
import request from '../../../static/js/http';
export default class Mycaigou extends Component {
  constructor(props) {
    super(props);
    this.state = {
      biaotitab: [
        { name: '采购中', id: '1', type: '0' },
        { name: '已结束', id: '2', type: '1' },
        { name: '被驳回', id: '3', type: '2' },
      ],

      isguanli: false,
      currentIndex: 0,
      purchaselist: [],
      fenye: [],
      statu: 0+'',
      purchaseId: '',
      item: [],
      isxiugai: false,
    }
    this.child = React.createRef();
  }
  componentDidMount() {
    this.getpurchaselist()
  }


  //我的采购列表
  getpurchaselist() {
    const { statu } = this.state
    ////console.log(statu);
    let memberId = cookie.load('lj-pc-userInfo').memberId
    ////console.log(memberId);
    request('post', '/api/apply/purchase/listByUserId', {
      memberId,
      statu,
      pageSize: 5,
      pageNum: 1
    }).then(res => {
      ////console.log(res);
      this.setState({
        purchaselist: res.data.list,
        fenye: res.data
      })
    })
  }
  //结束采购
  finishPurchase() {
    const { purchaseId } = this.state
    request('post', '/api/apply/purchase/updateApplyPurchase', {
      statu: 1,
      purchaseId,
    }).then(res => {
      ////console.log(res);
      if (res.code == 0) {
        this.getpurchaselist()
        message.success(res.msg)
      } else {
        message.success(res.msg)
      }
    })
  }
  //删除采购
  delPurchase() {
    const { purchaseId } = this.state
    ////console.log(purchaseId);
    request('post', '/api/apply/purchase/deleteApplyPurchase', {
      id: purchaseId
    }).then(res => {
      ////console.log(res);
      if (res.code == 0) {
        this.getpurchaselist()
        message.success(res.msg)
      } else {
        message.success(res.msg)
      }
      this.setState({
        isguanli: false
      })
    })
  }
  //重新采购
  upPurchase() {
    const { purchaseId } = this.state
    request('post', '/api/apply/purchase/updateApplyPurchase', {
      statu: 0,
      purchaseId,
    }).then(res => {
      ////console.log(res);
      if (res.code == 0) {
        this.getpurchaselist()
        message.success(res.msg)
      } else {
        message.success(res.msg)
      }
    })
    this.setState({
      isguanli: false
    })
  }
  //分页
  pagination = (a, b) => {
    ////console.log(a, b);
    const { statu } = this.state
    ////console.log(statu);
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/apply/purchase/listByUserId', {
      pageSize: b,
      pageNum: a,
      memberId,
      statu,
    }).then(res => {
      // ////console.log(res);
      if (res.code === 0) {
        this.setState({
          purchaselist: res.data.list,
          fenye: res.data
        })
      }
    })
  }
  tab = (type, index) => {
    ////console.log(index);
    this.setState({
      currentIndex: index,
      statu: type
    }, function () {
      this.getpurchaselist()
    })
  }
  showguanli = (purchaseId, item) => {
    this.setState({
      isguanli: true,
      purchaseId,
      item
    })
  };

  guanliOk = () => {
    this.setState({
      isguanli: false
    }, function () {

    })
  };
  guanliCancel = () => {
    this.setState({
      isguanli: false
    }, function () {
    })
  };

  guanliover = () => {
    this.setState({
      isguanli: false
    }, function () {
      this.finishPurchase()
    })
  };
  guanlireg = () => {
    this.setState({
      isxiugai: true
    })
  };
  xiugaiOk = () => {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    ////console.log(this.child);
    let title = this.child.current.state.title
    ////console.log(this.child.current.state.imagesPath);
    const { purchaseId } = this.state
    request('post', '/api/apply/purchase/updateApplyPurchase', {
      memberId,
      title,
      purchaseId,
      purchaseNum: this.child.current.state.purchaseNum,
      purchaseClass: this.child.current.state.purchaseClass,
      purchaseLevel: this.child.current.state.purchaseLevel,
      purchasePrice: this.child.current.state.purchasePrice,
      fromword: this.child.current.state.fromword,
      go: this.child.current.state.go,
      imagesPath: this.child.current.state.imagesPath,
      conent: this.child.current.state.conent,
      statu: 1
    }).then(res => {
      ////console.log(res);
      this.getpurchaselist()
    })


    this.setState({
      isxiugai: false
    })
  };

  xiugaiCancel = () => {
    this.setState({
      isxiugai: false
    })
  };
  //已结束
  guanliDel = () => {
    this.setState({
      isguanli: false
    }, function () {
      this.delPurchase()
    })
  };
  guanliUp = () => {
    this.setState({
      isguanli: false
    }, function () {
      this.upPurchase()
    })
  };
  xiangqing(purchaseId, item) {
    this.props.history.push({ pathname: '/myProfile/Caigouoffer', state: { purchaseId, item } })
  }
  render() {
    const { biaotitab, itemList, purchaselist, fenye, statu, item } = this.state

    return (
      <>
        <div className="mycaigou">
          <div className="biaoti">
            我的采购
          </div>
          <div className="caigou">
            <div className="caigoutab">
              {
                biaotitab.map((item, index) => {
                  return <div className={`tabitem ${this.state.currentIndex === index ? 'active' : ''}`} onClick={() => this.tab(item.type, index)} key={item.id}>
                    {item.name}
                  </div>
                })
              }
            </div>
            <div className="caigoulist">
              {
                purchaselist && purchaselist.map(item => {
                  return <div className="caigouitem" key={item.purchaseId}>
                    <div className="itemleft">
                      <div className="item-name">
                        {item.title}
                      </div>
                      <div className="zhong">
                        采购{item.purchaseNum}斤
                    </div>
                      <div className="pinzhi">
                        品质要求：{item.purchasePrice}元/斤 {item.purchaseLevel}
                      </div>
                    </div>
                    <div className="itemright">
                      <div className="baojia" onClick={() => this.xiangqing(item.purchaseId, item)}>
                        {item.totalnum == 0 ? '' : `${item.totalnum}条报价`}
                        {/* <i className="you"></i> */}
                      </div>
                      <div className="guanli">
                        <Button type="primary" onClick={() => this.showguanli(item.purchaseId, item)}>
                          管理
                      </Button>
                      </div>
                    </div>
                  </div>
                })
              }

            </div>
          </div>
          <Pagination pageSize='5' defaultCurrent={1} current={fenye.pageNum} onChange={this.pagination} total={fenye.total} />
        </div>
        {/* <ModelBottom/> */}
        <Modal title="管理"
          className="gunaliModal"
          visible={this.state.isguanli}
          onOk={this.guanliOk}
          onCancel={this.guanliCancel}
          cancelText={statu == 0 ? '结束采购' : (statu == 1 ? '删除采购' : '删除')}
          okText={statu == 0 ? '修改采购' : (statu == 1 ? '重新上架' : '修改')}
          footer={[

            <div className="footer">
              <Button onClick={statu == 0 ? () => this.guanliover() : (statu == 1 ? () => this.delPurchase() : () => this.delPurchase())}>
                {statu == 0 ? '结束采购' : (statu == 1 ? '删除采购' : '删除')}
              </Button>
              <Button onClick={statu == 0 ? () => this.guanlireg() : (statu == 1 ? () => this.upPurchase() : () => this.guanlireg())}>
                {statu == 0 ? '修改采购' : (statu == 1 ? '重新上架' : '修改')}
              </Button>
            </div>

          ]}

        >
          <Modal cancelText="取消" okText="确定" className="caigouModal" title="修改采购" visible={this.state.isxiugai} onOk={this.xiugaiOk} onCancel={this.xiugaiCancel}>
            <Xiugai ref={this.child} />
          </Modal>
          <div className="guanliinfo">
            <div className="guanlixiangqing">
              <div className="guanliname">
                {item.title}-{item.purchaseClass}
              </div>
              <div className="guanlizhong">
                {item.purchaseNum}斤
                  </div>
              <div className="guanlipinzhi">
                品质要求：{item.purchaseLevel}
              </div>
            </div>
            <div className="caigoutype">
              <div className="caigoutypeitem">
                <div>采购状态：</div><div>{item.statu == 0 ? '采购中' : (item.statu == 1 ? '已结束' : '被驳回')}</div>
              </div>
              <div className="caigoutypeitem">
                <div>品种：</div><div>{item.purchaseClass}</div>
              </div>
              <div className="caigoutypeitem">
                <div>内容：</div><div>{item.conent}</div>
              </div>
              <div className="caigoutypeitem">
                <div>货源地：</div><div>{item.fromword}</div>
              </div>
              <div className="caigoutypeitem">
                <div>收货地：</div><div>{item.go}</div>
              </div>
              <div className="caigoutypeitem">
                <div>浏览次数：</div><div>{item.scannum}次</div>
              </div>
              <div className="caigoutypeitem">
                <div>采购时间：</div><div>{item.createAt}</div>
              </div>
            </div>

          </div>
        </Modal>
      </>
    )
  }
}