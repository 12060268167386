import React, { Component } from 'react';
import { withRouter } from "react-router-dom"
import {
  Breadcrumb, Input, Avatar, Button, Pagination, Modal, message, Form, Select, Radio, Upload
  , Icon
} from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import request from '../../../static/js/http'
import cookie from 'react-cookies'
import { Link } from 'react-router-dom';
import './index.scss';
import tou from '../../../static/img/del_home_lun.png';
const { Search } = Input;
const { confirm } = Modal;
const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;
class Myorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0, // 切换
      list: [
        { name: '全部', id: 0, type: 10 },
        { name: '待修改', id: 1, type: 0 },
        { name: '待支付', id: 2, type: 1 },
        { name: '待发货', id: 3, type: 2 },
        { name: '待收货', id: 4, type: 3 },
        { name: '待评价', id: 5, type: 4 },
        { name: '售后/退款', id: 6, type: 99 }
      ],
      returnreason: [
        { name: '卖家缺货' },
        { name: '线下交易' },
        { name: '不想买了' },
        { name: '信息有误重新下单' },
        { name: '其他原因' },
      ],
      orderList: [],
      isLoading: false,
      dingantype: 10,
      orderdata: [],
      ontype: '',
      isaftersale: false,
      value: 1,
      previewVisible: false,
      previewImage: '',
      imgList: [],
      imagesPath: [],
      orderId: '',
      refundamount: '',
      reasonforreturn: '',
      textarea: '',
      refundList: [],    //售后订单
      refunddata: [],
      businessModelValue: '0',
      expressName: '',
      expressCode: '',
      payment:''
    };
  }
  componentDidMount() {
    this.aftersale()
    // this.getOrderList()
    // ////console.log(this.props.location);
    if (!this.props.location.state) {
      let _ = this
      let memberId = cookie.load('lj-pc-userInfo').memberId
      _.setState({ isLoading: true })
      request('post', '/api/order/order/getOrderList', {
        memberId,
        type: 10,
        pageNum: 1,
        pageSize: 10
      }).then(res => {
        if (res.code === 0) {
          ////console.log(res);
          this.setState({
            orderList: res.data.list,
            orderdata: res.data,
            isLoading: false,
            ontype: 0
          })
        }
      })
    } else {
      let _ = this
      let type = this.props.location.state.type
      let memberId = cookie.load('lj-pc-userInfo').memberId
      _.setState({ isLoading: true })
      request('post', '/api/order/order/getOrderList', {
        memberId,
        type,
        pageNum: 1,
        pageSize: 10
      }).then(res => {
        if (res.code === 0) {
          ////console.log(res);
          this.setState({
            orderList: res.data.list,
            orderdata: res.data,
            isLoading: false,
            ontype: type
          })
        }
      })
    }
  }
  componentWillMount() {

    if (!this.props.location.state) {
      return this.setState({ index: 0 })
    } else {
      switch (this.props.location.state.type) {
        case 10: return this.setState({ index: 0 })
        case 1: return this.setState({ index: 2 })
        case 2: return this.setState({ index: 3 })
        case 3: return this.setState({ index: 4 })
        case 4: return this.setState({ index: 5 })
        case 7: return this.setState({ index: 0 })
        case 99: return this.setState({ index: 6 })
        default:
          break;
      }
    }


  }


  xiangqing = (orderId, res) => {
    this.state.index != 6 ? this.props.history.push({ pathname: '/myProfile/orderdetails', state: { orderId } }) : this.props.history.push({ pathname: '/myProfile/refundDetails', state: { orderId, res } })
    // RefundDetails
  }
  shouhuo = (orderId) => {
    this.props.history.push({ pathname: '/home/confirmorder', state: { orderId } })
  }
  zhifu = (orderId) => {
    this.props.history.push({ pathname: '/payment', state: { orderId } })
  }
  queren = (orderId) => {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/order/order/confirm', {
      memberId,
      orderId
    }).then(res => {
      if (res.code === 500) {
        Modal.warning({
          title: '信息',
          content: res.msg,
        });
      } else {
        ////console.log(res);
        Modal.warning({
          title: '信息',
          content: res.msg,
        });
        this.setState({
          orderList: res.data.list,
          orderdata: res.data,
        })
      }

    })
  }
  st = (i, type) => {
    ////console.log(type, i);
    this.setState({
      index: i,
      dingantype: type
    }, function () {
      if (type == 99) {
        this.setState({
          orderList: '',
          // orderdata: res.data,
          // isLoading: false,
          // ontype: type
        }, function (params) {
          this.aftersale()
        })
      } else {
        let memberId = cookie.load('lj-pc-userInfo').memberId
        let type = this.state.dingantype
        request('post', '/api/order/order/getOrderList', {
          memberId,
          type,
          pageNum: 1,
          pageSize: 10
        }).then(res => {
          if (res.code === 0) {
            console.log(res);
            this.setState({
              orderList: res.data.list,
              orderdata: res.data,
              isLoading: false,
              ontype: type
            })
          }
        })
      }

    });
  }

  onSearch = a => {

    ////console.log(a);
  }
  status = () => {
    this.state.orderList.map(item => {
      switch (item.status) {
        case 0: return "待修改"
        case 1: return "等待买家支付"
        case 2: return "等待卖家发货"
        case 3: return "待收货"
        case 4: return "待评价"
        case 7: return "订单完成"
        // case 10: return <> </>
        default: return "交易关闭"
        // break;
      }
    })

  }
  pagination = (a, b) => {
    ////console.log('@', a, b);
    const { ontype } = this.state
    ////console.log(ontype);
    // // let type = this.state.dingantype
    let memberId = cookie.load('lj-pc-userInfo').memberId
    // this.setState({ isLoading: true })
    request('post', '/api/order/order/getOrderList', {
      memberId,
      type: !ontype?this.props.location.state.type:ontype,
      pageNum: a,
      pageSize: 10
    }).then(res => {
      if (res.code === 0) {
        console.log(res);
        this.setState({
          orderList: res.data.list,
          orderdata: res.data,

          // isLoading: false
        })
      }
    }
    )
  }
  ordercancel(orderId) {
    ////console.log(orderId);
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/order/order/cancel', {
      orderId,
      memberId
    }).then(res => {
      ////console.log(res);
      let type = this.state.dingantype
      request('post', '/api/order/order/getOrderList', {
        memberId,
        type,
        pageNum: 1,
        pageSize: 10
      }).then(res => {
        if (res.code === 0) {
          ////console.log(res);
          this.setState({
            orderList: res.data.list,
            orderdata: res.data,
          })
        }
      })
    })
  }
  showordercancelConfirm = (orderId) => {
    let that = this
    confirm({
      title: '确定取消订单吗？',
      icon: <ExclamationCircleOutlined />,
      content: '确定取消订单吗？',
      okText: '是',
      okType: 'danger',
      cancelText: '不',
      onOk() {
        ////console.log('OK');
        that.ordercancel(orderId)
      },
      onCancel() {

        ////console.log('Cancel');
      }
    });
  }
  orderremove(orderId) {
    ////console.log(orderId);
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/order/order/remove', {
      orderId,
      memberId
    }).then(res => {
      ////console.log(res);
      message.info(res.msg);
      let type = this.state.dingantype
      request('post', '/api/order/order/getOrderList', {
        memberId,
        type,
        pageNum: 1,
        pageSize: 10
      }).then(res => {
        if (res.code === 0) {
          ////console.log(res);
          this.setState({
            orderList: res.data.list,
            orderdata: res.data,
          })
        }
      })
    })
  }

  showDeleteConfirm = (orderId) => {
    let that = this
    confirm({
      title: '确定删除订单？',
      icon: <ExclamationCircleOutlined />,
      content: '确定删除订单？',
      okText: '是',
      okType: 'danger',
      cancelText: '不',
      onOk() {
        that.orderremove(orderId)
      },
      onCancel() {
        ////console.log('Cancel');
      }
    });
  }
  promptWarningConfirm = (orderId,payment) => {
    let that = this
    confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '请与卖方协商无异议后，再进行退货/退款操作',
      okText: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        that.showaftersale(orderId)
        that.setState({
          refundamount:payment
        })
      },
      onCancel() {
        ////console.log('Cancel');
      }
    });
  }
  dropBackOrderConfirm = (orderId,payment) => {
    let that = this
    confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '请与卖方协商无异议后，再进行退货/退款操作',
      okText: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        that.dropBackOrder(orderId,payment)
      },
      onCancel() {
        ////console.log('Cancel');
      }
    });
    
  }
  //售后退货
  aftersale() {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/order/order/saleOrderList', {
      memberId,
      pageNum: 1,
      pageSize: 10
    }).then(res => {
      ////console.log(res.data.list);
      this.setState({
        refundList: res.data.list,
        refunddata: res.data,
      })
    })
  }
  //待发货申请退货
  dropBackOrder(orderId,payment) {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/order/order/backOrder', {
      memberId,
      orderId,
      price: payment,
      reason: "",
      type: "0",
      expressName: "",
      expressCode: ""
    }).then(res => {
      if (res.code == 0) {
        message.success('申请成功')
      } else {
        message.warning('申请失败')
      }
      request('post', '/api/order/order/getOrderList', {
        memberId,
        type: 2,
        pageNum: 1,
        pageSize: 10
      }).then(res => {
        if (res.code === 0) {
          ////console.log(res);
          this.setState({
            orderList: res.data.list,
            orderdata: res.data,
          })
        }
      })
    })
  }
  //申请退货
  backOrder() {
    const { orderId, refundamount, textarea, expressName, expressCode, businessModelValue } = this.state
    ////console.log(businessModelValue);
    if (refundamount == '') {
      message.warning('请填写退款金额')
    } else if (expressName == '') {
      message.warning('请填写物流单号/电话号码')
    } else if (expressCode == '') {
      message.warning('请填写物流公司/司机姓名')
    } else if (textarea == '') {
      message.warning('请填写退款/退货原因 ')
    } else {
      let memberId = cookie.load('lj-pc-userInfo').memberId
      request('post', '/api/order/order/backOrder', {
        memberId,
        orderId,
        price: refundamount+'',
        reason: textarea,
        type: businessModelValue,
        expressName,
        expressCode
      }).then(res => {
        ////console.log(res);
        this.aftersaleOk()
        if (res.code == 0) {
          message.success('申请成功')
        } else {
          message.warning('申请失败')
        }
        request('post', '/api/order/order/getOrderList', {
          memberId,
          type: 3,
          pageNum: 1,
          pageSize: 10
        }).then(res => {
          if (res.code === 0) {
            ////console.log(res);
            this.setState({
              orderList: res.data.list,
              orderdata: res.data,
            })
          }
        })
      })
    }
  }
  tixing(){
    message.success('提醒成功')
  }
  //获取退货/退款input值
  getInputValue(e, name) {
    ////console.log(e.target.value, name);
    this.setState({
      [name]: e.target.value
    })
  }
  showaftersale = (orderId) => {
    this.setState({
      isaftersale: true,
      orderId
    })
  };

  aftersaleOk = () => {
    this.setState({
      isaftersale: false
    })
  };

  aftersaleCancel = () => {
    this.setState({
      isaftersale: false
    })
  };
  //去评价
  toevaluate(item, orderId, detailId) {
    this.props.history.push({ pathname: '/myProfile/Toevaluate', state: { item, orderId, detailId } })
  }
  
  //退货金额
  refundamount = (e) => {
    ////console.log(e);
    this.setState({
      refundamount: e.target.value
    })
  }
  onChange = e => {
    ////console.log('radio checked', e.target.value);
    this.setState({
      value: e.target.value
    })
  };
  //退货原因
  textarea = (e) => {
    ////console.log(e.target.value);
    this.setState({
      textarea: e.target.value
    })
  }
  //经营模式
  businessModel = e => {
    this.setState({
      businessModelValue: e.target.value,
    })
  };

  /////////////////////////////////////////////////////////////////////////////
  //上传图片
  uploadhandleChange = ({ file, fileList }) => {
    
    fileList.forEach(imgItem => {
      if (imgItem && imgItem.status == 'done' && imgItem.response && imgItem.response.imgUrl) {
        imgItem.thumbUrl = imgItem.response.imgUrl;
      }
    });

    if (file.status === 'done') {
      let filesList = []
      fileList.map(item => {
        ////console.log(item.response.data);
        filesList.push(item.response.data)
      })
      ////console.log(filesList);
      setTimeout(() => {
        this.setState({
          imagesPath: filesList
        })
      }, 0);
    }
    setTimeout(() => {
      this.setState({
        imgList: fileList,
      });
    }, 0);
  };
  handleCancel = () => setTimeout(() => {
    this.setState({ previewVisible: false })
  }, 0);;

  handlePreview = file => {
    setTimeout(() => {
      this.setState({
        previewImage: file.url || file.thumbUrl,
        previewVisible: true,
      });
    }, 0);
  };

  handleBeforeUpload = file => {
    //限制图片 格式、size、分辨率
    const isJPG = file.type === 'image/jpeg';
    const isJPEG = file.type === 'image/jpeg';
    const isGIF = file.type === 'image/gif';
    const isPNG = file.type === 'image/png';
    if (!(isJPG || isJPEG || isGIF || isPNG)) {
      Modal.error({
        title: '只能上传JPG 、JPEG 、GIF、 PNG格式的图片~',
      });
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      Modal.error({
        title: '超过2M限制，不允许上传~',
      });
      return;
    }
    return (isJPG || isJPEG || isGIF || isPNG) && isLt2M && this.checkImageWH(file);
  };

  //返回一个 promise：检测通过则返回resolve；失败则返回reject，并阻止图片上传
  checkImageWH(file) {
    let self = this;
    return new Promise(function (resolve, reject) {
      let filereader = new FileReader();
      filereader.onload = e => {
        let src = e.target.result;
        const image = new Image();
        image.onload = function () {
         
          file.width = this.width;
          file.height = this.height;
          resolve();
        };
        image.onerror = reject;
        image.src = src;
      };
      filereader.readAsDataURL(file);
    });
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props;
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {// values 是form表单里的参数
      // 点击按钮后，将表单提交给后台
      dispatch({
        type: 'mymodel/submitFormData',
        payload: values,
      });
    });
  };

  render() {
    const { index, list, orderList, isLoading, orderdata, previewVisible, previewImage, imgList, refundList } = this.state;
    if (isLoading) {
      return orderList
    }
    // ////console.log(refundList);
    const true1 = true;
    const false1 = false;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text" style={{ fontSize: '20px' }} >上传图片</div>
      </div>
    );
    return (
      <div className="order1">
        <div className="wei">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>当前位置</Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/my/index">个人中心</Link></Breadcrumb.Item>
            <Breadcrumb.Item>我的订单</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="width1400">
          <div className="biaotou">
            <div className="list">
              {list.map(a => (
                <div
                  key={a.id}
                  className={`item cursor ${a.id === index ? 'st' : ''}`}
                  onClick={() => this.st(a.id, a.type)}
                >{a.name}
                </div>
              ))}
            </div>
            <div>
             
            </div>
          </div>
          <div className="biaotou1">
            <div>商品信息</div>
            <div>商品价格</div>
            <div>数量</div>
            <div>订单状态</div>
            <div>运费</div>
            <div>原价</div>
            <div>实收款</div>
            <div>操作</div>
          </div>
          <div className="ul">
            {
              this.state.index != 6 ?
                orderList && orderList.map(a => (
                 
                  <div key={a.orderId} className="li" >
                    <div className="a">
                      <Avatar size={25} src={tou} />
                      <span>{a.shopName}</span>
                      <span>订单号：{a.orderSn}</span>
                      <span><DeleteOutlined onClick={() => this.showDeleteConfirm(a.orderId)} /></span>
                    </div>
                    <div className="b">
                      {
                        a.goodsList.map(res => (
                          // ////console.log(res),
                          <div key={res.detailId} className="flex1">
                            <div>
                              <img src={res.goodsImg} alt="商品名称" />
                              <div>
                                <div>{res.goodsName}</div>
                                <div>商品等级：{res.goodsProp}</div>
                                <div>总重：{res.productWeight}斤</div>
                              </div>
                            </div>
                            <div>{res.price}元/{res.productUnit}</div>
                            <div>{res.total}</div>
                            <div style={{ cursor: 'pointer' }} onClick={() => this.xiangqing(a.orderId)}>
                              {
                                <div onClick={() => this.xiangqing(a.orderId)} style={{ cursor: 'pointer' }}>
                                  {
                                    (() => {
                                      switch (a.status) {
                                        case 3: return "查看物流"
                                        case 4: return "查看物流"
                                        case 7: return "查看物流"
                                        // case 10: return <> </>
                                        default:
                                          break;
                                      }
                                    }
                                    )()
                                  }
                                </div>
                              }
                              {
                                (() => {
                                  switch (a.status) {
                                    case 0: return "待修改"
                                    case 1: return "等待买家支付"
                                    case 2: return "等待卖家发货"
                                    case 3: return "待收货"
                                    case 4: return "待评价"
                                    case 7: return "订单完成"
                                    // case 10: return <> </>
                                    default:
                                      break;
                                  }
                                }
                                )()
                              }
                              <div style={{ cursor: 'pointer' }} onClick={() => this.xiangqing(a.orderId)}>订单详情</div>
                              {/* <div className="fontw">查看物流</div> */}
                              {/* <div onClick={()=>this.shouhuo(a.orderId)}>去确认收货</div> */}
                            </div>
                            <div>{a.orderPost}</div>
                            <div>{res.allPrice}</div>
                            <div>{res.allPrice}</div>
                            <div style={{ flexWrap: 'wrap' }}>
                              {
                                // console.log(new Date().getTime() - a.createAt >60*60*24)
                                // console.log(60*60*24)
                                (() => {
                                  switch (a.status) {
                                    case 0: return <><Button style={{ display: true1 ? 'block' : 'none' }}>联系商家</Button> <Button style={{ display: true1 ? 'block' : 'none' }} onClick={() => this.showordercancelConfirm(a.orderId)} type="dashed">取消订单</Button></>
                                    case 1: return <> {new Date().getTime()/1000 - a.createAt/1000>60*60*24?'订单失效' : <><Button style={{ display: true1 ? 'block' : 'none' }} onClick={() => this.zhifu(a.orderId)} type="primary">立即支付</Button> <Button style={{ display: true1 ? 'block' : 'none' }} onClick={() => this.showordercancelConfirm(a.orderId)} type="dashed">取消订单</Button></>}</>
                                    case 2: return <><Button style={{ display: true1 ? 'block' : 'none' }} onClick={() => this.dropBackOrderConfirm(a.orderId,a.payment)}>申请退款</Button> <Button style={{ display: true1 ? 'block' : 'none' }} onClick={() => this.tixing()}>提醒发货</Button> <Button style={{ display: true1 ? 'block' : 'none' }}>联系商家</Button></>
                                    case 3: return <><Button style={{ display: true1 ? 'block' : 'none' }} onClick={() => this.queren(a.orderId)}>确认收货</Button><Button style={{ display: true1 ? 'block' : 'none' }} onClick={() => this.promptWarningConfirm(a.orderId,a.payment)}>申请退款</Button><Button style={{ display: true1 ? 'block' : 'none' }}>联系商家</Button></>
                                    case 4: return <Button onClick={() => this.toevaluate(res, a.orderId, res.detailId)} style={{ display: true1 ? 'block' : 'none' }}>去评价</Button>
                                    case 7: return "订单完成"
                                    // case 10: return <> </>
                                    default: return '交易关闭'
                                  }
                                }
                                )()
                              }
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                )) :
                refundList && refundList.map(a => (
                
                  <div key={a.orderId} className="li" >
                    <div className="a">
                      <Avatar size={25} src={tou} />
                      <span>{a.shopName}</span>
                      <span>订单号：{a.orderSn}</span>
                      <span><DeleteOutlined onClick={() => this.showDeleteConfirm(a.orderId)} /></span>
                    </div>
                    <div className="b">
                      {
                        a.detailList.map(res => (
                          // ////console.log(res),
                          <div key={res.detailId} className="flex1">
                            <div>
                              <img src={res.goodsImg} alt="商品名称" />
                              <div>
                                <div>{res.goodsName}</div>
                                <div>商品等级：花皮</div>
                                <div>总重：{res.productWeight}斤</div>
                              </div>
                            </div>
                            <div>{res.price}元/{res.productUnit}</div>
                            <div>{res.total}</div>
                            <div>

                              {(() => {
                                switch (a.status) {
                                  case 0: return "申请退款/退货中"
                                  case 1: return "退款/退货成功"
                                  case 2: return "退款/退货失败"
                                  
                                  default: return "申请退款/退货中"
                                  // break;
                                }
                              }
                              )()}
                              <div style={{ cursor: 'pointer' }} onClick={() => this.xiangqing(a.orderId, a)}>订单详情</div>
                            
                            </div>
                            <div>{a.orderPost}</div>
                            <div>{a.orderPrice}</div>
                            <div>{a.payment}</div>

                            <div style={{ flexWrap: 'wrap' }}>
                              {(() => {
                                switch (a.status) {
                                  case 0: return <><Button style={{ display: true1 ? 'block' : 'none' }}>申请退款/退货中</Button></>
                                  case 1: return <><Button style={{ display: true1 ? 'block' : 'none' }}>退款/退货成功</Button></>
                                  case 2: return <><Button style={{ display: true1 ? 'block' : 'none' }}>退款/退货失败</Button></>
                                  default: return <><Button style={{ display: true1 ? 'block' : 'none' }}>申请退款/退货中</Button></>
                                }
                              }
                              )()}

                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                ))
            }
          </div>
          <div className="rightbuttom">
            {/* refunddata */}
            {
              this.state.index != 6 ? <Pagination current={orderdata.pageNum} pageSize="10" onChange={this.pagination} total={orderdata.total} /> : <Pagination  current={orderdata.pageNum} pageSize="10" onChange={this.pagination} total={this.state.refunddata.total} />
            }

          </div>
        </div>
        <Modal footer={[
          <Button onClick={() => this.backOrder()}>提交</Button>
        ]} className="aftersaleModal" title="售后退款" visible={this.state.isaftersale} onOk={this.aftersaleOk} onCancel={this.aftersaleCancel}>
          <div className="shouhou">
 
            <Form name="nest-messages" onFinish={this.onFinish}>
              <Form.Item
                name={['jiage']}
                label="退款金额："
              >
                <span></span>
                <Input type="number" placeholder="请输入退货金额"  value={this.state.refundamount} onChange={(e) => this.refundamount(e)} />
              </Form.Item>
            </Form>
            经营模式：
            <Radio.Group onChange={(e) => this.businessModel(e)} value={this.state.businessModelValue}>
              <Radio value={0}>公司</Radio>
              <Radio value={1}>个人</Radio>
            </Radio.Group>
            {
              this.state.businessModelValue == 0 ?

                <div className="gongsi">
                  <Form name="nest-messages" onFinish={this.onFinish}>
                    <Form.Item
                      name={['danhao']}
                      label="物流单号:"
                      rules={[
                        {
                          required: true,
                          message: '请输入物流单号!'
                        }
                      ]}
                    >
                      <Input type="text" placeholder="请填写物流单号" value={this.state.expressName} onChange={(e) => this.getInputValue(e, 'expressName')} />
                    </Form.Item>
                  </Form>
                  <Form name="nest-messages" onFinish={this.onFinish}>
                    <Form.Item
                      name={['gongsi']}
                      label="物流公司:"
                      rules={[
                        {
                          required: true,
                          message: '请填写物流公司!'
                        }

                      ]}
                    >
                      <Input type="text" placeholder="请填写物流公司" value={this.state.expressCode} onChange={(e) => this.getInputValue(e, 'expressCode')} />
                    </Form.Item>
                  </Form>
                </div>
                :
                <div className="gongsi">
                  <Form name="nest-messages" onFinish={this.onFinish}>
                    <Form.Item
                      name={['danhao']}
                      label="电话号码:"
                      rules={[
                        {
                          required: true,
                          message: '请填写电话号码!'
                        }, {
                          pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
                          message: '不合法的手机号格式!'
                        }

                      ]}
                    >
                      <Input type="text" placeholder="请填写电话号码" value={this.state.expressName} onChange={(e) => this.getInputValue(e, 'expressName')} />
                    </Form.Item>
                  </Form>
                  <Form name="nest-messages" onFinish={this.onFinish}>
                    <Form.Item
                      name={['gongsi']}
                      label="司机姓名:"
                      rules={[
                        {
                          required: true,
                          message: '请填写司机姓名!'
                        }

                      ]}
                    >
                      <Input type="text" placeholder="请填写司机姓名" value={this.state.expressCode} onChange={(e) => this.getInputValue(e, 'expressCode')} />
                    </Form.Item>
                  </Form>
                </div>
            }
            <div className="text">
              <span>详细内容：</span>
              <TextArea placeholder="请输入详细内容" value={this.state.textarea} onChange={(e) => this.textarea(e, "textarea")} rows={3} />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
export default withRouter(Myorder)