import React, { Component } from 'react';
import './index.scss';
import { Modal, Button, Form, Input, message } from 'antd';
import { withRouter } from "react-router-dom"
import request from '../../../../static/js/http';
class Intie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowDelIntie: false,
      isShowIntie: false,
      intieAccount: '',    //支付宝账户
      intieName: '',    //支付宝实名
      intiePhone: '',    //手机号
      intiePhoneCode: '',    //短信验证码
      intiePassWord: '',    //支付密码
      userInfo: '',
    }
  }
  componentDidMount() {
    this.userInfo()
  }
  aliAccount() {
    const { intieName, intieAccount, intiePhoneCode, intiePhone } = this.state
    if (intieName == '') {
      message.warning('请填写支付宝账户')
    } else if (intieAccount == "") {
      message.warning('请填写支付宝实名')
    } else if (intiePhoneCode == "") {
      message.warning('请填写手机号')
    } else if (intiePhone == "") {
      message.warning('请填写短信验证码')
    } else {
      request('post', '/api/member/user/changeInfo', {
        aliName: intieName,
        aliAccount: intieAccount,
        code: intiePhoneCode,
        phone: intiePhone
      }).then(res => {
        if (res.code == 0) {
          message.success(res.msg)
        } else {
          message.warning(res.code)
        }
        this.userInfo()
        this.IntieOk()
      })
    }
  }
  // aliBang(){
  //   request('post','/api/member/ali/getSign').then(res=>{
  //     //console.log(res);
  //   })
  // }
  userInfo() {
    request('post', '/api/member/user/info').then(res => {
      //console.log(res);
      this.setState({
        userInfo: res.data
      })
    })
  }
  //删除绑定的支付宝账号信息
  userRemoveAli() {
    request('post', '/api/member/user/removeAli').then(res => {
      //console.log(res);

      if (res.code == 0) {
        message.success(res.msg)
      } else {
        message.warning(res.msg)
      }
      this.userInfo()
    })
  }

  //获取input的值
  getInputV(e, name) {
    this.setState({
      [name]: e.target.value
    })
  }

  //删除绑定
  showDelIntie = () => {
    this.setState({
      isShowDelIntie: true
    })
  };

  DelIntieOk = () => {
    this.setState({
      isShowDelIntie: false
    },function (params) {
      this.userRemoveAli()
    })
  };

  DelIntieCancel = () => {
    this.setState({
      isShowDelIntie: false
    })
  };
  //绑定
  showIntie = () => {
    // this.aliBang()
    this.setState({
      isShowIntie: true
    })
  };

  IntieOk = () => {
    this.setState({
      isShowIntie: false
    })
  };

  IntieCancel = () => {
    this.setState({
      isShowIntie: false
    })
  };
  render() {
    const { userInfo } = this.state
    const layout = {
      labelCol: {
        span: 3,
      },
      wrapperCol: {
        // span: 12,
      },
    };

    return (
      <>
        <div className="intie">
          <div className="tips">
            温馨提示：（每种卡型只能绑定一种卡号，如需变更，请先删除已绑定卡）
          </div>
          <div className="intieTable">
            <table className="listTable">
              <thead className="listThead">
                <tr className="listTr">
                  <th>绑定卡类型</th>
                  <th>绑定卡卡号</th>
                  <th>操作</th>
                </tr>
              </thead>

              {
                //{/* 已有绑定的卡 */}
                userInfo.aliAccount ? <tbody className="listTbody">
                  <tr className="TbodyTr">
                    <td>支付宝</td>
                    <td>{userInfo.aliAccount}</td>
                    <td className="delIntie" onClick={() => this.showDelIntie()}>删除绑定</td>
                  </tr>
                </tbody> :
                  //  {/* 没有绑定的卡 */}
                  <tbody className="listTbody">
                    <tr className="TbodyTr">
                      <td>支付宝</td>
                      <td>未绑定</td>
                      <td className="delIntie" onClick={() => this.showIntie()}>去绑定</td>
                    </tr>
                  </tbody>
              }


            </table>
          </div>
        </div>

        <Modal title="提示" className="delIntieModal" visible={this.state.isShowDelIntie} onOk={this.DelIntieOk} onCancel={this.DelIntieCancel}>
          <p>确定要解除绑定支付宝账号？</p>
        </Modal>
        <Modal footer={[]} title="绑定支付宝" className="intieModal" visible={this.state.isShowIntie} onOk={this.IntieOk} onCancel={this.IntieCancel}>
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
          >
            <Form.Item
              label="支付宝账户:"
              name="intieAccount"
              onChange={(e) => this.getInputV(e, 'intieAccount')}
              rules={[
                {
                  message: '请输入正确的支付宝账户!',
                }, {
                  pattern: /^1\d{10}$/,
                  message: '请输入正确的支付宝账户!'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="支付宝实名:"
              name="intieName"
              onChange={(e) => this.getInputV(e, 'intieName')}
              rules={[
                {
                  message: '请输入正确的支付宝实名!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="手机："
              name="intiePhone"
              onChange={(e) => this.getInputV(e, 'intiePhone')}
              rules={[
                {
                  message: '请输入手机号!',
                },
              ]}
            >
              <Input type="number" />
              <span className="tips">为了您的账户安全，每个账号仅限绑定一个支付宝</span>
            </Form.Item>
            <Form.Item
              label="短信验证码:"
              name="intiePhoneCode"
              onChange={(e) => this.getInputV(e, 'intiePhoneCode')}
              rules={[
                {
                  message: '请输入短信验证码!',
                },
              ]}
            >
              <div className="phoneCode">
                <Input type="number" />
                <Button danger>获取验证码</Button>
              </div>
            </Form.Item>
            {/* <Form.Item
              label="支付密码:"
              name="intiePassWord"
              onChange={(e) => this.getInputV(e, 'intiePassWord')}
              rules={[
                {
                  message: '请输入支付密码!',
                },
              ]}
            >
              <Input type="number" />
              <span className="forgetPassword">忘记密码？</span>
            </Form.Item> */}
            <Form.Item >
              <div className="intieBtn">
                <Button type="primary" onClick={() => this.aliAccount()}>
                  立即绑定
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  }
}
export default withRouter(Intie)