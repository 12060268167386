import React, { Component } from 'react';
import { withRouter } from "react-router-dom"
import { Tabs } from 'antd';
import Carousel1 from '../../../components/Carousel1';
import Caigoudating from './Purchasinghall'
import Gongyingdating from './Supplyhall'
import Lengkuchuzu from './Leasehall'
import Lengkuqiuzu from './Wantedhall'
import request from '../../../static/js/http'
import './index.scss';
const { TabPane } = Tabs;
class Cuohedating extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  callback(key) {
    ////console.log(key);
  }
  Demo = () => (
    <Tabs defaultActiveKey="1" onChange={this.callback}>
      <TabPane tab="采购大厅" key="1">
        <Caigoudating />
      </TabPane>
      <TabPane tab="供应大厅" key="2">
        <Gongyingdating />
      </TabPane>
      <TabPane tab="冷库出租" key="3">
        <Lengkuchuzu />
      </TabPane>
      <TabPane tab="冷库求租" key="4">
        <Lengkuqiuzu />
      </TabPane>
    </Tabs>
  );
  render() {


    return (
      <>
        <Carousel1 />
        <div className="cuoheW">
          <div className="cuohe">
            {this.Demo()}
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(Cuohedating)