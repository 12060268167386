import React, { Component } from 'react';
import './index.scss';
import 'moment/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider, DatePicker, Space, Button, Pagination } from 'antd';
import { withRouter } from "react-router-dom"
import request from '../../../../static/js/http';
class Financialdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recordList: [],
      startAt: '',
      endAt: '',
      cashId: ''
    }
  }
  componentDidMount() {
    this.recordListF()
  }

  recordListF() {
    request('post', '/api/member/money/recordList', {
      pageNum: 1,
      pageSize: 10
    }).then(res => {
      //console.log(res);
      if (res.code == 0) {
        this.setState({
          recordList: res.data
        })
      }
    })
  }

  ////////////////////时间筛选
  //开始时间
  startTime = (date, dateString) => {
    //console.log(date, dateString);
    this.setState({
      startAt: dateString
    })
  }

  //结束时间
  endTime = (date, dateString) => {
    //console.log(date, dateString);
    this.setState({
      endAt: dateString
    })
  }
  inputV(e) {
    this.setState({
      cashId: e.target.value
    })
  }
  filtrate() {
    const { startAt, endAt, cashId } = this.state
    request('post', '/api/member/money/recordList', {
      startAt,
      endAt,
      cashId,
      pageNum: 1,
      pageSize: 10
    }).then(res => {
      //console.log(res);
      this.setState({
        recordList: res.data,
      })
    })
  }

  ///////////分页
  pagination = (a, b) => {
    const { startAt, endAt } = this.state
    request('post', '/api/member/money/recordList', {
      startAt,
      endAt,
      pageSize: b,
      pageNum: a,
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          recordList: res.data,
        })
      }
    })
  }
  render() {
    const { recordList } = this.state
    //console.log(recordList);
    return (
      <>
        <div className="financialDetails">
          <div className="filtrate">
            <div className="time">
              <span className="lookTime">查询时间:</span>
              <Space direction="vertical" >
                <ConfigProvider locale={zhCN}>
                  <DatePicker onChange={this.startTime} />
                  <span style={{ margin: '0 10px' }}>至</span>
                  <DatePicker onChange={this.endTime} />
                </ConfigProvider>
              </Space>
            </div>
            <div className="serialNumber">
              <span className="serialNumberSpan">编号:</span>
              <input onChange={(e) => this.inputV(e)} className="serialNumberInput" type="text" />
              <Button type="primary" onClick={() => this.filtrate()}>
                查询
              </Button>
            </div>
          </div>
          <div className="financialDetailsTable">
            <table className="listTable">
              <thead className="listThead">
                <tr className="listTr">
                  <th>编号</th>
                  <th>简单描述</th>
                  <th>交易时间</th>
                  <th>交易类型</th>
                  <th>变动资金(元)</th>
                </tr>
              </thead>
              {
                recordList.list && recordList.list.map(item => {
                  return <tbody key={item.cashId} className="listTbody">
                    <tr className="TbodyTr">
                      <td>{item.cashId}</td>
                      <td>商城交易</td>
                      <td>{item.createAt}</td>
                      <td>{item.type == 0 ? '充值成功' : (item.type == 1 ? '期货买入' : item.type == 2 ? '提现成功' : '期货卖出')}</td>
                      <td>{item.type == 0 ? '+' : (item.type == 3 ? '+' : '-')}{item.money}</td>
                    </tr>
                  </tbody>
                })
              }
            </table>
            <Pagination pageSize='10' current={recordList.pageNum} onChange={this.pagination} defaultCurrent={1} total={recordList.total} />
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(Financialdetails)