import React, { Component } from 'react';
import { message, Button } from 'antd';
import './index.scss';
import { Link } from 'react-router-dom';
import cookie from 'react-cookies';
import img from '../../../static/img/erwei.jpg'
// import jr1 from '../../../static/img/jr1.png'
// import jr2 from '../../../static/img/jr2.png'
// import jr3 from '../../../static/img/jr3.png'
// import jr4 from '../../../static/img/jr4.png'

import request from '../../../static/js/http';


export default class Financial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // name: '',
      // phone: '',
      // company: '',
    }
  }


  // submit() {
  //   const { name, phone, company } = this.state
  //   if (name == '') {
  //     message.warning('请填写姓名')
  //   } else if (phone == '') {
  //     message.warning('请填写联系方式')
  //   } else if (!/^1\d{10}$/.test(phone)) {
  //     message.warning('请填写正确的联系方式')
  //   } else if (company == '') {
  //     message.warning('请填写公司名字')
  //   } else {
  //     let memberId = cookie.load('lj-pc-userInfo').memberId
  //     request('post', '/api/qa/service/addSave', {
  //       memberId,
  //       name,
  //       phone,
  //       company,
  //       idcard: '',
  //       content: ''
  //     }).then(res => {
  //       ////console.log(res);
  //       if (res.code === 0) {
  //         this.info()
  //       } else {
  //         this.err()
  //       }
  //     })
  //   }
  // }
  // ipt(e) {
  //   this.setState({
  //     [e.target.name]: e.target.value
  //   })
  // }
  // info = () => {
  //   message.success('提交成功');
  // };
  // err = () => {
  //   message.warning('提交失败');
  // }
  render() {

    return (
      <>
        <div className="borrowingInformationW">
          <div className="borrowingInformation">
            <div className="borrowingInformationTop">
              <div>灵活借还</div>
              <div>24小时审批</div>
              <div>息费透明</div>
            </div>
            <div className="loanInfo">
              <div className="loanInfoLeft">
                <div className="lending">
                  <div className="lendingName">
                    最高借贷额度
                  </div>
                  <div className="lendingPrice">
                    1,000,000
                  </div>
                </div>
                <div className="borrowingRate">
                  <div className="borrowingRateItem">
                    <div className="title">
                      超低利率
                    </div>
                    <div className="content">
                      最低年化利率5%
                    </div>
                  </div>
                  <div className="borrowingRateItem">
                    <div className="title">
                      额度范围
                    </div>
                    <div className="content">
                      50,000 - 1,000,000
                    </div>
                  </div>
                  <div className="borrowingRateItem">
                    <div className="title">
                      借款期限
                    </div>
                    <div className="content">
                      可选3、6、12、24、36期
                    </div>
                  </div>
                  <div className="borrowingRateItem">
                    <div className="title">
                      还款规则
                    </div>
                    <div className="content">
                      当日借款，次日可还
                    </div>
                  </div>
                </div>
              </div>
              <div className="loanInfoRight">
                <div className="QRcode">
                  <img src={img} alt="" />
                </div>
                <div className="explain">
                  打开辣椒APP，查看额度，
                  随借随用
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="regulationW">
          <div className="regulation">
            仓库存储辣椒（抵押物）
          </div>
        </div>
        <div className="guaranteeW">
          <div className="guarantee">
            只能抵押处于持仓状态的辣椒
          </div>
        </div>
        <div className="guaranteeTable">
          <table className="listTable">
            <thead className="listThead">
              <tr className="listTr">
                <th>持仓类型</th>
                <th>辣椒品种</th>
                <th>质检等级</th>
                <th>现存数量（斤）</th>
                <th>存储地址</th>
                <th>选择</th>
              </tr>
            </thead>
            <tbody className="listTbody">
              <tr className="TbodyTr" >
                <td>自存</td>
                <td>山樱椒</td>
                <td>优质</td>
                <td>1000</td>
                <td>一号仓库，007库位</td>
                <td><input type="checkbox" /></td>
              </tr>
              <tr className="TbodyTr" >
                <td>期货</td>
                <td>子弹头</td>
                <td>优质</td>
                <td>1000</td>
                <td>一号仓库，007库位</td>
                <td><input type="checkbox" /></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="myLines">
          <div className="myLinesTop">
            <div className="bigBorrowMoney">
              <div className="bigBorrowMoneyTitle">
                大额借款
              </div>
              <div className="bigBorrowMoneyContent">
                仅用于期货交易，交易完成后可提现
              </div>
            </div>
            <div className="equalityBorrowMoney">
              <div className="equalityBorrowMoneyTitle">
                等额借款
              </div>
              <div className="equalityBorrowMoneyContent">
                可提现，可用于期货交易
              </div>
            </div>
          </div>
          <div className="myLinesBottom">
            <Link to="/home/FinancialSubmitInfo">
            <div className="lookMyLinesBtn">
            查看我的额度
            </div>
            </Link>
          </div>
          <p className="hint">点击上方按钮即同意用户服务协议隐私政策，并将获得您注册时的相关个人信息。</p>
        </div>
        {/* <div className="jrBannerW">
          <div className="jrBanner">
            <img src={banner} alt="" />
          </div>
          <div className="informatiom">
            <div className="information-top">
              填写信息
          </div>
            <form action="" className="jrinfo">
              <div className="infoname">
                <input type="text" name='name' onChange={(e) => this.ipt(e)} placeholder="姓名" />
              </div>
              <div className="infophone">
                <input type="number" name='phone' onChange={(e) => this.ipt(e)} placeholder="联系方式" />
              </div>
              <div className="gsmc">
                <input type="text" name='company' onChange={(e) => this.ipt(e)} placeholder="公司名字" />
              </div>
            </form>
            <button className="infobtn" onClick={() => this.submit()}>
              提交
          </button>
          </div>
        </div>
        <div className="bannerBottomW">
          <div className="bannerBottom">
            <div className="bannerBottomItem">
              <div className="itemnav">
                <p className="itemNavTop">
                  额度高
                </p>
                <p className="itemNavBottom">
                  自动调整  最高达百万
                </p>
              </div>
            </div>
            <div className="bannerBottomItem">
              <div className="itemnav">
                <p className="itemNavTop">
                  融资快
                </p>
                <p className="itemNavBottom">
                  全程线上操作 秒批秒放
                </p>
              </div>
            </div>
            <div className="bannerBottomItem">
              <div className="itemnav">
                <p className="itemNavTop">
                  费用低
                </p>
                <p className="itemNavBottom">
                  2天免息 随借随还
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="kttjW">
          <div className="kttj">
            <div className="kttjtop">
              开通条件
            </div>
            <div className="tj2">
              <div className="tj1">
                <div className="tj">
                  <div className="tjItem fl">
                    <img src={jr1} alt="" />
                    <p>1 : 平台辣椒店铺1年以上</p>
                  </div>
                  <div className="tjItem fl">
                    <img src={jr2} alt="" />
                    <p>2.近半年辣椒成交额20万以上</p>
                  </div>
                  <div className="tjItem fl">
                    <img src={jr3} alt="" />
                    <p>3.近半年商城辣椒交易量20吨以上</p>
                  </div>
                  <div className="tjItem fl">
                    <img src={jr4} alt="" />
                    <p>3.店铺信誉值90分以上</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </>
    )
  }
}