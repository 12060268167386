import React, { Component, } from 'react';
import './index.scss'
import cookie from 'react-cookies';
export default class Xiugaimima extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  componentDidMount() {
    let phone = cookie.load('lj-pc-userInfo').memberPhone
    // (Array(length).join('0')+num).slice(-length)
  }
  //验证码 
  code=(e)=>{
    ////console.log(e.target.value);
  }
  //新密码
  newPassword=(e)=>{
    ////console.log(e.target.value);
  }
  //确认新密码
  okNewPassword=(e)=>{
    ////console.log(e.target.value);
  }
  render() {
    return (
      <>
        <div className="xgmm">
          <div className="yanzhengma">
            <input type="number" onChange={(e)=>this.code(e)} placeholder="输入验证码" /><button>获取验证码</button>
          </div>
          <div className="shuruNewPassword">
            输入新密码：<input onChange={(e)=>this.newPassword(e)} type="text" name="1"/>
          </div>
          <div className="querenNewPassword">
            确认新密码：<input onChange={(e)=>this.okNewPassword(e)} type="text" />
          </div>
        </div>
      </>
    )
  }
}