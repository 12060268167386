import React, { Component } from 'react';
import './index.scss';
import Financialdetails from './Financialdetails'
import Pay from './Pay'
import Withdraw from './Withdraw'
import Intie from './Intie'
import { Link } from 'react-router-dom';
import lun from '../../../static/img/del_home_lun.png';
import qiye from '../../../static/img/qiye1.png';
import geren from '../../../static/img/geren.png';
import chongzhi from '../../../static/img/chongzhi.png';
import tixian from '../../../static/img/tixian.png';
import order1 from '../../../static/img/order1.png';
import order2 from '../../../static/img/order2.png';
import order3 from '../../../static/img/order3.png';
import order4 from '../../../static/img/order4.png';
import order5 from '../../../static/img/order5.png';
import order6 from '../../../static/img/order6.png';
import Iiem from '../../../components/Item';
import request from '../../../static/js/http'
import cookie from 'react-cookies'
export default class Myindex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s: false,
      userInfo: [],
      shopAuth: [],
      shopNum: 0,
      goodsNum: 0,
      isShow: 0, //商品推荐-0，详情-1，充值-2，提现-3，换绑-4
      zfNum: 0,
      fhNum: 0,
      shNum: 0,
      pjNum: 0,
      tkNum: 0,
    };
  }


  componentDidMount() {
    this.shangpin()
    this.zf()
    this.fh()
    this.sh()
    this.pj()
    this.tk()
  }
  userInfo() {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/member/user/info', {
      memberId
    }).then(res => {
      //console.log(res);
      this.setState({
        userInfo: res.data,
        shopAuth: res.data.shopAuth && res.data.shopAuth
      }, function () {

        ////console.log(this.state.userInfo);
        ////console.log(this.state.shopAuth);
      })
    })
  }
  shop() {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/shop/shop/collectList', {
      memberId,
      pageNum: 1,
      pageSize: 20,
    }).then(res => {
      this.setState({
        shopNum: res.data.total && res.data.total
      })
    })
  }
  shangpin() {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/member/goods/collectList', {
      memberId,
      pageNum: 1,
      pageSize: 20,
    }).then(res => {
      //console.log(res);
      this.setState({
        goodsNum: res.data.total
      }, function (params) {
        this.userInfo()
        this.shop()
      })
    })
  }
  isShow(e) {
    //console.log(e);
    this.setState({
      isShow: e
    })
  }

  //待支付
  zf() {
    request('post', '/api/order/order/getOrderList', {
      pageNum: 1,
      pageSize: 10,
      type: 1
    }).then(res => {
      console.log(res);
      this.setState({
        zfNum: res.data.total
      })
    })
  }
  //代发货
  fh() {
    request('post', '/api/order/order/getOrderList', {
      pageNum: 1,
      pageSize: 10,
      type: 2
    }).then(res => {
      this.setState({
        fhNum: res.data.total
      })
    })
  }
  //待收货
  sh() {
    request('post', '/api/order/order/getOrderList', {
      pageNum: 1,
      pageSize: 10,
      type: 3
    }).then(res => {
      this.setState({
        shNum: res.data.total
      })
    })
  }
  //待评价
  pj() {
    request('post', '/api/order/order/getOrderList', {
      pageNum: 1,
      pageSize: 10,
      type: 4
    }).then(res => {
      this.setState({
        pjNum: res.data.total
      })
    })
  }
  //退款
  tk() {
    request('post', '/api/order/order/saleOrderList', {
      pageNum: 1,
      pageSize: 10,
    }).then(res => {
      this.setState({
        tkNum: res.data.total
      })
    })
  }
  render() {
    const { s, isShow, userInfo } = this.state;

    const authType = () => {
      const { userInfo } = this.state
      ////console.log(shopAuth);
      if (userInfo.level === null) {
        return '个人'
      } else {
        switch (userInfo.level) {
          case 0:
            return '个人';
          case 1:
            return '企业';
          case 2:
            return '经纪人';
          default:
            return '未认证'
        }
      }
    }
    return (
      <div className="myindex">
        <div className="top">
          <img className="img" src={this.state.userInfo.memberAvator} alt="头像" />
          <div style={{ marginLeft: -50 }} className="a1">
            <div className="a1left">
              <div>{this.state.userInfo.memberName}</div>
              <div>{userInfo.level == 0 ? <img src={geren} alt="认证类别" /> : (userInfo.level == 1 ? <img src={qiye} alt="企业" /> : <img src={geren} alt="认证类别" />)}</div> {/*/my/renzheng--认证详情  authentication--我要认证   Auditresult--审核*/}
            </div>

            <div className="a1Right">
              <div className="na">{authType()}</div>
              <Link to={'/home/OpenVip'}>
                <div className="openVip">开通会员</div>
              </Link>

            </div>
          </div>
          <Link to={`${this.state.shopAuth == null ? '/authentication' : (this.state.shopAuth.authStatus == 0 ? '/Auditresult' : (this.state.shopAuth.authStatus == 1 ? '/my/renzheng' : '/Auditresult'))}`} target="_blank">
            <div className="a2">
              <div>认证情况 {'>'}</div>
              <div>查看详情</div>
            </div>
          </Link>
          {/* <div className="a2">
            <div>优惠券  {'>'}</div>
            <div>查看详情</div>
          </div> */}
          <Link to={`/myProfile/Shoppingcat`} target="_blank">
            <div className="a2" >
              <div >购物车  {'>'}</div>
              <div>查看详情</div>
            </div>
          </Link>

          <div className="a2">
            <div className="balance">
              <span>余额：{this.state.userInfo.money}</span>
              <span onClick={() => this.isShow(1)}>查看详情</span>
            </div>
            <div className="topUpWithdraw">
              <div className="topUpBtn" onClick={() => this.isShow(2)}>
                <img src={chongzhi} alt="" />
                充值
              </div>
              <div className="WithdrawBtn" onClick={() => this.isShow(3)} >
                <img src={tixian} alt="" />
                提现
              </div>
            </div>
          </div>
          <div className="a2">
            <div className="numBalance">
              数字货币：{this.state.userInfo.freeze}
            </div>
          </div>
        </div>
        <div className="top2">
          <div className="b1">
            <div className="title">我的订单</div>
            <div className="list">
              <div>
                <Link to={{ pathname: '/myProfile/order', state: { type: 10 } }}>
                  <img src={order1} alt="全部" />
                  <span>全部</span>
                </Link>
              </div>
              <div>
                <Link to={{ pathname: '/myProfile/order', state: { type: 1 } }}>
                  <img src={order2} alt="待支付" />
                  <span>待支付</span>
                  <span>{this.state.zfNum}</span>
                </Link>
              </div>
              <div>
                <Link to={{ pathname: '/myProfile/order', state: { type: 2 } }}>
                  <img src={order3} alt="待发货" />
                  <span>待发货</span>
                  <span>{this.state.fhNum}</span>
                </Link>
              </div>
              <div>
                <Link to={{ pathname: '/myProfile/order', state: { type: 3 } }}>
                  <img src={order4} alt="待收货" />
                  <span>待收货</span>
                  <span>{this.state.shNum}</span>
                </Link>
              </div>
              <div>
                <Link to={{ pathname: '/myProfile/order', state: { type: 4 } }}>
                  <img src={order5} alt="待评价" />
                  <span>待评价</span>
                  <span>{this.state.pjNum}</span>
                </Link>
              </div>
              <div>
                <Link to={{ pathname: '/myProfile/order', state: { type: 99 } }}>
                  <img src={order6} alt="售后/退款" />
                  <span>售后/退款</span>
                  <span>{this.state.tkNum}</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="b2">
            <div className="title">我的收藏</div>
            <div className="nei">
              <Link tag="div" style={{ display: 'flex', justifyContent: 'space-around' }} to='/myProfile/Collect'>
                <div>商品收藏<span>{this.state.goodsNum}</span></div>
                <div>店铺收藏<span>{this.state.shopNum}</span></div>
              </Link>
            </div>
          </div>
        </div>
        <div className="tuijian">
          <div className="title">
            {
              isShow == 0 ? '商品推荐' : (isShow == 1 ? '财务明细' : (isShow == 2 ? '请输入充值金额' : '提现申请'))
            }
          </div>
          {
            isShow == 0 ?
              <div className="item1">
                <Iiem />
              </div>
              : (isShow == 1 ?
                <div className="inquireDeaL">
                  <Financialdetails />
                </div>
                : (isShow == 2 ?
                  <div className="goPay">
                    <Pay />
                  </div>
                  : (isShow == 3 ?
                    <Withdraw data={this.state.userInfo && this.state.userInfo} content={(val) => this.isShow(val)} />
                    : <Intie />
                  )
                )
              )
          }
        </div>
      </div>
    );
  }
}
