import React, { Component, useState } from 'react';
import cookie from 'react-cookies';
import { message, Button } from 'antd';
import './index.scss'
import request from '../../../static/js/http';

export default class Myyijian extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone:'',
      content:'',
    }
  }
  componentDidMount() {

  }

  //意见反馈
  opinion() {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    const {phone,content} = this.state
    request('post', '/api/cms/opinion/addSave',{
      memberId,
      content,
      img:''
      // phone,
    }).then(res=>{
      ////console.log(res);
      if (res.code===0) {
        message.success('提交成功');
        this.setState({
          content:''
        },function () {
          this.setState({
            content:''
          })
        })
      }
    })
  }
  content(e){
    this.setState({
      content:e.target.value
    })
  }
  phone(e){
    this.setState({
      phone:e.target.value
    })
  }

  render() {
    return (
      <>
        <div className="myyijian">
          <div className="yijian">
            <div className="miaoshu">
              <span>意见描述：</span>
              <textarea className="textarea" value={this.state.content} onChange={(e)=>this.content(e)} placeholder="在这里您可以提出您的宝贵意见和建议，我们会竭尽全力倾听您的想法！"></textarea>
            </div>
            {/* <div className="phone">
              联系方式：<input className="ipt" type="number" onChange={(e)=>this.phone(e)}  />
            </div> */}
            <Button type="primary" onClick={()=>this.opinion()} >
              提交
          </Button>
          </div>

        </div>
      </>
    )
  }
}