import React from 'react';
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd'
import MyRoute from "./pages/MyRoute/MyRoute"
// import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Switch, Route, Redirect, Router } from 'react-router-dom';
import Top from './components/Top';
import Footer from './components/Footer';
import Login from './pages/Login';
import Home from './pages/Home';
import Index1 from './pages/Home/Index1';// 首页
import Cuohedating from './pages/Home/TogetherHall';// 撮合大厅
import Caigouxiangqing from './pages/Home/TogetherHall/Purchasinghall/Purchasedetails';//采购大厅详情
import Gongyingxiangqing from './pages/Home/TogetherHall/Supplyhall/Supplydetails';//供应大厅详情
import Chuzuxiangqing from './pages/Home/TogetherHall/Leasehall/Leasedetails';//冷库出租详情
import Qiuzuxiangqing from './pages/Home/TogetherHall/Wantedhall/Wanteddetails';//冷库求租详情
import Details from './pages/Home/Details';// 商品详情
import Search from './pages/Home/Search';// 搜索
import News from './pages/Home/News';// 消息列表
import Newsitem from './pages/Home/Newsitem';// 消息详情
import Guarantee from './pages/Home/Guarantee'; // 买家保障
import Boutique from './pages/Home/Boutique';// 优品优选
import Retail from './pages/Home/Retail';// 精品零售
import Agriculture from './pages/Home/Agriculture'; // 农资商城
import Confirmorder from './pages/Home/Confirmorder';// 确认订单
import Financial from './pages/Home/Financial';// 金融服务
import FinancialSubmitInfo from './pages/Home/Financial/FinancialSubmitInfo';// 金融服务-信息提交
import Payment from './pages/Payment';// 支付方式
import Store from './pages/Store';// 店铺详情
import My from './pages/My';// 个人中心
import Myrenzheng from './pages/My/Renzheng'; //认证详情
import My1 from './pages/Myprofile';// 个人中心的二级页面
import Myindex from './pages/My/Myindex';// 个人中心首页
import Myindex1 from './pages/My/Accountsettings';// 个人中心首页
import Myserve from './pages/My/Myserve';// 个人中心我的服务
import Mycaigou from './pages/My/Mycaigou';// 个人中心我的采购
import Caigouoffer from './pages/My/Mycaigou/Shoudaobaojia'//个人中心我的采购报价
import Mygongying from './pages/My/Mygongying';// 个人中心我的供应
import Mychuzu from './pages/My/Mychuzu';// 个人中心冷库出租
import Myzuping from './pages/My/Myzuping';// 个人中心冷库租赁
import Myhangqing from './pages/My/Myhangqing';// 个人中心我的行情
import Mydizhi from './pages/My/Mydizhi';// 个人中心地址管理
import Mypingjia from './pages/My/Mypingjia';// 个人中心评价管理
import Pingjiaxiangqing from './pages/My/Mypingjia/Details'//个人中心评价管理详情
import Myyijian from './pages/My/Myyijian';// 个人中心意见反馈
import Myzuji from './pages/My/Myzuji';// 个人中心我的足迹
import Lianxikefu from './pages/My/Lianxikefu';// 个人中心联系客服
import Myorder from './pages/Myprofile/Myorder';// 个人中心订单管理
import OrderDetails from './pages/Myprofile/OrderDetails';// 订单详情
import RefundDetails from './pages/Myprofile/RefundDetails';// 售后订单详情
import Collect from './pages/Myprofile/Collect';// 收藏
import Identity from './pages/Home/Identity';// 选择身份
import Authentication from './pages/Authentication';// 认证
import Auditresult from './pages/Authentication/Auditresult';// 认证结果
import Examine from './pages/Examine';// 认证情况
import Shoppingcat from './pages/Shoppingcat'//购物车
// import Shopcat from './pages/Shopcat'//购物车
import AliPayPc from './pages/Myprofile/Zhifu' //支付页面
import Market from './pages/Home/Market'//行情大厅
import Rcserve from './pages/Home/Rcserve' //农技服务
import Questiondetails from './pages/Home/Rcserve/questiondetails' //农技服务
import Toevaluate from './pages/Myprofile/Toevaluate' //去评价
import MarketApp from './pages/MarketApp' //去评价
import Agriculdetails from './pages/Home/Agriculture/Agriculdetails' //农资商城商品详情
import MarketConsult from './pages/Home/MarketConsult' //行业资讯
import MarketConsultDetails from './pages/Home/MarketConsult/MarketConsultDetails' //行业资讯详情
import Futurestrading from './pages/Home/Futurestrading' //期货交易
import FuturestradingDetails from './pages/Home/Futurestrading/FuturestradingDetails' //期货交易详情
import SearchShop from './pages/Home/searchShop' //搜索店铺
import OpenVip from './pages/Home/OpenVip' //搜索店铺
export default class IRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <ConfigProvider locale={zhCN}>
          <Top />
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/payment" component={Payment} />
            <Route path="/store/:id" component={Store} />
            <MyRoute path="/authentication" component={Authentication} />
            <Route path="/Auditresult" component={Auditresult} />
            <MyRoute path="/my/renzheng" component={Myrenzheng} />
            <Route path="/examine" component={Examine} />
            <Route path="/home/search" component={Search} />
            <Route path="/home/SearchShop" component={SearchShop} />
            <Route
              path="/my"
              render={() => (
                <My>
                  <Switch>
                    <MyRoute path="/my/index" component={Myindex} />
                    <MyRoute path="/my/index1" component={Myindex1} />
                    {/* <MyRoute path="/my/index" component={Myindex} /> */}
                    <MyRoute path="/my/serve" component={Myserve} />
                    <MyRoute path="/my/caigou" component={Mycaigou} />
                    <MyRoute path="/my/gongying" component={Mygongying} />
                    <MyRoute path="/my/zuping" component={Myzuping} />
                    <MyRoute path="/my/hangqing" component={Myhangqing} />
                    <MyRoute path="/my/dizhi" component={Mydizhi} />
                    <MyRoute path="/my/pingjia" component={Mypingjia} />
                    <MyRoute path="/my/yijian" component={Myyijian} />
                    <MyRoute path="/my/zuji" component={Myzuji} />
                    <MyRoute path="/my/kefu" component={Lianxikefu} />
                    <MyRoute path="/my/chuzu" component={Mychuzu} />
                    <Redirect to="/my/index" />
                  </Switch>
                </My>
              )}
            />
            <Route
              path="/myProfile"
              render={() => (
                <My1>
                  <Switch>
                    <MyRoute path="/myProfile/order" component={Myorder} />
                    <MyRoute path="/myProfile/orderdetails" component={OrderDetails} />
                    <MyRoute path="/myProfile/refundDetails" component={RefundDetails} />
                    <MyRoute path="/myProfile/Shoppingcat" component={Shoppingcat} />
                    {/* <MyRoute path="/myProfile/Shopcat" component={Shopcat} /> */}
                    <MyRoute path="/myProfile/Caigouoffer" component={Caigouoffer} />
                    <MyRoute path="/myProfile/Pingjiaxiangqing" component={Pingjiaxiangqing} />
                    <MyRoute path="/myProfile/aliPayPc" component={AliPayPc} />
                    <MyRoute path="/myProfile/Collect" component={Collect} />
                    <MyRoute path="/myProfile/MarketApp" component={MarketApp} />
                    <MyRoute path="/myProfile/Toevaluate" component={Toevaluate} />
                    <Redirect to="/my/index" />
                  </Switch>
                </My1>
              )}
            />
            <Route
              path="/home"
              render={() => (
                <Home>
                  <Switch>
                    <Route path="/home/index" component={Index1} />
                    <Route path="/home/cuohedating" component={Cuohedating} />
                    <Route path="/home/MarketConsult" component={MarketConsult} />
                    <Route path="/home/MarketConsultDetails" component={MarketConsultDetails} />
                    <Route path="/home/details/:id" component={Details} />
                    <Route path="/home/agriculdetails/:id" component={Agriculdetails} />
                    <Route path="/home/news" component={News} />
                    <Route path="/home/Market" component={Market} />
                    <Route path="/home/futurestrading" component={Futurestrading} />
                    <Route path="/home/futurestradingDetails/:id" component={FuturestradingDetails} />
                    <Route path="/home/newsitem" component={Newsitem} />
                    <Route path="/home/guarantee" component={Guarantee} />
                    <Route path="/home/boutique" component={Boutique} />
                    <Route path="/home/retail" component={Retail} />
                    <Route path="/home/agriculture" component={Agriculture} />
                    <Route path="/home/confirmorder" component={Confirmorder} />
                    <Route path="/home/identity" component={Identity} />
                    <Route path="/home/financial" component={Financial} />
                    <Route path="/home/FinancialSubmitInfo" component={FinancialSubmitInfo} />
                    <Route path="/home/caigouxiangqing" component={Caigouxiangqing} />
                    <Route path="/home/gongyingxiangqing" component={Gongyingxiangqing} />
                    <Route path="/home/chuzuxiangqing" component={Chuzuxiangqing} />
                    <Route path="/home/qiuzuxiangqing" component={Qiuzuxiangqing} />
                    <Route path="/home/OpenVip" component={OpenVip} />
                    <MyRoute path="/home/rcserve" component={Rcserve} />
                    <MyRoute path="/home/questiondetails" component={Questiondetails} />
                    <Redirect to="/home/index" />
                  </Switch>
                </Home>
              )}
            />

            <Redirect to="/home" />
          </Switch>
          <Footer />
        </ConfigProvider>
      </div>

    );
  }
}
// import React, { Component } from 'react';
// import './index.scss';
// class MarketApp extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//     }
//   }
//   render(){
//     return(
//       <>

//       </>
//     )
//   }
// }
// export default MarketApp