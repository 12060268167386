import React, { Component, useState } from 'react';
import cookie from 'react-cookies';
import './index.scss'
import { Modal, Button, Pagination, message,Image } from 'antd';
import Xiugai from './fabugongying'
import request from '../../../static/js/http';
import { withRouter } from 'react-router';
class Mygongying extends Component {
  constructor(props) {
    super(props);
    this.state = {
      biaotitab: [
        { name: '供应中', id: '1', type: '0' },
        { name: '已结束', id: '2', type: '1' },
        { name: '被驳回', id: '3', type: '2' },
      ],
      itemList: [
        { img: 'https://t.irocks.cn/cherry_custom/imgs/20200429/42554d773c5c9949f98bdf1dd08ed957.jpg', name: '辣椒-子弹头', jiage: 200, lv: '花皮', connet: '优质好货', id: '1', type: '2' },
        { img: 'https://t.irocks.cn/cherry_custom/imgs/20200429/42554d773c5c9949f98bdf1dd08ed957.jpg', name: '辣椒-子弹头', jiage: 200, lv: '花皮', connet: '优质好货', id: '2', type: '2' },
        { img: 'https://t.irocks.cn/cherry_custom/imgs/20200429/42554d773c5c9949f98bdf1dd08ed957.jpg', name: '辣椒-子弹头', jiage: 200, lv: '花皮', connet: '优质好货', id: '3', type: '2' },
        { img: 'https://t.irocks.cn/cherry_custom/imgs/20200429/42554d773c5c9949f98bdf1dd08ed957.jpg', name: '辣椒-子弹头', jiage: 200, lv: '花皮', connet: '优质好货', id: '4', type: '2' },


      ],
      isbianji: false,
      isdel: false,
      isover: false,
      isup: false,
      currentIndex: 0,
      supplylist: [],
      fenye: [],
      statu: 0,
      supperyId: '',

    }
    this.child = React.createRef();
  }
  componentDidMount() {
    this.getsupplylist()
  }

  //我的供应列表
  getsupplylist() {
    const { statu } = this.state
    ////console.log(statu);
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/apply/supply/listByUserId', {
      memberId,
      statu,
      pageSize: 5,
      pageNum: 1
    }).then(res => {
      ////console.log(res);
      if (res.code == 0) {
        this.setState({
          supplylist: res.data.list,
          fenye: res.data
        }, function () {

        })
        message.success(res.msg);
      } else {
        message.warning(res.msg);
      }

    })
  }
  // 下架
  Supplyover() {
    const { supperyId } = this.state
    ////console.log(supperyId);
    request('post', '/api/apply/supply/updateApplySupply', {
      supperyId,
      statu: 1,
    }).then(res => {
      ////console.log(res);
      if (res.code == 0) {
        this.getsupplylist()
        message.success(res.msg);
      } else {
        message.warning(res.msg);
      }
    })
  }
  // 上架
  Supplyup() {
    const { supperyId } = this.state
    ////console.log(supperyId);
    request('post', '/api/apply/supply/updateApplySupply', {
      supperyId,
      statu: 0,
    }).then(res => {
      ////console.log(res);
      if (res.code == 0) {
        this.getsupplylist()
        message.success(res.msg);
      } else {
        message.warning(res.msg);
      }
    })
  }
  // 删除
  Supplydel() {
    const { supperyId } = this.state
    ////console.log(supperyId);
    request('post', '/api/apply/supply/deleteApplySupply', {
      id: supperyId,
    }).then(res => {
      ////console.log(res);
      if (res.code == 0) {
        this.getsupplylist()
        message.success(res.msg);
      } else {
        message.warning(res.msg);
      }
    })
  }
  //修改
  reg = () => {
    const { supperyId } = this.state
    let memberId = cookie.load('lj-pc-userInfo').memberId
    let title = this.child.current.state.title
    request('post', '/api/apply/supply/updateApplySupply', {
      memberId,
      title,
      supperyId,
      goodclass: this.child.current.state.purchaseClass,
      level: this.child.current.state.purchaseLevel,
      supperPrice: this.child.current.state.purchasePrice,
      fromword: this.child.current.state.fromword,
      go: this.child.current.state.go,
      imagesPath: this.child.current.state.imagesPath,
      conment: this.child.current.state.conent,
    }).then(res => {
      ////console.log(res);
      this.getsupplylist()
      if (res.code === 0) {
        message.success({
          content: res.msg,
        });
      }
    })
    ////console.log(this);
  }
  //分页
  pagination = (a, b) => {
    ////console.log(a, b);
    const { statu } = this.state
    ////console.log(statu);
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/apply/supply/listByUserId', {
      pageSize: b,
      pageNum: a,
      memberId,
      statu,
    }).then(res => {
      ////console.log(res);
      if (res.code == 0) {
        this.setState({
          supplylist: res.data.list,
          fenye: res.data
        })
        message.success(res.msg);
      }
    })
  }
  tab = (type, index) => {
    ////console.log(index);
    this.setState({
      currentIndex: index,
      statu: type
    }, function () {
      this.getsupplylist()
    })
  }
  showbianji = (supperyId) => {
    this.setState({
      isbianji: true,
      supperyId
    })
  };

  bianjiOk = () => {
    this.setState({
      isbianji: false
    })
  };

  bianjiCancel = () => {
    this.setState({
      isbianji: false
    })
  };
  //删除商品
  showdel = (supperyId) => {
    ////console.log(supperyId);
    this.setState({
      isdel: true,
      supperyId
    })
  };

  delOk = () => {

    this.setState({
      isdel: false,
    }, function () {
      this.Supplydel()
    })
  };

  delCancel = () => {
    this.setState({
      isdel: false
    })
  };
  //下架商品
  showover = (supperyId) => {
    ////console.log(supperyId);
    this.setState({
      isover: true,
      supperyId
    })
  };

  overOk = () => {

    this.setState({
      isover: false,
    }, function () {
      this.Supplyover()
    })
  };

  overCancel = () => {
    this.setState({
      isover: false
    })
  };
  //上架商品
  showup = (supperyId) => {
    ////console.log(supperyId);
    this.setState({
      isup: true,
      supperyId
    })
  };

  upOk = () => {
    this.setState({
      isup: false,
    }, function () {
      this.Supplyup()
    })
  };

  upCancel = () => {
    this.setState({
      isup: false
    })
  };
  //
  render() {
    const { biaotitab, itemList, supplylist, fenye, statu } = this.state
    // let img = ''
    // const Img = () => {
    //   if (supplylist[0]) {
    //     ////console.log(supplylist[0].image[0]);
    //     supplylist.map(item => {

    //       let img = ''
    //       let arr = item.image
    //       let reg = /(\[)|(\])/g
    //       let imageArr = arr.replace(reg, "")
    //        img = imageArr.split(',')[0]
    //        return <img src={img} alt="" />
    //       // ////console.log(item.image.replace(/(\[)|(\])/g,""))
    //     })
    //   }
    // }

    return (
      <>
        <div className="mygongying">
          <div className="biaoti">
            我的供应
          </div>
          <div className="gongying">
            <div className="gongyingtab">
              {
                biaotitab.map((item, index) => {
                  return <div className={`tabitem ${this.state.currentIndex === index ? 'active' : ''}`} onClick={() => this.tab(item.type, index)} key={item.id}>
                    {item.name}
                  </div>
                })
              }
            </div>
            <table className="listTable">
              <thead className="listThead">
                <tr className="listTr">
                  <th>供应主图</th>
                  <th>品种</th>
                  <th>价格</th>
                  <th>等级</th>
                  <th>内容</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody className="listTbody">
                {
                  supplylist && supplylist.map(item => (
                    <tr className="TbodyTr" key={item.supperyId}>
                      <td>
                        <Image width={80} src={item.image.replace(/(\[)|(\])|(\")/g, "").split(",")[0]} alt="" />
                      </td>
                      <td>{item.goodclass}</td>
                      <td>{item.supperPrice}</td>
                      <td>{item.level}</td>
                      <td>{item.conment}</td>
                      {
                        statu == 0 ?
                          <td>
                            <Button onClick={() => this.showbianji(item.supperyId)}>
                              编辑
                         </Button>
                            <Button onClick={() => this.showover(item.supperyId)}>
                              下架
                         </Button>
                          </td> :
                          (statu == 1 ?
                            <td>
                              <Button onClick={() => this.showdel(item.supperyId)}>
                                删除供应
                         </Button>
                              <Button onClick={() => this.showup(item.supperyId)}>
                                重新上架
                         </Button>
                            </td> :
                            <td>
                              <Button onClick={() => this.showdel(item.supperyId)}>
                                删除
                         </Button>
                              <Button onClick={() => this.showbianji(item.supperyId)}>
                                修改
                         </Button>
                            </td>
                          )
                      }
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          <Pagination pageSize='5' defaultCurrent={1} current={fenye.pageNum} onChange={this.pagination} total={fenye.total} />
        </div>
        <Modal className="bianjiModal" title="编辑" visible={this.state.isbianji} onOk={this.bianjiOk} onCancel={this.bianjiCancel}
          footer={[
            <Button onClick={() => this.reg()} type="primary">
              确认编辑
                        </Button>
          ]}
        >
          <Xiugai ref={this.child} />
        </Modal>
        <Modal title="删除信息"
          cancelText={'取消'}
          okText={'确认'}
          visible={this.state.isdel} onOk={() => this.delOk()} onCancel={this.delCancel}>
          确认删除该商品
          </Modal>
        <Modal title="下架信息"
          cancelText={'取消'}
          okText={'确认'}
          visible={this.state.isover} onOk={() => this.overOk()} onCancel={this.overCancel}>
          确认下架该商品
          </Modal>
        <Modal title="上架信息"
          cancelText={'取消'}
          okText={'确认'}
          visible={this.state.isup} onOk={() => this.upOk()} onCancel={this.upCancel}>
          确认下架该商品
          </Modal>
      </>
    )
  }
}
export default withRouter(Mygongying)