import React, { Component } from 'react';
import {
  Form, Input, Button, message, Row, Col, Upload
} from 'antd';
import cookie from 'react-cookies';
import { withRouter } from "react-router-dom"
import {
  LoadingOutlined, PlusOutlined, ExclamationCircleFilled
} from '@ant-design/icons';
import './index.scss';
import request from '../../../static/js/http';
class Geren extends Component {
  constructor() {
    super();
    this.state = {
      btnText: '发送验证码',
      btnBool: false,
      ipo: '',
      loading: false,
      imageUrl: '',
      loading1: false,
      imageUrl1: '',
      authName: '',
      authIdcard: '',
      codeValue: '',
    };
  }
  componentDidMount() {
    console.log(this.props.location);
  }
  person() {
    const { ipo, authName, authIdcard, imageUrl, imageUrl1, codeValue } = this.state
    let memberId = cookie.load('lj-pc-userInfo').memberId
    if (!ipo) {
      message.warning('请填写手机号')
    } else if (!authName) {
      message.warning('请填写姓名')
    } else if (!imageUrl) {
      message.warning('请上传身份证正面')
    } else if (!imageUrl1) {
      message.warning('请上传身份证反面')
    } else if (!authIdcard) {
      message.warning('请填写身份证号码')
    } else if (!codeValue) {
      message.warning('请填写验证码')
    } else {
      request('post', `${!this.props.location.state?'/api/member/shop/auth':'/api/member/shop/editAuth'}`, {
        memberId,
        authPhone: ipo,
        authName,
        authIdcard,
        authHead: imageUrl,
        authTail: imageUrl1,
        authType: 2,
        authEnterprise: '',
        authLicense: '',
        authFood: '',
        authCode: '',
        authOperator: '',
      }).then(res => {
        if (res.code == 0) {
          message.success(res.msg)
        } else {
          message.warning(res.msg)
        }
      })
    }
  }
  code() {
    const { ipo } = this.state
    request('post', '/api/member/sms/send', {
      phone: ipo
    }).then(res => {
      if (res.code == 0) {
        message.success(res.msg)
      } else {
        message.warning(res.msg)
      }
    })
  }

  onFinish = values => {
    // eslint-disable-next-line no-console
  };

  // 获取input的值
  input(v) {
    this.setState({
      ipo: v.target.value
    });
  }
  // 获取authName的值
  authName(v) {
    this.setState({
      authName: v.target.value
    });
  }
  // 获取authIdcard的值
  authIdcard(v) {
    this.setState({
      authIdcard: v.target.value
    });
  }
  // 获取authIdcard的值
  codeValue(v) {
    this.setState({
      codeValue: v.target.value
    });
  }

  sendCode() {
    const { ipo } = this.state;
    const text = /^[1][3,4,5,7,8,9][0-9]{9}$/;
    if (!text.test(ipo)) {
      message.error('手机号格式错误');
    } else {
      let maxTime = 60;
      this.code()
      this.timer = setInterval(() => {
        if (maxTime > 0) {
          --maxTime;
          this.setState({
            btnText: `重新获取${maxTime}s`,
            btnBool: true
          });
        } else {
          this.setState({
            btnText: '发送验证码',
            btnBool: false
          });
        }
      }, 1000);
    }
  }

  handleChange = (info, a) => {
    if (info.file.status === 'uploading') {
      if (a === 1) {
        this.setState({ loading: true });
      } else {
        this.setState({ loading1: true });
      }
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      if (a === 1) {
        this.setState({
          imageUrl: info.file.response.data,
          loading: false
        })
      } else {
        this.setState({
          imageUrl1: info.file.response.data,
          loading1: false
        })
      }
    }
  };

  render() {
    const { btnText, btnBool, loading, imageUrl, loading1, imageUrl1 } = this.state;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>身份证正面</div>
      </div>
    );
    const uploadButton1 = (
      <div>
        {loading1 ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>身份证反面</div>
      </div>
    );
    return (
      <div className="geren">
        <Form
          name="normal_login1"
          className="login-form"
          autoComplete="off"
          initialValues={{
            remember: true
          }}
          wrapperCol={{ span: 14 }}
          labelCol={{ span: 6 }}
          size="large"
          onFinish={values => this.onFinish(values)}
          id="mobilelogin1"
        >
          <Form.Item
            name="ipo"
            label="手机号"
            rules={[
              {
                required: true,
                message: '请输入手机号'
              }, {
                pattern: /^1\d{10}$/,
                message: '不合法的手机号格式!'
              }
            ]}
          >
            <Input
              type="number"
              autoComplete="off"
              onChange={v => this.input(v)}
              maxLength="11"
              placeholder="请输入手机号"
            />
          </Form.Item>
          <Form.Item
            label="验证码"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Row>
              <Col span={16}>
                <Form.Item
                  name="yanzheng"
                  rules={[
                    {
                      required: true,
                      message: '请输入验证码!'
                    }
                  ]}
                  className="yan"
                >
                  <Input autoComplete="off"
                    type="number"
                    onChange={v => this.codeValue(v)}
                    placeholder="请输入验证码!" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Button className="botton" onClick={() => this.sendCode()} type="primary" disabled={btnBool}>{btnText}</Button>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            name="username"
            label="姓名"
            rules={[
              {
                required: true,
                message: '请输入您的姓名'
              }
            ]}
          >
            <Input
              autoComplete="off"
              placeholder="请输入您的姓名"
              onChange={v => this.authName(v)}
            />
          </Form.Item>
          <Form.Item
            name="id"
            label="身份证"
            rules={[
              {
                required: true,
                message: '请输入身份证号'
              }, {
                pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
                message: '不合法的身份证号格式!'
              }
            ]}
          >
            <Input
              type="number"
              autoComplete="off"
              maxLength="18"
              placeholder="请输入身份证号"
              onChange={v => this.authIdcard(v)}
            />
          </Form.Item>
          <Form.Item
            label="负责人身份证"
            rules={[{ required: true }]}
            className="tu"
          >
            <Row>
              <Col span={12}>
                <Upload
                  action="http://1.116.154.35:25921/clife-boot-api-app/api/cms/common/upload"
                  name="file"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={() => this.beforeUpload}
                  onChange={(i) => this.handleChange(i, 1)}
                >
                  {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%', height: '100%' }} /> : uploadButton}
                </Upload>
              </Col>
              <Col span={12}>
                <Upload
                  name="file"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action="http://1.116.154.35:25921/clife-boot-api-app/api/cms/common/upload"
                  beforeUpload={() => this.beforeUpload}
                  onChange={(i) => this.handleChange(i, 2)}
                >
                  {imageUrl1 ? <img src={imageUrl1} alt="avatar" style={{ width: '100%', height: '100%' }} /> : uploadButton1}
                </Upload>
              </Col>
            </Row>
            <Row>
              <Col style={{ textAlign: 'left', color: '#C7C7C7' }} span={12}>
                <ExclamationCircleFilled style={{ color: '#D94511', marginRight: '4px' }} /><span>要求：证件文字清晰，建议使用原图；</span>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 8, offset: 6 }}>
            <Button type="primary" onClick={() => this.person()} htmlType="submit" className="bo">
              确认提交
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}
export default withRouter(Geren)