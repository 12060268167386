import React, { Component } from 'react';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import './index.scss';
import img from '../../../../static/img/del_home_left.png';
import request from '../../../../static/js/http';
import { withRouter } from 'react-router';
 class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [1, 2, 3, 4, 5],
      supply: [],
      purchase: [],
      time: '',
      supplypageNum: 1,
      purchasepageNum: 1,
    };
  }
  componentDidMount() {
    this.supply()
    this.purchase()
  }
  supply() {
    const { supplypageNum } = this.state
    request('post', '/api/apply/supply/list', {
      pageSize: 5,
      pageNum: supplypageNum,
      statu:0
    }).then(res => {
      this.setState({
        supply: res.data,
        time: res.data.list[0].createAt
      })
      ////console.log(res);
    })
  }
  purchase() {
    const { purchasepageNum } = this.state
    request('post', '/api/apply/purchase/list ', {
      pageSize: 5,
      pageNum: purchasepageNum,
      statu:0
    }).then(res => {
      this.setState({
        purchase: res.data,
        time: res.data.list[0].createAt
      })
      ////console.log(res);
    })
  }
  supplyjia = () => {
    const { supplypageNum } = this.state;
    this.setState({
      supplypageNum: supplypageNum + 1
    }, function () {
      this.supply()
    });
  }

  supplyjian = () => {
    const { supplypageNum } = this.state;
    this.setState({
      supplypageNum: supplypageNum - 1
    }, function () {
      this.supply()
    });
  }
  purchasejia = () => {
    const { purchasepageNum } = this.state;
    this.setState({
      purchasepageNum: purchasepageNum + 1
    }, function () {
      this.purchase()
    });
  }

  purchasejian = () => {
    const { purchasepageNum } = this.state;
    this.setState({
      purchasepageNum: purchasepageNum - 1
    }, function () {
      this.purchase()
    });
  }
  supplydetails(id) {
    // ////console.log(this.props);
    this.props.history.push({ pathname: '/home/gongyingxiangqing', state: { id } })
  }
  purchasedetails(id) {
    this.props.history.push({ pathname: '/home/caigouxiangqing', state: { id } })
  }
  render() {
    const { supply, purchase, supplypageNum, purchasepageNum } = this.state;

    return (
      <div className="rankings">
        <div className="rankList">
          <div className="one">
            <div className="title">最新供应</div>
            <hr />
            <div className="right">
              <Button className="button" disabled={supplypageNum <= 1} size="large" onClick={() => this.supplyjian()} icon={<LeftOutlined />} />
              <Button className="button" disabled={supplypageNum >= supply.pages} size="large" onClick={() => this.supplyjia()} icon={<RightOutlined />} />
            </div>
          </div>
          <div className="date">
            最新更新时间：{this.state.time}
          </div>
          <div className="list">
            {
              supply.list && supply.list.map(a => (
                <div key={a.supperyId} onClick={() => this.supplydetails(a.supperyId)} className="item hover">
                  <img src={img} alt="种类" />
                  <div className="zhong">
                    <div>{a.title}</div>
                    <div className="address sheng2" title={`供应地区：${a.fromword}`}>供应地区：{a.fromword}</div>
                  </div>
                  <div className="qian">{a.supperPrice}元/斤</div>
                </div>
              ))
            }
          </div>
        </div>
        <div className="rankList">
          <div className="one">
            <div className="title">最新采购</div>
            <hr />
            <div className="right">
              <Button className="button" disabled={purchasepageNum <= 1} size="large" onClick={() => this.purchasejian()} icon={<LeftOutlined />} />
              <Button className="button" disabled={purchasepageNum >= purchase.pages} size="large" onClick={() => this.purchasejia()} icon={<RightOutlined />} />
            </div>
          </div>
          <div className="date">
            最新更新时间：{this.state.time}
          </div>
          <div className="list">
            {
              purchase.list && purchase.list.map(a => (
                <div key={a.purchaseId} onClick={() => this.purchasedetails(a.purchaseId)} className="item hover">
                  <img src={img} alt="种类" />
                  <div className="zhong">
                    <div>{a.title}</div>
                    <div className="address sheng2" title={`供应地区：${a.fromword}`}>供应地区：{a.fromword}</div>
                  </div>
                  <div className="qian">{a.purchaseNum}斤</div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Home)