import React, { Component } from 'react';
import './index.scss';
import cookie from 'react-cookies';
import * as echarts from 'echarts';
import request from '../../../../static/js/http';
import moment from 'moment'
import './china'

class Pricedistributionarea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marketList: [],
      cities: [],
      province: '',
      secondCity: '',
      getMarketTradeRecord: [],
      currentIndex: '',
      type: '',
      areaCode:'',
      areaType:'',
      time: [
        { name: '月度', id: 1, type: 1 },
        { name: '季度', id: 2, type: 2 },
        { name: '年度', id: 3, type: 3 }
      ],
      timeIndex: 0,
    }
  }
  componentDidMount() {
    // this.market()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    const { currentIndex, type,areaCode,areaType } = this.state
    if (currentIndex !== nextProps.currentIndex) {
      this.setState({
        currentIndex: nextProps.currentIndex,
      }, function () {
        this.market()
        //console.log(currentIndex);
      })
    }
    if (type !== nextProps.type) {
      this.setState({
        type: nextProps.type,
      }, function () {
        this.market()
        //console.log(type);
      })
    }
    if (areaCode !== nextProps.areaCode) {
      this.setState({
        areaCode: nextProps.areaCode,
      }, function () {
        this.market()
        //console.log(areaCode);
      })
    }
    if (areaType !== nextProps.areaType) {
      this.setState({
        areaType: nextProps.areaType,
      }, function () {
        this.market()
        //console.log(areaType);
      })
    }
  }
  market() {
    const { currentIndex, type,areaType ,areaCode} = this.state
    console.log(currentIndex);
    request('post', '/api/market/dock/getHistoryPrice', {
      memberId: cookie.load('lj-pc-userInfo') ? cookie.load('lj-pc-userInfo').memberId : '',
      productId: currentIndex,
      areaType,
      areaCode,
      historyType: type
    }).then(res => {
      //console.log(res);
      this.setState({
        marketList: res.data
      }, function () {
        this.historicalPrice()
        this.getMarketTradeRecord()
      })
    })
  }
  tab = (type, index) => {
    //console.log(type);
    this.setState({
      timeIndex: index,
      type
    }, function (params) {
      this.market()
    })
  }
  handleProvinceChange = value => {
    this.setState({
      province: value
    }, function () {
    })
  };
  //获取全国农产品价格
  getMarketTradeRecord() {
    const { currentIndex } = this.state
    request('post', '/api/market/dock/getMarketTradeRecord', {
      productId: currentIndex,
      pageIndex: 1,
      pageRows: 8
    }).then(res => {
      //console.log(res.data && res.data.data);
      if (res.data && res.data.data) {
        this.setState({
          getMarketTradeRecord: res.data.data
        })
      }

    })
  }
  historicalPrice() {
    const { marketList } = this.state
    let tradeDateStr = []
    let tradePrice = []
    if (marketList) {
      //console.log(marketList);
      marketList[0].map(item => {
        //console.log(item);
        tradeDateStr.push(item.tradeDateStr)
        tradePrice.push(item.tradePrice)
      })
    }
    var chartDom = document.getElementById('historicalPrice');
    var myChart = echarts.init(chartDom);
    var option;

    option = {
      xAxis: {
        type: 'category',
        data: tradeDateStr
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        data: tradePrice,
        type: 'line'
      }]
    };

    option && myChart.setOption(option);
  }

  render() {
    const { getMarketTradeRecord, timeIndex,type,time } = this.state
    // //console.log(getMarketTradeRecord.data);
    return (
      <>
        <div className="pricedistributionarea">
          <div className="pricedistributionareaLeft">
            <div className="pricedistributionareaTop">
              <div className="left">
                <div className="red"></div>
                <div className="title">
                  历史价格趋势
                </div>
              </div>
              <div className="right">
                {
                  time.map((item, index) => {
                    return <div className={`item ${timeIndex === index ? 'active' : ''}`} onClick={() => this.tab(item.type, index)} key={item.id}>
                      {item.name}
                    </div>
                  })
                }
              </div>
            </div>
            <div className="view">
              <div className="map">
                <div id="historicalPrice" style={{ width: '100%', height: '100%' }}></div>
              </div>
              {/* <div className="crosswise">
              <div id="crosswise" style={{ width: 610, height: 540 }}></div>
            </div> */}
            </div>
          </div>
          <div className="pricedistributionareaRight">
            <div className="pricedistributionareaTop">
              <div className="left">
                <div className="red"></div>
                <div className="title">
                  全国农产品价格
                </div>
              </div>
            </div>
            <table className="listTable">
              <thead className="listThead">
                <tr className="listTr">
                  <th>时间</th>
                  <th>品种</th>
                  <th>批发价</th>
                  <th>市场名称</th>
                  <th>产地</th>
                </tr>
              </thead>
              <tbody className="listTbody">
                {
                  getMarketTradeRecord && getMarketTradeRecord.map(item => {
                    return <tr key={item.priceId} className="TbodyTr" >
                      <td>{moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                      <td>{item.productName}</td>
                      <td>{item.tradePrice}</td>
                      <td>{item.tradePlaceName}</td>
                      <td>{item.originPlace}</td>
                    </tr>
                  })
                }

              </tbody>
            </table>

          </div>


          {/* <div className="xinxi">
            <div className="xinxiitem">
              <div className="data">
                <span>{type==1?'7日':'30日'}均价</span>
              </div>
              <div className="price">
                <span>{marketList&&marketList.avgPrice}</span>元/斤
              </div>
            </div>
            <div className="xinxiitem">
              <div className="data">
                <span>{type==1?'7日':'30日'}最低</span>
              </div>
              <div className="price">
                <span>{marketList&&marketList.minPrice}</span>元/斤
              </div>
            </div>
            <div className="xinxiitem">
              <div className="data">
                <span>{type==1?'7日':'30日'}最高</span>
              </div>
              <div className="price">
                <span>{marketList&&marketList.maxPrice}</span>元/斤
              </div>
            </div>
          </div>
          <p className="content">数值描述法：直接用价格增长为描述对象，绘制价格—时间曲线。数值描述法可以直观地描述价格的变动趋势，但是由于各种商品的价格相差较大，且数据量很大，无法体现商品格变化的整体规律。</p> */}
        </div>
      </>
    )
  }
}
export default Pricedistributionarea