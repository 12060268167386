import React, { Component, PureComponent } from 'react';

import { Upload, Icon, Modal, Form } from 'antd';

const FormItem = Form.Item;

class PicturesWall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: '',
      imgList: [],
      questionImg: '',
    };
  }
  handleChange = ({ file, fileList }) => {
    // ////console.log(JSON.stringify(file)); // file 是当前正在上传的 单个 img
    // ////console.log(JSON.stringify(fileList)); // fileList 是已上传的全部 img 列表
    ////console.log(file);
    ////console.log(fileList);

    // 【重要】将 图片的base64替换为图片的url。 这一行一定不会能少。
    // 图片上传成功后，fileList数组中的 thumbUrl 中保存的是图片的base64字符串，这种情况，导致的问题是：图片上传成功后，点击图片缩略图，浏览器会会卡死。而下面这行代码，可以解决该bug。
    fileList.forEach(imgItem => {
      if (imgItem && imgItem.status == 'done' && imgItem.response && imgItem.response.imgUrl) {
        imgItem.thumbUrl = imgItem.response.imgUrl;
      }
    });
    // fileList.map(item => {
    //   ////console.log(item);
    //   if (item.status === 'done') {
    //     let imgList = []
    //     this.setState({
    //       questionImg: item.response.data
    //     })
    //   }
    // })
    if (file.status === 'done') {
      let filesList = []
      fileList.map(item=>{
        ////console.log(item.response.data);
        filesList.push(item.response.data)
       })
       ////console.log(filesList);
       this.setState({
        questionImg:filesList
       })
    }
    this.setState({
      imgList: fileList,
    });
  };
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };

  handleBeforeUpload = file => {
    //限制图片 格式、size、分辨率
    const isJPG = file.type === 'image/jpeg';
    const isJPEG = file.type === 'image/jpeg';
    const isGIF = file.type === 'image/gif';
    const isPNG = file.type === 'image/png';
    if (!(isJPG || isJPEG || isGIF || isPNG)) {
      Modal.error({
        title: '只能上传JPG 、JPEG 、GIF、 PNG格式的图片~',
      });
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      Modal.error({
        title: '超过2M限制，不允许上传~',
      });
      return;
    }
    return (isJPG || isJPEG || isGIF || isPNG) && isLt2M && this.checkImageWH(file);
  };

  //返回一个 promise：检测通过则返回resolve；失败则返回reject，并阻止图片上传
  checkImageWH(file) {
    let self = this;
    return new Promise(function (resolve, reject) {
      let filereader = new FileReader();
      filereader.onload = e => {
        let src = e.target.result;
        const image = new Image();
        image.onload = function () {
          // 获取图片的宽高，并存放到file对象中
          ////console.log('file width :' + this.width);
          ////console.log('file height :' + this.height);
          file.width = this.width;
          file.height = this.height;
          resolve();
        };
        image.onerror = reject;
        image.src = src;
      };
      filereader.readAsDataURL(file);
    });
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props;
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {// values 是form表单里的参数
      // 点击按钮后，将表单提交给后台
      dispatch({
        type: 'mymodel/submitFormData',
        payload: values,
      });
    });
  };

  render() {
    const { previewVisible, previewImage, imgList } = this.state; //  从 state 中拿数据
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text" style={{ fontSize: '50px' }} >+</div>
      </div>
    );
    return (
      <div className="clearfix">
        <Form onSubmit={this.handleSubmit} hideRequiredMark style={{ marginTop: 8 }}>
          <FormItem>
            <Upload
              action="http://1.116.154.35:25921/clife-boot-api-app/api/cms/common/upload" // 这个是图片上传的接口请求，实际开发中，要替换成你自己的业务接口

              listType="picture-card"
              fileList={this.state.imgList}
              onPreview={this.handlePreview} // 点击图片缩略图，进行预览
              beforeUpload={this.handleBeforeUpload} // 上传之前，对图片的格式做校验，并获取图片的宽高
              onChange={this.handleChange} // 每次上传图片时，都会触发这个方法
            >
              {this.state.imgList.length >= 6 ? null : uploadButton}

            </Upload>

          </FormItem>
        </Form>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}

export default PicturesWall;