import React, { Component } from 'react';
import './index.scss';
import cookie from 'react-cookies';
import * as echarts from 'echarts';
import request from '../../../../static/js/http';
import { color } from 'echarts';
import { message } from 'antd';

class Priceexpectation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tp: [
        { name: '投票', id: 0, type: 0 },
        { name: '投票', id: 1, type: 1 },
        { name: '投票', id: 2, type: 2 },
      ],
      marketList: [],
      currentIndex: ''
    }
  }
  componentDidMount() {
    // this.market()

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentIndex } = this.state
    if (currentIndex !== nextProps.currentIndex) {
      this.setState({
        currentIndex: nextProps.currentIndex,
      }, function () {
        ////console.log(this.state.currentIndex);
        this.market()
      })
    }
  }
  market() {
    const { currentIndex } = this.state
    ////console.log(currentIndex);
    ////console.log(memberId);
    request('post', '/api/market/hall/list', {
      memberId: cookie.load('lj-pc-userInfo') ? cookie.load('lj-pc-userInfo').memberId : '',
      type: 1,
      classId: currentIndex
    }).then(res => {
      console.log(res);
      this.setState({
        marketList: res.data
      }, function () {
        this.rise()
        this.steady()
        this.fall()

      })
    })
  }
  //发布预测
  put(type) {
    const { marketList, currentIndex } = this.state
    ////console.log(currentIndex);
    if (!cookie.load('lj-pc-userInfo')) {
      message.warning('请先登录')
    } else {
      if (marketList.memberPut === 0) {
        let memberId = cookie.load('lj-pc-userInfo').memberId
        request('post', '/api/market/predict/put', {
          memberId,
          classId: currentIndex,
          type
        }).then(res => {
          ////console.log(res);
          this.market()
          message.success('投票成功')
        })
      } else {
        message.error('请勿重复投票')
      }
    }
  }
  rise() {
    const { marketList } = this.state
    var chart = echarts.init(document.getElementById('rise'));
    var option = {
      color: ['#E64F38', '#FCEDEB'],
      title: {
        text: '看涨',
        right: 'center'
      },
      series: [
        {
          name: '看涨',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          hoverAnimation: false,   //关闭放大动画
          selectedOffset: 0,     //选中块的偏移量
          label: {
            show: false,
            position: 'center',
            formatter: '{d}%'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '20',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            {
              value: marketList.preList[0].rate,
              name: '直接访问',
              selected: true,     //默认选中第一块
              label: {
                show: true,     //默认显示第一块
                fontSize: '42',
                fontWeight: '400',
                color: '#E64F38'
              }
            },
            {
              value: 100 - marketList.preList[0].rate, name: '邮件营销',
              label: {
                show: false,
                position: 'center',
                formatter: '{d}%'
              },
            },
          ]
        }
      ]
    };

    chart.setOption(option)
  }
  steady() {
    const { marketList } = this.state
    var chart = echarts.init(document.getElementById('steady'));
    var option = {
      color: ['#0B6DF1', '#E6F0FE'],
      title: {
        text: '看稳',
        right: 'center'
      },
      series: [
        {
          name: '看涨',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          hoverAnimation: false,   //关闭放大动画
          selectedOffset: 0,     //选中块的偏移量
          label: {
            show: false,
            position: 'center',
            formatter: '{d}%'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '20',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            {
              value: marketList.preList[1].rate,
              name: '直接访问',
              selected: true,     //默认选中第一块
              label: {
                show: true,     //默认显示第一块
                fontSize: '42',
                fontWeight: '400',
                color: '#E64F38'
              }
            },
            {
              value: 100 - marketList.preList[1].rate, name: '邮件营销',
              label: {
                show: false,
                position: 'center',
                formatter: '{d}%'
              },
            },
          ]
        }
      ]
    };

    chart.setOption(option)
  }
  fall() {
    const { marketList } = this.state
    var chart = echarts.init(document.getElementById('fall'));
    var option = {
      color: ['#F1820B', '#FEF2E6'],
      title: {
        text: '看跌',
        right: 'center',

      },
      series: [
        {
          name: '看涨',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          hoverAnimation: false,   //关闭放大动画
          selectedOffset: 0,     //选中块的偏移量
          label: {
            show: false,
            position: 'center',
            formatter: '{d}%'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '20',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            {
              value: marketList.preList[2].rate,
              name: '直接访问',
              selected: true,     //默认选中第一块
              label: {
                show: true,     //默认显示第一块
                fontSize: '42',
                fontWeight: '400',
                color: '#E64F38'
              }
            },
            {
              value: 100 - marketList.preList[2].rate, name: '邮件营销',
              label: {
                show: false,
                position: 'center',
                formatter: '{d}%'
              },
            },
          ]
        }
      ]
    };

    chart.setOption(option)
  }
  render() {
    const { marketList } = this.state
    let marketData = ''
    if (marketList) {
      //console.log(marketList);
      marketData = marketList
    }
    return (
      <>
        <div className="priceexpectation">
          <div className="priceexpectationTop">
            <div className="left">
              <div className="red"></div>
              <div className="title">
                价格预测
            </div>
            </div>
          </div>
          <p className="content">截止{marketData.nowTime}已有{marketData.allNum}人参与投票</p>
          <div className="priceexpectationList">
            <div id="rise" style={{ width: '400px', height: '400px' }}></div>
            <div id="steady" style={{ width: '400px', height: '400px' }}></div>
            <div id="fall" style={{ width: '400px', height: '400px' }}></div>
          </div>
          <div className="voteList">
            {
              this.state.tp.map(item => {
                return <div className={`${marketData.predictType === item.id ? 'tp' : 'vote'}`} key={item.id} onClick={() => this.put(item.type)} >
                  {marketData.predictType === item.id ? '已投票' : item.name}
                </div>
              })
            }
          </div>
        </div>

      </>

    )
  }
}
export default Priceexpectation