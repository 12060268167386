import React, { Component } from 'react';
import './index.scss';
import { Breadcrumb, Button, message } from 'antd';
import { withRouter } from 'react-router-dom';
import vip from '../../../static/img/vip.png'
import pic1 from '../../../static/img/pic1.png'
import pic2 from '../../../static/img/pic2.png'
import pic3 from '../../../static/img/pic3.png'
class OpenVip extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  openVip(){
    message.warning('敬请期待')
  }
  render() {
    return (
      <>
        <div className="breadcrumbW">
          <div className="breadcrumb">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>当前位置</Breadcrumb.Item>
              <Breadcrumb.Item href="/home/futurestrading">个人中心</Breadcrumb.Item>
              <Breadcrumb.Item>开通会员</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="openVip">
          <div className="bannerTopW">
            <div className="bannerTop">
              <div className="headline">
                <div className="headlineVPic">
                  <img src={vip} alt="" />
                </div>
                <div className="titleH1">
                  开通会员·享受以下权益
                </div>
              </div>
              <div className="price">
                <div className="priceLeft"></div>
                <div className="priceMiddle">
                  98元/年   每天仅需0.27元
                </div>
                <div className="priceRight"></div>
              </div>
              <div className="openBtn">
                <Button type="primary" onClick={()=>this.openVip()}>
                  立即购买
                </Button>
              </div>
            </div>
          </div>
          <div className="bannerBottomW">
            <div className="bannerBottom">
              <div className="VIPRights">
                <div className="VIPRightTitle">
                  <div className="VIPRightTitleRight"></div>
                  <div className="VIPRightTitleMiddle">
                    会员权益
                  </div>
                  <div className="VIPRightTitleLeft"></div>
                </div>
              </div>
              <div className="VIPRightsContentList">
                <div className="VIPRightsContentItem">
                  <img src={pic1} alt="" />
                </div>
                <div className="VIPRightsContentItem">
                  <div className="VIPRightsContentText">
                    <div className="VIPRightsContentItemTop">
                      ·实时掌握
                    </div>
                    <div className="VIPRightsContentItemBottom">
                      撮合大厅交易数据及市场信息
                    </div>
                  </div>
                </div>
                <div className="VIPRightsContentItem">
                  <img src={pic2} alt="" />
                </div>
                <div className="VIPRightsContentItem">
                  <div className="VIPRightsContentText">
                    <div className="VIPRightsContentItemTop">
                      ·农资商城
                    </div>
                    <div className="VIPRightsContentItemBottom">
                      享受9.5折优惠
                    </div>
                  </div>
                </div>
                <div className="VIPRightsContentItem">
                  <img src={pic3} alt="" />
                </div>
                <div className="VIPRightsContentItem">
                  <div className="VIPRightsContentText">
                    <div className="VIPRightsContentItemTop">
                      ·享受金融服务
                    </div>
                    <div className="VIPRightsContentItemBottom">
                      借款提现不收取任何手续费
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </>
    )
  }
}
export default withRouter(OpenVip)