import React, { Component } from 'react';
import './index.scss';
import yizan from '../../../../static/img/yizan.png'
import weizan from '../../../../static/img/weizan.png'
import cookie from 'react-cookies'
import { Modal, Button, Image, Collapse ,message} from 'antd';
import { withRouter } from 'react-router-dom';
import request from '../../../../static/js/http';

const { Panel } = Collapse;
class Questiondetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionInfo: [],
      answerList: [],
      answerContent: '',
      isbackground: false,
      replyName: '',
      answerId: '',
      replyList: [],
      repetitionValue: '',//防止重复评论
      pageSize: 20,          //加载评论
    }
  }

  componentDidMount() {
    this.questionInfo()
    this.answerList()
    this.replyList()
  }
  //问题详情
  questionInfo() {
    let memberId = cookie.load('lj-pc-userInfo').memberId //会员Id
    let questionId = this.props.location.state.id //问题ID
    request('post', '/api/qa/question/info', {
      memberId,
      questionId,
    }).then(res => {
      ////console.log(res);
      this.setState({
        questionInfo: res.data
      })
    })
  }
  //回答列表
  answerList() {
    let memberId = cookie.load('lj-pc-userInfo').memberId //会员Id
    const { pageSize } = this.state
    let questionId = this.props.location.state.id //问题ID
    request('post', '/api/qa/answer/list', {
      memberId,
      questionId,
      pageNum: 1,
      pageSize,
    }).then(res => {
      ////console.log(res);
      this.setState({
        answerList: res.data
      })
    })
  }

  //回答列表加载
  loadanswerList() {
    const { pageSize } = this.state
    this.setState({
      pageSize: pageSize + 20
    }, function () {
      ////console.log(pageSize);
      this.answerList(pageSize)
    })
  }

    //回复列表
    replyList() {
      let memberId = cookie.load('lj-pc-userInfo').memberId //会员Id
      const { answerId } = this.state
      ////console.log(answerId);
      request('post', '/api/qa/reply/list', {
        memberId,
        answerId,
        pageNum: 1,
        pageSize:999999999
      }).then(res => {
        ////console.log(res);
        this.setState({
          replyList: res.data
        })
      })
    }
    // 回复列表加载
    // loadreplyList(answerId) {
    //   ////console.log(answerId);
    //   const { pageSizes } = this.state
    //   this.setState({
    //     pageSizes: pageSizes + 10
    //   }, function () {
    //     this.replyList(pageSizes,answerId)
    //     ////console.log(pageSizes);
    //   })
    // }
  //回答问题
  addSave() {
    // repetitionValue
    let memberId = cookie.load('lj-pc-userInfo').memberId //会员Id
    let questionId = this.props.location.state.id //问题ID
    const { answerContent, repetitionValue } = this.state
    if (repetitionValue !== answerContent) {
      ////console.log('不同');
      if (answerContent) {
        request('post', '/api/qa/answer/addSave', {
          memberId,
          questionId,
          answerContent,
        }).then(res => {
          ////console.log(res);
          this.setState({
            repetitionValue: answerContent
          }, function () {
            this.answerList()
            this.replyList()
            message.success('评论成功')
          })

        })
      }
    } else {
      message.info('请勿重复评论');
    }

  }
  //回复评论
  replyaddSave() {
    let memberId = cookie.load('lj-pc-userInfo').memberId //会员Id
    const { answerId, answerContent, repetitionValue } = this.state
    if (repetitionValue !== answerContent) {
      if (answerContent) {
        request('post', '/api/qa/reply/addSave', {
          answerId,
          memberId,
          toMemberId: '',
          replyContent: answerContent
        }).then(res => {
          ////console.log(res);
          this.replyList()
          this.answerList()
        })
      }
    }


  }
  pinglun(e) {
    this.setState({
      answerContent: e.target.value
    })
  }

  reply(item) {
    this.setState({
      replyName: item.memberName,
      answerId: item.answerId,
      answerContent:''
    })
  }
  // 
  unfold(answerId) {
    ////console.log(answerId);
    this.setState({
      answerId
    }, function () {
      this.replyList()
    })
  }

  dianzan(answerId) {
    let memberId = cookie.load('lj-pc-userInfo').memberId //会员Id
    request('post', '/api/qa/answer/collect', {
      memberId,
      answerId,
    }).then(res => {
      ////console.log(res);
      this.answerList()
    })
  }
  render() {
    const { questionInfo, answerList, answerContent, replyName, replyList } = this.state
    ////console.log(replyName);
    let reg = /(\[)|(\])/g
    let img = questionInfo.questionImg && questionInfo.questionImg.replace(reg, "").split(",")
    return (
      <>
        <div className="questiondetails">
          <div className="wenti">
            <div className="rcserveList">
              <div className="item">
                <div className="itemTop">
                  <div className="wen">
                    问
              </div>
                  <div className="title">
                    {questionInfo.questionContent}
                  </div>
                </div>
                <div className="pic">
                  {
                    img && img.map((item, index) => {
                      return <Image width={70} key={index} src={item} placeholder={true} alt="" />
                    })
                  }

                </div>
                <div className="zhong">
                  <div className="tou">
                    <img src={questionInfo.memberAvator} alt="" />
                  </div>
                  <div className="name">
                    {questionInfo.memberName}
                  </div>
                </div>
                <div className="itemBottom">
                  <div className="time">
                    （提问时间：{questionInfo.createAt}）
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="revert">
            <div className="revertIpt">
              <textarea className="revertText" value={answerContent} onChange={(e) => this.pinglun(e)} placeholder={replyName == '' ? '说点什么...' : '回复@' + replyName + "："}>
              </textarea>
              <div className={`plbtn ${answerContent != '' ? 'active' : ''}`} onClick={() => `${replyName == '' ? this.addSave() : this.replyaddSave()}`}>
                评论
              </div>
            </div>
            <div className="allComment">
              <div className="all">
                全部评论 <span>{answerList.total}</span>
              </div>
              <div className="list">
                {
                  answerList.list && answerList.list.map(item => {
                    return <div className="item" key={item.answerId}>
                      <div className="itemTop">
                        <div className="itemLeft">
                          <div className="tou">
                            <img src={item.memberAvator} alt="" />
                          </div>
                          <div className="info">
                            <div className="name">{item.memberName}</div>
                            <div className="time">{item.createAt}
                              <div className="zan" onClick={() => this.dianzan(item.answerId)}>
                                <img src={item.collect == 1 ? yizan : weizan} alt="" />
                                <span className="zannum">{item.likeNum}</span>
                              </div></div>
                          </div>

                        </div>
                        <div className="huifuright" onClick={() => this.reply(item)}>
                          回复
                        </div>
                      </div>
                      <div className="content">
                        {item.answerContent}
                      </div>
                      <div className="tworeplyList">
                        {
                          item.comNum != 0 &&
                          <Collapse accordion onClick={() => this.unfold(item.answerId)}>
                            <Panel header={`共有${item.comNum}条回复`} key="1">
                              {
                                replyList.list && replyList.list.map(res => {
                                  if (item.answerId === res.answerId) {
                                    return <div className="tworeplyItem" key={res.replyId}>
                                      <div className="tworeplyname">
                                        {res.memberName}
                                      </div>
                                      <div className="tworeplycontent">
                                        {res.replyContent}
                                      </div>
                                    </div>
                                  }
                                })
                              }
                              {/* <Panel header="This is panel header 3" key="2">
                      
                            </Panel> */}
                            </Panel>
                            {/* <div className="loadreplyList" onClick={() => this.loadreplyList(item.answerId)}>
                                加载更多
                            </div> */}
                          </Collapse>
                        }
                      </div>

                    </div>
                  })
                }
              </div>
              <div className="loadanswerList" onClick={() => this.loadanswerList()}>
                加载更多
                      </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(Questiondetails)