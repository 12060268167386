import React, { Component } from 'react';
import Header from '../../components/Header';
import Bottom from './Index1/Bottom';

import './index.scss';

export default class Home extends Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line no-console
    this.state = {
      // list: [1, 2, 3]
    };
  }

  render() {
    const { children } = this.props;
    // eslint-disable-next-line no-console
    return (
      <div id="home">
        <Header />
        {children}
        <Bottom />
      </div>
    );
  }
}
