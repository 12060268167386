import React, { Component } from 'react';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { Tabs } from 'antd';
import './index.scss';
import Shangpinshoucang from './shangpinshoucang/index'
import Shopshoucang from './shopshoucang/index'
import request from '../../../static/js/http';
import cookie from 'react-cookies';
const { TabPane } = Tabs;
class Collect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }
  componentDidMount() {

  }
  callback(key) {
    ////console.log(key);
  }
  render(){
    const Shoucang = () => (
      <Tabs defaultActiveKey="1" onChange={this.callback}>
        <TabPane tab="商品收藏" key="1">
          <Shangpinshoucang/>
        </TabPane>
        <TabPane tab="店铺收藏" key="2">
          <Shopshoucang/>
        </TabPane>
      </Tabs>
    );
    return(
      <>
        <div className="shoucang">
         <Shoucang/>
        </div>
      </>
    )
  }
}
export default withRouter(Collect)