import React, { Component } from 'react';
import './index.scss'
import shenhezhong from '../../../static/img/shenhe1.png'
import shenheshibai from '../../../static/img/shenhe2.png'
import shenhechenggong from '../../../static/img/shenhe3.png'
import cookie from 'react-cookies';
import { Steps } from 'antd';
import request from '../../../static/js/http';
const { Step } = Steps;
export default class Auditresult extends Component {
  constructor() {
    super();
    this.state = {
      authStatus: ''
    }
  }
  componentDidMount() {
    this.userInfo()
  }
  userInfo() {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/member/user/info', {
      memberId
    }).then(res => {
      this.setState({
        authStatus: res.data.shopAuth.authStatus
      })
    })
  }
  tiao() {
    let url = this.props.location.pathname
    this.props.history.push({ pathname: '/authentication',state:{url}})
  }
  render() {
 
    return (
      <>
        <div className="auditresult">
          <div className="auditresultTopW">
            <div className="auditresultTop">
              <div className="step">
                <Steps current={this.state.authStatus == 0 ? '1' : '2'} >
                  <Step title="提交资料" />
                  <Step title="审核中" />
                  <Step title="审核结果" />
                </Steps>
              </div>
            </div>
          </div>
          <div className="auditresultContent">
            <div className="status">
              <img src={this.state.authStatus == 0 ? shenhezhong : (this.state.authStatus == 1 ? shenhechenggong : shenheshibai)} alt="" />
              <p>{this.state.authStatus == 0 ? '提交资料成功，正在审核中，请耐心等候！！' : (this.state.authStatus == 1 ? '审核成功' : <span>审核失败，请重新<span className='up' onClick={() => this.tiao()}>上传资料</span></span>)}</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}
