import React, { Component } from 'react';
import { Input } from 'antd';
import mylogo from '../../static/img/login_logo.png';
import { Link } from 'react-router-dom';
import './index.scss';

const { Search } = Input;
export default class My1 extends Component {
  render() {
    const { children } = this.props;
    ////console.log(children)
    return (
      <div className="my">
        <div className="top">
          <div className="topnei">
            <Link to="/home/index">
              <img className="img" src={mylogo} alt="辣椒开放式物流线上电子交易中心" />
            </Link>
            <div>
              <Link to="/home/search">
                <Search
                  placeholder="搜索"
                  allowClear
                  size="large"
                  onSearch={this.onSearch}
                />
              </Link>
            </div>
          </div>
        </div>
        <div>
          {children}
        </div>
      </div>
    );
  }
}
