import React, { Component, useState } from 'react';
import cookie from 'react-cookies';
import request from '../../../static/js/http';
import { withRouter, Link } from "react-router-dom"
import './index.scss'

class Myzuji extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footprintList: [],
      num:0
    }
  }

  componentDidMount() {
    this.footprintList()
  }

  //足迹列表
  footprintList() {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/goods/footprint/list', {
      memberId,
    }).then(res => {
      //console.log(res);
      let num = 0
      this.setState({
        footprintList: res.data
      })
      res.data.map(item=>{
        num += item.contentList.length
        this.setState({
          num
        })
      })
    })
  }

  //足迹数量

  render() {
    const { footprintList } = this.state
    return (
      <>
        <div className="myfootprint">
          <div className="footprintTop">
            我的足迹（仅展示近7天浏览记录）
          </div>

          {
            footprintList && footprintList.map((res, index) => {
              return <div className="day" key={index}>
                <div className="footprintTime">
                  <div className="time">
                    {res.dateTime}
                  </div>
                  <div className="baby">
                    （{this.state.num}件宝贝）
              </div>
                </div>
                <div className="shopList">
                  {
                    res.contentList.map(item => {
                      return <Link target="_blank"  key={item.goodsId} to={'/home/details/' + item.goodsId}>
                        <div className="shopItem">
                          <div className="itemPic">
                            <img src={item.goodsImg} alt="" />
                          </div>
                          <div className="itemInfo">
                            <div className="price">
                              {item.productPrice}元/斤
                         </div>
                            <div className="content">
                              {item.goodsName}
                            </div>
                          </div>
                        </div>
                      </Link>

                    })
                  }

                </div>
              </div>
            })
          }

        </div>
      </>
    )
  }
}
export default withRouter(Myzuji)