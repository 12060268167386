import React, { Component, useState } from 'react';
import { Image } from 'antd';
import cookie from 'react-cookies';
import './index.scss'
export default class Mypingjiaxiangqing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: []
    }
  }
  componentDidMount() {
    let item = this.props.location.state.item
    ////console.log(item);
    this.setState({
      item
    })
  }

  render() {
    const { item } = this.state
    return (
      <>
        <div className="pingjiaxiangqing">
          <div className="pingluninfo">
            <div className="info-li">
              评论人：{item.memberName}
            </div>
            <div className="info-li">
              评价类型：{item.star < 3 ? '差评' : (item.star > 3 ? '好评' : '中评')}
            </div>
            <div className="info-li">
              订单编号：12456783
          </div>
            <div className="info-li">
              评价时间：{item.createAt}
            </div>
            <div className="info-li">
              商品名称：{item.goodsName}
            </div>
            <div className="info-li">
              评价内容：{item.content}
            </div>
            <div className="pic">
              {
                item.commentImg && item.commentImg.split(",").map((pic,index) => {
                  return <Image key={index} src={pic} alt="" />
                })
              }
            </div>

          </div>
        </div>
      </>
    )
  }
}