import React, { Component } from 'react';
import { Table } from 'antd';
import './index.scss';
import moment from 'moment';
import cookie from 'react-cookies'
import { FallOutlined, LineOutlined, RiseOutlined } from '@ant-design/icons';
import request from '../../../../static/js/http';

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [
        {
          key: '0',
          hallId: '1',
          city: '市场',
          className: '子弹头',
          createDay: '11-20',
          price: '50元/斤',
          updown: 0
        },
        {
          key: '1',
          city: '市场',
          className: '子弹头',
          createDay: '11-20',
          price: '50元/斤',
          updown: 1
        },
        {
          key: '2',
          city: '市场',
          className: '子弹头',
          createDay: '11-20',
          price: '50元/斤',
          updown: 2
        },
        {
          key: '3',
          city: '市场',
          className: '子弹头',
          createDay: '11-20',
          price: '50元/斤',
          updown: 2
        },
        {
          key: '4',
          city: '市场',
          className: '子弹头',
          createDay: '11-20',
          price: '50元/斤',
          updown: 2
        },
        {
          key: '5',
          city: '市场',
          className: '子弹头',
          createDay: '11-20',
          price: '50元/斤',
          updown: 2
        },
        {
          key: '6',
          city: '市场',
          className: '子弹头',
          createDay: '11-20',
          price: '50元/斤',
          updown: 2
        },
        {
          key: '7',
          city: '市场',
          className: '子弹头',
          createDay: '11-20',
          price: '50元/斤',
          updown: 2
        },
        {
          key: '8',
          city: '市场',
          className: '子弹头',
          createDay: '11-20',
          price: '50元/斤',
          updown: 2
        },
        {
          key: '9',
          city: '市场',
          className: '子弹头',
          createDay: '11-20',
          price: '50元/斤',
          updown: 2
        }
      ],
      columns: [
        {
          title: '地点',
          dataIndex: 'originPlace',
          key: 'originPlace',
          align: 'center'
        },
        {
          title: '产品/品种',
          dataIndex: 'productName',
          key: 'productName',
          align: 'center'
        },
        {
          title: '批发价',
          dataIndex: 'tradePrice',
          key: 'tradePrice',
          align: 'center'
        },
      ],
      varietyList: [],
      currentIndex: '',
      getList: []
    };
  }
  componentDidMount() {
    this.variety()

  }
  variety() {
    request('post', '/api/market/dock/productList').then(res => {
      this.setState({
        varietyList: res.data
      }, function (params) {
        if (this.state.currentIndex == '') {
          this.setState({
            // currentIndex: res.data[0].agriculturalProduct[0].productId
          }, function (params) {
            this.getList()
          })
        }
      })
    })
  }
  getList() {
    const { currentIndex } = this.state
    request('post', '/api/market/dock/getMarketTradeRecord', {
      productId: currentIndex,
      pageIndex: 1,
      pageRows: 10
    }).then(res => {
      console.log(res);
      this.setState({
        getList: res.data
      })
    })
  }
  tab = (classId) => {
    // console.log(classId);
    // this.child.market()
    ////console.log(this);
    this.setState({
      currentIndex: classId
    }, function (params) {
      this.getList()
    })
  }
  render() {
    const { currentIndex, columns, varietyList, getList } = this.state;
    return (
      <div className="ranking">
        <div className="one">
          <div className="title"></div>
        </div>
        <div className="varietyList">
          {
            varietyList && varietyList.map((item, index) => {
              return item.agriculturalProduct && item.agriculturalProduct.map(ite => {
                return <div className={`varietyItem ${currentIndex === ite.productId ? 'active' : ''}`} key={ite.productId} onClick={() => this.tab(ite.productId)}>
                  {ite.productName}
                </div>
              })
            })
          }
        </div>
        <div>
          {/* <Table
            dataSource={getList.data}
            columns={columns}
            pagination={false}
            rowKey={(record) => record.priceId}
            onRow={record => ({
              onClick: () => {
                // eslint-disable-next-line no-console
                console.log(record);
              } // 点击行
            })}
          /> */}
        </div>
      </div>
    );
  }
}
