import React, { Component } from 'react';
import './index.scss';
import city from '../../../../static/js/city/city'
import geren from '../../../../static/img/geren.png'
import qiye from '../../../../static/img/qiye1.png'
import Qiuzu from './Pushwanted/index'
import cookie from 'react-cookies';
import { withRouter } from 'react-router-dom';
import request from '../../../../static/js/http'
import { Menu, Select, Cascader, Button, message, Space, Table, Tag, Modal, Pagination } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
const { Column, ColumnGroup } = Table;
const { Option } = Select;
class Lengkuqiuzu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: city,
      isModalVisible: false,
      leaseList: [],
      fenye: '',
      address: '', //求租位置
      area: '',    //求租面积
    }
    this.child = React.createRef();
  }

  componentDidMount() {
    this.getList()
  }


  getList() {
    request('post', '/api/storage/lease/list', {
      pageSize: 10,
      pageNum: 1,
      statu: 0
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          leaseList: res.data.list,
          fenye: res.data
        })
      }
    })
  }

  //求租位置
  dizhi() {
    const { address } = this.state
    ////console.log(address);
    request('post', '/api/storage/lease/list', {
      pageSize: 10,
      pageNum: 1,
      address
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          leaseList: res.data.list,
          fenye: res.data
        })
      }
    })
  }
  //求租位置
  city(e) {
    ////console.log(this);
    this.setState({
      address: e.join("")
    }, function () {
      ////console.log(this.state.address);
      this.weizhi()
    })
  }
  //求租位置
  weizhi() {
    const { address } = this.state
    ////console.log(address);
    request('post', '/api/storage/lease/list', {
      pageSize: 10,
      pageNum: 1,
      address
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          leaseList: res.data.list,
          fenye: res.data
        })
      }
    })
  }
  //求租面积
  qiuzumianji() {
    const { area } = this.state
    ////console.log(area);
    request('post', '/api/storage/lease/list', {
      pageSize: 10,
      pageNum: 1,
      areanumer: area
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          leaseList: res.data.list,
          fenye: res.data
        })
      }
    })
  }
  //求租面积
  mianji = (e) => {
    ////console.log(e);
    this.setState({
      area: e
    }, function () {
      this.qiuzumianji()
    })
    ////console.log('click', e);
  }
  priceChange(e, name) {
    this.setState({
      [name]: e.target.value
    })
  }
  priceOk() {
    const { min, max, area, address } = this.state
    request('post', '/api/storage/lease/list', {
      pageSize: 10,
      pageNum: 1,
      min,
      max,
      areanumer: area,
      address
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          leaseList: res.data.list,
          fenye: res.data
        })
      }
    })
  }
  //发布
  fabu = () => {
    if (!cookie.load('lj-pc-userInfo')) {
      message.warning('请先登录')
    } else {
      if (this.child.current.state.title == '') {
        message.warning('请输入标题')
      } else if (this.child.current.state.price == "") {
        message.warning('请输入价格')
      } else if (this.child.current.state.qiuzuarea == "") {
        message.warning('请输入求租地址')
      } else if (this.child.current.state.address == "") {
        message.warning('请输入求租面积')
      } else if (this.child.current.state.imagesPath == "") {
        message.warning('请上传图片')
      } else if (this.child.current.state.conent == "") {
        message.warning('请输入内容')
      } else {
        let memberId = cookie.load('lj-pc-userInfo').memberId
        let title = this.child.current.state.title
        request('post', '/api/storage/lease/insertTbStorageLease', {
          memberId,
          title,
          price: this.child.current.state.price,
          area: this.child.current.state.qiuzuarea,
          address: this.child.current.state.address,
          imagesPath: this.child.current.state.imagesPath,
          content: this.child.current.state.conent,
          statu: "0"
        }).then(res => {
          this.getList()
          if (res.code == 0) {
            message.success(res.msg);
            this.setState({
              isModalVisible: false
            })
          } else {
            message.warning(res.msg)
          }
        })
      }
    }
  }

  //分页
  pagination = (a, b) => {
    ////console.log(a, b);
    request('post', '/api/storage/lease/list', {
      pageSize: b,
      pageNum: a,
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          leaseList: res.data.list,
          fenye: res.data
        })
      }
    })
  }
  handleButtonClick(e) {
    message.info('Click on left button.');
    ////console.log('click left button', e);
  }
  handleMenuClick(e) {
    ////console.log('click', e);
  }
  showModal = () => {
    this.setState({
      isModalVisible: true
    })
  };

  handleOk = () => {
    this.fabu()
 
  };

  handleCancel = () => {
    ////console.log(1);
    this.setState({
      isModalVisible: false
    })
  };
  xiangqing(id) {
    this.props.history.push({ pathname: '/home/qiuzuxiangqing', state: { id } })
  }
  render() {
    const { leaseList, fenye, area } = this.state
    // const mianji = (
    //   <Menu onClick={this.mianji}>
    //     <Menu.Item key="1">
    //       1~10平方米
    //     </Menu.Item>
    //     <Menu.Item key="2">
    //       11~20平方米
    //     </Menu.Item>
    //     <Menu.Item key="3">
    //       21~40平方米
    //     </Menu.Item>
    //     <Menu.Item key="4">
    //       41~60平方米
    //     </Menu.Item>
    //     <Menu.Item key="5">
    //       61~100平方米
    //     </Menu.Item>
    //     <Menu.Item key="6">
    //       100平方米以上
    //     </Menu.Item>
    //   </Menu>
    // );

    return (
      <>
        <div className="lengkuqiuzu">
          <div className="xuanzelanW">
            <div className="xuanzelan">
              <div className="xuanzeleft">
                <div className="pz">
                  <span>地址</span>
                  <Cascader options={this.state.options} onChange={(e) => this.city(e)} placeholder="请选择出租位置" />
                </div>
                <div className="lv">
                  <span>面积</span>
                  <Select style={{ width: 180 }} onChange={(e) => this.mianji(e)} allowClear>
                    <Option value="1">1~10平方米</Option>
                    <Option value="2">11~20平方米</Option>
                    <Option value="3">21~40平方米</Option>
                    <Option value="4">41~60平方米</Option>
                    <Option value="5">61~100平方米</Option>
                    <Option value="6">100平方米以上</Option>
                  </Select>
                </div>
                <div className="jiage">
                  <span>价格</span>
                  <input type="number" name="min" onChange={(e) => this.priceChange(e, 'min')} />—
                  <input type="number" name="max" onChange={(e) => this.priceChange(e, 'max')} />
                </div>
                <button onClick={() => this.priceOk()}>确定</button>
              </div>
              <div className="fabucaigou">
                <Button type="primary" onClick={this.showModal}>
                  冷库求租
              </Button>
                <Modal className="caigouModal" title="冷库求租" visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}
                  footer={[<Button key="submit" style={{
                    width: "120px",
                    height: '40px',
                    background: '#BD2C21',
                  }} onClick={this.handleOk} type="primary" >
                    确认发布
                </Button>]}>
                  <Qiuzu ref={this.child} />
                </Modal>
              </div>
            </div>
          </div>
          <div className="biaodan">
            <table className="listTable">
              <thead className="listThead">
                <tr className="listTr">
                  <th>价格</th>
                  <th>求租地址</th>
                  <th>求租面积</th>
                  <th>发布人</th>
                  <th>认证情况</th>
                  <th>发布时间</th>
                  <th>详情</th>
                </tr>
              </thead>
              <tbody className="listTbody">
                {
                  leaseList.map((item) => {
                    return <tr className="TbodyTr" onClick={() => this.xiangqing(item.leaseId)} key={item.leaseId}>
                      <td>{item.price}元/月</td>
                      <td>{item.address}</td>
                      <td>{item.area}m²</td>
                      <td>{item.createBy}</td>
                      <td>
                        <img src={
                          (() => {
                            switch (item.memeberlevel) {
                              case "0": return geren;
                              case "1": return qiye;
                              case "2": return geren;
                              default: return geren;
                            }
                          }
                          )()}
                          alt="" />
                      </td>
                      <td>{item.createAt}</td>
                      <td className="ckxq">查看详情</td>
                    </tr>
                  })
                }
              </tbody>
            </table>

          </div>
          <div className="fenyeqi">
            <Pagination style={{ marginBottom: '57px' }} pageSize='10' current={fenye.pageNum} onChange={this.pagination} defaultCurrent={1} total={fenye.total} />
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(Lengkuqiuzu)