import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PubSub from 'pubsub-js';
import PasswordLogin from './InLogin/PasswordLogin';
import MobileLogin from './InLogin/MobileLogin';

import InLogin from './InLogin';
import ForgetPassword from './ForgetPassword';

import loginlogo from '../../static/img/webljLogo.jpg';
import Background from '../../static/img/login_background.png';
import cookie from 'react-cookies'
import './index.scss';


const sectionStyle = {
  background: `url(${Background}) no-repeat center center`,
  backgroundSize: '100% 100%',
  width: '100%',
  minwidth: '1420px',
  height: '760px'
};
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sta: true
    };
  }

  bgChange(boolean) {
    this.setState({
      sta: boolean
    });
  }

  componentDidMount() {
    // cookie.remove('lj-pc-userInfo')
    this.token = PubSub.subscribe('atguigu', (_, stateObj) => {
      this.setState(stateObj);
    });
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.token);
  }

  render() {
    const { sta } = this.state;
    return (
      <div>
        <div className="logo">
          <img alt="辣椒开放式物流 线上电子交易中心" src={loginlogo} />
        </div>
        <div style={sectionStyle}>
          <div className="bgInterior">
            <div className="form">
              {sta ? (
                <InLogin>
                  <Route path="/login/mobile" component={MobileLogin} />
                  <Route path="/login/password" component={PasswordLogin} />
                  <Redirect to="/login/mobile" />
                </InLogin>
              ) : <ForgetPassword changeColor={boolean => { this.bgChange(boolean); }} />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Login;
