import React, { Component } from 'react';
import './index.scss';
import { Button, message } from 'antd';
import { withRouter } from "react-router-dom"
import dian1 from '../../../static/img/xianzhedian1.png';
import dian2 from '../../../static/img/xuanzhedian2.png';
import ren1 from '../../../static/img/xuanzheren1.png';
import ren2 from '../../../static/img/xuanzheren2.png';
import request from '../../../static/js/http';

 class Identity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      index2: 0
    };
  }
  componentDidMount(){
    this.userInfo()
  }
  sy = (a, b) => {
    if (b === 'index2') {
      this.setState({
        [b]: a
      });
    } else {
      this.setState({
        [b]: a,
        index2: 0
      });
    }
  }
  userInfo(){
    request('post','/api/member/user/info').then(res=>{
      if (res.code==0) {
        if (res.data.auth==0) {
          request('post','/api/member/user/changeInfo',{
            level:0
          }).then(res=>{
          })
        }
      }
    })
  }
  changeInfo(){
    const {index2} = this.state
    request('post','/api/member/user/changeInfo',{
      level:index2
    }).then(res=>{
      if (res.code==0) {
        message.success(res.msg)
        this.props.history.push({ pathname: '/home/index'})
      }else{
        message.warning(res.msg)
      }
    })
  }
  
  render() {
    const { index, index2 } = this.state;
    return (
      <div className="identity">
        <div className="nei">
          <div className="on1">
            <div>请选择身份</div>
            <div className={`cursor ${index === 0 ? 'sty' : ''}`} onClick={() => this.sy(0, 'index')}>
              <img src={index === 2 ? ren1 : ren2} alt="人" />
              <span>我是买家</span>
            </div>
            <div className={`cursor ${index === 1 ? 'sty' : ''}`} onClick={() => this.sy(1, 'index')}>
              <img src={index === 1 ? dian1 : dian2} alt="人" />
              <span>我是卖家</span>
            </div>
          </div>
          <div className="on2">
            <div>请选择您的身份</div>
            <div className={`cursor ${index2 === 0 ? 'sty' : ''}`} onClick={() => this.sy(0, 'index2')}>
              {index === 0 ? '个人' : '农户'}
            </div>
            <div className={`cursor ${index2 === 1 ? 'sty' : ''}`} onClick={() => this.sy(1, 'index2')}>
              {index === 0 ? '企业' : '商贩'}
            </div>
            <div className={`cursor ${index2 === 2 ? 'sty' : ''}`} onClick={() => this.sy(2, 'index2')}>
              {index === 0 ? '经纪人' : '经纪人'}
            </div>
          </div>
          <Button onClick={()=>this.changeInfo()} type="primary" size="default">
            确认提交
          </Button>
        </div>
      </div>
    );
  }
}
export default withRouter(Identity)