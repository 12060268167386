import React, { Component, useState } from 'react';
import close from '../../../static/img/close.png'
import tou from '../../../static/img/w1.png'
import Xiugaimima from './xiugaimima/index'
import cookie from 'react-cookies';
import { Button, Modal, Radio, message, Form, Upload, Icon } from 'antd';
import './index.scss'
import request from '../../../static/js/http';
const FormItem = Form.Item;
export default class Myindex extends Component {

  constructor(props) {
    super(props);
    this.state = {
      accountInfoList: [

      ],
      isModalVisible: false,
      xiugaimima: false,
      virusId: 0,
      phone: '',
      codeText: '',
      code: '',
      newPassword: '',
      okNewPassword: '',
      btnText: '发送验证码',
      btnBool: false,
      phoneText: '发送验证码',
      phoneBool: false,
      isphone: false,
      phoneval: '',
      phonecode: '',
      isname: false,
      nameval: '',
      previewVisible: false,
      previewImage: '',
      imgList: [],
      imagesPath: [],
      userInfo:[],
      isShowUpLoad:false
    };
  }
  componentDidMount() {
    this.phone()
    this.getUserInfo()
  }
  //个人信息
  getUserInfo(){
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post','/api/member/user/info',{
      memberId,
    }).then(res=>{
      ////console.log(res);
      this.setState({
        userInfo:res.data
      })
    })
  }
  isShowUpLoad(){
    this.setState({
      isShowUpLoad:true
    })
  }
  isShowUpLoadOk(){
    this.setState({
      isShowUpLoad:false
    },function () {
      // imagesPath
      const{imagesPath} = this.state
      ////console.log(imagesPath);
      let memberId = cookie.load('lj-pc-userInfo').memberId
      request('post','/api/member/user/changeInfo',{
        memberId,
        memberAvator:imagesPath[0]
      }).then(res=>{
        ////console.log(res);
        if (res.code==0) {
          message.success(res.msg)
        }
        this.getUserInfo()
      })
    })
  }
  isShowUpLoadNo(){
    this.setState({
      isShowUpLoad:false
    })
  }
  showModal = () => {
    this.setState({
      isModalVisible: true
    })
  };
  //设置身份标识
  setVirusId(){
    const{virusId} =this.state
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post','/api/member/user/changeInfo',{
      memberId,
      level:virusId
    }).then(res=>{
      ////console.log(res);
      if (res.code==0) {
        message.success(res.msg)
        this.getUserInfo()
      }else{
        message.warning(res.msg)
      }
    })
  }
  handleOk = () => {
    this.setState({
      isModalVisible: false
    },function () {
      this.setVirusId()
    })
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false
    })
  };
  showphone = () => {
    this.setState({
      isphone: true
    })
  };
  phoneOk = () => {
    this.querenphone()
    this.setState({
      isphone: false
    })
  };
  phoneCancel = () => {
    this.setState({
      isphone: false
    })
  };
  //确认修改手机号
  querenphone = () => {
    this.setState({
      isphone: false
    })
  }
  //修改手机号value
  phoneval = (e) => {
    this.setState({
      phoneval: e.target.value
    })
  }
  //修改手机号验证码
  phonecode = (e) => {
    this.setState({
      phonecode: e.target.value,
    })

  }
  xiugaimima = () => {
    this.setState({
      xiugaimima: true
    })
  }
  xiugaimimaOK = () => {
    this.queren()
    this.setState({
      xiugaimima: false
    })
  }
  xiugaimimaCancel = () => {
    this.setState({
      xiugaimima: false
    })
  }
  onChange = e => {
    this.setState({
      virusId: e.target.value
    });
  };
  phone() {
    let memberPhone = cookie.load('lj-pc-userInfo').memberPhone
    this.setState({
      phone: memberPhone.substring(memberPhone.length - 4)
    })
  }
  //验证码 
  codeText = (e) => {
    this.setState({
      codeText: e.target.value
    })
  }
  //新密码
  newPassword = (e) => {
    this.setState({
      newPassword: e.target.value
    })
  }
  //确认新密码
  okNewPassword = (e) => {
    this.setState({
      okNewPassword: e.target.value
    })
  }
  //发送验证码
  sendPhoneCode() {
    ////console.log(1);
    const { phoneval } = this.state
    // let memberPhone = cookie.load('lj-pc-userInfo').memberPhone
    const text = /^[1][3,4,5,7,8,9][0-9]{9}$/;
    if (!text.test(phoneval)) {
      message.error('手机号格式错误');
    } else {
      // request('post','/api/member/sms/send',{
      //   phone:memberPhone
      // }).then(res=>{
      //   ////console.log(res);
      //   this.setState({
      //     code:res.data
      //   })
      // })
      let maxTime = 60;
      this.timer = setInterval(() => {
        if (maxTime > 0) {
          --maxTime;
          this.setState({
            phoneText: `重新获取${maxTime}s`,
            phoneBool: true
          });
        } else {
          this.setState({
            phoneText: '发送验证码',
            phoneBool: false
          });
        }
      }, 1000);
    }
  }
  //发送验证码
  sendCode() {
    let memberPhone = cookie.load('lj-pc-userInfo').memberPhone
    const text = /^[1][3,4,5,7,8,9][0-9]{9}$/;
    if (!text.test(memberPhone)) {
      message.error('手机号格式错误');
    } else {
      request('post', '/api/member/sms/send', {
        phone: memberPhone
      }).then(res => {
        ////console.log(res);
        this.setState({
          code: res.data
        })
      })
      let maxTime = 60;
      this.timer = setInterval(() => {
        if (maxTime > 0) {
          --maxTime;
          this.setState({
            btnText: `重新获取${maxTime}s`,
            btnBool: true
          });
        } else {
          this.setState({
            btnText: '发送验证码',
            btnBool: false
          });
        }
      }, 1000);
    }
  }
  queren() {
    let memberPhone = cookie.load('lj-pc-userInfo').memberPhone
    const { newPassword, okNewPassword, code } = this.state;
    if (newPassword !== okNewPassword) {
      message.error('密码不一致');
    } else {
      request('post', '/api/member/code/resetPassword', {
        memberPhone,
        code,
        newPassword
      }).then(res => {
        ////console.log(res);
        if (res.code === 500) {
          message.error(res.msg);
        } else if (res.code === 0) {
          message.success(res.msg)
          this.setState({
            xiugaimima: false
          })
        }
      })
    }
  }
  showname = () => {
    this.setState({
      isname: true
    })
  };
  nameOk = () => {
    this.querenname()
    this.setState({
      isname: false
    })
  };
  nameCancel = () => {
    this.setState({
      isname: false
    })
  };
  //确认修改姓名
  querenname = () => {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    const {nameval} = this.state
    ////console.log(nameval);
    request('post', '/api/member/user/changeInfo', {
      memberId,
      memberName:nameval,
    }).then(res => {
      ////console.log(res);
      if (res.code==0) {
        message.success(res.msg)
        request('post', '/api/member/user/info', {
          memberId
        }).then(res => {
          if (res.code === 0) {
            let data = res.data
            let inFifteenMinute = new Date(new Date().getTime() + 168 * 3600 * 1000);//七天
            cookie.save('lj-webPc-data', data, { expires: inFifteenMinute, path: '/' })
            location.reload()
          }
        })
      }else{
        message.warning(res.msg)
      }
      this.getUserInfo()
    })
    this.setState({
      isname: false
    },function (params) {
      this.getUserInfo()
    })
    
  }
  //修改姓名value
  nameval = (e) => {
    this.setState({
      nameval: e.target.value
    })
  }

  file(e){
    ////console.log(e);
  }

  /////////////////////////////////////////////////////////////////////////////
  //上传图片
  uploadhandleChange = ({ file, fileList }) => {
    // ////console.log(JSON.stringify(file)); // file 是当前正在上传的 单个 img
    // ////console.log(JSON.stringify(fileList)); // fileList 是已上传的全部 img 列表
    ////console.log(file);
    ////console.log(fileList);

    // 【重要】将 图片的base64替换为图片的url。 这一行一定不会能少。
    // 图片上传成功后，fileList数组中的 thumbUrl 中保存的是图片的base64字符串，这种情况，导致的问题是：图片上传成功后，点击图片缩略图，浏览器会会卡死。而下面这行代码，可以解决该bug。
    fileList.forEach(imgItem => {
      if (imgItem && imgItem.status == 'done' && imgItem.response && imgItem.response.imgUrl) {
        imgItem.thumbUrl = imgItem.response.imgUrl;
      }
    });

    if (file.status === 'done') {
      let filesList = []
      fileList.map(item => {
        ////console.log(item.response.data);
        filesList.push(item.response.data)
      })
      ////console.log(filesList);
      setTimeout(() => {
        this.setState({
          imagesPath: filesList
        })
      }, 0);
    }
    setTimeout(() => {
      this.setState({
        imgList: fileList,
      });
    }, 0);
  };

  handlePreview = file => {
    setTimeout(() => {
      this.setState({
        previewImage: file.url || file.thumbUrl,
        previewVisible: true,
      });
    }, 0);
  };

  handleBeforeUpload = file => {
    //限制图片 格式、size、分辨率
    const isJPG = file.type === 'image/jpeg';
    const isJPEG = file.type === 'image/jpeg';
    const isGIF = file.type === 'image/gif';
    const isPNG = file.type === 'image/png';
    if (!(isJPG || isJPEG || isGIF || isPNG)) {
      Modal.error({
        title: '只能上传JPG 、JPEG 、GIF、 PNG格式的图片~',
      });
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      Modal.error({
        title: '超过2M限制，不允许上传~',
      });
      return;
    }
    return (isJPG || isJPEG || isGIF || isPNG) && isLt2M && this.checkImageWH(file);
  };

  //返回一个 promise：检测通过则返回resolve；失败则返回reject，并阻止图片上传
  checkImageWH(file) {
    let self = this;
    return new Promise(function (resolve, reject) {
      let filereader = new FileReader();
      filereader.onload = e => {
        let src = e.target.result;
        const image = new Image();
        image.onload = function () {
          // 获取图片的宽高，并存放到file对象中
          ////console.log('file width :' + this.width);
          ////console.log('file height :' + this.height);
          file.width = this.width;
          file.height = this.height;
          resolve();
        };
        image.onerror = reject;
        image.src = src;
      };
      filereader.readAsDataURL(file);
    });
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props;
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {// values 是form表单里的参数
      // 点击按钮后，将表单提交给后台
      dispatch({
        type: 'mymodel/submitFormData',
        payload: values,
      });
    });
  };

  render() {
    const { previewVisible, previewImage, imgList, isModalVisible, accountInfoList, btnBool, btnText, phone, phoneBool, phoneText,userInfo } = this.state;
    const { value } = this.state;
    const layout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    };
    const tailLayout = {
      wrapperCol: {
        offset: 8,
        span: 16,
      },
    };
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">上传</div>
      </div>
    );
    return (
      <>
        <div className="accountSet">
          <div className="accountSetItem">
            <div className="accountSetItem-name">
              <span>我的头像</span>
            </div>
            <div className="shu"></div>
            <div className="shuright">
              <img src={userInfo.memberAvator} alt="" />
            </div>
            <div className="accountSetItem-type" onClick={()=>this.isShowUpLoad()} >
              上传
             
            </div>
            {/* <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal> */}
          </div>
          <div className="accountSetItem">
            <div className="accountSetItem-name">
              <span>姓名</span>
            </div>
            <div className="shu"></div>
            <div className="shuright">
              <span>{userInfo.memberName}</span>
            </div>
            <div className="accountSetItem-type" onClick={() => this.showname()}>
              编辑
              </div>
          </div>
          <div className="accountSetItem">
            <div className="accountSetItem-name">
              <span>联系方式</span>
            </div>
            <div className="shu"></div>
            <div className="shuright">
              <span>{userInfo.memberPhone}</span>
            </div>
            {/* <div className="accountSetItem-type" onClick={() => this.showphone()}>
              修改手机号码
              </div> */}
          </div>
          <div className="accountSetItem">
            <div className="accountSetItem-name">
              <span>身份标识</span>
            </div>
            <div className="shu"></div>
            <div className="shuright">
              <span>{userInfo.level==0?'个人':(userInfo.level==1?'企业':(userInfo.level==2?'经纪人':'未认证'))}</span>
            </div>
            <div className="accountSetItem-type" onClick={this.showModal}>
              选身份
                </div>
          </div>
          <div className="accountSetItem">
            <div className="accountSetItem-name">
              <span>修改登录密码</span>
            </div>
            <div className="shu"></div>
            <div className="shuright">
              <span>***********</span>
            </div>
            <div className="accountSetItem-type" onClick={this.xiugaimima}>
              修改
            </div>

          </div>

        </div>
        <div className="statusId">
          <Modal className="statusIdModal" title="身份标识" visible={isModalVisible} onOk={() => this.handleOk()} onCancel={()=>this.handleCancel()} footer={[
            <Button type="primary" danger onClick={() => this.handleOk()}>
              保存
           </Button>
          ]}>

            {/* <div>
              选择身份：
              <Radio.Group onChange={this.onChange} value={value}>
                <Radio value={1}>我是买家</Radio>
                <Radio value={2}>我是卖家</Radio>
              </Radio.Group>
            </div> */}

            <div className="identityList">
              <div style={{ width: '72px' }}>
                选择身份：
              </div>
              <Radio.Group onChange={this.onChange} defaultValue="0" buttonStyle="solid">
                <Radio.Button value={0}>个人</Radio.Button>
                <Radio.Button value={1}>企业</Radio.Button>
                <Radio.Button value={2}>经纪人</Radio.Button>
              </Radio.Group>
            </div>
          </Modal>
        </div>
        <div className="statusId">
          <Modal className="gaimima" title="修改登录密码" visible={this.state.xiugaimima} onOk={this.xiugaimimaOK} onCancel={this.xiugaimimaCancel} footer={[
            <Button onClick={() => this.queren()} type="primary" danger>
              确认修改
            </Button>
          ]}>

            <div className='gaimimeg'>
              您的账号已绑定尾号为{phone}的手机号码，请输入验证码验证身份
            </div>

            <div className="xiugaimima">
              <div className="xgmm">
                <div className="yanzhengma">
                  <input type="number" onChange={(e) => this.codeText(e)} placeholder="输入验证码" /><button disabled={btnBool} onClick={() => this.sendCode()}>{btnText}</button>
                </div>
                <div className="shuruNewPassword">
                  输入新密码：<input onChange={(e) => this.newPassword(e)} type="text" name="1" />
                </div>
                <div className="querenNewPassword">
                  确认新密码：<input onChange={(e) => this.okNewPassword(e)} type="text" />
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <Modal className="gaiphone" title="修改手机号码" visible={this.state.isphone} onOk={this.phoneOk} onCancel={this.phoneCancel} footer={[
          <Button onClick={() => this.querenphone()} type="primary" danger>
            确认修改
            </Button>
        ]}>

          <div className="xiugaimima">
            <div className="xgmm">
              <div className="shuruNewPassword">
                请输入手机号：<input type="number" onChange={(e) => this.phoneval(e)} type="text" name="1" />
              </div>
              <div className="yanzhengma">
                请输入验证码：<input type="number"  style={{ float: 'none' }} onChange={(e) => this.phonecode(e)} placeholder="输入验证码" /><button disabled={phoneBool} onClick={() => this.sendPhoneCode()}>{phoneText}</button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal className="gainame" title="修改姓名" visible={this.state.isname} onOk={this.nameOk} onCancel={this.nameCancel} footer={[
          <Button onClick={() => this.querenname()} type="primary" danger>
            确认修改
            </Button>
        ]}>

          <div className="xiugaimima">
            <div className="xgmm">
              <div className="shuruNewPassword">
                请输入姓名：<input onChange={(e) => this.nameval(e)} type="text" name="1" />
              </div>
            </div>
          </div>
        </Modal>
        <Modal title="上传头像" visible={this.state.isShowUpLoad} onOk={()=>this.isShowUpLoadOk()} onCancel={()=>this.isShowUpLoadNo()}>
       <Form onSubmit={this.handleSubmit} hideRequiredMark>
                <FormItem className="upload">
                  <Upload
                    action="http://1.116.154.35:25921/clife-boot-api-app/api/cms/common/upload" // 这个是图片上传的接口请求，实际开发中，要替换成你自己的业务接口
                    className="upload"
                    listType="picture-card"
                    fileList={this.state.imgList}
                    onPreview={this.handlePreview} // 点击图片缩略图，进行预览
                    beforeUpload={this.handleBeforeUpload} // 上传之前，对图片的格式做校验，并获取图片的宽高
                    onChange={this.uploadhandleChange} // 每次上传图片时，都会触发这个方法
                  >
                    {this.state.imgList.length >= 1 ? null : uploadButton}

                  </Upload>
                </FormItem>
              </Form>
      </Modal>

        {/* <div className="Popout">
          <div className="popTitle">
            <span>身份标识</span>
            <div className="close">
              <img src={close} alt="" />
            </div>
          </div>
          <div className="popBody">
            <div className="deal">
              选择身份:
            <label > <input type="radio" name='gender' value="Man"
                onChange={this.handleChange} />我是买家</label>
              <label > <input type="radio" name='gender' value="Women"
                onChange={this.handleChange} />我是卖家</label>
            </div>
            <div className="identityList">
              选择身份:
                <div className="identityItem">个人</div>
                <div className="identityItem">大企业</div>
                <div className="identityItem">批发市场</div>
            </div>
          </div>
        </div> */}
      </>
    )
  }
}