import React, { Component } from 'react';
import { withRouter,Link } from "react-router-dom"
import cookie from 'react-cookies'
import { Form, Input, Button, Checkbox, Cascader, message, Popconfirm, Modal } from 'antd';
import {
  ArrowLeftOutlined
} from '@ant-design/icons';
import './index.scss';
import city from '../../../../static/js/city/city'
import request from '../../../../static/js/http';
class Biaodan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addressList: [],       //收获列表
      addressId: '',         //收货ID
      isxiugai: false,          //修改
      options: city,
      shouhuodi: '',
      fullName: '',
      phone: '',
      address: '',       //详细地址
      province: '',      //省
      city: '',          //市
      area: '',          //区
      default1: 1,        //1-默认，0-非默认
      fullNameValue: '',
      pathname: '',
      isShow:false
    }
  }

  componentDidMount() {
    this.addressList()
    this.todetails()
  }


  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.fullName !== nextState.fullName) {
      return false
    }
    if (this.state.phone !== nextState.phone) {
      return false
    }
    if (this.state.address !== nextState.address) {
      return false
    }
    if (this.state.province !== nextState.province) {
      return false
    }
    if (this.state.city !== nextState.city) {
      return false
    }
    if (this.state.area !== nextState.area) {
      return false
    }
    if (this.state.default1 !== nextState.default1) {
      return false
    }
    return true;
  }



  //添加收货地址
  address = () => {
    const { fullName, phone, address, province, city, area, default1 } = this.state
    let memberId = cookie.load('lj-pc-userInfo').memberId
    ////console.log(fullName, phone, address, province, city, area,);

    if (!fullName) {
      message.error('请填写收货人')
    } else if (!phone) {
      message.error('请填写电话')
    } else if (!address) {
      message.error('请填写详细地址')
    } else if (!province) {
      message.error('请填写所在地区')
    } else if (!city) {
      message.error('请填写所在地区')
    } else {

      request('post', '/api/member/address/add', {
        memberId,
        fullName,
        phone,
        address,
        province,
        city,
        area,
        default: default1
      }).then(res => {
        ////console.log(res);
        this.addressList()
        if (res.code === 0) {
          setTimeout(() => {
            message.success('保存成功');
            this.setState({
              fullName: '',
              phone: '',
              address: '',
              province: '',
              city: '',
              area: '',
            }, function () {
              this.setState({
                fullName: '',
                phone: '',
                address: '',
                province: '',
                city: '',
                area: '',
              })
            })
          }, 0);
        } else {
          message.error('保存失败');
        }
      })
    }
  }

  //修改收货地址信息
  xiugai() {
    const { fullName, phone, address, province, city, area, default1, addressId } = this.state
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/member/address/change', {
      memberId,
      fullName,
      phone,
      address,
      province,
      city,
      area,
      default: default1,
      addressId
    }).then(res => {
      ////console.log(res);
      this.addressList()
      this.setState({
        isxiugai: false
      }, function () {
        this.setState({
          fullName: '',
          address: '',
          province: '',
          city: '',
          area: '',
          default: ''
        })
      })
    })
  }


  //收货人
  fullName = (e) => {
    let fullName = e.target.value
    this.setState({
      fullName
    })
  }

  // 电话
  phone(e) {
    this.setState({
      phone: e.target.value
    })
    ////console.log(e.target.value);
  }

  //所在地区
  city(e) {
    if (!e[2]) {
      this.setState({
        province: e[0],
        city: e[1],
        area: ''
      })
    } else {
      this.setState({
        province: e[0],
        city: e[1],
        area: e[2]
      })
    }

  }

  //详细地址
  xiangxi(e) {
    this.setState({
      address: e.target.value
    })
    ////console.log(e.target.value);
  }
  //默认地址
  default(e) {
    if (e.target.checked) {
      ////console.log(1);
      this.setState({
        default1: 1
      })
    } else {
      ////console.log(0);
      this.setState({
        default1: 0
      })
    }
  }
  //收货地址列表
  addressList = () => {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/member/address/list', {
      memberId,
    }).then(res => {
      ////console.log(res);
      this.setState({
        addressList: res.data
      })
    })
  }
  //谁为默认地址
  regdefault(addressId) {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/member/address/default', {
      memberId,
      addressId
    }).then(res => {
      ////console.log(res);
      this.addressList()
    })
  }
  //删除收货地址
  del(addressId) {
    ////console.log(addressId);
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/member/address/remove', {
      memberId,
      addressId
    }).then(res => {
      ////console.log(res);
      this.addressList()
    })

  }

  //详情页跳转过来的
  todetails() {
    if (this.props.location.state) {
      this.setState({
        pathname: this.props.location.state.pathname
      }, function () {
        if (this.props.location.state.pathname.indexOf('/home/details/') == 0) {
          // return <Button><ArrowLeftOutlined />返回上一级</Button>
          this.setState({
            isShow:true
          })
        }
        ////console.log(this.props.location.state.pathname.indexOf('/home/details/'));
      })
    }
  }
  showxiugai = (addressId) => {
    this.setState({
      isxiugai: true,
      addressId
    })
  };

  xiugaiOk = () => {
    this.setState({
      isxiugai: false
    })
  };

  xiugaiCancel = () => {
    this.setState({
      isxiugai: false
    })
  };
  delId(addressId) {
    // this.del(addressId)
    ////console.log(addressId);
    this.setState({
      addressId
    })
  }
  render() {
    const { addressList, addressId } = this.state
    const layout = {
      labelCol: {
        span: 2,
      },
      wrapperCol: {
        span: 9,
      },
    };
    const tailLayout = {
      wrapperCol: {
        offset: 2,
        span: 9,
      },
    };
    const layoutB = {
      labelCol: {
        span: 3,
      },
      wrapperCol: {
        span: 14,
      },
    };
    const tailLayoutB = {
      wrapperCol: {
        offset: 2,
        span: 10,
      },
    };


    const onFinish = (values) => {
      ////console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
      ////console.log('Failed:', errorInfo);
    };

    let that = this
    ////console.log(addressId);
    const text = '是否确认删除';
    function confirm(e) {
      that.del(addressId)
      message.success('删除成功');
    }
    function cancel(e) {

    }
    const Moren = () => (
      <div className="moren">
        默认地址
      </div>
    )

    const Demo = () => {
      const onFinish = (values) => {
        ////console.log('Success:', values);
      };

      const onFinishFailed = (errorInfo) => {
        ////console.log('Failed:', errorInfo);
      };


      return (
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="收货人："
            name="username"

            onChange={(e) => this.fullName(e)}
            rules={[
              {
                message: '请输入姓名!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="电话："
            name="phone"
            onChange={(e) => this.phone(e)}
            rules={[
              {
                message: '请输入手机号!',
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <div className="citydiv">
            <span>所在地区：</span>
            <Cascader className="city" options={this.state.options} onChange={(e) => this.city(e)} placeholder="请选择" />
          </div>
          <div className="xiangxi">
            <span>详细地址：</span>
            <textarea
              onChange={(e) => this.xiangxi(e)}
              name="" id="" cols="66" rows="4" placeholder="请填写街道、门牌号"></textarea>
          </div>
          <Form.Item {...tailLayout} name="remember" valuePropName="checked">
            <Checkbox onChange={(e) => this.default(e)}>设为默认地址</Checkbox>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button onClick={() => this.address()} type="primary">
              保存
            </Button>
            {this.state.isShow?<Link to={this.state.pathname}><Button><ArrowLeftOutlined />返回上一级</Button></Link>:''}
          </Form.Item>
        </Form>
      );
    };
    return (
      <>
        <div className="biaodan">
          <Demo />
        </div>
        <div className="biaoge">
          <div className="tishi">
            <span>!</span><span>已保存了{addressList.length}条收货地址</span>
          </div>
          <div className="shouhuobiaoge">
            <table className="listTable">
              <thead className="listThead">
                <tr className="listTr">
                  <th style={{ width: '144px' }}>收货人</th>
                  <th style={{ width: '174px' }}>所在地区</th>
                  <th style={{ width: '342px' }}>详细地址</th>
                  <th style={{ width: '175px' }}>电话</th>
                  <th style={{ width: '174px' }}>操作</th>
                  <th style={{ width: '179px' }}></th>
                </tr>
              </thead>
              <tbody className="listTbody">

                {
                  addressList.map(item => {
                    return <tr className="TbodyTr" key={item.addressId}>
                      <td>{item.fullName}</td>
                      <td>{item.province}{item.city}{item.area}</td>
                      <td>{item.address}</td>
                      <td>{item.phone}</td>
                      <td>
                        <Button type="primary" onClick={() => this.showxiugai(item.addressId)}>
                          修改
                        </Button>
                        <span style={{ margin: '0 10px' }}>|</span>

                        <Popconfirm
                          placement="topRight"
                          title={text}
                          onConfirm={confirm}
                          onCancel={cancel}
                          okText="确认"
                          cancelText="取消"
                        >
                          {/* <a href="#">Delete</a> */}
                          <Button onClick={() => this.delId(item.addressId)} style={{ border: 'none' }}>删除</Button>
                        </Popconfirm></td>
                      <td onClick={() => this.regdefault(item.addressId)}>{item.defaultValue === 1 ? <Moren /> : '设为默认'}</td>
                    </tr>
                  })
                }

              </tbody>
            </table>
          </div>
        </div>
        <Modal className="xiugaiModal" footer={[]} title="修改地址" visible={this.state.isxiugai} onOk={this.xiugaiOk} onCancel={this.xiugaiCancel} >
          <div className="biaodan">
            <Form
              {...layoutB}
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="收货人："
                name="username"
                onChange={(e) => this.fullName(e)}
                rules={[
                  {
                    message: '请输入姓名!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="电话："
                name="phone"
                onChange={(e) => this.phone(e)}
                rules={[
                  {
                    message: '请输入手机号!',
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
              <div className="citydiv">
                <span>所在地区：</span>
                <Cascader className="city" options={this.state.options} onChange={(e) => this.city(e)} placeholder="请选择" />
              </div>
              <div className="xiangxi">
                <span>详细地址：</span>
                <textarea
                  onChange={(e) => this.xiangxi(e)}
                  name="" id="" cols="66" rows="4" placeholder="请填写街道、门牌号"></textarea>
              </div>
              <Form.Item {...tailLayoutB} name="remember" valuePropName="checked">
                <Checkbox onChange={(e) => this.regdefault(e)}>设为默认地址</Checkbox>
              </Form.Item>


              <Form.Item {...tailLayoutB}>
                <Button onClick={() => this.xiugai()} type="primary">
                  确认修改
            </Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </>
    )
  }
}
export default withRouter(Biaodan)