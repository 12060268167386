import React, { Component } from 'react';
import './index.scss';
import { Modal, Button, Form, Input, message } from 'antd';
import { withRouter } from "react-router-dom"
import xiazaiApp from '../../../../static/img/erwei.jpg'
import request from '../../../../static/js/http';
class Withdraw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: 1,
      withdrawAccount: '',    //支付宝账户
      withdrawPrice: '',    //提现金额
      withdrawPhone: '',    //手机号
      withdrawPhoneCode: '',    //短信验证码
      withdrawPassWord: '',    //登录密码
      isShowIntie: false,
      intieAccount: '',    //支付宝账户
      intieName: '',    //支付宝实名
      intiePhone: '',    //手机号
      intiePhoneCode: '',    //短信验证码
      intiePassWord: '',    //支付密码
      userInfo: []
    }
  }

  componentDidMount() {
    this.userInfo()
  }
  userInfo() {
    request('post', '/api/member/user/info').then(res => {
      //console.log(res);
      this.setState({
        userInfo: res.data,
        shopAuth: res.data.shopAuth
      }, function () {

        ////console.log(this.state.userInfo);
        ////console.log(this.state.shopAuth);
      })
    })
  }
  aliAccount() {
    const { intieName, intieAccount, intiePhoneCode, intiePhone } = this.state
    if (intieName == '') {
      message.warning('请填写支付宝账户')
    } else if (intieAccount == "") {
      message.warning('请填写支付宝实名')
    } else if (intiePhoneCode == "") {
      message.warning('请填写手机号')
    } else if (intiePhone == "") {
      message.warning('请填写短信验证码')
    } else {
      request('post', '/api/member/user/changeInfo', {
        aliName: intieName,
        aliAccount: intieAccount,
        code: intiePhoneCode,
        phone: intiePhone
      }).then(res => {
        if (res.code == 0) {
          message.success(res.msg)
        } else {
          message.warning(res.code)
        }
      })
    }
  }
  moneyWithdraw() {
    const { withdrawPrice, withdrawPhone, withdrawPhoneCode } = this.state
    if (withdrawPrice == '') {
      message.warning('提现金额')
    } else if (withdrawPhone == '') {
      message.warning('请填写手机号')
    } else {
      request('post', '/api/member/money/withdraw', {
        money: withdrawPrice,
        type: 0
      }).then(res => {
        //console.log(res);
        if (res.code == 0) {
          message.success(res.msg)
        } else {
          message.warning(res.msg)
        }
      })
    }
    // else if (withdrawPhoneCode == '') {
    //   message.warning('请填写短信验证码')
    // }
  }
  //获取input的值
  getInputV(e, name) {
    //console.log(e.target.value + "++" + name);
    this.setState({
      [name]: e.target.value
    })
  }
  isShow(e) {
    this.props.content(e)
  }
  //绑定
  showIntie = () => {
    this.setState({
      isShowIntie: true
    })
  };

  IntieOk = () => {
    this.setState({
      isShowIntie: false
    })
  };

  IntieCancel = () => {
    this.setState({
      isShowIntie: false
    })
  };
  render() {
    const { userInfo } = this.state
    const layout = {
      labelCol: {
        // span: 3,
      },
      wrapperCol: {
        // span: 12,
      },
    };
    const layoutB = {
      labelCol: {
        span: 3,
      },
      wrapperCol: {
        // span: 12,
      },
    };
    return (
      <>
        <div className="withdraw">
          {
            userInfo.aliAccount != '' ? <>
              <div className="withdrawTop">
                <div className="withdrawTopUp">
                  <div className="usablePrice">
                    您的可用金额：<span className="price"> {userInfo.money}</span> 元
                  </div>
                  <div className="lookFinancialdetails" onClick={() => this.isShow(1)}>
                    查看财务明细
                  </div>
                </div>
                <div className="withdrawTopUserName">
                  用户名：{userInfo.aliName}
                </div>
              </div>

              <div className="withdrawBottom">
                <Form
                  {...layout}
                  name="basic"
                  initialValues={{
                    remember: true,
                  }}
                // onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    label="提现至："
                    name="withdrawAccount"
                    onChange={(e) => this.getInputV(e, 'withdrawAccount')}
                    rules={[
                      {
                        message: '请输入正确的支付宝账户!',
                      },
                    ]}
                  >
                    <Input value={userInfo.aliAccount} />
                    <span className="tips">{userInfo.aliAccount != '' ? '' : '未绑定支付宝'}</span>
                    {/* <span className="getPhoneCode" onClick={() => this.isShow(4)}>点击换绑</span> */}
                  </Form.Item>


                  <Form.Item
                    label="提现金额："
                    name="withdrawPrice"
                    onChange={(e) => this.getInputV(e, 'withdrawPrice')}
                    rules={[
                      {
                        message: '请输入正确的支付宝实名!',
                      },
                    ]}
                  >
                    <Input value={this.state.withdrawPrice} />
                    <span className="tips">满1元可提现</span>
                  </Form.Item>
                  <Form.Item
                    label="手机："
                    name="withdrawPhone"
                    onChange={(e) => this.getInputV(e, 'withdrawPhone')}
                    rules={[
                      {
                        message: '请输入手机号!',
                      },
                    ]}
                  >
                    <Input value={this.state.withdrawPhone} type="number" />
                  </Form.Item>
                  {/* <Form.Item
                    label="短信验证码:"
                    name="withdrawPhoneCode"
                    onChange={(e) => this.getInputV(e, 'withdrawPhoneCode')}
                    rules={[
                      {
                        message: '请输入短信验证码!',
                      },
                    ]}
                  >
                    <div className="phoneCode">
                      <Input value={this.state.withdrawPhoneCode} type="number" />
                      <Button danger>获取验证码</Button>
                    </div>
                  </Form.Item> */}
                  {/* <Form.Item
                label="登录密码："
                name="withdrawPassWord"
                onChange={(e) => this.getInputV(e, 'withdrawPassWord')}
                rules={[
                  {
                    message: '请输入支付密码!',
                  },
                ]}
              >
                <Input value={this.state.withdrawPassWord} type="number" />
                <Button type="primary" className="detectionBtn">
                  检测
                </Button>
                <span className="forgetPassword" >忘记密码？</span>
              </Form.Item> */}

                  <Form.Item >
                    <div className="withdrawBtn">
                      <Button type="primary" onClick={() => this.moneyWithdraw()}>
                        确认提交
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </> :
              <div className="withdrawBottom">
                <div className="xiazaiApp">
                  <p>前往APP绑定收款账号</p>
                  <img src={xiazaiApp} alt="" />
                </div>
              </div>

          }
        </div>
        <Modal footer={[]} title="绑定支付宝" className="intieModal" visible={this.state.isShowIntie} onOk={this.IntieOk} onCancel={this.IntieCancel}>
          <Form
            {...layoutB}
            name="basic"
            initialValues={{
              remember: true,
            }}
          >
            <Form.Item
              label="支付宝账户:"
              name="intieAccount"
              onChange={(e) => this.getInputV(e, 'intieAccount')}
              rules={[
                {
                  message: '请输入正确的支付宝账户!',
                }, {
                  pattern: /^1\d{10}$/,
                  message: '请输入正确的支付宝账户!'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="支付宝实名:"
              name="intieName"
              onChange={(e) => this.getInputV(e, 'intieName')}
              rules={[
                {
                  message: '请输入正确的支付宝实名!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="手机："
              name="intiePhone"
              onChange={(e) => this.getInputV(e, 'intiePhone')}
              rules={[
                {
                  message: '请输入手机号!',
                },
              ]}
            >
              <Input type="number" />
              <span className="tips">为了您的账户安全，每个账号仅限绑定一个支付宝</span>
            </Form.Item>
            <Form.Item
              label="短信验证码:"
              name="intiePhoneCode"
              onChange={(e) => this.getInputV(e, 'intiePhoneCode')}
              rules={[
                {
                  message: '请输入短信验证码!',
                },
              ]}
            >
              <div className="phoneCode">
                <Input type="number" />
                <Button danger>获取验证码</Button>
              </div>
            </Form.Item>
            {/* <Form.Item
              label="支付密码:"
              name="intiePassWord"
              onChange={(e) => this.getInputV(e, 'intiePassWord')}
              rules={[
                {
                  message: '请输入支付密码!',
                },
              ]}
            >
              <Input type="number" />
              <span className="forgetPassword">忘记密码？</span>
            </Form.Item> */}
            <Form.Item >
              <div className="intieBtn">
                <Button type="primary" onClick={() => this.aliAccount()}>
                  立即绑定
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  }
}
export default withRouter(Withdraw)