import React, { Component } from 'react';
import { BackTop } from 'antd';
import cart from '../../static/img/cart.png';
import top from '../../static/img/top.png';
import shouchang from '../../static/img/shouchang.png';
import { withRouter } from 'react-router-dom';
 class index extends Component {
  gouwu() {
    this.props.history.push('/myProfile/Shoppingcat')
  }
  shouchang(){
    this.props.history.push('/myProfile/Collect')
  }
  render() {
    return (
      <div>
        <BackTop>
          <div className="fiexd" style={{padding:'0'}}>
              <div onClick={()=>{this.shouchang()}}>
                <img src={shouchang} alt="收藏" />
                <div>收藏</div>
              </div>
              <div onClick={()=>{this.gouwu()}}>
                <img src={cart} alt="购物车" />
                <div>购物车</div>
              </div>
            <div>
              <img src={top} alt="购物车" />
              <div>TOP</div>
            </div>
          </div>
        </BackTop>
      </div>
    );
  }
}
export default withRouter(index)
