
import React, { Component, PureComponent } from 'react';

import { Upload, Icon, Modal, Form } from 'antd';

const FormItem = Form.Item;

class PicturesWall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: '',
      imgList: [],
      imagesPath: '',
    };
  }
  handleChange = ({ file, fileList }) => {
    // ////console.log(JSON.stringify(file)); // file 是当前正在上传的 单个 img
    // ////console.log(JSON.stringify(fileList)); // fileList 是已上传的全部 img 列表
    ////console.log(file);
    ////console.log(fileList);

    // 【重要】将 图片的base64替换为图片的url。 这一行一定不会能少。
    // 图片上传成功后，fileList数组中的 thumbUrl 中保存的是图片的base64字符串，这种情况，导致的问题是：图片上传成功后，点击图片缩略图，浏览器会会卡死。而下面这行代码，可以解决该bug。
    fileList.forEach(imgItem => {
      if (imgItem && imgItem.status == 'done' && imgItem.response && imgItem.response.imgUrl) {
        imgItem.thumbUrl = imgItem.response.imgUrl;
      }
    });

    if (file.status === 'done') {
      let filesList = []
      fileList.map(item => {
        ////console.log(item.response.data);
        filesList.push(item.response.data)
      })
      ////console.log(filesList);
      this.setState({
        questionImg: filesList
      })
    }
    this.setState({
      imgList: fileList,
    });
  };
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };

  handleBeforeUpload = file => {
    //限制图片 格式、size、分辨率
    const isJPG = file.type === 'image/jpeg';
    const isJPEG = file.type === 'image/jpeg';
    const isGIF = file.type === 'image/gif';
    const isPNG = file.type === 'image/png';
    if (!(isJPG || isJPEG || isGIF || isPNG)) {
      Modal.error({
        title: '只能上传JPG 、JPEG 、GIF、 PNG格式的图片~',
      });
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      Modal.error({
        title: '超过2M限制，不允许上传~',
      });
      return;
    }
    return (isJPG || isJPEG || isGIF || isPNG) && isLt2M && this.checkImageWH(file);
  };

  //返回一个 promise：检测通过则返回resolve；失败则返回reject，并阻止图片上传
  checkImageWH(file) {
    let self = this;
    return new Promise(function (resolve, reject) {
      let filereader = new FileReader();
      filereader.onload = e => {
        let src = e.target.result;
        const image = new Image();
        image.onload = function () {
          // 获取图片的宽高，并存放到file对象中
          ////console.log('file width :' + this.width);
          ////console.log('file height :' + this.height);
          file.width = this.width;
          file.height = this.height;
          resolve();
        };
        image.onerror = reject;
        image.src = src;
      };
      filereader.readAsDataURL(file);
    });
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props;
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {// values 是form表单里的参数
      // 点击按钮后，将表单提交给后台
      dispatch({
        type: 'mymodel/submitFormData',
        payload: values,
      });
    });
  };

  render() {
    const { previewVisible, previewImage, imgList } = this.state; //  从 state 中拿数据
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text" style={{ fontSize: '20px' }} >上传图片</div>
      </div>
    );
    return (
      <div className="upLoadPic">
        <div className="uploadTxt">上传图片：</div>
        <Form onSubmit={this.handleSubmit} hideRequiredMark style={{ marginTop: 8 }}>
          <FormItem>
            <Upload
              action="http://1.116.154.35:25921/clife-boot-api-app/api/cms/common/upload" // 这个是图片上传的接口请求，实际开发中，要替换成你自己的业务接口

              listType="picture-card"
              fileList={this.state.imgList}
              onPreview={this.handlePreview} // 点击图片缩略图，进行预览
              beforeUpload={this.handleBeforeUpload} // 上传之前，对图片的格式做校验，并获取图片的宽高
              onChange={this.handleChange} // 每次上传图片时，都会触发这个方法
            >
              {this.state.imgList.length >= 6 ? null : uploadButton}

            </Upload>

          </FormItem>
        </Form>
        <div />
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}

export default PicturesWall;












// import React, { Component, PureComponent } from 'react';
// import { Upload, Modal } from 'antd';
// import { PlusOutlined } from '@ant-design/icons';
// import './index.scss'
// import request, { post } from '../../../../../../static/js/http';
// import cookie, { load } from 'react-cookies'
// function getBase64(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = error => reject(error);
//   });
// }
// export default class PicturesWall extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       previewVisible: false,
//       previewImage: '',
//       previewTitle: '',
//       fileList: [

//       ],
//       loading: false,
//       imageUrl: '',
//       loading1: false,
//       imageUrl1: '',
//     };
//     // this.inputRef = React.createRef();
//   }
//   componentDidMount() {
//     ////console.log(this);
//   }
//   handleCancel = () => this.setState({ previewVisible: false });

//   handlePreview = async file => {
//     if (!file.url && !file.preview) {
//       file.preview = await getBase64(file.originFileObj);
//     }
//     this.setState({
//       previewImage: file.url || file.preview,
//       previewVisible: true,
//       previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
//     });

//   };

//   handleChange = (info, a) => {


//     if (info.file.status === 'uploading') {
//       if (a === 1) {
//         this.setState({ loading: true,});
//       } else {
//         this.setState({ loading1: true, });
//       }

//       return;
//     }
//     if (info.file.status === 'done') {
//       // Get this url from response in real world.
//       if (a === 1) {
//         this.setState({
//           imageUrl: info.file.response.data,
//           loading: false
//         })
//       } else {
//         this.setState({
//           imageUrl1: info.file.response.data,
//           loading1: false
//         })
//       }
//     }
//   };
//   // http://1.116.154.35:25921/clife-boot-api-app/api/cms/common/upload

//   render() {
//     const { previewVisible,loading, imageUrl, loading1, imageUrl1, previewImage, fileList, previewTitle } = this.state;
//     ////console.log(imageUrl);

//     const uploadButton = (
//       <div>
//         <PlusOutlined />
//         <div style={{ marginTop: 8 }}></div>
//       </div>
//     );
//     return (
//       <div className="upLoadPic">
//         <div className="uploadTxt">上传图片：</div>
//         <Upload
//           action="http://1.116.154.35:25921/clife-boot-api-app/api/cms/common/upload"
//           name="file"
//           listType="picture-card"
//           className="avatar-uploader"
//           showUploadList={false}
//           beforeUpload={() => this.beforeUpload}
//           onChange={(i) => this.handleChange(i, 1)}
//         >
//           {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%', height: '100%' }} /> : uploadButton}
//         </Upload>
//         <Modal
//           visible={previewVisible}
//           title={previewTitle}
//           footer={null}
//           onCancel={this.handleCancel}
//         >
//           <img alt="example" style={{ width: '100%' }} src={previewImage} />
//         </Modal>
//       </div>
//     );
//   }
// }

// import { Upload, Icon, Modal, Form } from 'antd';

// const FormItem = Form.Item;

// class PicturesWall extends PureComponent {
//   state = {
//     previewVisible: false,
//     previewImage: '',
//     imgList: [],
//   };

// componentDidUpdate(){
//   ////console.log(this.state.imgList);
// }
//   handleChange = ({ file, fileList }) => {
//     ////console.log(file);
//     ////console.log(fileList);
//     ////console.log(JSON.stringify(file)); // file 是当前正在上传的 单个 img
//     ////console.log(JSON.stringify(fileList)); // fileList 是已上传的全部 img 列表

//     this.setState({
//       imgList: fileList,
//     });
//   };


//   handleCancel = () => this.setState({ previewVisible: false });

//   handlePreview = file => {
//     this.setState({
//       previewImage: file.url || file.thumbUrl,
//       previewVisible: true,
//     });
//   };


//   // 参考链接：https://www.jianshu.com/p/f356f050b3c9
//   handleBeforeUpload = file => {
//     //限制图片 格式、size、分辨率
//     const isJPG = file.type === 'image/jpeg';
//     const isJPEG = file.type === 'image/jpeg';
//     const isGIF = file.type === 'image/gif';
//     const isPNG = file.type === 'image/png';
//     if (!(isJPG || isJPEG || isGIF || isPNG)) {
//       Modal.error({
//         title: '只能上传JPG 、JPEG 、GIF、 PNG格式的图片~',
//       });
//       return;
//     }
//     const isLt2M = file.size / 1024 / 1024 < 2;
//     if (!isLt2M) {
//       Modal.error({
//         title: '超过2M限制，不允许上传~',
//       });
//       return;
//     }
//     return (isJPG || isJPEG || isGIF || isPNG) && isLt2M && this.checkImageWH(file);
//   };

//   //返回一个 promise：检测通过则返回resolve；失败则返回reject，并阻止图片上传
//   checkImageWH(file) {
//     let self = this;
//     return new Promise(function(resolve, reject) {
//       let filereader = new FileReader();
//       filereader.onload = e => {
//         let src = e.target.result;
//         const image = new Image();
//         image.onload = function() {
//           // 获取图片的宽高，并存放到file对象中
//           ////console.log('file width :' + this.width);
//           ////console.log('file height :' + this.height);
//           file.width = this.width;
//           file.height = this.height;
//           resolve();
//         };
//         image.onerror = reject;
//         image.src = src;
//       };
//       filereader.readAsDataURL(file);
//     });
//   }

//   handleSubmit = e => {
//     const { dispatch, form } = this.props;
//     e.preventDefault();
//     form.validateFieldsAndScroll((err, values) => {// values 是form表单里的参数
//       // 点击按钮后，将表单提交给后台
//       dispatch({
//         type: 'mymodel/submitFormData',
//         payload: values,
//       });
//     });
//   };

//   render() {
//     const { previewVisible, previewImage, imgList } = this.state; //  从 state 中拿数据
//     const uploadButton = (
//       <div>
//         <Icon type="plus" />
//         <div className="ant-upload-text">Upload</div>
//       </div>
//     );
//     return (
//       <div className="clearfix">
//         <Form onSubmit={this.handleSubmit} hideRequiredMark style={{ marginTop: 8 }}>
//           <FormItem label="图片图片">
//             {(
//               <Upload
//                 action="//jsonplaceholder.typicode.com/posts/" // 这个是图片上传的接口请求，实际开发中，要替换成你自己的业务接口
//                 data={file => ({ // data里存放的是接口的请求参数
//                   // param1: myParam1,
//                   // param2: myParam2,
//                   photoCotent: file, // file 是当前正在上传的图片
//                   photoWidth: file.height, // 通过  handleBeforeUpload 获取 图片的宽高
//                   photoHeight: file.width,
//                 })}
//                 listType="picture-card"
//                 fileList={this.state.imgList}
//                 onPreview={this.handlePreview} // 点击图片缩略图，进行预览
//                 beforeUpload={this.handleBeforeUpload} // 上传之前，对图片的格式做校验，并获取图片的宽高
//                 onChange={this.handleChange} // 每次上传图片时，都会触发这个方法
//               >
//                 {this.state.imgList.length >= 9 ? null : uploadButton}
//               </Upload>
//             )}
//           </FormItem>
//         </Form>
//         <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
//           <img alt="example" style={{ width: '100%' }} src={previewImage} />
//         </Modal>
//       </div>
//     );
//   }
// }

// export default PicturesWall;