import React, { Component, useState } from 'react';
import cookie from 'react-cookies';
import { Popconfirm, message, Button } from 'antd';
import './index.scss'
import request from '../../../../static/js/http';
export default class Caigouoffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offerList: [],
      item:[]
    }
  }
  componentDidMount() {
    this.offerList()
    this.setState({
      item:this.props.history.location.state.item
    })
    ////console.log(this.props.history.location.state.item);
  }
  offerList() {
    let purchaseId = this.props.history.location.state.purchaseId
    ////console.log(purchaseId);
    let memberid = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/apply/purchase/queryApplyPurchaseReport', {
      memberid,
      purchaseId
    }).then(res => {
      ////console.log(res);
      this.setState({
        offerList: res.data
      })
    })
  }
  render() {
    const { offerList,item } = this.state
    function confirm() {
      // copy(text)
      message.success('复制成功，去📞吧');
    }
    return (
      <>
        <div className="caigouoffer">
          <div className="offertop">
            <div className="offertopname">
              {item.title}-{item.purchaseClass}
            </div>
            <div className="offertopzhong">
              {item.purchaseNum}斤
            </div>
            <div className="offertoppinzhi">
              品质要求：{item.purchaseLevel}
            </div>
          </div>
          <div className="offerlist">
            {
              offerList && offerList.map(item => {
                return <div className="offeritem" key={item.id}>
                  <div className="itemleft">
                    <div className="xinxitop">
                      <div className="itempic">
                        <img src="https://img1.baidu.com/it/u=2496571732,442429806&fm=26&fmt=auto&gp=0.jpg" alt="" />
                      </div>
                      <div className="iteminfo">
                        <div className="name">
                          {item.title}
                  </div>
                        {/* <div className="conten">
                          新鲜辣椒，正规厂家直销
                  </div> */}
                        {/* <div className="dizhi">
                          河南省郑州市
                  </div> */}
                      </div>
                    </div>
                    <div className="xinxibottom">
                      <div className="tou">
                        <img src={item.avator} alt="" />
                      </div>
                      <div className="maijia">
                        卖家：{item.reportName}
                  <img src="" alt="" className="biaoshi" />
                      </div>
                    </div>
                  </div>
                  <div className="itemright">
                    <div className="jiage">
                      {item.price}元/斤
                </div>
                    <div className="itemyouxia">
                      <Popconfirm
                        placement="topRight"
                        title={item.phone}
                        onConfirm={confirm}
                        okText="复制"
                        cancelText="取消"
                      >
                        <Button>打电话</Button>
                      </Popconfirm>
                      {/* <div className="liaoyiliao">
                        聊一聊
                </div> */}
                    </div>
                  </div>
                </div>
              })
            }

          </div>
        </div>
      </>
    )
  }
}