import React, { Component } from 'react';
import { Input, Select, Button, Pagination } from 'antd';
import { LeftOutlined, LineChartOutlined, RightOutlined, FieldTimeOutlined } from '@ant-design/icons';
import Carousel1 from '../../../components/Carousel1';
import BackTop1 from '../../../components/BackTop1';
import './index.scss';
import request from '../../../static/js/http';
import { Link } from 'react-router-dom';
export default class Boutique extends Component {
  constructor(props) {
    super(props);
    this.state = {
      priceTop: 0,
      priceDown: 0,
      pageNum: 1,
      // list: [{ name: '不限', id: 0 }, { name: '子弹头1', id: 1 }, { name: '子弹头1', id: 2 }],
      index2: '', // 切换种类
      optimizationList: [],
      sortType: '',
      zhonglei: [],
      classId: '',
      twoList: [],
      twoClassId: '',
      all: true,
    };
  }

  componentDidMount() {
    this.zhonglei()
    this.all()


  }
  //种类
  zhonglei() {
    request('post', '/api/goods/class/bList', {
      classId: 1
    }).then(res => {
      console.log(res);
      if (res.code === 0) {
        this.setState({
          zhonglei: res.data
        }, function () {
          this.twoList()
        })
      }
    })
  }
  //二级列表
  twoList() {
    const { zhonglei, twoClassId ,all} = this.state
    console.log(twoClassId == '' ? zhonglei[0].classId : twoClassId);
    if (!this.state.all) {
      request('post', '/api/goods/class/sList', {
        classId: twoClassId == '' ? zhonglei[0].classId : twoClassId
      }).then(res => {
        console.log(res);
        this.setState({
          twoList: res.data
        }, function () {
          this.getList()
        })
      })
    }
  }
  twoBtn(classId){
    console.log(classId);
    request('post','/api/goods/class/sList',{
      classId
    }).then(res=>{
      console.log(res);
      if (res.code === 0) {
        this.getList()
        // this.setState({
        //   optimizationList: res.data
        // })
      }
    })
  }
  //获取列表
  getList(classId) {
    const { pageNum, twoList, all } = this.state
    // console.log(typeof (classId) == 'undefined' ? typeof (twoList[0]) == 'undefined' ? classId : twoList[0].classId : (typeof (twoList) == 'undefined' ? 0 : classId));
    request('post', '/api/goods/goods/agrList', {
      classId: typeof (classId) == 'undefined' ? typeof (twoList[0]) == 'undefined' ? classId : twoList[0].classId : (typeof (twoList) == 'undefined' ? 0 : classId),
      // classId:classId?classId:'',
      pageNum,
      pageSize: 10,
      lat: 1,
      lon: 1,
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          optimizationList: res.data
        })
      }
    })
  }
  //全部
  zonghe() {
    this.getList()
  }
  //销量
  sales = (classIds) => {
    console.log(classIds);
    const { sortType, pageNum, twoList,twoClassId ,classId} = this.state
    if (sortType == 0) {
      this.setState({
        sortType: 1
      })
    } else if (sortType == 1) {
      this.setState({
        sortType: 0
      })
    }
    request('post', '/api/goods/goods/agrList', {
      classId:typeof (classIds) == 'undefined' ? typeof (twoList[0]) == 'undefined' ? classId : twoList[0].classId : (typeof (twoList) == 'undefined' ? 0 : classId),
      pageNum,
      pageSize: 10,
      lat: 1,
      lon: 1,
      sortType,
    }).then(res => {
      console.log(res);
      this.setState({
        optimizationList: res.data
      })
    })
  }
  //价格筛选
  jiaqian() {
    ////console.log('价格筛选');
    let { priceTop, priceDown, pageNum, classId ,twoList} = this.state
    request('post', '/api/goods/goods/agrList', {
      classId: typeof (classId) == 'undefined' ? typeof (twoList[0]) == 'undefined' ? 'return' : twoList[0].classId : (typeof (twoList) == 'undefined' ? 0 : classId),
      pageNum,
      pageSize: 10,
      lat: 1,
      lon: 1,
      priceTop: priceTop,
      priceDown: priceDown,
    }).then(res => {
      this.setState({
        optimizationList: res.data
      })
    })
  }
  input = (e, name) => {
    // eslint-disable-next-line no-console
    ////console.log('aaa', e, name);
    this.setState({
      [name]: e.target.value
    });
  }

  select1 = e => {
    // eslint-disable-next-line no-console
    ////console.log('aaa', e);
    const { priceTop, priceDown, pageNum, classId } = this.state
    request('post', '/api/goods/goods/agrList', {
      classId,
      pageNum,
      pageSize: 10,
      lat: 1,
      lon: 1,
      goodsProp: e,
      priceTop,
      priceDown,
      recomSy: 0
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          optimizationList: res.data
        })
      }
    })
  }

  add = () => {
    const { pageNum } = this.state;

    this.setState({
      pageNum: pageNum + 1
    }, function () {
      this.getList()
    });
  }

  jian = () => {
    const { pageNum } = this.state;
    this.setState({
      pageNum: pageNum - 1
    }, function () {
      this.getList()
    });
  }

  onChange = pageNum => {
    ////console.log(pageNum);
    //// eslint-disable-next-line no-console
    this.setState({
      pageNum
    });
    const { priceTop, priceDown, sortType, classId } = this.state
    ////console.log(sortType)
    if (sortType) {
      request('post', '/api/goods/goods/agrList', {
        classId,
        pageNum,
        pageSize: 10,
        lat: 1,
        lon: 1,
        priceTop,
        priceDown,
        recomSy: 0,
        sortType
      }).then(res => {
        ////console.log(res);
        if (res.code === 0) {
          this.setState({
            optimizationList: res.data
          })
        }
      })
    } else {
      request('post', '/api/goods/goods/agrList', {
        classId: 0,
        pageNum,
        pageSize: 10,
        lat: 1,
        lon: 1,
        priceTop,
        priceDown,
        recomSy: 0,
      }).then(res => {
        ////console.log(res);
        if (res.code === 0) {
          this.setState({
            optimizationList: res.data
          })
        }
      })
    }
  };

  sta = (name, index, classId) => {
    const { priceTop, priceDown, pageNum } = this.state
    request('post', '/api/goods/goods/agrList', {
      classId,
      pageNum,
      pageSize: 10,
      lat: 1,
      lon: 1,
      goodsName: name,
      priceTop,
      priceDown,
      recomSy: 0
    }).then(res => {
      if (res.code === 0) {
        this.setState({
          optimizationList: res.data,
          classId,
          twoClassId: classId
        }, function () {
          this.twoList()
          console.log(classId);
        })
      }
    })
    // 切换类别
    this.setState({
      index2: index,
      all: false
    });
  }
  all() {
    this.setState({
      all: true,
      index2: '',
      twoList: [],
      classId: '',
    }, function () {
      const { pageNum } = this.state
      request('post', '/api/goods/goods/agrList', {
        classId: '',
        pageNum,
        pageSize: 10,
        lat: 1,
        lon: 1,
      }).then(res => {
        console.log(res);
        if (res.code === 0) {
          this.setState({
            optimizationList: res.data
          })
        }
      })
    })

  }
  xiangqing(id) {
    this.props.history.push({ pathname: '/home/agriculdetails', state: { id } })
  }
  render() {
    const {
      pageNum, priceTop, priceDown, index2, list, optimizationList, zhonglei
    } = this.state;
    return (
      <div>
        <Carousel1 />
        <div className="agriculture">
          <div className="agriculturewai">
            <div className="agriculturenei">
              <div style={{ width: '44px' }}>品种：</div>
              <div className="dataList">
                <div className={`item hover ${this.state.all ? 'sta' : ''}`} onClick={() => this.all()}>全部商品</div>

                {
                  zhonglei.map((a, index) => (
                    <div
                      className={`item hover ${index2 === index ? 'sta' : ''}`}
                      key={a.classId}
                      onClick={() => this.sta(a.className, index, a.classId,)}
                    >
                      {a.className}
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          <div className="twolist">
            {
              this.state.twoList.map(item => {
                return <div className="twoItem" key={item.classId} onClick={() => this.twoBtn(item.classId)}>
                  {item.className}
                </div>
              })
            }
          </div>
          <div className="topwai">
            <div className="topnei">
              <div className="dis">
                <div className="o1" onClick={() => this.zonghe()}>全部</div>
                <div className="o1" onClick={() => this.sales()}>销量</div>
                <div className="o2">
                  <span className="span">价格</span>
                  <Input type="number" onChange={e => this.input(e, 'priceTop')} style={{ width: 100, height: 35, textAlign: 'center' }} prefix={<span>￥</span>} />
                  <span className="span">-</span>
                  <Input type="number"
                    className="site-input-right"
                    onChange={e => this.input(e, 'priceDown')}
                    prefix={<span>￥</span>}
                    style={{
                      width: 100,
                      height: 35,
                      textAlign: 'center'
                    }}
                  />
                </div>
                <div className="o5">
                  <button onClick={() => this.jiaqian()}>确定</button>
                </div>
              </div>
              <div className="o4">
                <Button type="primary" disabled={pageNum <= 1} onClick={() => this.jian()} icon={<LeftOutlined />} />
                <span className="span1"><span>{pageNum}</span>/{optimizationList.pages}</span>
                <Button type="primary" disabled={pageNum >= optimizationList.pages} onClick={() => this.add()} icon={<RightOutlined />} />
              </div>
            </div>
          </div>
          <div className="list">
            {
              optimizationList.list && optimizationList.list.map(item => (
                <Link key={item.goodsId} target="_blank" to={'/home/agriculdetails/' + item.goodsId}>
                  <div className="item111 hover" key={item.goodsId}>
                    <img className="img" src={item.goodsImg} alt="辣椒" />
                    <div className="jia">￥<span>{item.productPrice}</span>元</div>
                    <div className="you">
                      {/* <div className="youpin">优品优选</div> */}
                      <div className="goodsName">{item.goodsName}</div>
                    </div>
                    <div className="dian">
                      <div className="dianright">
                        <div className="sheng">{item.shopName}</div>
                        {/* <div className="sheng">河南省</div> */}
                      </div>
                    </div>
                    <div className="diandi">
                      <div>
                        <LineChartOutlined className="icon" />
                        <span>{item.goodsSaleNum?item.goodsSaleNum:'0'}</span>
                      </div>
                      <div>
                        <FieldTimeOutlined className="icon" />
                        <span>48小时发货</span>
                      </div>
                    </div>
                  </div >
                </Link>
              ))
            }
          </div>
          <div className="seardi">
            <Pagination
              showQuickJumper
              showSizeChanger={false}
              current={pageNum}
              pageSize={10}
              onChange={(e) => this.onChange(e)}
              defaultCurrent={1}
              total={optimizationList.total}
            />
          </div>
          <BackTop1 />
        </div>
      </div>
    );
  }
}
