import React, { Component } from 'react';
import './index.scss';
import cookie from 'react-cookies';
import * as echarts from 'echarts';
import request from '../../../../static/js/http';
class Piechart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pinzhong: [],
      jiaoyi: []
    }
  }
  componentDidMount() {
    this.market()
  }
  market() {
    request('post', '/api/market/hall/list', {
      memberId:cookie.load('lj-pc-userInfo') ? cookie.load('lj-pc-userInfo').memberId : '',
      type: 1,
      classId: 1
    }).then(res => {
      ////console.log(res);
      this.setState({
        marketList: res.data
      }, function () {
        this.fenbu()
        this.condition()
      })
    })
  }
  fenbu() {
    request('post', '/api/market/chart/hallList', {
      memberId:cookie.load('lj-pc-userInfo') ? cookie.load('lj-pc-userInfo').memberId : '',
    }).then(res => {
      let pinzhong = [];
      let jiaoyi = [];
      for (let i = 0; i < res.data.name.length; i++) {
        let pinzhongObj = {}
        pinzhongObj.name = res.data.name[i];
        pinzhongObj.value = res.data.allNum[i]
        pinzhong.push(pinzhongObj)
      }
      for (let i = 0; i < res.data.className.length; i++) {
        let jiaoyiObj = {}
        jiaoyiObj.name = res.data.className[i];
        jiaoyiObj.value = res.data.classStock[i]
        jiaoyi.push(jiaoyiObj)
      }
      this.setState({
        pinzhong,
        jiaoyi
      }, function () {
        this.variety()
        this.condition()
      })
    })
  }
  //品种分布
  variety() {
    const { pinzhong } = this.state
    let data = pinzhong
    // classStock
    // let a = {
    //   value: fenbu.className && fenbu.className.map(item => {
    //     ////console.log(item);
    //     // return item
    //     data['value']=item
    //     ////console.log(data);
    //   }),
    //   name: fenbu.classStock && fenbu.classStock.map(item => {
    //     ////console.log(item);
    //     return item
    //   })
    // }
    var chartDom = document.getElementById('variety');
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        orient: 'vertical',
        left: 'right',
      },
      series: [
        {
          name: '品种分布',
          type: 'pie',
          radius: '60%',
          data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          label: {
            show: true,
            position: 'inside',

          },
        }
      ]
    };

    option && myChart.setOption(option);
  }

  //交易分布
  condition() {
    const { jiaoyi } = this.state
    let data = jiaoyi
    var chartDom = document.getElementById('condition');
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        orient: 'vertical',
        right: 'right',
        top: 'center'
      },
      series: [
        {
          name: '访问来源',
          type: 'pie',
          radius: '80%',
          data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
              fontSize: '100%',
            }
          },
          // label: {
          //   show: true,
          //   position: 'inside',

          // },

          label: {
            position: 'inside',
            show: true,
            formatter: '{d}%'
          },
          labelLine: { show: true }

        }
      ]
    };
    option && myChart.setOption(option);
  }
  render() {
    return (
      <>
        <div className="piechartList">
          <div className="piechart">
            <div className="piechartTop">
              <div className="left">
                <div className="red"></div>
                <div className="title">
                  品种分布
            </div>
              </div>
            </div>
            <div id="variety" style={{ width: '100%', height: '100%' }}></div>
          </div>
          <div className="piechart">
            <div className="piechartTop">
              <div className="left">
                <div className="red"></div>
                <div className="title">
                  交易分布
            </div>
              </div>
            </div>
            <div className="item">
              <div id="condition" style={{ width: '100%', height: '100%' }}></div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default Piechart