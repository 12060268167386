import React, { Component } from 'react';
import './index.scss';
import Carousel1 from '../../../components/Carousel1';
import { withRouter } from "react-router-dom"
import request from '../../../static/js/http';
import { Pagination } from 'antd';
import marketright from '../../../static/img/MarketConsult.jpg'
class MarketConsult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getHotList: [],
      getList: [],
      topPic:[]
    }
  }
  componentDidMount() {
    this.getHotList()
    this.getList()
    this.topPic()
  }
  getHotList() {
    request('post', '/api/market/information/list', {
      pageNum: 1,
      pageSize: 10,
      // title:'',
      type: 1,
    }).then(res => {
      ////console.log(res);
      this.setState({
        getHotList: res.data
      })
    })
  }
  getList() {
    request('post', '/api/market/information/list', {
      pageNum: 1,
      pageSize: 5,
      // title:'',
      type: 0,
    }).then(res => {
      //console.log(res);
      this.setState({
        getList: res.data
      })
    })
  }
  topPic() {
    request('post', '/api/market/information/list', {
      pageNum: 1,
      pageSize: 5,
      // title:'',
      type: 0,
    }).then(res => {
      ////console.log(res);
      this.setState({
        topPic: res.data
      })
    })
  }
  pagination = (a, b) => {
    ////console.log(a, b);
    request('post', '/api/market/information/list', {
      pageSize: b,
      pageNum: a,
      type: 0,
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          getList: res.data,
        })
      }
    })
  }
  xiangqing(id){
    this.props.history.push({ pathname: '/home/MarketConsultDetails', state: { id } })
  }
  render() {
    const { getHotList, getList,topPic } = this.state

    return (
      <>
        <Carousel1 />
        <div className="marketConsult">
          <div className="marketConsultWTop">
            {
              <div className="marketConsultLeft" >
                <div className="marketConsultLeftBig" onClick={()=>this.xiangqing(topPic.list && topPic.list[0].informationId)}>
                  <img src={topPic.list && topPic.list[0].img}  alt="" />
                  <div className="hotContent">
                    <div className="title">
                    {topPic.list && topPic.list[0].title}
                      </div>
                    <div className="time">
                      发布时间：{topPic.list && topPic.list[0].createAt}
                      </div>
                    <div className="content">
                    {topPic.list && topPic.list[0].content}
                      </div>
                  </div>
                </div>
                <div className="marketConsultLeftBigSmall">
                  <div className="pic" onClick={()=>this.xiangqing(topPic.list && topPic.list[1].informationId)}>
                    <img src={topPic.list && topPic.list[1].img }  alt="" />
                  </div>
                  <div className="pic" onClick={()=>this.xiangqing(topPic.list && topPic.list[2].informationId)}>
                    <img src={topPic.list && topPic.list[2].img }  alt="" />
                  </div>
                </div>
              </div>

            }

            <div className="marketConsultRight">
              <span className="new">最新资讯</span>
              <ul className="ul">
                {
                  getList.list && getList.list.map((item, index) => {
                    return <li key={item.informationId} onClick={()=>this.xiangqing(item.informationId)}><span className="news">{item.title}</span><span className="time" >{item.createAt}</span></li>
                  })
                }
              </ul>
            </div>
          </div>
          <div className="marketConsultContent">
            <div className="marketConsultContentList">
              {
                getList.list && getList.list.map((item, index) => {
                  return <div className="ContentItem" onClick={()=>this.xiangqing(item.informationId)} key={item.informationId}>
                    <div className="ContentItemLeft">
                      <img src={item.img} alt="" />
                    </div>
                    <div className="ContentItemRight">
                      <div className="title">
                        {item.title}
                      </div>
                      <div className="content">
                        {/* {item.content} */}
                        <div dangerouslySetInnerHTML={{__html:`${item.content}`}}></div>
                      </div>
                      <div className="time">
                        发布时间：{item.createAt}
                      </div>
                    </div>
                  </div>
                })
              }

            </div>
            <div className="marketConsultContentRight">
              <div className="topPic">
                <img src={marketright} alt="" />
              </div>
              <div className="bottomContent">
                <div className="title">
                  热搜咨询
                </div>
                {
                  getHotList.list && getHotList.list.map((item, index) => {
                    return <div className="bottomContentItem" onClick={()=>this.xiangqing(item.informationId)} key={item.informationId}>
                      <div className="pic">
                        <img src={item.img} alt="" />
                      </div>
                      <div className="picRight">
                        <div className="title">
                          <div dangerouslySetInnerHTML={{__html:`${item.content}`}}></div>
                        </div>
                        <div className="time">
                          发布时间：{item.createAt}
                        </div>
                      </div>
                    </div>
                  })
                }

              </div>
            </div>

          </div>
          <Pagination style={{ marginBottom: '57px', textAlign: 'center' }} pageSize='5' current={getList.pageNum} onChange={this.pagination} defaultCurrent={1} total={getList.total} />
        </div>
      </>
    )
  }
}
export default withRouter(MarketConsult)