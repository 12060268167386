import React, { Component } from 'react'
import {
  Form, Input, Button, message, Row, Col, Upload
} from 'antd';
import cookie from 'react-cookies'
import { LoadingOutlined, PlusOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import request from '../../../../static/js/http';
export default class Qiye extends Component {
  constructor() {
    super();
    this.state = {
      userInfo: [],
      shopAuth: [],
    };
  }
  componentDidMount() {
    this.userInfo()
  }
  userInfo() {
    let userInfo = cookie.load('lj-pc-userInfo')
    request('post', '/api/member/user/info', {
      memberId: userInfo.memberId
    }).then(res => {
      ////console.log(res);
      this.setState({
        userInfo: res.data,
        shopAuth: res.data.shopAuth
      }, function () {
        ////console.log(this.state.userInfo);
        ////console.log(this.state.shopAuth);
      })
    })
  }

  render() {
    const { shopAuth } = this.state;
    return (
      <div className="qiye">
        <Form
          name="normal_login1"
          className="login-form"
          autoComplete="off"
          initialValues={{
            remember: true
          }}
          wrapperCol={{ span: 14 }}
          labelCol={{ span: 6 }}
          size="large"
          onFinish={values => this.onFinish(values)}
          id="mobilelogin1"
        >
          <Form.Item
            name="faren"
            label="企业法人"
            rules={[{
              required: true,
              message: '请输入法定身份证姓名'
            }
            ]}
          >
            <div className="div">
              {shopAuth.authOperator}
            </div>
          </Form.Item>
          <Form.Item
            name="quancheng"
            label="企业全称"
            rules={[{
              required: true,
              message: '请按照营业执照上的名字填写'
            }
            ]}
          >
            <div className="div">
              {shopAuth.authEnterprise}
            </div>
          </Form.Item>
          <Form.Item
            name="id"
            label="身份证"
            rules={[
              {
                required: true,
                message: '请输入身份证号'
              }, {
                pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
                message: '不合法的身份证号格式!'
              }
            ]}
          >
            <div className="div">
              {shopAuth.authIdcard}
            </div>
          </Form.Item>
          <Form.Item
            name="xinyong"
            label="机构/社会信用代码"
            rules={[
              {
                required: true,
                message: '机构/社会信用代码'
              }
            ]}
          >
            <div className="div">
              {shopAuth.authCode}
            </div>
          </Form.Item>
          <Form.Item
            label="营业资质"
            rules={[{ required: true }]}
            className="tu"
          >
            <img style={{ width: '300px', height: '200px' }} src={shopAuth.authHead} alt="" />
            <Row>
              <Col style={{ textAlign: 'left', color: '#C7C7C7' }} span={12}>
                <ExclamationCircleFilled style={{ color: '#D94511', marginRight: '4px' }} /><span>要求：证件文字清晰，建议使用原图；</span>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            label="食品安全"
            rules={[{ required: true }]}
            className="tu"
          >
            <img style={{ width: '300px', height: '200px' }} src={shopAuth.authFood} alt="" />
            <Row>
              <Col style={{ textAlign: 'left', color: '#C7C7C7' }} span={12}>
                <ExclamationCircleFilled style={{ color: '#D94511', marginRight: '4px' }} /><span>要求：证件文字清晰，建议使用原图；</span>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            name="ipo"
            label="手机号"
            rules={[
              {
                required: true,
                message: '请输入手机号'
              }, {
                pattern: /^1\d{10}$/,
                message: '不合法的手机号格式!'
              }
            ]}
          >
            <div className="div">
              {shopAuth.authPhone}
            </div>
          </Form.Item>

        </Form>
      </div>
    )
  }
}
