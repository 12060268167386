import React, { Component } from 'react';
import { Input, Select, Button, Pagination, Badge } from 'antd';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { LeftOutlined, RightOutlined, LineChartOutlined, FieldTimeOutlined, ShoppingCartOutlined, BellOutlined } from '@ant-design/icons';
import loginlogo from '../../../static/img/webljLogo.jpg';
import Item from '../../../components/Item';
import BackTop1 from '../../../components/BackTop1';
import './index.scss';
import request from '../../../static/js/http';
import left1 from '../../../static/img/del_home_left.png';
import cookie from 'react-cookies';
const { Option } = Select;
const { Search } = Input;
class Search1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shopNum: 0,
      list: [{ name: '辣椒', id: 1 }, { name: '辣椒', id: 2 }, { name: '辣椒', id: 3 }, { name: '辣椒', id: 4 }],
      tab: [
        { name: '首页', id: '1', path: '/home/index', sta: true },
        { name: '优品优选', id: '2', path: '/home/boutique', sta: false },
        { name: '乡村振兴', id: '3', path: '/home/retail', sta: false },
        { name: '撮合大厅', id: '4', path: '/home/Cuohedating', sta: false },
        { name: '期货交易', id: '5', path: '/home/futurestrading', sta: false },
        { name: '行情大厅', id: '6', path: '/home/Market', sta: false },
        { name: '行业资讯', id: '7', path: '/home/MarketConsult', sta: false },
        { name: '农资商城', id: '8', path: '/home/agriculture', sta: false },
        { name: '农技服务', id: '9', path: '/home/rcserve', sta: false },
        { name: '金融服务', id: '10', path: '/home/financial', sta: false }
      ],
      searchVal: '',
      input1: 0,
      input2: 0,
      index1: 1,
      searchData: [],
      goodsProp: '',
      sortType: 0,
      historyList: [],
      weightTop: '',
      weightDown: '',
    };
  }
  componentDidMount() {
    this.search()
    this.history()
    this.shopNum()
  }
  history() {
    if (!cookie.load('lj-pc-userInfo')) {
      return
    } else {
      let memberId = cookie.load('lj-pc-userInfo').memberId
      ////console.log(memberId);
      request('post', '/api/goods/search/list', {
        memberId,
        type: 1,
      }).then(res => {
        ////console.log(res);
        let shopNum = 0
        res.data.map(item => {
          shopNum += item.list.length
          ////console.log(item);
          ////console.log(item.list.length);
          // let shopNum +=item.list.length
          //  item.list.length
          ////console.log(shopNum);
        })
        this.setState({
          historyList: res.data[0].list
        }, function () {
          ////console.log(this.state.historyList);
        })
      })
    }
  }




  xiangqing(id) {
    ////console.log('跳转');
    // ////console.log(this.props.history.push({pathname: '/home/details',state:'id'}));
    this.props.history.push({ pathname: '/home/details', state: { id } })
  }
  jiaqian() {
    let { input1, input2, index1, searchVal, goodsProp } = this.state
    request('post', '/api/goods/goods/appIndex', {
      classId: 0,
      pageNum: index1,
      pageSize: 10,
      lat: 1,
      lon: 1,
      // content: searchVal,
      goodsProp,
      priceTop: input1,
      priceDown: input2,

    }).then(res => {
      this.setState({
        searchData: res.data
      })
    })
  }
  input = (e, name) => {
    this.setState({
      [name]: e.target.value
    });
  }
  groupof = (e, name) => {
    this.setState({
      [name]: e.target.value
    }, function (params) {
      this.weight()
    });
  }
  zonghe() {
    request('post', '/api/goods/goods/appIndex', {
      classId: 0,
      pageNum: 1,
      pageSize: 10,
      lat: 1,
      lon: 1,
    }).then(res => {
      this.setState({
        searchData: res.data
      })
    })
  }
  select1 = e => {
    ////console.log('aaa', e);
    this.setState({
      goodsProp: e
    })
    const { index1, searchVal } = this.state
    request('post', '/api/goods/goods/appIndex', {
      classId: 0,
      pageNum: index1,
      pageSize: 10,
      lat: 1,
      lon: 1,
      goodsProp: e,
      // content: searchVal,
    }).then(res => {
      this.setState({
        searchData: res.data
      })
    })
  }

  add = () => {
    const { index1 } = this.state;
    this.setState({
      index1: index1 + 1
    }, function () {
      this.search()
    });
  }

  jian = () => {
    const { index1 } = this.state;
    this.setState({
      index1: index1 - 1
    }, function () {
      this.search()
    });
  }
  search() {
    const { index1, searchVal, goodsProp } = this.state

    request('post', '/api/goods/goods/appIndex', {
      classId: 0,
      pageNum: index1,
      pageSize: 10,
      lat: 1,
      lon: 1,
      // content: searchVal,
      goodsProp: goodsProp,
    }).then(res => {
      this.setState({
        searchData: res.data
      })
    })
  }
  onChange = page => {
    this.setState({
      index1: page
    }, function () {
      this.search()
    });
  };
  onSearch = value => {
    this.setState({
      searchVal: value
    }, function () {
      request('post', '/api/goods/goods/appIndex', {
        memberId: !cookie.load('lj-pc-userInfo') ? '' : cookie.load('lj-pc-userInfo').memberId,
        classId: 0,
        pageNum: 1,
        pageSize: 10,
        lat: 1.22,
        lon: 1.22,
        content: value,
        // goodsProp: goodsProp,
      }).then(res => {
        this.setState({
          searchData: res.data
        }, function () {
          this.history()
        })
      })
    })
  }
  lishi = value => {
    ////console.log(value);
    this.setState({
      searchVal: value
    }, function () {
      let memberId = cookie.load('lj-pc-userInfo').memberId
      request('post', '/api/goods/goods/appIndex', {
        memberId,
        classId: 0,
        pageNum: 1,
        pageSize: 10,
        lat: 1.22,
        lon: 1.22,
        content: value,
        // goodsProp: goodsProp,
      }).then(res => {
        this.setState({
          searchData: res.data
        }, function () {
          this.history()
        })
      })
    })
  }

  sales = () => {
    let { sortType } = this.state
    if (sortType == 0) {
      this.setState({
        sortType: 1
      })
    } else if (sortType == 1) {
      this.setState({
        sortType: 0
      })
    }
    const { index1, goodsProp, searchVal } = this.state
    request('post', '/api/goods/goods/appIndex', {
      classId: 0,
      pageNum: index1,
      pageSize: 10,
      lat: 1,
      lon: 1,
      // content: searchVal,
      goodsProp: goodsProp,
      sortType
    }).then(res => {
      this.setState({
        searchData: res.data
      })
    })
  }
  weight() {
    const { weightTop, weightDown, } = this.state
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/goods/goods/appIndex', {
      memberId,
      classId: 0,
      pageNum: 1,
      pageSize: 10,
      lat: 1.22,
      lon: 1.22,
      weightTop,
      weightDown
      // goodsProp: goodsProp,
    }).then(res => {
      this.setState({
        searchData: res.data
      }, function () {
        this.history()
      })
    })
  }
  handleChange = (value) => {
    ////console.log(`selected ${value}`);
    if (value == '商家') {
      this.props.history.push('/home/SearchShop')
    }
  }

  sta = s => {
    const { tab } = this.state;
    const tab1 = tab.map(a => {
      a.sta = false;
      return a;
    });
    tab1[s - 1].sta = true;
    this.setState({
      tab: tab1
    });
  }
  //购物车数量
  shopNum = () => {
    if (!cookie.load('lj-pc-userInfo')) {
      request('post', '/api/member/cart/list', {
        memberId: ''
      }).then(res => {
        if (res.code === 0) {
          this.setState({
            shopList: res.data
          }, function () {
            let shopNum = 0
            this.state.shopList.map(item => {
              shopNum += item.goodsList.length
              this.setState({
                shopNum
              })
            })
          })
        }
      })
    } else {
      let userInfo = cookie.load('lj-pc-userInfo')
      request('post', '/api/member/cart/list', {
        memberId: userInfo.memberId
      }).then(res => {
        if (res.code === 0) {
          this.setState({
            shopList: res.data
          }, function () {
            let shopNum = 0
            this.state.shopList.map(item => {
              shopNum += item.goodsList.length
              this.setState({
                shopNum
              })
            })
          })
        }
      })
    }
  }
  render() {
    const { shopNum, historyList, tab } = this.state;
    // eslint-disable-next-line no-unused-vars
    const { index1, input1, input2, searchData } = this.state;
    return (
      <>
        <div id="header">
          <div className="homeToplo">
            <Link to="/home/index">
              <img className="login" alt="辣椒开放式物流 线上电子交易中心" src={loginlogo} />
            </Link>
            <div className="hometop">
              <div className="flex1">
                <Search
                  addonBefore={
                    <Select defaultValue="商品" className="select-before" onChange={this.handleChange}>
                      <Option value="商品">商品</Option>
                      <Option value="商家">商家</Option>
                    </Select>
                  }
                  placeholder="请输入搜索内容"
                  onSearch={this.onSearch}
                  enterButton
                />
                <Link to={`/myProfile/Shoppingcat`} target="_blank">
                  <div className="searchright1">
                    <Badge count={shopNum} className="svg">
                      <ShoppingCartOutlined style={{ color: '#B92115' }} />
                    </Badge>
                    <div className="one">
                      <div className="a2" >
                        <div >购物车 </div>
                        <div>查看我的购物车</div>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to={`/home/news`} target="_blank">
                  <div className="searchright1">
                    <Badge className="svg">
                      <BellOutlined style={{ color: '#B92115' }} />
                    </Badge>
                    <div className="one">
                      <div>消息</div>
                      <div>查看最新消息公告</div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="list">
                {
                  historyList.map(a => <div onClick={() => this.lishi(a.content)} key={a.id}>{a.content}</div>)
                }
              </div>
            </div>
          </div>
          <div className="tab">
            {
              tab.map(a => (
                <NavLink
                  to={a.path}
                  key={a.id}
                  activeClassName="sta"
                  className="item "
                >
                  {a.name}
                </NavLink>
              ))
            }
          </div>
        </div>
        <div className="search">
          <div className="topwai">
            <div className="topnei">
              <div className="o1" onClick={() => this.zonghe()}>全部</div>
              <div className="o1" onClick={() => this.sales()}>销量</div>
              <div className="o2">
                <span className="span">价格(斤)</span>
                <Input type="number" onChange={e => this.input(e, 'input1')} style={{ width: 100, height: 35, textAlign: 'center' }} prefix={<span>￥</span>} />
                <span className="span">-</span>
                <Input type="number"
                  className="site-input-right"
                  onChange={e => this.input(e, 'input2')}
                  prefix={<span>￥</span>}
                  style={{
                    width: 100,
                    height: 35,
                    textAlign: 'center'
                  }}
                />
              </div>
              <div className="o3">
                <span className="span">起批量</span>
                {/* defaultValue="lucy" */}
                {/* <Select onChange={e => this.select1(e, 'select1')} style={{ width: 120, height: 35 }} allowClear>
                  <Option value="200斤以上">200斤以上</Option>
                  <Option value="200斤以下">200斤以下</Option>
                </Select> */}
                <Input type="number" onChange={e => this.groupof(e, 'weightTop')} style={{ width: 100, height: 35, textAlign: 'center' }} />
                <span className="span">-</span>
                <Input type="number" onChange={e => this.groupof(e, 'weightDown')} style={{ width: 100, height: 35, textAlign: 'center' }} />
              </div>
              <div className="o3">
                <span className="span">等级</span>
                {/* defaultValue="lucy" */}
                <Select onChange={e => this.select1(e, 'select2')} style={{ width: 120, height: 35 }} allowClear>
                  <Option value="精品">精品</Option>
                  <Option value="通货">通货</Option>
                </Select>
              </div>
              <div className="o3" onClick={() => this.jiaqian()}>
                <button>确定</button>
              </div>
              <div className="o4">
                <Button type="primary" disabled={index1 <= 1} onClick={() => this.jian()} icon={<LeftOutlined />} />
                <span className="span1"><span>{index1}</span>/{searchData.pages}</span>
                <Button type="primary" disabled={index1 >= searchData.pages} onClick={() => this.add()} icon={<RightOutlined />} />
              </div>
            </div>
          </div>
          <div className="itemitem">
            <div className="list">
              {/* <Item goodsProp={this.state.goodsProp} index1={this.state.index1} /> */}
              {
                searchData.list && searchData.list.map(item => (
                  <Link target="_blank" key={item.goodsId} to={'/home/details/' + item.goodsId}>
                    <div className="item111 hover" key={item.goodsId}>

                      <img className="img" src={item.goodsImg} alt="辣椒" />
                      <div className="jia">￥<span>{item.productPrice}</span>/斤</div>
                      <div className="you">
                        <div className="youpin">{item.recomSy ==0 ? '优品优选': '乡村振兴'}</div> 
                        <div className="name sheng">{item.goodsName}</div>
                      </div>
                      <div className="dian">
                        <img className="dianlogo" src={left1} alt=" " />
                        <div className="dianright">
                          <div className="sheng">{item.shopName}</div>
                          <div className="sheng">河南省</div>
                        </div>
                      </div>
                      <div className="diandi">
                        <div>
                          <LineChartOutlined className="icon" />
                          <span>{item.goodsSaleNum?item.goodsSaleNum:'0'}</span>
                        </div>
                        <div>
                          <FieldTimeOutlined className="icon" />
                          <span>48小时发货</span>
                        </div>
                      </div>

                    </div >
                  </Link>

                ))
              }
            </div>
          </div>

          <div className="seardi">
            <Pagination showQuickJumper showSizeChanger={false} current={index1} pageSize='10' onChange={this.onChange} defaultCurrent={1} total={searchData.total} />
          </div>

          <div id="home">
            <BackTop1 />
          </div>
        </div>

      </>
    );
  }
}
export default withRouter(Search1)