import React, { Component, useState } from 'react';
import cookie from 'react-cookies';
import './index.scss'
import { Modal, Button, Pagination } from 'antd';
import request from '../../../static/js/http';


export default class Myhangqing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hangqingList: [
        {
          title: '子弹头',
          content: '很划算呀，今年种辣椒是大好的一年；是打破以往记录的一年；是大家认为只要进入辣椒业就是挣钱；是多少人因为没有买到辣椒而后悔的一年很划算呀，今年种辣椒是大好的一年；是打破以往记录的一年；是大家认为只要进入辣椒业就是挣钱；是多少人因为没有买到辣椒而后悔的一年',
          name: '韦记辣椒厂',
          data: '2017-10-01 18:00',
        },
        {
          title: '子弹头',
          content: '很划算呀，今年种辣椒是大好的一年；是打破以往记录的一年；是大家认为只要进入辣椒业就是挣钱；是多少人因为没有买到辣椒而后悔的一年很划算呀，今年种辣椒是大好的一年；是打破以往记录的一年；是大家认为只要进入辣椒业就是挣钱；是多少人因为没有买到辣椒而后悔的一年',
          name: '韦记辣椒厂',
          data: '2017-10-01 18:00',
        },
        {
          title: '子弹头',
          content: '很划算呀，今年种辣椒是大好的一年；是打破以往记录的一年；是大家认为只要进入辣椒业就是挣钱；是多少人因为没有买到辣椒而后悔的一年很划算呀，今年种辣椒是大好的一年；是打破以往记录的一年；是大家认为只要进入辣椒业就是挣钱；是多少人因为没有买到辣椒而后悔的一年',
          name: '韦记辣椒厂',
          data: '2017-10-01 18:00',
        },
      ],
      marketList: [],
      isdel: false,
      fenye:[],
      shareId:'',
    }
  }
  componentDidMount() {
    this.marketList()
    this.pagination()
    if (cookie.load('lj-pc-userInfo')) {
      let memberId = cookie.load('lj-pc-userInfo').memberId
    } else {
      this.props.history.push('/login')
    }
  }
  marketList() {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/market/share/myList', {
      memberId,
      pageNum: 1,
      pageSize: 10
    }).then(res => {
      ////console.log(res);
      this.setState({
        marketList: res.data
      })
    })
  }
  // /api/market/share/remove
  remove(shareId){
    ////console.log(shareId);
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post','/api/market/share/remove',{
      memberId,
      shareId
    }).then(res=>{
      ////console.log(res);
      this.marketList()
    })
  }
  //分页
  pagination = (a, b) => {
    ////console.log('@', a, b);
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/market/share/myList', {
      memberId,
      pageNum: a,
      pageSize: b,
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
         fenye: res.data,
         marketList: res.data
        })
      }
    })
  }
  showdel = (shareId) => {
    ////console.log(shareId);
    this.setState({
      isdel: true,
      shareId
    })
  };

  delOk = () => {
    this.setState({
      isdel: false
    },function () {
      const {shareId} = this.state
      ////console.log(shareId);
      this.remove(shareId)
    })
  };

  delCancel = () => {
    this.setState({
      isdel: false
    })
  };
  render() {
    const { hangqingList, marketList,fenye } = this.state
    ////console.log(fenye);
    return (
      <>
        <div className="myhangqing">
          <div className="biaoti">
            我的行情
          </div>
          <div className="hangqingList">
            {
              marketList.list && marketList.list.map(item => (
                <div className="hangqingitem" key={item.shareId}>
                  <div className="title">{item.className}</div>
                  <div className="content">{item.content}</div>
                  <div className="info">
                    <span className="name">{item.memberName}</span>
                    <span className="time">{item.createAt}</span>
                  </div>
                  <div className="itembottom">
                    <Button type="primary" onClick={()=>this.showdel(item.shareId)}>
                      删除
               </Button>
                  </div>
                </div>
              ))
            }
          </div>
          <Pagination
                  pageSize='5'
                  current={fenye.pageNum}
                  onChange={this.pagination}
                  total={fenye.total}
                />
        </div>
        <Modal title="删除" visible={this.state.isdel} onOk={this.delOk} onCancel={this.delCancel}>
          确认删除
      </Modal>
      </>
    )
  }
}