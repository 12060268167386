import React, { Component } from 'react';
import './index.scss';
import city from '../../../../../static/js/city/city'
import { Form, Input,  Select, Cascader,Modal,Upload } from 'antd';
import request from '../../../../../static/js/http'
const { Option } = Select;
const FormItem = Form.Item;
const areas = [
  { label: '精品', value: '精品' },
  { label: '优质', value: '优质' },
  { label: '通货', value: '通货' },
  { label: '花皮', value: '花皮' },
];
const huopindengji = [
  { label: '精品', value: '精品' },
  { label: '优质', value: '优质' },
  { label: '通货', value: '通货' },
  { label: '花皮', value: '花皮' },
];

class Gongyingdating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: city,
      // shouhuodi:city,
      purchasePrice: '',
      // purchaseNum: '',
      purchaseClass: '',
      purchaseList: [],
      purchaseLevel: '',
      fromword: '',
      conent: '',
      title: '',
      previewVisible: false,
      previewImage: '',
      imgList: [],
      imagesPath: [],

    }
    this.inputRef = React.createRef();
    this.child = React.createRef();

  }
  componentDidMount() {
    this.purchaseList()
    ////console.log(this.child);

  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.purchaseClass !== nextState.purchaseClass) {
      return false
    }
    if (this.state.purchaseLevel !== nextState.purchaseLevel) {
      return false
    }
    if (this.state.purchasePrice !== nextState.purchasePrice) {
      return false
    }
    if (this.state.imagesPath !== nextState.imagesPath) {
      return false
    }
    if (this.state.fromword !== nextState.fromword) {
      return false
    }
    if (this.state.conent !== nextState.conent) {
      return false
    }
    if (this.state.title !== nextState.title) {
      return false
    }
    if (this.state.go !== nextState.go) {
      return false
    }
    return true;
  }
  city(e) {

    this.setState({
      fromword: e.join("")
    }, function () {
      ////console.log(this.state.fromword);
    })
  }
  shouhuodi(e) {
    this.setState({
      go: e.join("")
    }, function () {
      ////console.log(this.state.go);
    })
  }
  purchaseNum = (e) => {
    ////console.log(e.target.value);
    const purchaseNum = e.target.value;
    this.setState({
      purchaseNum
    })
  }
  title = (e) => {
    ////console.log(e.target.value);
    const title = e.target.value
    this.setState({
      title
    })
  }
  purchasePrice = (e) => {
    ////console.log(e.target.value);
    const purchasePrice = e.target.value;
    this.setState({
      purchasePrice
    })
  }
  purchaseList = () => {
    request('post', '/api/goods/class/sList', {
      classId: 1
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        ////console.log(res.data);
        this.setState({
          purchaseList: res.data
        })
      }
    })
  }
  handleChange = (e) => {
    ////console.log(e);
    setTimeout(() => {
      this.setState({
        purchaseClass: e
      }, function () {
        ////console.log(this.state.purchaseClass);
      })
    }, 0);
  };
  onFinish = values => {
    ////console.log('Received values of form:', values);
  };
  textareatxt = (e) => {
    ////console.log(e.target.value);
    this.setState({
      conent: e.target.value
    })
  }
  /////////////////////////////////////////////////////////////////////////////
  //上传图片
  uploadhandleChange = ({ file, fileList }) => {
    fileList.forEach(imgItem => {
      if (imgItem && imgItem.status == 'done' && imgItem.response && imgItem.response.imgUrl) {
        imgItem.thumbUrl = imgItem.response.imgUrl;
      }
    });

    if (file.status === 'done') {
      let filesList = []
      fileList.map(item => {
        filesList.push(item.response.data)
      })
      ////console.log(filesList);
      setTimeout(() => {
        this.setState({
          imagesPath: filesList
        })
      }, 0);
    }
    setTimeout(() => {
      this.setState({
        imgList: fileList,
      });
    }, 0);
  };
  handleCancel = () => setTimeout(() => {
    this.setState({ previewVisible: false })
  }, 0);;

  handlePreview = file => {
    setTimeout(() => {
      this.setState({
        previewImage: file.url || file.thumbUrl,
        previewVisible: true,
      });
    }, 0);
  };

  handleBeforeUpload = file => {
    //限制图片 格式、size、分辨率
    const isJPG = file.type === 'image/jpeg';
    const isJPEG = file.type === 'image/jpeg';
    const isGIF = file.type === 'image/gif';
    const isPNG = file.type === 'image/png';
    if (!(isJPG || isJPEG || isGIF || isPNG)) {
      Modal.error({
        title: '只能上传JPG 、JPEG 、GIF、 PNG格式的图片~',
      });
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      Modal.error({
        title: '超过2M限制，不允许上传~',
      });
      return;
    }
    return (isJPG || isJPEG || isGIF || isPNG) && isLt2M && this.checkImageWH(file);
  };

  //返回一个 promise：检测通过则返回resolve；失败则返回reject，并阻止图片上传
  checkImageWH(file) {
    let self = this;
    return new Promise(function (resolve, reject) {
      let filereader = new FileReader();
      filereader.onload = e => {
        let src = e.target.result;
        const image = new Image();
        image.onload = function () {
          // 获取图片的宽高，并存放到file对象中
          ////console.log('file width :' + this.width);
          ////console.log('file height :' + this.height);
          file.width = this.width;
          file.height = this.height;
          resolve();
        };
        image.onerror = reject;
        image.src = src;
      };
      filereader.readAsDataURL(file);
    });
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props;
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {// values 是form表单里的参数
      // 点击按钮后，将表单提交给后台
      dispatch({
        type: 'mymodel/submitFormData',
        payload: values,
      });
    });
  };

  render() {
    const { previewVisible, previewImage, imgList, purchaseList } = this.state; //  从 state 中拿数据

    const handleChange = (e) => {
      ////console.log(e);
      this.setState({
        purchaseLevel: e
      })

    };
    const uploadButton = (
      <div>
        <div className="ant-upload-text" style={{ fontSize: '20px' }} >上传图片</div>
      </div>
    );
    return (
      <>
        <div className="gongying">
          <Form name="nest-messages" onFinish={this.onFinish}>
            <Form.Item
              name={['biaoti']}
              label="标题"
            >
              <Input onChange={(e) => this.title(e)} ref={this.inputRef} />
            </Form.Item>
          </Form>
          <Form name="control-ref" onFinish={this.onFinish}>
            <Form.Item label="* 供应品种" rules={[{ required: true, message: '请选择品种' }]}>
              <Select
                placeholder="请选择供应的货品"
                onChange={(e) => this.handleChange(e)}
                allowClear
              >
                {
                  purchaseList.map((item,index) => {
                    return <Option key={index} value={item.className}>{item.className}</Option>
                  })
                }
              </Select>
            </Form.Item>
          </Form>

          <Form name="nest-messages" onFinish={this.onFinish}>
            <Form.Item
              name={['jiage']}
              label="供应价格"
            >
              <Input type="number" onChange={(e) => this.purchasePrice(e)} ref={this.inputRef} />
            </Form.Item>
          </Form>
          <Form name="dynamic_form_nest_item" onFinish={this.onFinish} autoComplete="off">
            <Form.Item name="huopindengji" label="商品等级" rules={[{ message: 'Missing area' }]}>
              <Select options={huopindengji} onChange={handleChange} />
            </Form.Item>
          </Form>
          <div className="qiwanghuoyuan">
            <span className="qiwangspan">收货地：</span>
            <Cascader options={this.state.options} onChange={(e) => this.shouhuodi(e)} placeholder="请选择收货地" />
          </div>
          <div className="qiwanghuoyuan">
            <span className="qiwangspan">期望货源地：</span>
            <Cascader options={this.state.options} onChange={(e) => this.city(e)} placeholder="请选择期望货源地" />
          </div>
          <div className="upLoadPic">
            <div className="uploadTxt">上传图片：</div>
            <Form onSubmit={this.handleSubmit} hideRequiredMark style={{ marginTop: 8 }}>
              <FormItem>
                <Upload
                  action="http://1.116.154.35:25921/clife-boot-api-app/api/cms/common/upload" // 这个是图片上传的接口请求，实际开发中，要替换成你自己的业务接口
                  listType="picture-card"
                  fileList={this.state.imgList}
                  onPreview={this.handlePreview} // 点击图片缩略图，进行预览
                  beforeUpload={this.handleBeforeUpload} // 上传之前，对图片的格式做校验，并获取图片的宽高
                  onChange={this.uploadhandleChange} // 每次上传图片时，都会触发这个方法
                >
                  {this.state.imgList.length >= 6 ? null : uploadButton}

                </Upload>
              </FormItem>
            </Form>
            <div />
            <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
          </div>
          <div className="explain">
            <span className="spantext">内容：</span>
            <textarea maxLength="300" onChange={(e) => this.textareatxt(e)} placeholder="请输入说明内容"></textarea>
          </div>
        </div>
      </>
    )

  }

}
export default Gongyingdating