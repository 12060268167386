import React, { Component } from 'react';
import './index.scss';
import Gongying from './Pushsupply'
import geren from '../../../../static/img/geren.png'
import qiye from '../../../../static/img/qiye1.png'
import request from '../../../../static/js/http'
import city from '../../../../static/js/city/city'
import cookie from 'react-cookies';
import { withRouter } from 'react-router-dom';
import { Select, Button, message, Cascader,  Modal, Pagination } from 'antd';
const { Option } = Select;
class Gongyingdating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: city,
      supplyList: [],         //供应列表
      fenye: '',              //分页
      isModalVisible: false,
      varietyList: [], //品种列表
      varietyName: '',  //品种名字
      lvName: '',       //等级名字
      fromword: '',     //期望货源地
      go: '',     //期望收货地
      min: '',
      max: '',
    }
    this.child = React.createRef();
  }

  componentDidMount() {
    this.getList()
    this.varietyList()

  }
  //列表
  getList() {
    request('post', '/api/apply/supply/list', {
      pageSize: 10,
      pageNum: 1,
      statu: '0'
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          supplyList: res.data.list,
          fenye: res.data
        })
      }
    })
  }

  //发布供应
  fabu = () => {
    if (!cookie.load('lj-pc-userInfo')) {
      message.warning('请先登录')
    } else {
      if (this.child.current.state.title == '') {
        message.warning('请输入标题')
      } else if (this.child.current.state.purchaseClass == "") {
        message.warning('请输入供应品种')
      } else if (this.child.current.state.purchasePrice == "") {
        message.warning('请输入价格')
      } else if (this.child.current.state.purchaseLevel == "") {
        message.warning('请输入商品等级')
      } else if (this.child.current.state.go == "") {
        message.warning('请输入收货地')
      } else if (this.child.current.state.fromword == "") {
        message.warning('请输入期望货源地')
      } else if (this.child.current.state.imagesPath == "") {
        message.warning('请上传图片')
      } else if (this.child.current.state.conent == "") {
        message.warning('请输入内容')
      } else {
        let memberId = cookie.load('lj-pc-userInfo').memberId
        let title = this.child.current.state.title
        request('post', '/api/apply/supply/insertTbStorageLease', {
          memberId,
          title,
          goodclass: this.child.current.state.purchaseClass,
          level: this.child.current.state.purchaseLevel,
          supperPrice: this.child.current.state.purchasePrice,
          fromword: this.child.current.state.fromword,
          go: this.child.current.state.go,
          imagesPath: this.child.current.state.imagesPath,
          conment: this.child.current.state.conent,
          statu: '0'
        }).then(res => {
          this.getList()
          if (res.code == 0) {
            message.success(res.msg);
            this.setState({
              isModalVisible: false
            })
          } else {
            message.warning(res.msg)
          }
        })
      }
    }
  }
  priceChange(e, name) {
    this.setState({
      [name]: e.target.value
    })
  }

  priceOk() {
    const { min, max } = this.state
    request('post', '/api/apply/supply/list', {
      pageSize: 10,
      pageNum: 1,
      min,
      max,
      statu: '0'
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          supplyList: res.data.list,
          fenye: res.data
        })
      }
    })
  }
  //品种下拉
  pinzhongmenu(e) {
    ////console.log('click', e);
    this.setState({
      varietyName: e
    }, function () {
      this.variety()
    })
  }

  //等级下拉
  dengjimenu(e) {
    ////console.log('click', e);
    this.setState({
      lvName: e
    }, function () {
      this.lvxuanze()
    })
  }
  handleButtonClick(e) {
    message.info('Click on left button.');
    ////console.log('click left button', e);
  }
  handleMenuClick(e) {
    ////console.log('click', e);
  }
  showModal = () => {
    this.setState({
      isModalVisible: true
    })
  };

  handleOk = () => {
    this.fabu()
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false
    })
  };

  //品种下拉
  varietyList() {
    request('post', '/api/goods/class/sList', {
      classId: 1,
    }).then(res => {
      ////console.log(res);
      this.setState({
        varietyList: res.data
      })
    })
  }

  //品种选择
  variety() {
    const { varietyName } = this.state
    ////console.log(varietyName);
    request('post', '/api/apply/supply/list', {
      pageSize: 10,
      pageNum: 1,
      goodclass: this.state.varietyName
    }).then(res => {
      //console.log(res);
      if (res.code === 0) {
        this.setState({
          supplyList: res.data.list,
          fenye: res.data
        })
      }
    })
  }
  //等级选择
  lvxuanze() {
    const { lvName } = this.state
    ////console.log(lvName);
    request('post', '/api/apply/supply/list', {
      pageSize: 10,
      pageNum: 1,
      level: this.state.lvName
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          supplyList: res.data.list,
          fenye: res.data
        })
      }
    })
  }
  //期望地
  qiwangdi() {
    const { fromword } = this.state
    request('post', '/api/apply/supply/list', {
      pageSize: 10,
      pageNum: 1,
      fromword
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          supplyList: res.data.list,
          fenye: res.data
        })
      }
    })
  }
  //收货地
  shouhuo() {
    const { go } = this.state
    request('post', '/api/apply/supply/list', {
      pageSize: 10,
      pageNum: 1,
      go
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          supplyList: res.data.list,
          fenye: res.data
        })
      }
    })
  }



  //期望货源地
  city(e) {
    ////console.log(e);
    this.setState({
      fromword: e.join("")
    }, function () {
      ////console.log(this.state.fromword);
      this.qiwangdi()
    })
  }

  //期望收货地
  go(e) {
    this.setState({
      go: e.join("")
    }, function () {
      ////console.log(this.state.go);
      this.shouhuo()
    })
  }


  //切换分页
  pagination = (a, b) => {
    ////console.log(a, b);
    request('post', '/api/apply/supply/list', {
      pageSize: b,
      pageNum: a,
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          supplyList: res.data.list,
          fenye: res.data
        })
      }
    })
  }
  xiangqing(id) {
    this.props.history.push({ pathname: '/home/gongyingxiangqing', state: { id } })
  }
  render() {
    const { supplyList, fenye, varietyList, varietyName, lvName } = this.state
    // const pinzhongmenu = (
    //   <Menu onClick={(e) => this.pinzhongmenu(e)}>
    //     {
    //       varietyList.map(item => {
    //         return <Menu.Item key={item.classId}>
    //           {item.className}
    //         </Menu.Item>
    //       })
    //     }
    //   </Menu>
    // );

    // const dengjimenu = (


    //   // <Menu onClick={(e) => this.dengjimenu(e)} allowClear>
    //   //   <Menu.Item key='1'>
    //   //     全部
    //   //   </Menu.Item>
    //   //   <Menu.Item key='2'>
    //   //     精品
    //   //   </Menu.Item>
    //   //   <Menu.Item key='3'>
    //   //     优质
    //   //   </Menu.Item>
    //   //   <Menu.Item key='4'>
    //   //     通货
    //   //   </Menu.Item>
    //   //   <Menu.Item key='5'>
    //   //     花皮
    //   //   </Menu.Item>
    //   // </Menu>
    // );
    return (
      <>
        <div className="gongyingdating">
          <div className="xuanzelanW">
            <div className="xuanzelan">
              <div className="xuanzeleft">
                <div className="pz">
                  <span>品种</span>
                  <Select style={{ width: 120 }} onChange={(e) => this.pinzhongmenu(e)} allowClear>
                    {
                      varietyList.map(item => {
                        return <Option key={item.classId} value={item.className}>{item.className}</Option>
                      })
                    }
                  </Select>
                </div>
                <div className="lv">
                  <span>等级</span>
                  <Select style={{ width: 120 }} onChange={(e) => this.dengjimenu(e)} allowClear>
                    <Option value="精品">精品</Option>
                    <Option value="优质">优质</Option>
                    <Option value="通货">通货</Option>
                    <Option value="花皮">花皮</Option>
                  </Select>
                  {/* <Space wrap>
                    <Dropdown overlay={dengjimenu}>
                      <Button>
                        {lvName}<DownOutlined />
                      </Button>
                    </Dropdown>
                  </Space> */}
                </div>
                <div className="shouhuodi">
                  <span>收货地</span>
                  <Cascader options={this.state.options} onChange={(e) => this.go(e)} placeholder="请选择期望收货地" />
                </div>
                <div className="huoyuan">
                  <span>期望货源地</span>
                  <Cascader options={this.state.options} onChange={(e) => this.city(e)} placeholder="请选择期望货源地" />
                </div>
                <div className="jiage">
                  <span>价格</span>
                  <input type="number" name="min" onChange={(e) => this.priceChange(e, 'min')} />—
              <input type="number" name="max" onChange={(e) => this.priceChange(e, 'max')} />
                </div>
                <button onClick={() => this.priceOk()}>确定</button>
              </div>
              <div className="fabugongying">
                <Button type="primary" danger onClick={this.showModal}>
                  发布供应
              </Button>
                <Modal className="gongyingModal" title="发布供应" visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}
                  footer={[<Button key="submit" style={{
                    width: "120px",
                    height: '40px',
                    background: '#BD2C21',
                  }} onClick={this.handleOk} type="primary" >
                    确认发布
                </Button>]}>
                  <Gongying ref={this.child} />
                </Modal>
              </div>
            </div>
          </div>
          <div className="biaodan">
            <table className="listTable">
              <thead className="listThead">
                <tr className="listTr">
                  <th>供应品种</th>
                  <th>供应价格</th>
                  <th>货源地</th>
                  <th>发布人</th>
                  <th>认证情况</th>
                  <th>发布时间</th>
                  <th>详情</th>
                </tr>
              </thead>
              <tbody className="listTbody">
                {
                  supplyList.map((item) => {
                    return <tr className="TbodyTr" onClick={() => this.xiangqing(item.supperyId)} key={item.supperyId}>
                      <td>{item.goodclass}</td>
                      <td>{item.supperPrice}</td>
                      <td>{item.fromword}</td>
                      <td>{item.createby}</td>
                      <td>
                        <img src={
                          (() => {
                            switch (item.level) {
                              case "0": return geren;
                              case "1": return qiye;
                              case "2": return geren;
                              default: return geren;
                            }
                          }
                          )()}
                          alt="" />
                      </td>
                      <td>{item.createAt}</td>
                      <td className="ckxq">查看详情</td>
                    </tr>
                  })
                }
              </tbody>
            </table>

          </div>
          <div className="fenyeqi">
            <Pagination style={{ marginBottom: '57px' }} pageSize='10' current={fenye.pageNum} onChange={this.pagination} defaultCurrent={1} total={fenye.total} />
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(Gongyingdating)