import React, { Component } from 'react';
import { Input, Select, Badge } from 'antd';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { ShoppingCartOutlined, BellOutlined } from '@ant-design/icons';
import loginlogo from '../../static/img/webljLogo.jpg';
import './index.scss';
import request from '../../static/js/http';
import cookie from 'react-cookies';
const { Option } = Select;
const { Search } = Input;
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 5,
      list: [{ name: '辣椒', id: 1 }, { name: '辣椒', id: 2 }, { name: '辣椒', id: 3 }, { name: '辣椒', id: 4 }],
      tab: [
        { name: '首页', id: '1', path: '/home/index', sta: true },
        { name: '优品优选', id: '2', path: '/home/boutique', sta: false },
        { name: '乡村振兴', id: '3', path: '/home/retail', sta: false },
        { name: '撮合大厅', id: '4', path: '/home/Cuohedating', sta: false },
        { name: '期货交易', id: '5', path: '/home/futurestrading', sta: false },
        { name: '行情大厅', id: '6', path: '/home/Market', sta: false },
        { name: '行业资讯', id: '7', path: '/home/MarketConsult', sta: false },
        { name: '农资商城', id: '8', path: '/home/agriculture', sta: false },
        { name: '农技服务', id: '9', path: '/home/rcserve', sta: false },
        { name: '金融服务', id: '10', path: '/home/financial', sta: false }
      ],
      searchVal: '',
      historyList: [],
      shopNum: 0,
    };
  }
  componentDidMount() {
    this.history()
    this.shopNum()
  }
  //跳转搜索页面
  searchSkip() {
    this.props.history.push("/home/search")
  }
  history() {
    if (!cookie.load('lj-pc-userInfo')) {
      return
    } else {

      let memberId = cookie.load('lj-pc-userInfo').memberId
      request('post', '/api/goods/search/list', {
        memberId,
        type: 1,
      }).then(res => {
        this.setState({
          historyList: res.data && res.data[0].list
        })
      })
    }
  }
  onSearch = value => {
    this.setState({
      searchVal: value
    })
  }
  handleChange(value) {
    // eslint-disable-next-line no-console
  }

  sta = s => {
    const { tab } = this.state;
    const tab1 = tab.map(a => {
      a.sta = false;
      return a;
    });
    tab1[s - 1].sta = true;
    this.setState({
      tab: tab1
    });
  }
  //购物车数量
  shopNum = () => {
    if (!cookie.load('lj-pc-userInfo')) {
      request('post', '/api/member/cart/list', {
        memberId: ''
      }).then(res => {
        if (res.code === 0) {
          this.setState({
            shopList: res.data
          }, function () {
            let shopNum = 0
            this.state.shopList.map(item => {
              shopNum += item.goodsList.length
              this.setState({
                shopNum
              })
            })
          })
        }
      })
    } else {
      let userInfo = cookie.load('lj-pc-userInfo')
      request('post', '/api/member/cart/list', {
        memberId: userInfo.memberId
      }).then(res => {
        if (res.code === 0) {
          this.setState({
            shopList: res.data
          }, function () {
            let shopNum = 0
            this.state.shopList.map(item => {
              shopNum += item.goodsList.length
              this.setState({
                shopNum
              })
            })
          })
        }
      })
    }
  }
  render() {
    const { shopNum, count, historyList, tab } = this.state;
    return (
      <div id="header">
        <div className="homeToplo">
          <Link to="/home/index">
            <img className="login" alt="辣椒开放式物流 线上电子交易中心" src={loginlogo} />
          </Link>

          <div className="hometop">
            <div className="flex1">
              <div onClick={() => this.searchSkip()}>
                <Search
                  addonBefore={
                    <Select defaultValue="商品" className="select-before" onChange={this.handleChange}>
                      <Option value="商品">商品</Option>
                      <Option value="商家">商家</Option>
                    </Select>
                  }
                  placeholder="请输入搜索内容"
                  onSearch={this.onSearch}
                  enterButton
                />
              </div>
              <Link to={`/myProfile/Shoppingcat`} target="_blank">
                <div className="searchright1">
                  <Badge count={shopNum} className="svg">
                    <ShoppingCartOutlined style={{ color: '#B92115' }} />
                  </Badge>
                  <div className="one">
                    <div className="a2" >
                      <div >购物车 </div>
                      <div>查看我的购物车</div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to={`/home/news`} target="_blank">
                <div className="searchright1">
                  <Badge className="svg">
                    <BellOutlined style={{ color: '#B92115' }} />
                  </Badge>
                  <div className="one">
                    <div>消息</div>
                    <div>查看最新消息公告</div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="list">
              {
                historyList && historyList.map(a => <div key={a.id}>{a.content}</div>)
              }
            </div>
          </div>
        </div>
        <div className="tab">
          {
            tab && tab.map(a => (
              <NavLink
                to={a.path}
                key={a.id}
                activeClassName="sta"
                className="item "
              >
                {a.name}
              </NavLink>
            ))
          }
        </div>
      </div>
    );
  }
}
export default withRouter(Header)