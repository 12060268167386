import React, { Component } from 'react';
import { Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom"
import PubSub from 'pubsub-js';
import request from '../../../../static/js/http'
import './index.module.scss';
import cookie from 'react-cookies'
class Passwordlogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zhanghao: '',
      mima: '',
    };
  }
  componentDidMount() {
    // cookie.remove('lj-pc-userInfo')
    // cookie.remove('lj-pc-token')
  }
  zhanghao = (v) => {
    ////console.log(v.target.value);
    this.setState({
      zhanghao: v.target.value
    });
  }
  mima = (v) => {
    ////console.log(v.target.value);
    this.setState({
      mima: v.target.value
    });
  }
  passwordLogin = () => {
    let memberPhone = this.state.zhanghao
    let password = this.state.mima
    request('post', '/api/member/psd/login', {
      memberPhone,
      password
    }).then(res => {
      console.log(res);
      if (res.code === 0) {
        let userInfo = res.data
        let memberId = res.memberId
        let inFifteenMinute = new Date(new Date().getTime() + 168 * 3600 * 1000);//七天
        cookie.save('lj-pc-userInfo', userInfo, { expires: inFifteenMinute, path: '/' })
        if (res.data.level == 10) {
          this.props.history.push({ pathname: "/home/identity" })
        } else {
          this.props.history.push({ pathname: "/home/index" })
        }
        request('post', '/api/member/user/info', {
          memberId
        }).then(res => {
          if (res.code === 0) {
            let data = res.data
            let inFifteenMinute = new Date(new Date().getTime() + 168 * 3600 * 1000);//七天
            cookie.save('lj-webPc-data', data, { expires: inFifteenMinute, path: '/' })
            location.reload()
          }
        })
       
        // this.props.memberName(cookie.load('lj-pc-userInfo').memberName)
        // this.props.history.push({ pathname: "/home/index" })
        // window.location.replace('/home/index')

     
      } else {
        message.info(res.msg);
      }
    })
  }
  isShow(e) {
    // this.props.content(e)  
    //console.log(this.props);
  }


  render() {
    // 获取表单数据
    const onFinish = values => {
      // eslint-disable-next-line no-console
      ////console.log('Received values of form: ', values);
    };
    // 切换组件
    const a = () => {
      PubSub.publish('atguigu', { sta: false });
    };
    return (
      <>
        <Form
          name="normal_login"
          autoComplete="off"
          className="login-form"
          initialValues={{
            remember: true
          }}
          size="large"
          onFinish={onFinish}
        >
          <Form.Item

            name="username"
            rules={[
              {
                required: true,
                message: '请输入用户账号',
              }, {
                pattern: /^1\d{10}$/,
                message: '不合法的手机号格式!'
              }
            ]}
          >
            <Input
              type="number"
              onChange={v => this.zhanghao(v)}
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="请输入账号"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: '请输入用户密码!'
              }
            ]}
          >
            <Input.Password
              onChange={v => this.mima(v)}
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="请输入密码"
            />
          </Form.Item>
          <Form.Item>
            <a className="login-form-forgot" onClick={a}>
              忘记密码
            </a>
          </Form.Item>

          <Form.Item>
            <Button onClick={() => this.passwordLogin()} type="primary" htmlType="submit" className="login-form-button">
              登录
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  };
}

export default withRouter(Passwordlogin)
