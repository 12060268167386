import React, { Component } from 'react';
import { withRouter } from "react-router-dom"
import cookie from 'react-cookies'
import './index.scss';
import Xiugai from './xiugai'
import { Popconfirm, Button, Modal, Form, Input, Checkbox, Cascader ,message} from 'antd';
import request from '../../../../static/js/http';
import city from '../../../../static/js/city/city'
class Biaoge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: city,
      addressList: [],       //收获列表
      addressId: '',         //收货ID
      isxiugai: false,          //修改
      fullName: '',
      phone: '',
      address: '',       //详细地址
      province: '',      //省
      city: '',          //市
      area: '',          //区
      default1: 1,        //1-默认，0-非默认
    }
  }
  componentDidMount() {
    this.addressList()
  }
  //修改收货地址信息
  xiugai() {
    const { fullName, phone, address, province, city, area, default1, addressId } = this.state
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/member/address/change', {
      memberId,
      fullName,
      phone,
      address,
      province,
      city,
      area,
      default: default1,
      addressId
    }).then(res => {
      ////console.log(res);
      this.addressList()
      this.setState({
        isxiugai: false
      },function () {
        this.setState({
          fullName: '',
          address: '',
          province: '',
          city: '',
          area: '',
          default: ''
        })
      })
    })
  }
  //收货人
  fullName = (e) => {
    let fullName = e.target.value
    this.setState({
      fullName
    })
  }

  // 电话
  phone(e) {
    this.setState({
      phone: e.target.value
    })
    ////console.log(e.target.value);
  }

  //所在地区
  city(e) {
    if (!e[2]) {
      this.setState({
        province: e[0],
        city: e[1],
        area: ''
      })
    } else {
      this.setState({
        province: e[0],
        city: e[1],
        area: e[2]
      })
    }

  }

  //详细地址
  xiangxi(e) {
    this.setState({
      address: e.target.value
    })
    ////console.log(e.target.value);
  }
  //收货地址列表
  addressList = () => {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/member/address/list', {
      memberId,
    }).then(res => {
      ////console.log(res);
      this.setState({
        addressList: res.data
      })
    })
  }
  //谁为默认地址
  regdefault(addressId) {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/member/address/default', {
      memberId,
      addressId
    }).then(res => {
      ////console.log(res);
      this.addressList()
    })
  }
  //删除收货地址
  del(addressId) {
    ////console.log(addressId);
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/member/address/remove', {
      memberId,
      addressId
    }).then(res => {
      ////console.log(res);
      this.addressList()
    })

  }

  showxiugai = (addressId) => {
    this.setState({
      isxiugai: true,
      addressId
    })
  };

  xiugaiOk = () => {
    this.setState({
      isxiugai: false
    })
  };

  xiugaiCancel = () => {
    this.setState({
      isxiugai: false
    })
  };
  delId(addressId){
    // this.del(addressId)
    ////console.log(addressId);
    this.setState({
      addressId
    })
  }
  render() {
    let that = this
    const { addressList, addressId } = this.state
    ////console.log(addressId);
    const text = '是否确认删除';
    function confirm(e) {
      that.del(addressId)
      message.success('删除成功');
    }
    function cancel(e) {

    }
    const Moren = () => (
      <div className="moren">
        默认地址
      </div>
    )



    ////////////////////////////////////////////////////////
    const layout = {
      labelCol: {
        span: 3,
      },
      wrapperCol: {
        span: 14,
      },
    };
    const tailLayout = {
      wrapperCol: {
        offset: 2,
        span: 10,
      },
    };


    const onFinish = (values) => {
      ////console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
      ////console.log('Failed:', errorInfo);
    };




    return (
      <>
        <div className="biaoge">
          <div className="tishi">
            <span>!</span><span>已保存了{addressList.length}条收货地址</span>
          </div>
          <div className="shouhuobiaoge">
            <table className="listTable">
              <thead className="listThead">
                <tr className="listTr">
                  <th style={{ width: '144px' }}>收货人</th>
                  <th style={{ width: '174px' }}>所在地区</th>
                  <th style={{ width: '342px' }}>详细地址</th>
                  <th style={{ width: '175px' }}>电话</th>
                  <th style={{ width: '174px' }}>操作</th>
                  <th style={{ width: '179px' }}></th>
                </tr>
              </thead>
              <tbody className="listTbody">

                {
                  addressList.map(item => {
                    return <tr className="TbodyTr" key={item.addressId}>
                      <td>{item.fullName}</td>
                      <td>{item.province}{item.city}{item.area}</td>
                      <td>{item.address}</td>
                      <td>{item.phone}</td>
                      <td>
                        <Button type="primary" onClick={() => this.showxiugai(item.addressId)}>
                          修改
                        </Button>
                        <span style={{ margin: '0 10px' }}>|</span>

                        <Popconfirm
                          placement="topRight"
                          title={text}
                          onConfirm={confirm}
                          onCancel={cancel}
                          okText="确认"
                          cancelText="取消"
                        >
                            {/* <a href="#">Delete</a> */}
                          <Button onClick={()=>this.delId(item.addressId)} style={{ border: 'none' }}>删除</Button>
                        </Popconfirm></td>
                      <td onClick={() => this.regdefault(item.addressId)}>{item.defaultValue === 1 ? <Moren /> : '设为默认'}</td>
                    </tr>
                  })
                }

              </tbody>
            </table>
          </div>
        </div>
        <Modal className="xiugaiModal" footer={[]} title="修改地址" visible={this.state.isxiugai} onOk={this.xiugaiOk} onCancel={this.xiugaiCancel} >
          <div className="biaodan">
            <Form
              {...layout}
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="收货人："
                name="username"
                onChange={(e) => this.fullName(e)}
                rules={[
                  {
                    message: '请输入姓名!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="电话："
                name="phone"
                onChange={(e) => this.phone(e)}
                rules={[
                  {
                    message: '请输入手机号!',
                  },
                ]}
              >
                <Input type="number"/>
              </Form.Item>
              <div className="citydiv">
                <span>所在地区：</span>
                <Cascader className="city" options={this.state.options} onChange={(e) => this.city(e)} placeholder="请选择" />
              </div>
              <div className="xiangxi">
                <span>详细地址：</span>
                <textarea
                  onChange={(e) => this.xiangxi(e)}
                  name="" id="" cols="66" rows="4" placeholder="请填写街道、门牌号"></textarea>
              </div>
              <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                <Checkbox onChange={(e) => this.regdefault(e)}>设为默认地址</Checkbox>
              </Form.Item>


              <Form.Item {...tailLayout}>
                <Button onClick={() => this.xiugai()} type="primary">
                  确认修改
            </Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </>
    )
  }
}
export default withRouter(Biaoge)