import React, { Component } from 'react';
import './index.scss';
import Carousel1 from '../../../components/Carousel1';
import { withRouter } from 'react-router-dom';
import request from '../../../static/js/http';
import tiwen from '../../../static/img/tiwen.png'
import Upload from './upload/index'
import cookie from 'react-cookies'
import { Modal, Button, Image, Spin, Input  } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const { TextArea } = Input;
class Rcserve extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionList: [],  //问题列表
      istiwen: false,
      questionContent: '',
      isLoadingMore: false,
      pageSize: 6
    }
    this.upload = React.createRef();
  }

  componentDidMount() {
    this.getList()

  }
  loadMoreDataFn() {
    const { pageSize } = this.state
    this.setState({
      pageSize: pageSize + 6
    }, function () {
      const { pageSize } = this.state
      let memberId = cookie.load('lj-pc-userInfo').memberId
      request('post', '/api/qa/question/list', {
        memberId,
        pageNum: 1,
        pageSize
      }).then(res => {
        if (res.code === 0) {
          ////console.log(res);
          setTimeout(() => {
            this.setState({
              questionList: res.data
            })
          }, 500);

        }
      })
    })
  }
  //获取问题列表
  getList() {
    const { pageSize } = this.state
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/qa/question/list', {
      memberId,
      pageNum: 1,
      pageSize
    }).then(res => {
      if (res.code === 0) {
        ////console.log(res);
        this.setState({
          questionList: res.data
        })
      }
    })
  }
  tiwenText(e) {
    // ////console.log(this.upload.current.state.questionImg);
    ////console.log(e.target.value);
    this.setState({
      questionContent: e.target.value
    })
  }
  showTiWenModal = () => {
    this.setState({
      istiwen: true
    })
  };

  TiWenOk = () => {
    this.addSave()
    this.setState({
      istiwen: false
    })
  };

  TiWenCancel = () => {
    this.setState({
      istiwen: false
    })
  };
  addSave() {
    const { questionContent } = this.state
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/qa/question/addSave', {
      memberId,
      questionContent,
      questionImg: String(this.upload.current.state.questionImg)
    }).then(res => {
      ////console.log(res);
    })
  }
  
   load = () => {
    const { questionList } = this.state
    if (questionList.list) {
      return questionList.list.length === questionList.total ? '到底啦' : '加载更多'
    }
  }
  xiangqing(id) {
    this.props.history.push({ pathname: '/home/questiondetails', state: { id } })
  }
  render() {
    const { questionList } = this.state
    let reg = /(\[)|(\])/g

    // ////console.log(questionList.list.length);
    // let load = questionList.list.length&&questionList.list.length
    // ////console.log(load);
    return (
      <>

        <Carousel1 />
        <div>
          {/* <div className="App">
        {this.state.data.map((item, index) => (
            <li key={index} className="li-item">{item}</li>
          ))}
        </div> */}

        </div>
        <div className="rcserve">
        <div className="tiwen" onClick={() => this.showTiWenModal()}>
              <img src={tiwen} alt="" />
            </div>
          <div className="rcserveList">
            {
              questionList.list && questionList.list.map(item => {
                return <div className="item" key={item.questionId} onClick={() => this.xiangqing(item.questionId)}>
                  <div className="itemTop">
                    <div className="wen">
                      问
              </div>
                    <div className="title">
                      {item.questionContent}
                    </div>
                  </div>
                  <div className="pic">
                    {
                      item.questionImg.replace(reg, "").split(",") && item.questionImg.replace(reg, "").split(",").map((v, index) => {
                        if (v) {
                          return <Image width={70} key={index} src={v} placeholder={true} alt="" />
                        }
                      })
                    }
                  </div>
                  <div className="zhong">
                    <div className="tou">
                      <img src={item.memberAvator} alt="" />
                    </div>
                    <div className="name">
                      {item.memberName}
                    </div>
                  </div>
                  {/* <div className="content">
                    <div className="da">
                      答
                </div>
                    <div className="daan">
                      你好，可以的，广东地区辣椒即可春播也可秋播。春播早熟种一般
                      1月份播种，晚熟品种一般2-3月播种，秋冬栽培可以在7 - 11月份
                      播种育苗。
                </div>
                  </div> */}
                  <div className="itemBottom">
                    <div className="time">
                      （更新时间：{item.createAt}）
                </div>
                  </div>
                </div>
              })
            }


          </div>

          <div className="loadMore" ref="wrapper" onClick={() => this.loadMoreDataFn(this, this)}>
            {
                this.load()
            }
          </div>
        </div>
        <Modal title="提问" footer={[
          <Button type="primary" onClick={() => this.TiWenOk()}>发布</Button>
        ]} className="tiwenModal" visible={this.state.istiwen} onOk={this.TiWenOk} onCancel={this.TiWenCancel}>
          <div className="content">
            <span className="tiwenspan">提问：</span>
            <TextArea className='tiwentext' showCount maxLength={150} onChange={(e)=>this.tiwenText(e)} />
            {/* <span className="textNum">0/150</span> */}
          </div>
          <div className="upload">
            <div className="uploadSpan">
              <span>上传图片:</span>
              <span>(最多6张)</span>
            </div>
            <div className="uploadPic">
              <Upload ref={this.upload} />
            </div>
          </div>
        </Modal>
      </>
    )
  }
}
export default withRouter(Rcserve)


  // render() {
  //   return (
  //     <div>
  //       <div className="App">
  //         {this.state.data.map((item, index) => (
  //           <li key={index} className="li-item">{item}</li>
  //         ))}
  //       </div>
        // <div className="loadMore" ref="wrapper" onClick={this.loadMoreDataFn.bind(this, this)}>加载更多</div>
  //     </div>
  //   );








