import React, { Component } from 'react';
import {
  Input, Select, Button, Pagination, Image, message, Popconfirm,
} from 'antd';
import { LeftOutlined, LineChartOutlined, RightOutlined, FieldTimeOutlined } from '@ant-design/icons';
import lun from '../../static/img/del_home_lun.png';
import geren from '../../static/img/geren.png';
import qiye from '../../static/img/qiye.png';
import qiye1 from '../../static/img/qiye1.png';
import './index.scss';
import { Link } from 'react-router-dom';
import request from '../../static/js/http';
import cookie from 'react-cookies';
import copy from 'copy-to-clipboard';
const { Option } = Select;
export default class Store extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNum: 1,
      index: 2,
      index1: 1,
      weightTop: '',
      weightDown: '',
      shopInfo: [],
      shoucang: '收藏',
      index2: '',
      getShopGoodsList: [],
      sortType: 0,
      priceTop: 0,
      priceDown: 0,
    };
  }
  componentDidMount() {
    this.shopInfo()
    this.getShopGoodsList()
  }

  shopInfo() {
    // let memberId = cookie.load('lj-pc-userInfo').memberId
    let shopId = this.props.match.params.id
    let { index2 } = this.state
    request('post', '/api/shop/shop/info', {
      memberId: cookie.load('lj-pc-userInfo') ? cookie.load('lj-pc-userInfo').memberId : '',
      shopId,
    }).then(res => {
      ////console.log(res);
      this.setState({
        shopInfo: res.data,
      }, function () {
        if (res.data.shopCollect === 0) {
          this.setState({
            shoucang: '收藏'
          })
        } else {
          this.setState({
            shoucang: '已收藏'
          })
        }
      })
    })
  }
  //获取店铺商品列表
  getShopGoodsList() {
    const { pageNum } = this.state
    request('post', '/api/goods/goods/otherList', {
      memberId: cookie.load('lj-pc-userInfo') ? cookie.load('lj-pc-userInfo').memberId : '',
      type: 0,
      pageNum,
      pageSize: 10,
      shopId: this.props.match.params.id,
    }).then(res => {
      ////console.log(res);
      this.setState({
        getShopGoodsList: res.data
      })
    })
  }
  groupof = (e, name) => {
    this.setState({
      [name]: e.target.value
    }, function () {
      this.weight()
    });
  }
  //分页
  onChange = pageNum => {
    this.setState({
      pageNum
    }, function () {
      this.getShopGoodsList()
    });
  }
  //右上角的箭头分页
  add = () => {
    const { pageNum } = this.state;
    this.setState({
      pageNum: pageNum + 1
    }, function () {
      this.getShopGoodsList()
    });
  }

  jian = () => {
    const { pageNum } = this.state;
    this.setState({
      pageNum: pageNum - 1
    }, function () {
      this.getShopGoodsList()
    });
  }
  //点击全部
  getAll() {
    this.getShopGoodsList()
  }
  //点击销量
  sales() {
    const { sortType } = this.state
    if (sortType == 0) {
      this.setState({
        sortType: 1
      })
    } else {
      this.setState({
        sortType: 0
      })
    }
    const { pageNum } = this.state
    ////console.log(sortType);
    request('post', '/api/goods/goods/otherList', {
      memberId: cookie.load('lj-pc-userInfo') ? cookie.load('lj-pc-userInfo').memberId : '',
      type: 0,
      pageNum,
      pageSize: 10,
      sortType,
      shopId: this.props.match.params.id,
    }).then(res => {
      ////console.log(res);
      this.setState({
        getShopGoodsList: res.data
      })
    })
  }
  //价格筛选
  jiaqian() {
    ////console.log('价格筛选');
    let { priceTop, priceDown, pageNum } = this.state
    request('post', '/api/goods/goods/otherList', {
      memberId: cookie.load('lj-pc-userInfo') ? cookie.load('lj-pc-userInfo').memberId : '',
      type: 0,
      pageNum,
      pageSize: 10,
      shopId: this.props.match.params.id,
      priceTop,
      priceDown,
    }).then(res => {
      ////console.log(res);
      this.setState({
        getShopGoodsList: res.data
      })
    })
  }
  input = (e, name) => {
    // eslint-disable-next-line no-console
    ////console.log('aaa', e, name);
    this.setState({
      [name]: e.target.value
    });
  }
  //起批量
  groupof = (e, name) => {
    this.setState({
      [name]: e.target.value
    }, function () {
      this.weight()
    });
  }
  weight() {
    const { pageNum, weightTop, weightDown, } = this.state
    request('post', '/api/goods/goods/otherList', {
      memberId: cookie.load('lj-pc-userInfo') ? cookie.load('lj-pc-userInfo').memberId : '',
      type: 0,
      pageNum,
      pageSize: 10,
      weightTop,
      weightDown,
      shopId: this.props.match.params.id,
      // goodsProp: goodsProp,
    }).then(res => {
      ////console.log(res);
      this.setState({
        getShopGoodsList: res.data
      })
    })
  }
  //等级筛选
  select1 = e => {
    // eslint-disable-next-line no-console
    const { pageNum } = this.state
    request('post', '/api/goods/goods/otherList', {
      memberId: cookie.load('lj-pc-userInfo') ? cookie.load('lj-pc-userInfo').memberId : '',
      type: 0,
      pageSize: 10,
      pageNum,
      lon: 1,
      goodsProp: e,
      shopId: this.props.match.params.id,
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          getShopGoodsList: res.data
        })
      }
    })
  }
  sty = id => {
    this.setState({
      index: id
    });
  }
  sta1 = () => {
    if (!cookie.load('lj-pc-userInfo')) {
      message.warning('请先登录')
    } else {
      let memberId = cookie.load('lj-pc-userInfo').memberId
      let shopId = this.props.match.params.id
      request('post', '/api/shop/shop/collect', {
        memberId,
        shopId
      }).then(res => {
        ////console.log(res);
        if (this.state.shoucang == '收藏') {
          message.success(res.msg)
          this.setState({
            index2: res.data,
            shoucang: '收藏'
          })
        } else {
          message.success(res.msg)
          this.setState({
            index2: res.data,
            shoucang: '已收藏'
          })
        }
        this.shopInfo()
      })
    }

  }
  render() {
    const { s, index, index1, shopInfo, shoucang, getShopGoodsList, pageNum } = this.state;
    const text = shopInfo.shopPhone
    function confirm() {
      copy(text)
      message.success('复制成功，去📞吧');
    }
    return (
      <div>
        <div className="details">
          <div className="towai">
            <div className="tonei">
              <Image.PreviewGroup>
                <Image className="to1" width={100} src={shopInfo.shopLogo} alt=" " />
              </Image.PreviewGroup>
              <div className="to2">
                <div className="sheng">{shopInfo.shopName}</div>
                <div>信誉分:{shopInfo.shopStar}分</div>
                <div className="sheng">所在地区：{shopInfo.shopProvince} {shopInfo.shopCity} {shopInfo.shopArea}</div>
                <div>身份认证：{shopInfo.shopType == 0 ? <img src={geren} alt="个人" /> : (shopInfo.shopType == 1 ? <img src={qiye1} alt="企业" /> : (shopInfo.shopType == 10 ? '未认证' : <img src={geren} alt="经纪人" />))}</div>
                <div className="sheng">主营类目：{shopInfo.shopName}</div>
              </div>
              <div className="to3">
                <div>
                  <div>累计成交额</div>
                  <div>{shopInfo.shopMoney}元</div>
                </div>
                <div>
                  <div>订单</div>
                  <div>{shopInfo.allNum}单</div>
                </div>
                <div>
                  <div>信誉值</div>
                  <div>{shopInfo.shopStar}分</div>
                </div>
                <div>
                  <div>访客</div>
                  <div>{shopInfo.shopViewNum}</div>
                </div>
              </div>
              <div className="to4">
                {/* <div>聊一聊</div> */}
                <div onClick={() => this.sta1()}>{shoucang}</div>
              </div>
            </div>
          </div>
          <div className="towai2">
            <div className="nei">
              <div className={`hover ${index === 1 ? 'sta' : ''}`} onClick={() => this.sty(1)}>商品列表</div>
              <div className={`hover ${index === 2 ? 'sta' : ''}`} onClick={() => this.sty(2)}>店铺信息</div>
              <div className={`hover ${index === 3 ? 'sta' : ''}`} onClick={() => this.sty(3)}>店铺资质</div>
              <div className={`hover ${index === 4 ? 'sta' : ''}`} onClick={() => this.sty(4)}>联系方式</div>
            </div>
          </div>
        </div>
        {
          index === 1 ? (
            <div className="search">
              <div className="topwai">
                <div className="topnei">
                  <div className="o1" onClick={() => this.getAll()}>全部</div>
                  <div className="o1" onClick={() => this.sales()}>销量</div>
                  <div className="o2">
                    <span className="span">价格</span>
                    <Input type="number" onChange={e => this.input(e, 'priceTop')} style={{ width: 100, height: 35, textAlign: 'center' }} prefix={<span>￥</span>} />
                    <span className="span">-</span>
                    <Input type="number"
                      className="site-input-right"
                      onChange={e => this.input(e, 'priceDown')}
                      prefix={<span>￥</span>}
                      style={{
                        width: 100,
                        height: 35,
                        textAlign: 'center'
                      }}
                    />
                  </div>
                  <div className="o3">
                    <span className="span">起批量</span>
                    {/* defaultValue="lucy" */}
                    <Input type="number" onChange={e => this.groupof(e, 'weightTop')} style={{ width: 100, height: 35, textAlign: 'center' }} />
                    <span className="span">-</span>
                    <Input type="number" onChange={e => this.groupof(e, 'weightDown')} style={{ width: 100, height: 35, textAlign: 'center' }} />
                  </div>
                  <div className="o3">
                    <span className="span">等级</span>
                    {/* defaultValue="lucy" */}
                    <Select onChange={e => this.select1(e, 'select2')} style={{ width: 120, height: 35 }} allowClear>
                      <Option value="精品">精品</Option>
                      <Option value="优质">优质</Option>
                      <Option value="通货">通货</Option>
                      <Option value="花皮">花皮</Option>
                    </Select>
                  </div>
                  <button className="cxBtn" onClick={() => this.jiaqian()}>查询</button>
                  <div className="o4">
                    <Button type="primary" disabled={pageNum <= 1} onClick={() => this.jian()} icon={<LeftOutlined />} />
                    <span className="span1"><span>{pageNum}</span>/{getShopGoodsList.pages}</span>
                    <Button type="primary" disabled={pageNum >= getShopGoodsList.pages} onClick={() => this.add()} icon={<RightOutlined />} />
                  </div>

                </div>
              </div>
              <div className="list">
                {
                  getShopGoodsList.list && getShopGoodsList.list.map(item => (
                    <Link target="_blank" key={item.goodsId} to={'/home/details/' + item.goodsId}>
                      <div className="item111 hover">
                        <img className="img" src={item.goodsImg} alt="辣椒" />
                        <div className="jia">￥<span>{item.productPrice}</span>元/斤</div>
                        <div className="you">
                            {/* // item.recomSy == 0 ? <div className="youpin">优品优选</div> : '' */}
                            <div className="youpin">{item.recomSy ==0 ? '优品优选': '乡村振兴'}</div> 
                          <div className="name sheng">{item.goodsName}</div>
                        </div>
                        <div className="dian">
                          <img className="dianlogo" src={item.shopLogo} alt=" " />
                          <div className="dianright">
                            <div className="sheng">{item.shopName}</div>
                            <div className="sheng">河南省</div>
                          </div>
                        </div>
                        <div className="diandi">
                          <div>
                            <LineChartOutlined className="icon" />
                            <span>{item.goodsSaleNum?item.goodsSaleNum:'0'}</span>
                          </div>
                          <div>
                            <FieldTimeOutlined className="icon" />
                            <span>48小时发货</span>
                          </div>
                        </div>

                      </div >
                    </Link>

                  ))
                }
              </div>
              <div className="seardi">
                <Pagination

                  showQuickJumper
                  showSizeChanger={false}
                  current={pageNum}
                  pageSize={10}
                  onChange={(e) => this.onChange(e)}
                  defaultCurrent={1}
                  total={getShopGoodsList.total}

                />
              </div>
            </div>
          )
            : index === 2 ? (
              <div className="dianpuxixi">
                <div className="on1">
                  <div>商家介绍</div>
                  <div>
                    <div>
                      {shopInfo.shopDesc}
                    </div>
                    <div>
                      <div>企业名称： {shopInfo.authEnterprise}</div>
                      <div>企业法人： {shopInfo.authName}</div>
                      <div>机构/社会信用代码： {shopInfo.authCode}</div>
                    </div>
                  </div>
                </div>
                <div className="on2">
                  <div>基本信息</div>
                  <div>
                    <div>所在地区：{shopInfo.shopProvince} {shopInfo.shopCity} {shopInfo.shopArea}</div>
                    <div>主营类目：{shopInfo.shopName}</div>
                    <div>身份认证：{shopInfo.shopType == 0 ? <img src={geren} alt="个人" /> : (shopInfo.shopType == 1 ? <img src={qiye1} alt="企业" /> : (shopInfo.shopType == 10 ? '未认证' : <img src={geren} alt="经纪人" />))}</div>
                  </div>
                </div>
                <div className="on3">
                  <div>店铺图片</div>
                  <div>
                    <Image.PreviewGroup>
                      {[1, 2, 3, 4].map(a => <Image width={170} className="img" src={lun} key={a} />)}
                    </Image.PreviewGroup>
                  </div>
                </div>
              </div>
            ) : index === 3 ? (
              <div className="dianpuxixi">
                <div className="on3">
                  <div>店铺资质</div>
                  <div>
                    <Image.PreviewGroup>
                      <div>
                        <Image width={170} className="img" src={shopInfo.authLicense} />
                        <div className="a">营业执照</div>
                      </div>
                      <div>
                        <Image width={170} className="img" src={shopInfo.authFood} />
                        <div className="a">食品安全</div>
                      </div>
                    </Image.PreviewGroup>
                  </div>
                </div>
              </div>
            ) : index === 4 ? (
              <div className="dianpuxixi">
                <div className="on3 on4">
                  <div>联系方式</div>
                  <div>{shopInfo.authEnterprise}</div>
                  <div>联系人：{shopInfo.authName} </div>
                  <div>联系电话：
                  <Popconfirm
                      placement="topRight"
                      title={text}
                      onConfirm={confirm}
                      okText="复制"
                      cancelText="取消"
                    >
                      <div className="hover">打电话</div>
                    </Popconfirm>
                  </div>
                  <div>所在地区：{shopInfo.shopProvince} {shopInfo.shopCity} {shopInfo.shopArea}</div>
                </div>
              </div>
            ) : ''
        }
      </div>
    );
  }
}
