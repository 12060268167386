import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './index.scss';

export default class InLogin extends Component {
  render() {
    const { children } = this.props;
    //console.log(children);
    return (
      <div id="inlogin">
        <div className="tablogin">
          <div><NavLink activeClassName="atguigu" to="/login/mobile">手机号登录</NavLink></div>
          <div><NavLink activeClassName="atguigu" to="/login/password">账号密码登录</NavLink></div>
        </div>
        <div className="switch">
          {children}
        </div>
      </div>
    );
  }
}
