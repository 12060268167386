import React, { Component } from 'react';
import { Select, Row, Col } from 'antd';
import Geren from './Geren';
import Qiye from './qiye'
import Broker from './Broker'
import './index.scss';
import img1 from '../../static/img/renzeng1.png';
import img2 from '../../static/img/renzeng2.png';
import img3 from '../../static/img/renzeng3.png';
import img4 from '../../static/img/renzheng4.png';
import img5 from '../../static/img/renzheng5.png';
export default class Authentication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index1: 1,
      index2: 'jack'
    };
  }

  sty = id => {
    this.setState({
      index1: id
    });
  }
  onGenderChange = (value) => {
    this.setState({
      index2: value
    })
  };
  render() {
    const { index1, index2 } = this.state;
    return (
      <div className="authentication">
        <div className="ding">
          <div className="title">请选择认证信息并填写{index2 === 'jack' ? true : false}</div>
          <div className="biao">
            <div className="xuan">
              <div className="hover" onClick={() => this.sty(1)}>
                <img src={img1} alt="惹人" />
                <div>
                  <div>个人店铺入驻</div>
                  <div>填写个人身份审核信息</div>
                </div>
                <img src={index1 === 1 ? img5 : img4} alt="未选中" />
              </div>
              <div className="hover" onClick={() => this.sty(2)}>
                <img src={img2} alt="惹人" />
                <div>
                  <div>企业店铺入驻</div>
                  <div>填写企业认证审核信息</div>
                </div>
                <img src={index1 === 2 ? img5 : img4} alt="未选中" />
              </div>
              <div className="hover" onClick={() => this.sty(3)}>
                <img src={img3} alt="惹人" />
                <div>
                  <div>经纪人入驻</div>
                  <div>填写经纪人认证审核信息</div>
                </div>
                <img src={index1 === 3 ? img5 : img4} alt="未选中" />
              </div>
            </div>
            <div className="padding1">
              {
                index1 === 1 ? <Geren authType={this.state.index1} /> : index1 === 2 ? <Qiye />
                  : <Broker authType={this.state.index1} />
              }
            </div>
          </div>
        </div>
        <div className="tips">
          <span className='sucIcon'>
            {/* <img src={suc} alt="" /> */}
          </span>
          <span className="tipsText">
            您已成功认证
          </span>
        </div>
      </div>
    );
  }
}
