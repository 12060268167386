import React, { Component } from 'react';
import './index.scss';
import cookie from 'react-cookies';
import * as echarts from 'echarts';
import request from '../../../../static/js/http';
import city from '../../../../static/js/city/city'
import { Cascader } from 'antd';
class Pricetrend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeIndex: 0,
      currentIndex: '',
      type: 1,
      marketList: [],
      options: city,
      areaType: "2",
      areaCode: '000000'
    }
  }
  componentDidMount() {
    this.market()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentIndex } = this.state
    if (currentIndex !== nextProps.currentIndex) {
      this.setState({
        currentIndex: nextProps.currentIndex,
      }, function () {
        this.market()
        //console.log(currentIndex);
      })
    }
  }

  market() {
    const { type, currentIndex, areaType, areaCode } = this.state
    request('post', '/api/market/dock/getPricesInfo', {
      memberId: cookie.load('lj-pc-userInfo') ? cookie.load('lj-pc-userInfo').memberId : '',
      productId: currentIndex + '',
      historyType: type,
      areaType,
      areaCode
    }).then(res => {
      console.log(res);
      this.setState({
        marketList: res.data
      }, function () {
        this.zhexian()
        this.props.onClick(this.state.type)
        this.props.areaType(areaType)
        this.props.areaCode(areaCode)
      })
    })
  }
  city = (value, code) => {
    if (value == '' || code == '') {
      this.setState({
        areaType: "2",
        areaCode: '000000'
      }, function (params) {
        this.market()
      })
    } else {
      this.setState({
        areaType: value.length + 1,
        areaCode: code[code.length - 1].code
      }, function (params) {
        this.market()

      })
    }
  }
  zhexian = () => {
    const { marketList } = this.state
    //console.log(marketList);
    let dateStr = []
    let contryAvgPrice = []
    if (marketList) {
      //console.log(marketList);
      marketList.contryWeekPrice.map(item => {
        //console.log(item);
        dateStr.push(item.dateStr)
        contryAvgPrice.push(item.contryAvgPrice)
        // return item
      })
    }
    var chartDom = document.getElementById('main');
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: dateStr
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        data: contryAvgPrice,
        type: 'line',
        areaStyle: {}
      }],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      }
    };
    option && myChart.setOption(option)

  }
  render() {
    const { marketList } = this.state

    return (
      <>
        <div className="pricetrend">
          <div className="pricetrendTop">
            <div className="left">
              <div className="red"></div>
              <div className="title">
                价格趋势
              </div>
              <Cascader style={{ marginLeft: 23 }} className="city" options={this.state.options} onChange={this.city} placeholder="全国" />

            </div>
          </div>
          <div className="xinxi">
            <div className="xinxiitem">
              <div className="data">
                <span>均价</span>
              </div>
              <div className="price">
                <span>{marketList && marketList.contryAvgPrice}</span>元/斤
              </div>
              <div className="growth">
                <span>同比增长:{marketList && marketList.contryAvgPriceGrowth}%</span>
              </div>
            </div>
            {/* <div className="xinxiitem">
              <div className="data">
                <span>最低</span>
              </div>
              <div className="price">
                <span>{marketList.minPrice}</span>元/斤
              </div>
            </div> */}
            <div className="xinxiitem">
              <div className="data">
                <span>最高价</span>
              </div>
              <div className="price">
                <span>{marketList && marketList.contryMaxPrice}</span>元/斤
              </div>
              <div className="growth">
                <span>同比增长:{marketList && marketList.contryMaxPriceGrowth}%</span>
              </div>
            </div>
          </div>
          {/* <p className="content">数值描述法：直接用价格增长为描述对象，绘制价格—时间曲线。数值描述法可以直观地描述价格的变动趋势，但是由于各种商品的价格相差较大，且数据量很大，无法体现商品格变化的整体规律。</p> */}
          <div id="main" style={{ width: 1400, height: 400 }}></div>
        </div>
      </>
    )
  }
}
export default Pricetrend