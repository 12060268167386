import React, { Component } from 'react';
import { Select, Row, Col } from 'antd';
import Geren from './Geren';
import Qiye from './qiye'
import Broker from './Broker'
import './index.scss';

import cookie from 'react-cookies';
import img1 from '../../../static/img/renzeng1.png';
import img2 from '../../../static/img/renzeng2.png';
import img3 from '../../../static/img/renzeng3.png';
import img4 from '../../../static/img/renzheng4.png';
import img5 from '../../../static/img/renzheng5.png';
import suc from '../../../static/img/suc.png';
import request from '../../../static/js/http';
const { Option } = Select;
export default class Authentication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index1: 1,
      index2: 'jack',
      shopAuth: [],
      userInfo: []
    };
  }

  componentDidMount() {
    this.userInfo()

    // if (!this.state.userInfo==0) {
    //   this.props.history.push({ pathname: '/authentication' })
    // }
  }
  userInfo() {
    let userInfo = cookie.load('lj-pc-userInfo')
    request('post', '/api/member/user/info', {
      memberId: userInfo.memberId
    }).then(res => {
      //console.log(res);
      this.setState({
        userInfo: res.data,
        shopAuth: res.data.shopAuth
      })
    })
  }


  sty = id => {
    this.setState({
      index1: id
    });
  }
  onGenderChange = (value) => {
    this.setState({
      index2: value
    })
  };
  render() {
    const { index1, index2, shopAuth } = this.state;
    return (
      <div className="authentication">
        <div className="ding">
          <div className="title">我的认证信息{index2 === 'jack' ? true : false}</div>
          <div className="biao">
            <div className="xuan">
              <div className={`${shopAuth.authType == 0 ? '' : 'notclickn'}`} onClick={() => this.sty(1)}>
                <img src={img1} alt="惹人" />
                <div>
                  <div>个人店铺入驻</div>
                  <div>填写个人身份审核信息</div>
                </div>
                <img src={index1 === 1 ? img5 : img4} alt="未选中" />
              </div>
              <div className={`${shopAuth.authType == 1 ? '' : 'notclickn'}`} onClick={() => this.sty(2)}>
                <img src={img2} alt="惹人" />
                <div>
                  <div>企业店铺入驻</div>
                  <div>填写企业认证审核信息</div>
                </div>
                <img src={index1 === 2 ? img5 : img4} alt="未选中" />
              </div>
              <div className={`${shopAuth.authType == 2 ? '' : 'notclickn'}`} onClick={() => this.sty(3)}>
                <img src={img3} alt="惹人" />
                <div>
                  <div>经纪人入驻</div>
                  <div>填写经纪人认证审核信息</div>
                </div>
                <img src={index1 === 3 ? img5 : img4} alt="未选中" />
              </div>
            </div>
            <div className="padding1">
              {
                index1 === 1 ? <Geren authType={this.state.index1} /> : index1 === 2 ? <Qiye />
                  : <Broker authType={this.state.index1} />
              }
            </div>
            <div className="tips">
              <span className='sucIcon'>
                <img src={suc} alt="" />
              </span>
              <span className="tipsText">
                您已成功认证
              </span>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
