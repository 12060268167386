import React, { Component } from 'react';
import moment from 'moment'
import {
  Breadcrumb, Image, message, InputNumber, Avatar, Rate, Pagination, Modal, Popconfirm
} from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { HeartOutlined, ShareAltOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';
// import List1 from './List';
import './index.scss';
import del1 from '../../../../static/img/del_img1.png';
import del2 from '../../../../static/img/del_img2.png';
import ipo from '../../../../static/img/ipo.png';
import request from '../../../../static/js/http'
import cookie from 'react-cookies'
class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s: true,
      list: [
        { id: 0, src: del1, sty: true },
        { id: 1, src: del2, sty: false },
        { id: 2, src: del2, sty: false },
        { id: 3, src: del2, sty: false }],
      list2: [{ id: 0, name: '花皮', sty: true }, { id: 1, name: '通货', sty: false }],
      guige: '',
      index: 0, // 图片下
      sum: 1, // 商品个数
      index3: 0, // 0商品详情 1评论
      index4: 0, // 评论类别
      shopInfo: [],
      goodsProduct: [],
      pingjiaList: [],
      shoucang: '',
      fenye: '',
      imgurl: '',
      tuijian: [],
      productList: [],
      productSpec: [],
      selectArr: [], //存放被选中的值
      sku: [],
      shopItemInfo: {}, //存放要和选中的值进行匹配的数据
      subIndex: [], //是否选中 因为不确定是多规格还是单规格，所以这里定义数组来判断
      arr: [],
      hotList: [],
      pageNum: 1,
      getProductSku: [],
      address: [],
      receipt: [],
      goodReputationList: [],
      mediumReviewList: [],
      negativCommentList: [],
      allCommentList: [],
      productStock: '',
    };
  }

  img = React.createRef();
  componentDidMount() {
    this.xiangqing()
    this.pingjiaList()
    this.tuijian()
    this.hotList()
    this.goodReputation()
    this.mediumReview()
    this.negativComment()
    this.allComment()
  }
  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return;
    };
  }
  xiangqing() {
    let goodsId = this.props.match.params.id
    ////console.log(goodsId);
    if (!cookie.load('lj-pc-userInfo')) {
      let memberId = ''
      request('post', '/api/goods/goods/agrInfo', {
        goodsId,
        memberId
      }).then(res => {
        ////console.log(res);
        if (res.code === 0) {
          this.setState({
            shopInfo: res.data,
            goodsProduct: res.data.goodsProduct,
            index2: res.data.isCollect,
            productList: res.data.productList
          }, function () {
            // const { shopInfo } = this.state
            // var goodsSpec = eval(shopInfo.goodsSpec)
            // goodsSpec.map(ite => {
            //   ite.specValues.map(it => {
            //   })
            // })
            if (this.state.index2 === 0) {
              this.setState({
                shoucang: '收藏'
              })
            } else {
              this.setState({
                shoucang: '已收藏'
              })
            }
            this.specificationInfo()
          })
        }
      })
    } else {
      let goodsId = this.props.match.params.id
      let memberId = cookie.load('lj-pc-userInfo').memberId
      request('post', '/api/goods/goods/agrInfo', {
        goodsId,
        memberId
      }).then(res => {
        ////console.log(res);

        if (res.code === 0) {
          this.setState({
            shopInfo: res.data,
            goodsProduct: res.data.goodsProduct,
            index2: res.data.isCollect,
            productList: res.data.productList
          }, function () {
            this.sta()
            ////console.log(index2);
            if (this.state.index2 === 0) {
              this.setState({
                shoucang: '收藏'
              })
            } else {
              this.setState({
                shoucang: '已收藏'
              })
            }
            this.specificationInfo()
          })
        }
      })
    }
  }

  //店长推荐
  tuijian() {
    request('post', '/api/goods/goods/agrList', {
      pageNum: 1,
      pageSize: 10,
      classId: ''
    }).then(res => {
      ////console.log(res);
      this.setState({
        tuijian: res.data
      })
    })
  }

  //热销榜
  hotList() {
    request('post', '/api/goods/goods/agrList', {
      sortType: 0,
      pageNum: 1,
      pageSize: 5,
      classId: ''
    }).then(res => {
      ////console.log(res);
      this.setState({
        hotList: res.data
      })
    })
  }
  //全部评论
  pingjiaList(type) {
    let goodsId = this.props.match.params.id
    request('post', '/api/order/order/commentList', {
      goodsId,
      memberId: !cookie.load('lj-pc-userInfo') ? '' : cookie.load('lj-pc-userInfo').memberId,
      pageNum: 1,
      pageSize: 10,
      type
    }).then(res => {
      if (res.code === 0) {
        this.setState({
          pingjiaList: res.data.list,
          fenye: res.data
        })
      }
    })
  }
  //全部
  allComment() {
    let goodsId = this.props.match.params.id
    request('post', '/api/order/order/commentList', {
      goodsId,
      memberId: !cookie.load('lj-pc-userInfo') ? '' : cookie.load('lj-pc-userInfo').memberId,
      pageNum: 1,
      pageSize: 9999,
      type: 0
    }).then(res => {
      ////console.log(res);
      ////console.log(res.data.list);
      if (res.code === 0) {
        this.setState({
          allCommentList: res.data.list
        })
      }
    })
  }

  //好评
  goodReputation() {
    let goodsId = this.props.match.params.id
    request('post', '/api/order/order/commentList', {
      goodsId,
      memberId: !cookie.load('lj-pc-userInfo') ? '' : cookie.load('lj-pc-userInfo').memberId,
      pageNum: 1,
      pageSize: 9999,
      type: 3
    }).then(res => {
      if (res.code === 0) {
        this.setState({
          goodReputationList: res.data.list
        })
      }
    })
  }
  //中评
  mediumReview() {
    let goodsId = this.props.match.params.id
    request('post', '/api/order/order/commentList', {
      goodsId,
      memberId: !cookie.load('lj-pc-userInfo') ? '' : cookie.load('lj-pc-userInfo').memberId,
      pageNum: 1,
      pageSize: 9999,
      type: 2
    }).then(res => {
      if (res.code === 0) {
        this.setState({
          mediumReviewList: res.data.list
        })
      }
    })
  }
  //差评
  negativComment() {
    let goodsId = this.props.match.params.id
    request('post', '/api/order/order/commentList', {
      goodsId,
      memberId: !cookie.load('lj-pc-userInfo') ? '' : cookie.load('lj-pc-userInfo').memberId,
      pageNum: 1,
      pageSize: 10,
      type: 1
    }).then(res => {
      if (res.code === 0) {
        this.setState({
          negativCommentList: res.data.list
        })
      }
    })
  }
  pagination = (a, b) => {
    let goodsId = this.props.match.params.id
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/order/order/commentList', {
      goodsId,
      memberId,
      pageNum: a,
      pageSize: b
    }).then(res => {
      if (res.code === 0) {
        this.setState({
          pingjiaList: res.data.list,
          fenye: res.data
        })
      }
    })
  }
  addcart(id) {
    if (!cookie.load('lj-pc-userInfo')) {
      this.props.history.push("/login")
    } else {
      let memberId = cookie.load('lj-pc-userInfo').memberId
      let num = this.state.sum
      request('post', '/api/member/cart/add', {
        productId: id,
        memberId,
        num
      }).then(res => {
        if (res.code === 0) {
          Modal.success({
            content: res.msg,
          });
        } else {
          Modal.warning({
            content: res.msg,
          });
        }
      })
    }
  }

  addOrder(productId) {
    if (!cookie.load('lj-pc-userInfo')) {
      message.warning('请登录')
    } else {
      let memberId = cookie.load('lj-pc-userInfo').memberId
      let num = this.state.sum
      const { receipt, shopInfo } = this.state
      request('post', '/api/order/goods/check', {
        productId: !productId ? shopInfo.productList[0].productId : productId,
        memberId,
        num,
        addressId: receipt.addressId
      }).then(res => {
        if (res.code === 0) {
          Modal.success({
            content: res.msg,
          });
          let orderId = res.data.orderId
          let data = res.data
          this.props.history.push({ pathname: '/home/confirmorder', state: { data } })
        } else {
          Modal.warning({
            content: res.msg,
          });
        }
      })
    }
  }
  //初始化规格
  specificationInfo() {
    const { shopInfo, arr } = this.state;
    if (!shopInfo.goodsSpec) {
      return
    } else {
      var goodsSpec = eval(shopInfo.goodsSpec)
      goodsSpec.map(ite => {
        arr.push(ite.specValues[0].specName + ":" + ite.specValues[0].specValueName)
      })
      let goodsId = this.props.match.params.id
        request('post', '/api/goods/goods/getProduct', {
          
          memberId: !cookie.load('lj-pc-userInfo') ? '' : cookie.load('lj-pc-userInfo').memberId,
          goodsId,
          spec: arr.toString().replace(/,+/g, "*")
        }).then(res => {
          ////console.log(res);
          this.setState({
            getProductSku: res.data
          })
        })
    }

  }

  sta = picurl => {
    // 显示图片
    const { shopInfo, imgurl } = this.state;
    if (imgurl === '') {
      this.setState({
        imgurl: shopInfo.bannerList[0].imgurl
      })
    } else {
      this.setState({
        imgurl: picurl
      }, function () {
      })
    }
  }



  sta1 = () => {
    if (!cookie.load('lj-pc-userInfo')) {
      this.props.history.push("/login")
    } else {
      let memberId = cookie.load('lj-pc-userInfo').memberId
      let goodsId = this.props.match.params.id
      request('post', '/api/member/goods/collectOrCancel', {
        memberId,
        goodsId
      }).then(res => {
        if (res.data === 0) {
          message.success(res.msg)
          this.setState({
            index2: res.data,
            shoucang: '收藏'
          })
        } else {
          message.success(res.msg)
          this.setState({
            index2: res.data,
            shoucang: '已收藏'
          })
        }
      })
    }

  }

  sta2 = (item, specValueId) => {
    let { shopInfo } = this.state
    var goodsSpec = eval(shopInfo.goodsSpec)
    goodsSpec.map(ite => {
      ite.specValues.map(it => {
      })
    })
    this.setState({ guige: item, specValueId }, function () {
    });
  }
  specificationBtn(item, n, event, index, specValueId, specName) {
    const { selectArr, subIndex, arr, sku } = this.state
    let goodsId = this.props.match.params.id
    let memberId = cookie.load('lj-pc-userInfo').memberId
    arr.splice(n, 1, specName + ':' + item)
    arr.toString().replace(/,+/g, "*")
    request('post', '/api/goods/goods/getProduct', {
      memberId,
      goodsId,
      spec: arr.toString().replace(/,+/g, "*")
    }).then(res => {
      this.setState({
        getProductSku: res.data
      })
    })
    if (sku[n] != specName) {
      sku[n] = specName
      subIndex[n] = index;
    }
    if (selectArr[n] != item) {
      selectArr[n] = item;
      subIndex[n] = index;
    }
    this.checkItem()
    this.setState({ specValueId });
  }
  checkItem() {
    const { shopInfo, selectArr, shopItemInfo, sku, arr } = this.state
    var goodsSpec = eval(shopInfo.goodsSpec)
    var option = goodsSpec;
    var result = []; //定义数组储存被选中的值
    var skuName = []
    for (var i in option) {
      result[i] = selectArr[i] ? selectArr[i] : '';
      skuName[i] = sku[i] ? sku[i] : '';
    }
    for (var i in option) {
      var last = result[i]; //把选中的值存放到字符串last去
      var la = skuName[i]

      for (var k in option[i].specValues) {
        skuName[i] = option[i].specValues[k].specName
        result[i] = option[i].specValues[k].specValueName; //赋值，存在直接覆盖，不存在往里面添加name值

        option[i].specValues[k].isShow = this.isMay(result); //在数据里面添加字段isShow来判断是否可以选择
      }
      result[i] = last; //还原，目的是记录点下去那个值，避免下一次执行循环时被覆盖
      skuName[i] = la
    }
  }
  isMay(result) {
    for (var i in result) {
      if (result[i] == '') {
        return true; //如果数组里有为空的值，那直接返回true
      }
    }
  }
  sta3 = id => {
    this.setState({
      index3: id
    });
  }

  sta4 = type => {
    this.setState({
      index4: type
    }, function () {
      this.pingjiaList(type)
    });
  }

  fuzi = () => {
    // 把内容复制到粘贴板
    copy(window.location.href);
    message.success('复制成功，去分享给好友吧');
  }

  onChange = value => {
    this.setState({
      sum: value
    });
  }

  onChange1 = (a, b, c) => {
    // eslint-disable-next-line no-console
    ////console.log(a, b, c);
  }

  next = () => {
    const { pageNum } = this.state;
    this.setState({
      pageNum: pageNum - 1
    }, function () {
      this.xiangqing()
    });
  }

  prev = () => {
    const { pageNum } = this.state;
    this.setState({
      pageNum: pageNum + 1
    }, function () {
      this.xiangqing()
    });
  }

  render() {
    const {
      shoucang, tuijian, imgurl, index2, sum, index3, index4, shopInfo, goodsProduct, pingjiaList, fenye, hotList } = this.state;

    var goodsSpec = eval(shopInfo.goodsSpec)
    const text = shopInfo.shopPhone
    function confirm() {
      copy(text)
      message.success('复制成功，去📞吧');
    }
    return (
      <div className="agriculdetails">

        <div className="width1400">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>当前位置</Breadcrumb.Item>
            <Breadcrumb.Item>商品详情</Breadcrumb.Item>
          </Breadcrumb>
          <div className="xiangqing">
            <div className="left">
              <div className="lefttop">
                <div className="leftleft">
                  <Image
                    width={350}
                    height={350}
                    src={imgurl?imgurl:shopInfo.goodsImg}
                    preview={false}
                    placeholder={true}
                  />
                  <div className="list">
                    {
                      shopInfo.bannerList && shopInfo.bannerList.map(a => (
                        <div key={a.imageId} onClick={() => this.sta(a.imgurl)}>
                          <img
                            key={a.imageId}
                            className={`imglist hover ${a.delFlag ? 'active' : ''}`}
                            src={a.imgurl}
                            alt="aaaaa"
                          />
                        </div>
                      ))
                    }
                    <img src="" alt="" />
                  </div>
                  <div className="an">
                    <span onClick={this.sta1}>
                      <HeartOutlined style={{ color: index2 === 0 ? '#666666' : '#BD2C21' }} /><span>{shoucang}</span>
                    </span>
                    <span onClick={this.fuzi}><ShareAltOutlined /><span>分享</span></span>
                  </div>
                </div>
                <div className="leftright">
                  <div className="on1">
                    {/* <div className="margin38">优选优品</div> */}
                    <div>{shopInfo.goodsName}</div>
                  </div>
                  <div className="on2">
                    <div>
                      <div>价格:</div>
                    </div>
                    <div className="on2nei">
                      <div> ￥{this.state.getProductSku.productPrice && this.state.getProductSku.productPrice || shopInfo.allPrice}</div>
                      {/* <div>50{a.id}0斤起批</div> */}
                    </div>
                  </div>
                  <div className="on3">
                    <div className="margin38">发货地址:</div>
                    <div>
                      {shopInfo.shopAddress}
                    </div>
                  </div>
                  <div className="guige">
                    <div className="guigeList">
                      {
                        goodsSpec && goodsSpec.map((item, i) => {
                          return <div key={item.specId}>
                            <div className="guigeName">{item.specName}：
                              {
                                item.specValues && item.specValues.map((a, index) => {
                                  return <div dataId={a.specId} onClick={() => this.specificationBtn(a.specValueName, i, event, index, a.specValueId, item.specName)}
                                    key={index}
                                    className={`button hover ${a.isShow ? '' : 'active', this.state.subIndex[i] == index ? 'active' : ''}`}>
                                    {a.specValueName}
                                  </div>
                                })
                              }
                            </div>
                          </  div>
                        })
                      }
                    </div>
                  </div>
                  <div className="on4">
                  </div>
                  <div className="on5">
                    <div className="margin38">数量:</div>
                    <div>
                      {/* max={100} */}
                      <InputNumber
                        defaultValue={1}
                        min={1}
                        formatter={value => `${value}`}
                        parser={value => value.replace('', '')}
                        onChange={this.onChange}
                      />
                      <div>（总重：{sum * goodsProduct.productWeight})</div>
                    </div>
                  </div>
                  <div className="on6">
                    <div className="hover" onClick={() => this.addcart(goodsProduct.productId)}>加入购物车</div>
                    <div className="hover" onClick={() => this.addOrder(goodsProduct.productId)}>立即采购</div>
                  </div>
                  <Popconfirm
                    placement="topRight"
                    title={text}
                    onConfirm={confirm}
                    okText="复制"
                    cancelText="取消"
                  >
                    <img className="on7 hover" src={ipo} alt="打电话" />
                  </Popconfirm>
                </div>
              </div>
              <div className="leftbottom">
                <div className="title">店长推荐</div>
                <div className="list">
                  {
                    tuijian.list && tuijian.list.map(item => {
                      return <Link target="_blank" key={item.goodsId} to={'/home/agriculdetails/' + item.goodsId}>
                        <div className="item ">
                          <img className="hover" src={item.goodsImg} alt="" />
                          <div>
                            <div>￥{item.productPrice}元</div>
                            <div>成交量{item.goodsSaleNum?item.goodsSaleNum:'0'}</div>
                          </div>
                        </div>
                      </Link>
                    })
                  }
                </div>
              </div>
            </div>
            <div className="right">
              <div className="title">热销榜</div>
              {
                hotList.list && hotList.list.map(item => (
                  <Link target="_blank" key={item.goodsId} to={'/home/agriculdetails/' + item.goodsId}>
                    <div className="list hover">
                      <div>
                        <img src={item.goodsImg} alt="农资" />
                        <div className="jia">￥{item.productPrice}</div>
                      </div>
                      <div>{item.goodsName}</div>
                    </div>
                  </Link>
                ))
              }
            </div>
          </div>
        </div>
        <div className="width1400 bo">
          <div className="left1">
            <div className="title">商家推荐</div>
            <div className="list">
              {
                tuijian.list && tuijian.list.map(item => {
                  return <Link target="_blank" key={item.goodsId} to={'/home/agriculdetails/' + item.goodsId}>
                    <div className="item ">
                      <img className="hover" src={item.goodsImg} alt="" />
                      <div>
                        <div>{item.goodsName}</div>
                        <div>￥{item.productPrice}</div>
                      </div>
                    </div>
                  </Link>
                })
              }
            </div>
          </div>
          <div className="right1">
            <div className="top">
              <div onClick={() => this.sta3(0)} className={index3 === 0 ? 'sta' : ''}>商品介绍</div>
              <div onClick={() => this.sta3(1)} className={index3 === 1 ? 'sta' : ''}>评价({this.state.allCommentList.length})</div>
            </div>
            {index3 === 0 ? (
              <div className="xiang">
                {shopInfo.detailImgList && shopInfo.detailImgList.map(a => (
                  <img className="img" key={a.imageId} src={a.imgurl} alt={a} />
                ))}
              </div>
            )
              : (
                <div className="pinglun">
                  <div className="sty">
                    <div onClick={() => this.sta4(0)} className={`hover ${index4 === 0 ? 'sta' : ''}`}>全部（{this.state.allCommentList.length}）</div>
                    <div onClick={() => this.sta4(3)} className={`hover ${index4 === 3 ? 'sta' : ''}`}>好评（{this.state.goodReputationList.length}）</div>
                    <div onClick={() => this.sta4(2)} className={`hover ${index4 === 2 ? 'sta' : ''}`}>中评（{this.state.mediumReviewList.length}）</div>
                    <div onClick={() => this.sta4(1)} className={`hover ${index4 === 1 ? 'sta' : ''}`}>差评（{this.state.negativCommentList.length}）</div>
                  </div>
                  <div className="list">
                    {
                      pingjiaList.map(a => (
                        <div className="item" key={a.commentId}>
                          <Avatar
                            className="img"
                            size={50}
                            src={a.memberAvator}
                          />
                          <div className="right2">
                            <div className="q1">{a.memberName}</div>
                            <div className="q2">
                              <Rate disabled allowHalf defaultValue={a.star} />
                              <span className="fen">{a.star}分</span>
                            </div>
                            <div className="q3" dangerouslySetInnerHTML={{ __html: `${a.content}` }}></div>
                            <div className="q4">
                              <Image.PreviewGroup>
                                {
                                  a.commentImg && a.commentImg.split(",").map((pic, index) => {
                                    return <Image
                                      key={index}
                                      width={60}
                                      height={60}
                                      src={pic}
                                    />
                                  })
                                }
                              </Image.PreviewGroup>
                            </div>
                            <div className="q5">{moment(a.createAt).format('YYYY-MM-DD')}</div>
                          </div>
                        </div>
                      ))
                    }
                    <Pagination defaultCurrent={1} current={fenye.pageNum} onChange={this.pagination} total={fenye.total} />
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Details)